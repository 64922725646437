export default [
    { id: '4be34a1d-90c2-41a1-a1ec-335bbd575fbc', name: 'BeyondBlue' },
    { id: 'e6ec0603-d628-4383-b28f-11496b3ae243', name: 'LifeLine' },
    { id: '5f615988-03bc-4d70-9627-5a24787d7d5f', name: 'BlackDog' },
    { id: 'ebf49acf-b7f7-42eb-b9b1-7967b6a129a6', name: 'ReachOut' },
    { id: '2984e0ac-b504-4c6f-b6bb-033ef2a32098', name: 'MensLine' },
    { id: '075782ea-d155-4cba-b408-7fd50858c2b7', name: 'Orygen' },
    { id: 'dc1e9d00-bec9-4a0e-aaf2-7bba2814e090', name: 'KidsHelpline' },
    { id: '616223fb-aedd-46bd-82dd-abe9ac201eee', name: 'Qlife' },
    { id: 'b1743a06-99fe-4e3a-87fd-0e0e9007fa44', name: 'MindHealthConnect' },
];