import React from "react";
import { Box, MenuItem, Select } from "@material-ui/core";
import { times } from "ramda";
import { useNotify } from "react-admin";

import { Story, useSaveStoryOrderMutation } from "../../../types/generated/gql";

interface Props {
  record: Story;
  onOpen: () => unknown;
  onClose: () => unknown;
}

export default function OrderDropdown({ record, onOpen, onClose }: Props) {
  const notify = useNotify();

  const [order, setOrder] = React.useState<number>(-1);

  const storyId = record.id;
  const storyOrder = record.order;

  const [saveStoryOrder] = useSaveStoryOrderMutation();

  React.useEffect(() => {
    setOrder(storyOrder || -1);
  }, [storyOrder]);

  return (
    <Box width={60}>
      <Select
        id="order-dropdown"
        value={order}
        onChange={(event) => {
          const order = event.target.value as number;

          setOrder(order);

          saveStoryOrder({
            variables: {
              id: storyId,
              order: order !== -1 ? order : null,
            },
          }).then(() => {
            if (order !== -1) {
              notify("Order for this story has been updated to " + order);
            } else {
              notify("Order for this story has been reset to Auto");
            }
          });
        }}
        onOpen={onOpen}
        onClose={onClose}
        fullWidth
      >
        <MenuItem value={-1}>&nbsp;</MenuItem>
        {times(
          (index) => (
            <MenuItem value={index + 1}>{index + 1}</MenuItem>
          ),
          10
        )}
      </Select>
    </Box>
  );
}
