import React from "react";
import {
  ArrayField,
  ArrayInput,
  BooleanInput,
  ChipField,
  Edit,
  SaveButton,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  SingleFieldList,
  TextField,
  TextInput,
  Toolbar,
  useNotify,
  useRedirect,
  useRefresh,
  useUnselectAll,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

import DeleteButton, { DeleteButtonType } from "../../components/DeleteButton";

const ROLE_CHOICES = [
  { name: "Admin", id: "admin" },
  { name: "User", id: "user" },
];

const useStyles = makeStyles({
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
});

const CustomToolbar = (props: any) => {
  const redirect = useRedirect();
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();

  return (
    <Toolbar {...props} classes={useStyles()}>
      <SaveButton />
      <DeleteButton
        type={DeleteButtonType.user}
        onDeleted={() => {
          notify("User deleted");
          redirect("/users");
          refresh();
          unselectAll("users");
        }}
        {...props}
      />
    </Toolbar>
  );
};

export const UserEdit = (props: any) => {
  return (
    <Edit {...props} undoable={false}>
      <SimpleForm toolbar={<CustomToolbar />}>
        <TextInput label="Username" source="display_name" />
        <TextField label="Email" source="account.email" />
        <BooleanInput label="Activated" source="account.active" />
        <ArrayField label="Roles" source="account.account_roles">
          <SingleFieldList>
            <ChipField source="role" />
          </SingleFieldList>
        </ArrayField>
        <ArrayInput label="Account Roles" source="account.account_roles">
          <SimpleFormIterator>
            <SelectInput label="Role" source="role" choices={ROLE_CHOICES} />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};
