import React from "react";
import moment from "moment";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useQuery } from "@apollo/client";

import { GetStoryDocument, Story } from "../../../types/generated/gql";
import { client } from "../../../dataProvider";

export const NotesTable = (props: any) => {
  const { record } = props;

  const { data: storyData } = useQuery(GetStoryDocument, {
    client,
    variables: {
      StoryId: record.id,
    },
  });

  const story = storyData && (storyData.story[0] as Story);

  // Note: Fetch again to enable auto refresh whenever a new approval moderator note is added

  return story?.moderator_notes?.length > 0 ? (
    <Box mt={2} mb={2}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell align="right">Action</TableCell>
              <TableCell align="right">Moderator</TableCell>
              <TableCell align="right">Note</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {story?.moderator_notes?.map((note: any) => (
              <TableRow key={note.id}>
                <TableCell component="th" scope="row">
                  {note.created_at && moment(note.created_at).format("l")}
                </TableCell>
                <TableCell align="right">{note.action}</TableCell>
                <TableCell align="right">{note.user.display_name}</TableCell>
                <TableCell align="right">{note.note}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  ) : (
    <div />
  );
};
