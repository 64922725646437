import React from "react";
import {
  Datagrid,
  DateField,
  List,
  TextField,
  useNotify,
  useRedirect,
  useRefresh,
  useUnselectAll,
} from "react-admin";
import { Drawer } from "@material-ui/core";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import { push } from "react-router-redux";

import DrawerContent from "../../components/DrawerContent";
import BulkDeleteButton, {
  DeleteButtonType,
} from "../../components/BulkDeleteButton";
import { FeedbackCreate } from "./feedback.create";

const BulkActionButtons = (props: any) => {
  const redirect = useRedirect();
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();

  return (
    <React.Fragment>
      <BulkDeleteButton
        type={DeleteButtonType.feedback}
        onDeleted={() => {
          notify("Feedbacks deleted");
          redirect("/feedbacks");
          refresh();
          unselectAll("feedbacks");
        }}
        {...props}
      />
    </React.Fragment>
  );
};
export const _FeedbackList = (props: any) => {
  const refresh = useRefresh();

  return (
    <React.Fragment>
      <List
        {...props}
        sort={{ field: "created_at", order: "DESC" }}
        bulkActionButtons={<BulkActionButtons />}
      >
        <Datagrid rowClick="edit">
          <DateField label="Date Added" source="created_at" />
          <TextField label="First Name" source="user.first_name" />
          <TextField label="Email" source="user.account.email" />
        </Datagrid>
      </List>
      <Route path={"/feedbacks/:id"}>
        {({ match }) => {
          return (
            <Drawer
              open={!!match}
              anchor="right"
              onClose={() => {
                props.push("/feedbacks");
                refresh();
              }}
            >
              {match && (
                <DrawerContent
                  onClose={() => {
                    props.push("/feedbacks");
                    refresh();
                  }}
                >
                  <FeedbackCreate
                    id={match.params.id}
                    onCancel={() => {
                      props.push("/feedbacks");
                      refresh();
                    }}
                    {...props}
                  />
                </DrawerContent>
              )}
            </Drawer>
          );
        }}
      </Route>
    </React.Fragment>
  );
};

export const FeedbackList = connect(undefined, { push })(_FeedbackList);
