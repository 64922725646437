import LibraryBooks from "@material-ui/icons/LibraryBooks";
import React from "react";
import { CardHeader, List, ListItem, ListItemText } from "@material-ui/core";

import Card from "../../../components/Card";
import { useStoryCountQuery } from "../../../types/generated/gql";

function getCount<O extends ReturnType<typeof useStoryCountQuery>>(obj: O) {
  return obj.data?.story_aggregate?.aggregate?.count;
}

export default function Stories() {
  const draftCount =
    getCount(useStoryCountQuery({ variables: { status: "DRAFT" } })) || 0;
  const submittedCount =
    getCount(useStoryCountQuery({ variables: { status: "SUBMITTED" } })) || 0;
  const approvedCount =
    getCount(useStoryCountQuery({ variables: { status: "APPROVED" } })) || 0;
  const publishedCount =
    getCount(useStoryCountQuery({ variables: { status: "PUBLISHED" } })) || 0;
  const pausedCount =
    getCount(useStoryCountQuery({ variables: { status: "PAUSED" } })) || 0;
  const quarantinedCount =
    getCount(useStoryCountQuery({ variables: { status: "QUARANTINED" } })) || 0;

  const total =
    draftCount +
    submittedCount +
    approvedCount +
    publishedCount +
    pausedCount +
    quarantinedCount;

  return (
    <Card>
      <CardHeader
        avatar={<LibraryBooks />}
        title="Stories"
        subheader={`total: ${total}`}
      />
      <List dense>
        <ListItem>
          <ListItemText primary="Draft" secondary={draftCount} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Submitted" secondary={submittedCount} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Approved" secondary={approvedCount} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Published" secondary={publishedCount} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Paused" secondary={pausedCount} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Quarantined" secondary={quarantinedCount} />
        </ListItem>
      </List>
    </Card>
  );
}
