import React from "react";
import {
  Edit,
  SimpleForm,
  TextField,
  Toolbar,
  useNotify,
  useRedirect,
  useRefresh,
  useUnselectAll,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

import DeleteButton, { DeleteButtonType } from "../../components/DeleteButton";

const useStyles = makeStyles({
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
});

const CustomToolbar = (props: any) => {
  const redirect = useRedirect();
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();

  return (
    <Toolbar {...props} classes={useStyles()}>
      <DeleteButton
        type={DeleteButtonType.feedback}
        onDeleted={() => {
          notify("Feedback deleted");
          redirect("/feedbacks");
          refresh();
          unselectAll("feedbacks");
        }}
        {...props}
      />
    </Toolbar>
  );
};

export const FeedbackCreate = (props: any) => {
  return (
    <Edit {...props}>
      <SimpleForm toolbar={<CustomToolbar />}>
        <TextField label="First Name" source="user.first_name" />
        <TextField label="Email" source="user.account.email" />
        <TextField label="Feedback" source="description" />
      </SimpleForm>
    </Edit>
  );
};
