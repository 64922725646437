import { Theme, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  (theme: Theme) => ({
    form: {
      padding: "0 1em 1em 1em",
    },
    input: {
      marginTop: "1em",
    },
    button: {
      width: "100%",
    },
    icon: {
      marginRight: theme.spacing(1),
    },
  }),
  { name: "RaLoginForm" }
);

export default useStyles;
