// in src/MyLoginPage.js
import * as React from "react";
import { Login } from "react-admin";

import LoginForm from "./LoginForm.container";

type LoginProps = Parameters<typeof Login>[0];

export default function LoginPage(props: LoginProps) {
  return (
    <Login {...props}>
      <LoginForm />
    </Login>
  );
}
