import React from "react";
import { Form } from "react-final-form";
import { useLogin, useNotify, useSafeSetState, useTranslate } from "ra-core";

import LoginForm from "./LoginForm.component";
import useStyles from "./LoginForm.styles";

export interface FormData {
  username: string;
  password: string;
  mfaToken: string;
}

export interface Props {
  redirectTo?: string;
}

export default (props: Props) => {
  const { redirectTo } = props;
  const [loading, setLoading] = useSafeSetState(false);
  const login = useLogin();
  const translate = useTranslate();
  const notify = useNotify();
  const classes = useStyles(props);

  const validate = (values: FormData) => {
    const errors = {
      username: undefined as string | undefined,
      password: undefined as string | undefined,
      mfaToken: undefined as string | undefined,
    };

    if (!values.username) {
      errors.username = translate("ra.validation.required");
    }
    if (!values.password) {
      errors.password = translate("ra.validation.required");
    }
    if (!values.mfaToken) {
      errors.mfaToken = translate("ra.validation.required");
    }
    return errors;
  };

  const submit = (values: unknown) => {
    setLoading(true);
    login(values, redirectTo)
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        notify(
          typeof error === "string"
            ? error
            : typeof error === "undefined" || !error.message
            ? "ra.auth.sign_in_error"
            : error.message,
          "warning"
        );
      });
  };

  const render = ({ handleSubmit }: { handleSubmit: () => unknown }) => {
    return <LoginForm {...{ handleSubmit, classes, loading, translate }} />;
  };

  return <Form onSubmit={submit} validate={validate} render={render} />;
};
