import React from "react";
import {
  Box,
  Chip,
  MenuItem,
  Select,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { prop, sortBy } from "ramda";
import { useField } from "react-final-form";

import { Tag, useTagsQuery } from "../../../types/generated/gql";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "left",
      flexWrap: "wrap",
      "& > *": {
        margin: theme.spacing(0.5),
      },
    },
  })
);

export const CreateStoryTags = () => {
  const classes = useStyles();

  const {
    input: { onChange },
  } = useField("tags");

  // Selected tags
  const [selectedTags, setSelectedTags] = React.useState<Array<Tag>>([]);

  const selectedTagIds = selectedTags.map((tag) => tag.id);

  // Filter selectable tags
  const { data } = useTagsQuery();
  const tags = data ? data.tag : [];
  const selectableTags = tags.filter((tag) => !selectedTagIds.includes(tag.id));

  const nameAlphabeticalSort = sortBy(prop("name"));

  return (
    <div>
      <div className={classes.root}>
        {selectedTags?.map((tag) => {
          return (
            <Chip
              label={tag.name}
              onDelete={() => {
                const newSelectedTags = selectedTags.filter(
                  (thisTag) => thisTag.id !== tag.id
                );
                setSelectedTags(newSelectedTags);

                onChange(newSelectedTags);
              }}
            />
          );
        })}
      </div>
      <Box width={300} mt={2}>
        <Select
          fullWidth
          value={"none"}
          onChange={(event) => {
            const tagId = event.target.value;
            const tag = tags.find((tag) => tag.id === tagId);

            if (tag) {
              const newSelectedTags = [tag as Tag, ...selectedTags];

              setSelectedTags(newSelectedTags);
              onChange(newSelectedTags);
            }
          }}
        >
          <MenuItem value={"none"} disabled>
            Select tag...
          </MenuItem>
          {nameAlphabeticalSort(selectableTags || [])?.map((tag) => (
            <MenuItem value={tag.id}>{tag.name}</MenuItem>
          ))}
        </Select>
      </Box>
    </div>
  );
};
