import React from "react";
import { Box, Button, TextField, Typography } from "@material-ui/core";
import { Labeled, Loading, useNotify } from "react-admin";

import decorateContent from "../methods/decorateContent";
import {
  Components_Stories_GetKeywordValuesQuery,
  GetStoryDocument,
  Story,
  useComponents_Stories_GetKeywordValuesQuery,
  useUpdateStoryTranscriptMutation,
} from "../../../types/generated/gql";

type ContentProps = {
  story: Story;
  content: string;
  keywords: Components_Stories_GetKeywordValuesQuery["keyword"];
  onTranscriptionUpdated?: (transcription: string) => unknown;
};

function Content({
  story,
  content,
  keywords,
  onTranscriptionUpdated,
}: ContentProps) {
  const decoratedContent = decorateContent(content, keywords);

  const notify = useNotify();

  const [editing, setEditing] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const [fieldContent, setFieldContent] = React.useState(content);

  const [updateStory] = useUpdateStoryTranscriptMutation();

  const refetchQueries = [
    {
      query: GetStoryDocument,
      variables: {
        StoryId: story.id,
      },
    },
  ];

  return (
    <Box>
      {editing ? (
        <Box display="flex" flexDirection="column">
          <TextField
            id="transcription-edit-field"
            label="Content"
            multiline
            value={fieldContent}
            variant="filled"
            onChange={(event) => setFieldContent(event.target.value)}
            style={{ width: "650px" }}
          />
          <Button
            disabled={saving}
            onClick={() => {
              setSaving(true);

              updateStory({
                refetchQueries,
                variables: {
                  id: story.id,
                  transcription: fieldContent,
                },
              }).then(() => {
                onTranscriptionUpdated && onTranscriptionUpdated(fieldContent);

                notify("The transcription for this story was updated");

                setSaving(false);
                setEditing(false);
              });
            }}
          >
            {saving ? "Saving..." : "Save"}
          </Button>
        </Box>
      ) : (
        <Box display="flex" flexDirection="column">
          <Labeled label="Content">
            <Typography>{decoratedContent}</Typography>
          </Labeled>
          <Button onClick={() => setEditing(true)}>Edit</Button>
        </Box>
      )}
    </Box>
  );
}

type Props = {
  story: Story;
  content: string | undefined;
  onTranscriptionUpdated: (transcription: string) => unknown;
};

export default function TranscriptContent({
  story,
  content,
  onTranscriptionUpdated,
}: Props) {
  const {
    loading,
    error,
    data,
  } = useComponents_Stories_GetKeywordValuesQuery();

  if (!content) return null;
  if (error) return <Content story={story} content={content} keywords={[]} />;

  return !error && loading ? (
    <Loading />
  ) : (
    <Content
      story={story}
      content={content}
      keywords={data?.keyword || []}
      onTranscriptionUpdated={onTranscriptionUpdated}
    />
  );
}
