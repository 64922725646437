import React from "react";
import {
  Box,
  Chip,
  MenuItem,
  Select,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { prop, sortBy } from "ramda";
import { useNotify } from "react-admin";
import { useQuery } from "@apollo/client";

import {
  GetStoryDocument,
  Story,
  Story_Tag,
  useDeleteStoryTagMutation,
  useInsertStoryTagMutation,
  useTagsQuery,
} from "../../../types/generated/gql";
import { client } from "../../../dataProvider";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "left",
      flexWrap: "wrap",
      "& > *": {
        margin: theme.spacing(0.5),
      },
    },
  })
);

export const StoryTags = (props: any) => {
  const classes = useStyles();
  const notify = useNotify();

  const { record } = props;

  // Note: Fetch again to enable auto refresh whenever tags were updated
  const { data: storyData } = useQuery(GetStoryDocument, {
    client,
    variables: {
      StoryId: record.id,
    },
  });

  const story = storyData && (storyData.story[0] as Story);
  const storyTags = story ? (story.tags as Array<Story_Tag>) : [];

  const [deleteStoryTag] = useDeleteStoryTagMutation();

  // Filter selectable tags
  const { data: allTagsData } = useTagsQuery();
  const allTags = allTagsData && allTagsData.tag;
  const allTagIds = allTags?.map((tag) => tag.id) || [];
  const storyTagIds = storyTags.map((tag) => tag.tag_id);

  const selectableTagIds = allTagIds.filter(
    (tagId) => !storyTagIds.includes(tagId)
  );
  const selectableTags = allTags?.filter((tag) =>
    selectableTagIds.includes(tag.id)
  );
  const nameAlphabeticalSort = sortBy(prop("name"));

  const [insertStoryTag] = useInsertStoryTagMutation();

  const refetchQueries = [
    {
      query: GetStoryDocument,
      variables: {
        StoryId: record.id,
      },
    },
  ];

  return (
    <div>
      <div className={classes.root}>
        {storyTags.map((tag) => {
          return (
            <Chip
              label={tag.tag.name}
              onDelete={() => {
                deleteStoryTag({
                  variables: {
                    id: tag.id,
                  },
                  refetchQueries,
                }).then(() => notify("Deleted story tag"));
              }}
            />
          );
        })}
      </div>
      <Box width={300} mt={2}>
        <Select
          fullWidth
          value={"none"}
          onChange={(event) => {
            const tagId = event.target.value;

            insertStoryTag({
              refetchQueries: [
                {
                  query: GetStoryDocument,
                  variables: {
                    StoryId: record.id,
                  },
                },
              ],
              variables: {
                object: {
                  story_id: record.id,
                  tag_id: tagId,
                },
              },
            }).then(() => notify("Added new tag to this story"));
          }}
        >
          <MenuItem value={"none"} disabled>
            Add new tag...
          </MenuItem>
          {nameAlphabeticalSort(selectableTags || [])?.map((tag) => (
            <MenuItem value={tag.id}>{tag.name}</MenuItem>
          ))}
        </Select>
      </Box>
    </div>
  );
};
