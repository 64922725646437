import store from "./store";
import { post } from "./api";

async function authenticatedPost(path: string, body: any) {
  return await post(path, body, store.token);
}

export async function register(
  email: string,
  password: string,
  userData?: any
): Promise<unknown> {
  return await post("/auth/register", { email, password, user_data: userData });
}

export async function activate(ticket: string): Promise<unknown> {
  return await post("/auth/activate", { ticket });
}

export async function changeEmailRequest(email: string): Promise<unknown> {
  return await authenticatedPost("/auth/change-email/request", {
    new_email: email,
  });
}

export async function changeEmailPerform(ticket: string): Promise<unknown> {
  return await authenticatedPost("/auth/change-email/change", { ticket });
}

export async function changePassword(
  oldPassword: string,
  newPassword: string
): Promise<unknown> {
  return await authenticatedPost("/auth/change-password", {
    old_password: oldPassword,
    new_password: newPassword,
  });
}

export async function changePasswordRequest(email: string): Promise<unknown> {
  return await authenticatedPost("/auth/change-password/request", { email });
}

export async function changePasswordPerform(
  newPassword: string,
  ticket: string
): Promise<unknown> {
  return await authenticatedPost("/auth/change-password/change", {
    new_password: newPassword,
    ticket,
  });
}
