import React from "react";
import { Box, Grid } from "@material-ui/core";

import DefinitionsCard from "./cards/Definitions";
import InfoTilesCard from "./cards/InfoTiles";
import ModerationCard from "./cards/Moderations";
import StoriesCard from "./cards/Stories";
import UsersCard from "./cards/Users";
import ViolationsCard from "./cards/Violations";

export default function Dashboard() {
  return (
    <Box m={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Grid container spacing={2}>
            <UsersCard />
            <InfoTilesCard />
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid container spacing={2}>
            <StoriesCard />
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid container spacing={2}>
            <ViolationsCard />
            <ModerationCard />
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DefinitionsCard />
        </Grid>
      </Grid>
    </Box>
  );
}
