import React from "react";
import { Box, MenuItem, Select } from "@material-ui/core";
import { times } from "ramda";
import { useNotify } from "react-admin";

import {
  Info_Tile,
  Tag,
  useSaveInfoTileOrderMutation,
  useSaveTagOrderMutation,
} from "../types/generated/gql";

export enum OrderDropdownType {
  infotiles,
  tags,
}

interface Props {
  type: OrderDropdownType;
  record: Info_Tile | Tag;
  onOpen: () => unknown;
  onClose: () => unknown;
}

export default function OrderDropdown({
  type,
  record,
  onOpen,
  onClose,
}: Props) {
  const notify = useNotify();

  const [order, setOrder] = React.useState<number>(-1);

  const recordId = record.id;
  const recordOrder = record.order;

  const [saveInfoTileOrder] = useSaveInfoTileOrderMutation();
  const [saveTagOrder] = useSaveTagOrderMutation();

  React.useEffect(() => {
    setOrder(recordOrder || -1);
  }, [recordOrder]);

  return (
    <Box width={60}>
      <Select
        id="order-dropdown"
        value={order}
        onChange={(event) => {
          const order = event.target.value as number;

          setOrder(order);

          if (type === OrderDropdownType.infotiles) {
            saveInfoTileOrder({
              variables: {
                id: recordId,
                order: order !== -1 ? order : null,
              },
            }).then(() => {
              if (order !== -1) {
                notify("Order for this info tile has been updated to " + order);
              } else {
                notify("Order for this info tile has been reset to Auto");
              }
            });
          } else if (type === OrderDropdownType.tags) {
            saveTagOrder({
              variables: {
                id: recordId,
                order: order !== -1 ? order : null,
              },
            }).then(() => {
              if (order !== -1) {
                notify("Order for this tag has been updated to " + order);
              } else {
                notify("Order for this tag has been removed");
              }
            });
          }
        }}
        onOpen={onOpen}
        onClose={onClose}
        fullWidth
      >
        <MenuItem value={-1}>&nbsp;</MenuItem>
        {times(
          (index) => (
            <MenuItem value={index + 1}>{index + 1}</MenuItem>
          ),
          10
        )}
      </Select>
    </Box>
  );
}
