import React from "react";
import HelpIcon from '@material-ui/icons/Help';
import { Grid, Card, CardHeader, Typography, List, ListItem, ListItemText } from "@material-ui/core";

export default function Definitions() {
  return (
    <Grid item xs={12}>
      <Card>
        <CardHeader avatar={<HelpIcon />} title={<Typography>Definitions</Typography>} />
        <List dense>
          <ListItem><ListItemText secondary="DRAFT - this is when a user has started to draft a story" /></ListItem>
          <ListItem><ListItemText secondary="SUBMITTED - this is when a user has sent a story to OurHerd for review" /></ListItem>
          <ListItem><ListItemText secondary="APPROVED - this is when a member of staff has approved a story" /></ListItem>
          <ListItem><ListItemText secondary="PUBLISHED - this is when a user publishes and makes their story public" /></ListItem>
          <ListItem><ListItemText secondary="PAUSED - this is when a user pauses their story from appearing on OurHerd" /></ListItem>
          <ListItem><ListItemText secondary="QUARANTINED - this is when an admin user (batyr staff) revokes/rejects a story" /></ListItem>
          <ListItem><ListItemText secondary="VIOLATIONS - this is when a user of OurHerd reports a story as unsafe" /></ListItem>
        </List>
      </Card>
    </Grid>
  );
}
