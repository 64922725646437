import Alert from "@material-ui/lab/Alert";
import React, { useEffect } from "react";
import { Box, Snackbar, TextField } from "@material-ui/core";
import { Toolbar, useRedirect } from "react-admin";
import { isNil } from "ramda";

import EditButton from "../../components/EditButton";
import authStore from "../../utils/auth/store";
import dataProvider from "../../dataProvider";
import ApproveButton, { ApproveButtonType } from "./partials/ApproveButton";
import DeleteButton, { DeleteButtonType } from "../../components/DeleteButton";
import RejectButton, { RejectButtonType } from "./partials/RejectButton";
import {
  useDeleteStoryMutation,
  useSendEmailMutation,
} from "../../types/generated/gql";

const ALERT_PUBLISH_MESSAGE =
  "Story has been approved and published on OurHerd";

// const ALERT_FEEDBACK_MESSAGE =
//   "An email has just been sent to the user notifying them for your feedback regarding their story.";

// const ALERT_DELETE_MESSAGE = "This story has been deleted.";

export const StoryToolbar = (props: any) => {
  const { resource, record, onDeleted } = props;

  const redirect = useRedirect();

  const isModeration = resource === "stories";
  const isViolations = resource === "violations";

  const [note, setNote] = React.useState("");
  const [moderator, setModerator] = React.useState({} as any);
  const [isAlertVisible, setIsAlertVisible] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [sendEmail] = useSendEmailMutation();

  useEffect(() => {
    const getModerator = async () => {
      const result = await dataProvider.getOne("users", {
        id: authStore.getClaim("x-hasura-user-id") as string,
      });
      setModerator(result.data);
    };

    getModerator();
  }, []);

  let approveButtonType;
  let rejectButtonType;

  if (isModeration) {
    approveButtonType = ApproveButtonType.moderation;
    rejectButtonType = RejectButtonType.moderation;
  }

  if (isViolations) {
    approveButtonType = ApproveButtonType.violations;
    rejectButtonType = RejectButtonType.violations;
  }

  const handleCloseAlert = (event: any, reason: string) => {
    if (reason === "clickaway") return;
    setIsAlertVisible(false);
  };

  const handleOnApproved = () => {
    setAlertMessage(ALERT_PUBLISH_MESSAGE);
    setIsAlertVisible(true);
    setNote("");
  };

  const handleOnRejected = () => {
    setNote("");
  };

  const handleOnDeleted = () => {
    onDeleted();
  };

  return !isNil(approveButtonType) && !isNil(rejectButtonType) ? (
    <Toolbar {...props}>
      <Box display="flex" flexDirection="column" flex={1} mt={2} mb={2}>
        <TextField
          label="Please note: only the moderators will see these notes"
          multiline={true}
          rows={7}
          value={note}
          onChange={(event) => setNote(event.target.value)}
        />

        {isModeration && (
          <Box justifyContent="space-between" display="flex" flex={1} mt={2}>
            <Box display="flex" flexDirection="row">
              <ApproveButton
                type={approveButtonType}
                note={note}
                onApproved={handleOnApproved}
                {...props}
              />
              <Box width={16} />
              <RejectButton
                type={rejectButtonType}
                note={note}
                onRejected={handleOnRejected}
                {...props}
              />
            </Box>

            <Box display="flex" flexDirection="row">
              <EditButton onClick={() => redirect(`/story/${record.id}`)} />
              <Box width={16} />
              <DeleteButton
                type={DeleteButtonType.story}
                onDeleted={handleOnDeleted}
                {...props}
              />
            </Box>
          </Box>
        )}
        {isViolations && (
          <Box justifyContent="space-between" display="flex" flex={1} mt={2}>
            <ApproveButton
              type={approveButtonType}
              note={note}
              onApproved={handleOnApproved}
              {...props}
            />
            <Box width={16} />
            <RejectButton
              type={rejectButtonType}
              note={note}
              onRejected={handleOnRejected}
              {...props}
            />
          </Box>
        )}
      </Box>
      <Snackbar
        open={isAlertVisible}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <>
          {/* @ts-ignore */}
          <Alert onClose={handleCloseAlert} severity="info">
            {alertMessage}
          </Alert>
        </>
      </Snackbar>
    </Toolbar>
  ) : (
    <div />
  );
};
