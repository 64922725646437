import Button from "@material-ui/core/Button";
import CardActions from "@material-ui/core/CardActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";
import { Field } from "react-final-form";
import { useTranslate } from "ra-core";

import TextInput from "./TextInput";
import useStyles from "./LoginForm.styles";

type LoginFormProps = {
  handleSubmit: () => unknown;
  classes: ReturnType<typeof useStyles>;
  loading: boolean;
  translate: ReturnType<typeof useTranslate>;
};

export default function LoginForm({
  handleSubmit,
  classes,
  loading,
  translate,
}: LoginFormProps) {
  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className={classes.form}>
        <div className={classes.input}>
          <Field
            autoFocus
            id="username"
            name="username"
            component={TextInput}
            label={translate("ra.auth.username")}
            disabled={loading}
          />
        </div>
        <div className={classes.input}>
          <Field
            id="password"
            name="password"
            component={TextInput}
            label={translate("ra.auth.password")}
            type="password"
            disabled={loading}
            autoComplete="current-password"
          />
        </div>
        <div className={classes.input}>
          <Field
            id="mfaToken"
            name="mfaToken"
            component={TextInput}
            label="MFA Token"
            disabled={loading}
          />
        </div>
      </div>
      <CardActions>
        <Button
          variant="contained"
          type="submit"
          color="primary"
          disabled={loading}
          className={classes.button}
        >
          {loading && (
            <CircularProgress
              className={classes.icon}
              size={18}
              thickness={2}
            />
          )}
          {translate("ra.auth.sign_in")}
        </Button>
      </CardActions>
    </form>
  );
}
