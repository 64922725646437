import React from "react";
import {
  Edit,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
} from "react-admin";

export const InfoTileEdit = (props: any) => {
  return (
    <Edit {...props} undoable={false}>
      <SimpleForm>
        <TextInput source="action_url" label="URL" />
        <TextInput source="accessibility_text" validate={[required()]} />
        <SelectInput
          source="status"
          choices={[
            {
              id: "ACTIVE",
              name: "Active",
            },
            {
              id: "ARCHIVED",
              name: "Archived",
            },
          ]}
          validate={[required()]}
        />
      </SimpleForm>
    </Edit>
  );
};
