import Button from "@material-ui/core/Button";
import React from "react";
import ThumbUp from "@material-ui/icons/ThumbUp";
import moment from "moment";
import { useApolloClient } from "@apollo/client";

import authStore from "../../../utils/auth/store";
import {
  GetPushSettingsForUserDocument,
  GetStoryDocument,
  GetUsersToPushForNewStoriesDocument,
  Story,
  User,
  useAddNoteMutation,
  useAddViolationNoteMutation,
  useSendPushToUserMutation,
  useSendPushToUsersMutation,
  useUpdateLastPushedNewStoryForUsersMutation,
} from "../../../types/generated/gql";

export enum ApproveButtonType {
  moderation,
  violations,
}

interface Props {
  type: ApproveButtonType;
  note: string;
  record: Story;
  onApproved: () => unknown;
}

export default function ApproveButton({
  type,
  note,
  record,
  onApproved,
}: Props) {
  const client = useApolloClient();

  const refetchQueries = [
    {
      query: GetStoryDocument,
      variables: {
        StoryId: record.id,
      },
    },
  ];
  const [addNote, { loading: addNoteLoading }] = useAddNoteMutation();
  const [
    addViolationNote,
    { loading: addViolationNoteLoading },
  ] = useAddViolationNoteMutation();

  const loading = addNoteLoading || addViolationNoteLoading;

  const [sendPushToUser] = useSendPushToUserMutation();
  const [sendPushToUsers] = useSendPushToUsersMutation();
  const [
    updateLastPushedNewStoryForUsers,
  ] = useUpdateLastPushedNewStoryForUsersMutation();

  const onApprovePushNotification = () => {
    client
      .query({
        query: GetPushSettingsForUserDocument,
        variables: {
          userId: record.user_id,
        },
      })
      .then((result) => {
        const user = result.data.user_by_pk as User;

        if (user.push_story_approved) {
          sendPushToUser({
            variables: {
              userId: record.user_id,
              message: "Wahoooo your story is live ⭐",
            },
          });
        }
      });

    const nowISOString = new Date().toISOString();
    const oneDayAgoString = moment().subtract(1, "days").toISOString();

    client
      .query({
        query: GetUsersToPushForNewStoriesDocument,
        variables: {
          storyUserId: record.user_id,
          date: oneDayAgoString,
        },
      })
      .then((result) => {
        const userIds = result.data.user.map((user: any) => user.id);

        sendPushToUsers({
          variables: {
            userIds,
            message: "NEW stories are ready for YOU! 🥁",
          },
        });

        updateLastPushedNewStoryForUsers({
          variables: {
            userIds,
            date: nowISOString,
          },
        });
      });
  };

  return (
    <Button
      variant="outlined"
      color="primary"
      size="small"
      onClick={() => {
        const nowISOString = new Date().toISOString();

        if (type === ApproveButtonType.moderation) {
          addNote({
            refetchQueries,
            variables: {
              note: {
                action: "APPROVE",
                note,
                story_id: record.id,
                user_id: authStore.getClaim("x-hasura-user-id"),
              },
              storyId: record.id,
              storyStatus: "PUBLISHED",
              publishedAt: nowISOString,
            },
          }).then(() => {
            onApproved();
            onApprovePushNotification();
          });
        }

        if (type === ApproveButtonType.violations) {
          addViolationNote({
            refetchQueries,
            variables: {
              object: {
                action: "APPROVE",
                note,
                story_id: record.id,
                user_id: authStore.getClaim("x-hasura-user-id"),
              },
              storyId: record.id,
              storyStatus: "PUBLISHED",
              publishedAt: nowISOString,
            },
          }).then(() => {
            onApproved();
            onApprovePushNotification();
          });
        }
      }}
      disabled={loading}
    >
      <ThumbUp
        color="primary"
        style={{ paddingRight: "0.5em", color: "green" }}
      />
      Approve
    </Button>
  );
}
