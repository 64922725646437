import { ApolloClient, ApolloLink, InMemoryCache } from "@apollo/client";
import { BatchHttpLink } from "apollo-link-batch-http";
import { DataProvider } from "react-admin";
import { setContext } from "@apollo/client/link/context";

import authStore from "../utils/auth/store";
import create from "./create";
import getList from "./getList";
import getMany from "./getMany";
import getOne from "./getOne";
import update from "./update";
import { initializeSession } from "../utils/auth/sessions";

// Instantiate required constructor fields
const cache = new InMemoryCache();

const httpLink = new BatchHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URL,
  batchInterval: 10,
});

const authLink = setContext(async (_, { headers }) => {
  // Refresh token if expired (there is a chance it wasn't due to timers not firing properly)
  if (authStore.isExpired()) {
    await initializeSession();
  }

  // get the authentication token from local storage if it exists
  const token = authStore.token;

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      "x-hasura-role": authStore.getHighestRole(),
      "x-hasura-user-id": authStore.getClaim("x-hasura-user-id"),
    },
  };
});

export const client = new ApolloClient({
  // Provide required constructor fields
  cache: cache,

  link: authLink.concat((httpLink as unknown) as ApolloLink),

  // Provide some optional constructor fields
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "network-only", // REMINDER: React-admin already has optimistic loading
    },
    query: {
      fetchPolicy: "network-only", // REMINDER: React-admin already has optimistic loading
    },
  },
});

export type Params = Record<string, any>;

export default ({
  getList: getList(client),
  getOne: getOne(client),
  getMany: getMany(client),
  create: create(client),
  update: update(client),
} as unknown) as DataProvider;
