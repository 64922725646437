import * as React from "react";
import { FC } from "react";
import { InputProps, SelectArrayInput } from "react-admin";

import topics from "../data/topics.data";

interface Props extends Omit<InputProps, "source"> {
  source?: string;
}

const TopicsInput: FC<Props> = ({ addField, ...rest }) => {
  return (
    <SelectArrayInput
      source="topics"
      {...rest}
      choices={topics.map((topic) => ({
        id: topic.id,
        name: topic.name,
      }))}
    />
  );
};

export default TopicsInput;
