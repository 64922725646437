import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import React from "react";
import { useListContext } from "react-admin";

import ConfirmDialog from "./ConfirmDialog";
import {
  FeedbacksDocument,
  StoriesDocument,
  TagsDocument,
  UsersDocument,
  useDeleteFeedbackMutation,
  useDeleteStoryMutation,
  useDeleteTagMutation,
  useDeleteUserMutation,
} from "../types/generated/gql";

export enum DeleteButtonType {
  story,
  user,
  feedback,
  tag,
}

interface Props {
  type: DeleteButtonType;
  onDeleted: () => unknown;
}

export default function BulkDeleteButton({ type, onDeleted }: Props) {
  const listContext = useListContext();
  const { selectedIds } = listContext;

  const [dialogOpen, setDialogOpen] = React.useState(false);

  const [deleteStory] = useDeleteStoryMutation();
  const [deleteUser] = useDeleteUserMutation();
  const [deleteFeedback] = useDeleteFeedbackMutation();
  const [deleteTags] = useDeleteTagMutation();

  const title = () => {
    if (type === DeleteButtonType.story) {
      return "Delete stories";
    }

    if (type === DeleteButtonType.user) {
      return "Delete users";
    }

    if (type === DeleteButtonType.feedback) {
      return "Delete feedbacks";
    }

    if (type === DeleteButtonType.tag) {
      return "Delete tags";
    }

    return "Delete items";
  };

  const message = () => {
    if (type === DeleteButtonType.story) {
      return "Are you sure you want to delete selected stories?";
    }

    if (type === DeleteButtonType.user) {
      return "Are you sure you want to delete selected users?";
    }

    if (type === DeleteButtonType.feedback) {
      return "Are you sure you want to delete selected feedbacks?";
    }

    if (type === DeleteButtonType.tag) {
      return "Are you sure you want to delete selected tags?";
    }

    return "Are you sure you want to delete selected items?";
  };

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        size="small"
        onClick={() => setDialogOpen(true)}
      >
        <DeleteIcon
          color="primary"
          style={{ paddingRight: "0.5em", color: "red" }}
        />
        Delete
      </Button>

      <ConfirmDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onYes={() => {
          if (type === DeleteButtonType.story) {
            selectedIds.forEach((id) => {
              deleteStory({
                refetchQueries: [
                  {
                    query: StoriesDocument,
                  },
                ],
                variables: {
                  id,
                },
              });
            });

            onDeleted();
          } else if (type === DeleteButtonType.user) {
            selectedIds.forEach((id) => {
              deleteUser({
                refetchQueries: [
                  {
                    query: UsersDocument,
                  },
                ],
                variables: {
                  id,
                },
              });
            });

            onDeleted();
          } else if (type === DeleteButtonType.feedback) {
            selectedIds.forEach((id) => {
              deleteFeedback({
                refetchQueries: [
                  {
                    query: FeedbacksDocument,
                  },
                ],
                variables: {
                  id,
                },
              });
            });

            onDeleted();
          } else if (type === DeleteButtonType.tag) {
            selectedIds.forEach((id) => {
              deleteTags({
                refetchQueries: [
                  {
                    query: TagsDocument,
                  },
                ],
                variables: {
                  id,
                },
              });
            });

            onDeleted();
          }

          setDialogOpen(false);
        }}
        onNo={() => setDialogOpen(false)}
        title={title()}
        message={message()}
      />
    </>
  );
}
