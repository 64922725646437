import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import React from "react";
import { CardHeader, List, ListItem, ListItemText } from "@material-ui/core";

import Card from "../../../components/Card";
import {
  Info_Tile_Status_Type_Enum,
  useInfoTileCountQuery,
} from "../../../types/generated/gql";

function getCount<O extends ReturnType<typeof useInfoTileCountQuery>>(obj: O) {
  return obj.data?.info_tile_aggregate?.aggregate?.count;
}

export default function InfoTiles() {
  const activeCount =
    getCount(
      useInfoTileCountQuery({
        variables: { status: Info_Tile_Status_Type_Enum.Active },
      })
    ) || 0;
  const archivedCount =
    getCount(
      useInfoTileCountQuery({
        variables: { status: Info_Tile_Status_Type_Enum.Archived },
      })
    ) || 0;

  const total = activeCount + archivedCount;

  return (
    <Card>
      <CardHeader
        avatar={<PhotoLibraryIcon />}
        title="InfoTiles"
        subheader={`total: ${total}`}
      />
      <List dense>
        <ListItem>
          <ListItemText primary="Active" secondary={activeCount} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Archived" secondary={archivedCount} />
        </ListItem>
      </List>
    </Card>
  );
}
