import { ApolloClient } from '@apollo/client';
import {
  GetInfoTilesDocument,
  GetReferralsDocument,
  GetTopicsDocument
} from '../types/generated/gql';
import { Params } from "./";

async function handleInfoTiles<T>(client: ApolloClient<T>, _resource: string, params: Params) {
  const response = await client.query({
    query: GetInfoTilesDocument,
    variables: {
      InfoTileIds: params.ids,
    },
  })
  const tiles = response.data.info_tile;

  return {
    data: tiles,
  };
}

async function handleReferrals<T>(client: ApolloClient<T>, _resource: string, params: Params) {
  const response = await client.query({
    query: GetReferralsDocument,
    variables: {
      ReferralsIds: params.ids,
    },
  })
  const referrals = response.data.referral;

  return {
    data: referrals,
  };
}

async function handleTopics<T>(client: ApolloClient<T>, _resource: string, params: Params) {
  const response = await client.query({
    query: GetTopicsDocument,
    variables: {
      TopicsIds: params.ids,
    },
  })
  const topics = response.data.topic;

  return {
    data: topics,
  };
}

export default function<T>(client: ApolloClient<T>) {
  return (resource: string, params: Params) => {
    switch(resource) {
      case "infotiles":
        return handleInfoTiles(client, resource, params)
      case "referrals":
        return handleReferrals(client, resource, params)
      case "topics":
        return handleTopics(client, resource, params)
    }

    throw new Error(`resource ${resource} was not handled`)
  }
}
