import React from "react";
import ReactHlsPlayer from "react-hls-player";
import { CircularProgress } from "@material-ui/core";
import {
  Edit,
  FileInput,
  ImageField,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
  useNotify,
  useRedirect,
  useRefresh,
} from "react-admin";
import { isNil } from "ramda";

import {
  Resource_Type_Enum,
  Story,
  useGetStoryQuery,
} from "../../types/generated/gql";
import { getResourceUrl } from "../../utils/resource";

enum StoryType {
  text = "TEXT",
  video = "VIDEO",
}

export const StoryEdit = (props: any) => {
  const { id } = props;

  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const { data } = useGetStoryQuery({
    variables: {
      StoryId: id,
    },
  });

  const story = data ? (data.story[0] as Story) : null;

  const [storyType, setStoryType] = React.useState<StoryType | null>(null);

  const [imageSelected, setImageSelected] = React.useState(false);
  const [videoSelected, setVideoSelected] = React.useState(false);

  const onSuccess = (data: any) => {
    notify("Story updated");
    redirect("/stories?refresh=true");
    refresh();
  };

  React.useEffect(() => {
    if (story) {
      setStoryType(story.type as StoryType);
    }
  }, [story]);

  const defaultValue = story && (story.type as StoryType);

  return story ? (
    <Edit onSuccess={onSuccess} {...props} undoable={false}>
      <SimpleForm>
        <TextInput
          source="title"
          label="Title"
          fullWidth
          validate={[required()]}
        />
        <SelectInput
          source="storyType"
          choices={[
            {
              id: "TEXT",
              name: "Text",
            },
            {
              id: "VIDEO",
              name: "Video",
            },
          ]}
          validate={[required()]}
          value={storyType}
          defaultValue={defaultValue}
          onChange={(event) => setStoryType(event.target.value as StoryType)}
        />
        <TextInput multiline source="caption" fullWidth />
        <TextInput multiline source="content" fullWidth />

        {storyType === "TEXT" && (
          <>
            <FileInput
              source="file"
              label="Image"
              accept="image/*"
              onChange={(event) => {
                setImageSelected(!isNil(event));
              }}
            >
              <ImageField source="src" title="title" />
            </FileInput>
            {!imageSelected &&
              story?.resource?.type === Resource_Type_Enum.Image && (
                <img
                  src={getResourceUrl(story.resource)}
                  width="30%"
                  alt="untitled"
                />
              )}
          </>
        )}

        {storyType === "VIDEO" && (
          <FileInput
            source="file"
            label="Video"
            accept="video/*"
            onChange={(event) => {
              setVideoSelected(!isNil(event));
            }}
          >
            <ImageField source="src" title="title" />
          </FileInput>
        )}

        {!videoSelected && storyType === "VIDEO" && story && story.resource && (
          <ReactHlsPlayer
            url={getResourceUrl(story.resource)}
            autoplay={false}
            controls={true}
            width="30%"
            height="auto"
          />
        )}
      </SimpleForm>
    </Edit>
  ) : (
    <CircularProgress />
  );
};
