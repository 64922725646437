import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import React from "react";

interface Props {
  onClick: () => unknown;
}

export default function EditButton({ onClick }: Props) {
  return (
    <>
      <Button variant="outlined" color="primary" size="small" onClick={onClick}>
        <EditIcon color="primary" style={{ paddingRight: "0.5em" }} />
        Edit
      </Button>
    </>
  );
}
