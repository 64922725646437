import {
  compose,
  curry,
  flatten,
  isNil,
  partition,
  reject,
  split,
} from "ramda";

type ContentNode = string | JSX.Element;
type ContentNodes = Array<ContentNode>;
type Keyword = {
  value: string;
  sentiment: {
    value: string;
  };
  keyword_referrals: Array<{
    referral: {
      value: string;
    };
  }>;
  keyword_topics: Array<{
    topic: {
      value: string;
    };
  }>;
};
type Keywords = Array<Keyword>;
type NodeTuple = [ContentNodes, ContentNodes];
type StringTuple = [string[], string[]];
type ElementBuilderFactory = (
  keyword: Keyword
) => (text: string) => JSX.Element;

function longZip<T>(a1: Array<T>, a2: Array<T>) {
  return a1.map((elem, idx) => [elem, a2[idx]]);
}
// case insensitive search but case sensitive replace all
// occurrences of each keyword in the node string.
function handleNode(
  factory: ElementBuilderFactory,
  keyword: Keyword,
  node: string
): ContentNodes {
  // this filter splits the string but keeps the delimiter phrase (and it's case information)
  // which is then used to generate correctly cased react elements & is all zipped back together
  const filter = new RegExp(`(${keyword.value.toLowerCase()})`, "i");
  const partialFactory = factory(keyword);

  const doMatchReplace = ([matches, rest]: StringTuple) =>
    [matches.map((match) => partialFactory(match)), rest] as NodeTuple;

  return compose(
    reject(isNil) as (values: Array<ContentNode | undefined>) => ContentNodes,
    flatten as (
      values: Array<Array<ContentNode | undefined>>
    ) => Array<ContentNode | undefined>,
    ([elements, strings]: NodeTuple) =>
      longZip(strings, elements) as Array<ContentNodes>,
    doMatchReplace,
    partition((phrase: string) => !!filter.exec(phrase)),
    split(filter)
  )(node);
}

function handleKeyword(
  factory: ElementBuilderFactory,
  nodes: ContentNodes,
  keyword: Keyword
): ContentNodes {
  return flatten(
    nodes.map((node) =>
      typeof node === "string" ? handleNode(factory, keyword, node) : node
    )
  );
}

// Replaces key-phrases in the string elements of the nodes: ContentNodes array
// leaving the react component elements in place.
// This implementation is not so preformat.
export default curry(
  (
    factory: ElementBuilderFactory,
    keywords: Keywords,
    nodes: ContentNodes
  ): ContentNodes =>
    flatten(
      keywords.reduce(
        (acc, keyword) => handleKeyword(factory, acc, keyword),
        nodes
      )
    )
);
