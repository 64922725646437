import React from "react";
import WarningIcon from "@material-ui/icons/Warning";
import { CardHeader, List, ListItem, ListItemText } from "@material-ui/core";

import Card from "../../../components/Card";
import { useAdminViolationCountQuery } from "../../../types/generated/gql";
import { useUserViolationCountQuery } from "../../../types/generated/gql";

type query =
  | typeof useAdminViolationCountQuery
  | typeof useUserViolationCountQuery;

function getCount<O extends ReturnType<query>>(obj: O) {
  return obj.data?.story_violation_aggregate?.aggregate?.count;
}

export default function Users() {
  const userViolationsCount = getCount(useUserViolationCountQuery()) || 0;
  const adminViolationsCount = getCount(useAdminViolationCountQuery()) || 0;

  const total = userViolationsCount + adminViolationsCount;

  return (
    <Card>
      <CardHeader
        avatar={<WarningIcon />}
        title="Violations"
        subheader={`total: ${total}`}
      />
      <List dense>
        <ListItem>
          <ListItemText
            primary="User Reported Violations"
            secondary={userViolationsCount}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Admin Reported Violations"
            secondary={adminViolationsCount}
          />
        </ListItem>
      </List>
    </Card>
  );
}
