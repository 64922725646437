import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  citext: any;
  date: any;
  json: any;
  jsonb: any;
  timestamp: any;
  timestamptz: any;
  uuid: any;
};

export type AddResult = {
  __typename?: 'AddResult';
  sum?: Maybe<Scalars['Int']>;
};

/** expression to compare columns of type Boolean. All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

export type DeleteStoryOutput = {
  __typename?: 'DeleteStoryOutput';
  success: Scalars['Boolean'];
};

export type DeleteUserOutput = {
  __typename?: 'DeleteUserOutput';
  success: Scalars['Boolean'];
};

export type InfoTileUrlOutput = {
  __typename?: 'InfoTileUrlOutput';
  url: Scalars['String'];
};

/** expression to compare columns of type Int. All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};

export type ReactToStoryAuthAccountProvidersArrRelInsertInput = {
  data: Array<ReactToStoryAuthAccountProvidersInsertInput>;
  on_conflict?: Maybe<ReactToStoryAuthAccountProvidersOnConflict>;
};

export type ReactToStoryAuthAccountProvidersBoolExp = {
  _and?: Maybe<Array<Maybe<ReactToStoryAuthAccountProvidersBoolExp>>>;
  _not?: Maybe<ReactToStoryAuthAccountProvidersBoolExp>;
  _or?: Maybe<Array<Maybe<ReactToStoryAuthAccountProvidersBoolExp>>>;
  account?: Maybe<ReactToStoryAuthAccountsBoolExp>;
  account_id?: Maybe<ReactToStoryUuidComparisonExp>;
  auth_provider?: Maybe<ReactToStoryStringComparisonExp>;
  auth_provider_unique_id?: Maybe<ReactToStoryStringComparisonExp>;
  created_at?: Maybe<ReactToStoryTimestamptzComparisonExp>;
  id?: Maybe<ReactToStoryUuidComparisonExp>;
  provider?: Maybe<ReactToStoryAuthProvidersBoolExp>;
  updated_at?: Maybe<ReactToStoryTimestamptzComparisonExp>;
};

export enum ReactToStoryAuthAccountProvidersConstraint {
  /** unique or primary key constraint */
  AccountProvidersAccountIdAuthProviderKey = 'account_providers_account_id_auth_provider_key',
  /** unique or primary key constraint */
  AccountProvidersAuthProviderAuthProviderUniqueIdKey = 'account_providers_auth_provider_auth_provider_unique_id_key',
  /** unique or primary key constraint */
  AccountProvidersPkey = 'account_providers_pkey'
}

export type ReactToStoryAuthAccountProvidersInsertInput = {
  account?: Maybe<ReactToStoryAuthAccountsObjRelInsertInput>;
  account_id?: Maybe<Scalars['uuid']>;
  auth_provider?: Maybe<Scalars['String']>;
  auth_provider_unique_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  provider?: Maybe<ReactToStoryAuthProvidersObjRelInsertInput>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

export type ReactToStoryAuthAccountProvidersOnConflict = {
  constraint: ReactToStoryAuthAccountProvidersConstraint;
  update_columns: Array<ReactToStoryAuthAccountProvidersUpdateColumn>;
  where?: Maybe<ReactToStoryAuthAccountProvidersBoolExp>;
};

export enum ReactToStoryAuthAccountProvidersUpdateColumn {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  AuthProvider = 'auth_provider',
  /** column name */
  AuthProviderUniqueId = 'auth_provider_unique_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type ReactToStoryAuthAccountRolesArrRelInsertInput = {
  data: Array<ReactToStoryAuthAccountRolesInsertInput>;
  on_conflict?: Maybe<ReactToStoryAuthAccountRolesOnConflict>;
};

export type ReactToStoryAuthAccountRolesBoolExp = {
  _and?: Maybe<Array<Maybe<ReactToStoryAuthAccountRolesBoolExp>>>;
  _not?: Maybe<ReactToStoryAuthAccountRolesBoolExp>;
  _or?: Maybe<Array<Maybe<ReactToStoryAuthAccountRolesBoolExp>>>;
  account?: Maybe<ReactToStoryAuthAccountsBoolExp>;
  account_id?: Maybe<ReactToStoryUuidComparisonExp>;
  created_at?: Maybe<ReactToStoryTimestamptzComparisonExp>;
  id?: Maybe<ReactToStoryUuidComparisonExp>;
  role?: Maybe<ReactToStoryStringComparisonExp>;
  roleByRole?: Maybe<ReactToStoryAuthRolesBoolExp>;
};

export enum ReactToStoryAuthAccountRolesConstraint {
  /** unique or primary key constraint */
  AccountRolesPkey = 'account_roles_pkey',
  /** unique or primary key constraint */
  UserRolesAccountIdRoleKey = 'user_roles_account_id_role_key'
}

export type ReactToStoryAuthAccountRolesInsertInput = {
  account?: Maybe<ReactToStoryAuthAccountsObjRelInsertInput>;
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  role?: Maybe<Scalars['String']>;
  roleByRole?: Maybe<ReactToStoryAuthRolesObjRelInsertInput>;
};

export type ReactToStoryAuthAccountRolesOnConflict = {
  constraint: ReactToStoryAuthAccountRolesConstraint;
  update_columns: Array<ReactToStoryAuthAccountRolesUpdateColumn>;
  where?: Maybe<ReactToStoryAuthAccountRolesBoolExp>;
};

export enum ReactToStoryAuthAccountRolesUpdateColumn {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Role = 'role'
}

export type ReactToStoryAuthAccountsArrRelInsertInput = {
  data: Array<ReactToStoryAuthAccountsInsertInput>;
  on_conflict?: Maybe<ReactToStoryAuthAccountsOnConflict>;
};

export type ReactToStoryAuthAccountsBoolExp = {
  _and?: Maybe<Array<Maybe<ReactToStoryAuthAccountsBoolExp>>>;
  _not?: Maybe<ReactToStoryAuthAccountsBoolExp>;
  _or?: Maybe<Array<Maybe<ReactToStoryAuthAccountsBoolExp>>>;
  account_providers?: Maybe<ReactToStoryAuthAccountProvidersBoolExp>;
  account_roles?: Maybe<ReactToStoryAuthAccountRolesBoolExp>;
  active?: Maybe<ReactToStoryBooleanComparisonExp>;
  created_at?: Maybe<ReactToStoryTimestamptzComparisonExp>;
  custom_register_data?: Maybe<ReactToStoryJsonbComparisonExp>;
  default_role?: Maybe<ReactToStoryStringComparisonExp>;
  email?: Maybe<ReactToStoryCitextComparisonExp>;
  id?: Maybe<ReactToStoryUuidComparisonExp>;
  is_anonymous?: Maybe<ReactToStoryBooleanComparisonExp>;
  mfa_enabled?: Maybe<ReactToStoryBooleanComparisonExp>;
  new_email?: Maybe<ReactToStoryCitextComparisonExp>;
  otp_secret?: Maybe<ReactToStoryStringComparisonExp>;
  password_hash?: Maybe<ReactToStoryStringComparisonExp>;
  refresh_tokens?: Maybe<ReactToStoryAuthRefreshTokensBoolExp>;
  role?: Maybe<ReactToStoryAuthRolesBoolExp>;
  ticket?: Maybe<ReactToStoryUuidComparisonExp>;
  ticket_expires_at?: Maybe<ReactToStoryTimestamptzComparisonExp>;
  updated_at?: Maybe<ReactToStoryTimestamptzComparisonExp>;
  user?: Maybe<ReactToStoryUserBoolExp>;
  user_id?: Maybe<ReactToStoryUuidComparisonExp>;
};

export enum ReactToStoryAuthAccountsConstraint {
  /** unique or primary key constraint */
  AccountsEmailKey = 'accounts_email_key',
  /** unique or primary key constraint */
  AccountsNewEmailKey = 'accounts_new_email_key',
  /** unique or primary key constraint */
  AccountsPkey = 'accounts_pkey',
  /** unique or primary key constraint */
  AccountsUserIdKey = 'accounts_user_id_key'
}

export type ReactToStoryAuthAccountsInsertInput = {
  account_providers?: Maybe<ReactToStoryAuthAccountProvidersArrRelInsertInput>;
  account_roles?: Maybe<ReactToStoryAuthAccountRolesArrRelInsertInput>;
  active?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  custom_register_data?: Maybe<Scalars['jsonb']>;
  default_role?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['citext']>;
  id?: Maybe<Scalars['uuid']>;
  is_anonymous?: Maybe<Scalars['Boolean']>;
  mfa_enabled?: Maybe<Scalars['Boolean']>;
  new_email?: Maybe<Scalars['citext']>;
  otp_secret?: Maybe<Scalars['String']>;
  password_hash?: Maybe<Scalars['String']>;
  refresh_tokens?: Maybe<ReactToStoryAuthRefreshTokensArrRelInsertInput>;
  role?: Maybe<ReactToStoryAuthRolesObjRelInsertInput>;
  ticket?: Maybe<Scalars['uuid']>;
  ticket_expires_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<ReactToStoryUserObjRelInsertInput>;
  user_id?: Maybe<Scalars['uuid']>;
};

export type ReactToStoryAuthAccountsObjRelInsertInput = {
  data: ReactToStoryAuthAccountsInsertInput;
  on_conflict?: Maybe<ReactToStoryAuthAccountsOnConflict>;
};

export type ReactToStoryAuthAccountsOnConflict = {
  constraint: ReactToStoryAuthAccountsConstraint;
  update_columns: Array<ReactToStoryAuthAccountsUpdateColumn>;
  where?: Maybe<ReactToStoryAuthAccountsBoolExp>;
};

export enum ReactToStoryAuthAccountsUpdateColumn {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomRegisterData = 'custom_register_data',
  /** column name */
  DefaultRole = 'default_role',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  IsAnonymous = 'is_anonymous',
  /** column name */
  MfaEnabled = 'mfa_enabled',
  /** column name */
  NewEmail = 'new_email',
  /** column name */
  OtpSecret = 'otp_secret',
  /** column name */
  PasswordHash = 'password_hash',
  /** column name */
  Ticket = 'ticket',
  /** column name */
  TicketExpiresAt = 'ticket_expires_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type ReactToStoryAuthProvidersBoolExp = {
  _and?: Maybe<Array<Maybe<ReactToStoryAuthProvidersBoolExp>>>;
  _not?: Maybe<ReactToStoryAuthProvidersBoolExp>;
  _or?: Maybe<Array<Maybe<ReactToStoryAuthProvidersBoolExp>>>;
  account_providers?: Maybe<ReactToStoryAuthAccountProvidersBoolExp>;
  provider?: Maybe<ReactToStoryStringComparisonExp>;
};

export enum ReactToStoryAuthProvidersConstraint {
  /** unique or primary key constraint */
  ProvidersPkey = 'providers_pkey'
}

export type ReactToStoryAuthProvidersInsertInput = {
  account_providers?: Maybe<ReactToStoryAuthAccountProvidersArrRelInsertInput>;
  provider?: Maybe<Scalars['String']>;
};

export type ReactToStoryAuthProvidersObjRelInsertInput = {
  data: ReactToStoryAuthProvidersInsertInput;
  on_conflict?: Maybe<ReactToStoryAuthProvidersOnConflict>;
};

export type ReactToStoryAuthProvidersOnConflict = {
  constraint: ReactToStoryAuthProvidersConstraint;
  update_columns: Array<ReactToStoryAuthProvidersUpdateColumn>;
  where?: Maybe<ReactToStoryAuthProvidersBoolExp>;
};

export enum ReactToStoryAuthProvidersUpdateColumn {
  /** column name */
  Provider = 'provider'
}

export type ReactToStoryAuthRefreshTokensArrRelInsertInput = {
  data: Array<ReactToStoryAuthRefreshTokensInsertInput>;
  on_conflict?: Maybe<ReactToStoryAuthRefreshTokensOnConflict>;
};

export type ReactToStoryAuthRefreshTokensBoolExp = {
  _and?: Maybe<Array<Maybe<ReactToStoryAuthRefreshTokensBoolExp>>>;
  _not?: Maybe<ReactToStoryAuthRefreshTokensBoolExp>;
  _or?: Maybe<Array<Maybe<ReactToStoryAuthRefreshTokensBoolExp>>>;
  account?: Maybe<ReactToStoryAuthAccountsBoolExp>;
  account_id?: Maybe<ReactToStoryUuidComparisonExp>;
  created_at?: Maybe<ReactToStoryTimestamptzComparisonExp>;
  expires_at?: Maybe<ReactToStoryTimestamptzComparisonExp>;
  refresh_token?: Maybe<ReactToStoryUuidComparisonExp>;
};

export enum ReactToStoryAuthRefreshTokensConstraint {
  /** unique or primary key constraint */
  RefreshTokensPkey = 'refresh_tokens_pkey'
}

export type ReactToStoryAuthRefreshTokensInsertInput = {
  account?: Maybe<ReactToStoryAuthAccountsObjRelInsertInput>;
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  refresh_token?: Maybe<Scalars['uuid']>;
};

export type ReactToStoryAuthRefreshTokensOnConflict = {
  constraint: ReactToStoryAuthRefreshTokensConstraint;
  update_columns: Array<ReactToStoryAuthRefreshTokensUpdateColumn>;
  where?: Maybe<ReactToStoryAuthRefreshTokensBoolExp>;
};

export enum ReactToStoryAuthRefreshTokensUpdateColumn {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  RefreshToken = 'refresh_token'
}

export type ReactToStoryAuthRolesBoolExp = {
  _and?: Maybe<Array<Maybe<ReactToStoryAuthRolesBoolExp>>>;
  _not?: Maybe<ReactToStoryAuthRolesBoolExp>;
  _or?: Maybe<Array<Maybe<ReactToStoryAuthRolesBoolExp>>>;
  account_roles?: Maybe<ReactToStoryAuthAccountRolesBoolExp>;
  accounts?: Maybe<ReactToStoryAuthAccountsBoolExp>;
  role?: Maybe<ReactToStoryStringComparisonExp>;
};

export enum ReactToStoryAuthRolesConstraint {
  /** unique or primary key constraint */
  RolesPkey = 'roles_pkey'
}

export type ReactToStoryAuthRolesInsertInput = {
  account_roles?: Maybe<ReactToStoryAuthAccountRolesArrRelInsertInput>;
  accounts?: Maybe<ReactToStoryAuthAccountsArrRelInsertInput>;
  role?: Maybe<Scalars['String']>;
};

export type ReactToStoryAuthRolesObjRelInsertInput = {
  data: ReactToStoryAuthRolesInsertInput;
  on_conflict?: Maybe<ReactToStoryAuthRolesOnConflict>;
};

export type ReactToStoryAuthRolesOnConflict = {
  constraint: ReactToStoryAuthRolesConstraint;
  update_columns: Array<ReactToStoryAuthRolesUpdateColumn>;
  where?: Maybe<ReactToStoryAuthRolesBoolExp>;
};

export enum ReactToStoryAuthRolesUpdateColumn {
  /** column name */
  Role = 'role'
}

export type ReactToStoryBooleanComparisonExp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

export type ReactToStoryCitextComparisonExp = {
  _eq?: Maybe<Scalars['citext']>;
  _gt?: Maybe<Scalars['citext']>;
  _gte?: Maybe<Scalars['citext']>;
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['citext']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['citext']>;
  _lte?: Maybe<Scalars['citext']>;
  _neq?: Maybe<Scalars['citext']>;
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['citext']>>;
  _nlike?: Maybe<Scalars['String']>;
  _nsimilar?: Maybe<Scalars['String']>;
  _similar?: Maybe<Scalars['String']>;
};

export type ReactToStoryDateComparisonExp = {
  _eq?: Maybe<Scalars['date']>;
  _gt?: Maybe<Scalars['date']>;
  _gte?: Maybe<Scalars['date']>;
  _in?: Maybe<Array<Scalars['date']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['date']>;
  _lte?: Maybe<Scalars['date']>;
  _neq?: Maybe<Scalars['date']>;
  _nin?: Maybe<Array<Scalars['date']>>;
};

export type ReactToStoryFeedbackArrRelInsertInput = {
  data: Array<ReactToStoryFeedbackInsertInput>;
  on_conflict?: Maybe<ReactToStoryFeedbackOnConflict>;
};

export type ReactToStoryFeedbackBoolExp = {
  _and?: Maybe<Array<Maybe<ReactToStoryFeedbackBoolExp>>>;
  _not?: Maybe<ReactToStoryFeedbackBoolExp>;
  _or?: Maybe<Array<Maybe<ReactToStoryFeedbackBoolExp>>>;
  created_at?: Maybe<ReactToStoryTimestamptzComparisonExp>;
  description?: Maybe<ReactToStoryStringComparisonExp>;
  id?: Maybe<ReactToStoryUuidComparisonExp>;
  updated_at?: Maybe<ReactToStoryTimestamptzComparisonExp>;
  user?: Maybe<ReactToStoryUserBoolExp>;
  user_id?: Maybe<ReactToStoryUuidComparisonExp>;
};

export enum ReactToStoryFeedbackConstraint {
  /** unique or primary key constraint */
  FeedbackPkey = 'feedback_pkey'
}

export type ReactToStoryFeedbackInsertInput = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<ReactToStoryUserObjRelInsertInput>;
  user_id?: Maybe<Scalars['uuid']>;
};

export type ReactToStoryFeedbackOnConflict = {
  constraint: ReactToStoryFeedbackConstraint;
  update_columns: Array<ReactToStoryFeedbackUpdateColumn>;
  where?: Maybe<ReactToStoryFeedbackBoolExp>;
};

export enum ReactToStoryFeedbackUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type ReactToStoryFlagBoolExp = {
  _and?: Maybe<Array<Maybe<ReactToStoryFlagBoolExp>>>;
  _not?: Maybe<ReactToStoryFlagBoolExp>;
  _or?: Maybe<Array<Maybe<ReactToStoryFlagBoolExp>>>;
  component_name?: Maybe<ReactToStoryStringComparisonExp>;
  display_name?: Maybe<ReactToStoryStringComparisonExp>;
  file_name?: Maybe<ReactToStoryStringComparisonExp>;
  flags?: Maybe<ReactToStoryUserFlagBoolExp>;
  id?: Maybe<ReactToStoryUuidComparisonExp>;
};

export enum ReactToStoryFlagConstraint {
  /** unique or primary key constraint */
  FlagPkey = 'flag_pkey'
}

export type ReactToStoryFlagInsertInput = {
  component_name?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  file_name?: Maybe<Scalars['String']>;
  flags?: Maybe<ReactToStoryUserFlagArrRelInsertInput>;
  id?: Maybe<Scalars['uuid']>;
};

export type ReactToStoryFlagObjRelInsertInput = {
  data: ReactToStoryFlagInsertInput;
  on_conflict?: Maybe<ReactToStoryFlagOnConflict>;
};

export type ReactToStoryFlagOnConflict = {
  constraint: ReactToStoryFlagConstraint;
  update_columns: Array<ReactToStoryFlagUpdateColumn>;
  where?: Maybe<ReactToStoryFlagBoolExp>;
};

export enum ReactToStoryFlagUpdateColumn {
  /** column name */
  ComponentName = 'component_name',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  FileName = 'file_name',
  /** column name */
  Id = 'id'
}

export type ReactToStoryIntComparisonExp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};

export type ReactToStoryJsonbComparisonExp = {
  _contained_in?: Maybe<Scalars['jsonb']>;
  _contains?: Maybe<Scalars['jsonb']>;
  _eq?: Maybe<Scalars['jsonb']>;
  _gt?: Maybe<Scalars['jsonb']>;
  _gte?: Maybe<Scalars['jsonb']>;
  _has_key?: Maybe<Scalars['String']>;
  _has_keys_all?: Maybe<Array<Scalars['String']>>;
  _has_keys_any?: Maybe<Array<Scalars['String']>>;
  _in?: Maybe<Array<Scalars['jsonb']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['jsonb']>;
  _lte?: Maybe<Scalars['jsonb']>;
  _neq?: Maybe<Scalars['jsonb']>;
  _nin?: Maybe<Array<Scalars['jsonb']>>;
};

export type ReactToStoryModeratorNoteArrRelInsertInput = {
  data: Array<ReactToStoryModeratorNoteInsertInput>;
  on_conflict?: Maybe<ReactToStoryModeratorNoteOnConflict>;
};

export type ReactToStoryModeratorNoteBoolExp = {
  _and?: Maybe<Array<Maybe<ReactToStoryModeratorNoteBoolExp>>>;
  _not?: Maybe<ReactToStoryModeratorNoteBoolExp>;
  _or?: Maybe<Array<Maybe<ReactToStoryModeratorNoteBoolExp>>>;
  action?: Maybe<ReactToStoryStringComparisonExp>;
  created_at?: Maybe<ReactToStoryTimestamptzComparisonExp>;
  id?: Maybe<ReactToStoryUuidComparisonExp>;
  note?: Maybe<ReactToStoryStringComparisonExp>;
  story?: Maybe<ReactToStoryStoryBoolExp>;
  story_id?: Maybe<ReactToStoryUuidComparisonExp>;
  updated_at?: Maybe<ReactToStoryTimestamptzComparisonExp>;
  user?: Maybe<ReactToStoryUserBoolExp>;
  user_id?: Maybe<ReactToStoryUuidComparisonExp>;
};

export enum ReactToStoryModeratorNoteConstraint {
  /** unique or primary key constraint */
  ModeratorNotePkey = 'moderator_note_pkey'
}

export type ReactToStoryModeratorNoteInsertInput = {
  action?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  story?: Maybe<ReactToStoryStoryObjRelInsertInput>;
  story_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<ReactToStoryUserObjRelInsertInput>;
  user_id?: Maybe<Scalars['uuid']>;
};

export type ReactToStoryModeratorNoteOnConflict = {
  constraint: ReactToStoryModeratorNoteConstraint;
  update_columns: Array<ReactToStoryModeratorNoteUpdateColumn>;
  where?: Maybe<ReactToStoryModeratorNoteBoolExp>;
};

export enum ReactToStoryModeratorNoteUpdateColumn {
  /** column name */
  Action = 'action',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Note = 'note',
  /** column name */
  StoryId = 'story_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type ReactToStoryOutput = {
  __typename?: 'ReactToStoryOutput';
  id: Scalars['uuid'];
  reaction_type: Scalars['String'];
  story_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

export type ReactToStoryPromptBoolExp = {
  _and?: Maybe<Array<Maybe<ReactToStoryPromptBoolExp>>>;
  _not?: Maybe<ReactToStoryPromptBoolExp>;
  _or?: Maybe<Array<Maybe<ReactToStoryPromptBoolExp>>>;
  created_at?: Maybe<ReactToStoryTimestamptzComparisonExp>;
  description?: Maybe<ReactToStoryStringComparisonExp>;
  id?: Maybe<ReactToStoryUuidComparisonExp>;
  position?: Maybe<ReactToStoryIntComparisonExp>;
  replies?: Maybe<ReactToStoryReplyBoolExp>;
  title?: Maybe<ReactToStoryStringComparisonExp>;
  updated_at?: Maybe<ReactToStoryTimestamptzComparisonExp>;
};

export enum ReactToStoryPromptConstraint {
  /** unique or primary key constraint */
  PromptPkey = 'prompt_pkey'
}

export type ReactToStoryPromptInsertInput = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  position?: Maybe<Scalars['Int']>;
  replies?: Maybe<ReactToStoryReplyArrRelInsertInput>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

export type ReactToStoryPromptObjRelInsertInput = {
  data: ReactToStoryPromptInsertInput;
  on_conflict?: Maybe<ReactToStoryPromptOnConflict>;
};

export type ReactToStoryPromptOnConflict = {
  constraint: ReactToStoryPromptConstraint;
  update_columns: Array<ReactToStoryPromptUpdateColumn>;
  where?: Maybe<ReactToStoryPromptBoolExp>;
};

export enum ReactToStoryPromptUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Position = 'position',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type ReactToStoryReactionArrRelInsertInput = {
  data: Array<ReactToStoryReactionInsertInput>;
  on_conflict?: Maybe<ReactToStoryReactionOnConflict>;
};

export type ReactToStoryReactionBoolExp = {
  _and?: Maybe<Array<Maybe<ReactToStoryReactionBoolExp>>>;
  _not?: Maybe<ReactToStoryReactionBoolExp>;
  _or?: Maybe<Array<Maybe<ReactToStoryReactionBoolExp>>>;
  created_at?: Maybe<ReactToStoryTimestamptzComparisonExp>;
  id?: Maybe<ReactToStoryUuidComparisonExp>;
  reaction_type?: Maybe<ReactToStoryStringComparisonExp>;
  story?: Maybe<ReactToStoryStoryBoolExp>;
  story_id?: Maybe<ReactToStoryUuidComparisonExp>;
  updated_at?: Maybe<ReactToStoryTimestamptzComparisonExp>;
  user?: Maybe<ReactToStoryUserBoolExp>;
  user_id?: Maybe<ReactToStoryUuidComparisonExp>;
  viewed_at?: Maybe<ReactToStoryTimestamptzComparisonExp>;
};

export enum ReactToStoryReactionConstraint {
  /** unique or primary key constraint */
  ReactionPkey = 'reaction_pkey'
}

export type ReactToStoryReactionInsertInput = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  reaction_type?: Maybe<Scalars['String']>;
  story?: Maybe<ReactToStoryStoryObjRelInsertInput>;
  story_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<ReactToStoryUserObjRelInsertInput>;
  user_id?: Maybe<Scalars['uuid']>;
  viewed_at?: Maybe<Scalars['timestamptz']>;
};

export type ReactToStoryReactionOnConflict = {
  constraint: ReactToStoryReactionConstraint;
  update_columns: Array<ReactToStoryReactionUpdateColumn>;
  where?: Maybe<ReactToStoryReactionBoolExp>;
};

export enum ReactToStoryReactionUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ReactionType = 'reaction_type',
  /** column name */
  StoryId = 'story_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  ViewedAt = 'viewed_at'
}

export type ReactToStoryReplyArrRelInsertInput = {
  data: Array<ReactToStoryReplyInsertInput>;
  on_conflict?: Maybe<ReactToStoryReplyOnConflict>;
};

export type ReactToStoryReplyBoolExp = {
  _and?: Maybe<Array<Maybe<ReactToStoryReplyBoolExp>>>;
  _not?: Maybe<ReactToStoryReplyBoolExp>;
  _or?: Maybe<Array<Maybe<ReactToStoryReplyBoolExp>>>;
  content?: Maybe<ReactToStoryStringComparisonExp>;
  created_at?: Maybe<ReactToStoryTimestamptzComparisonExp>;
  id?: Maybe<ReactToStoryUuidComparisonExp>;
  prompt?: Maybe<ReactToStoryPromptBoolExp>;
  prompt_id?: Maybe<ReactToStoryUuidComparisonExp>;
  story?: Maybe<ReactToStoryStoryBoolExp>;
  story_id?: Maybe<ReactToStoryUuidComparisonExp>;
  updated_at?: Maybe<ReactToStoryTimestamptzComparisonExp>;
};

export enum ReactToStoryReplyConstraint {
  /** unique or primary key constraint */
  ReplyPkey = 'reply_pkey'
}

export type ReactToStoryReplyInsertInput = {
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  prompt?: Maybe<ReactToStoryPromptObjRelInsertInput>;
  prompt_id?: Maybe<Scalars['uuid']>;
  story?: Maybe<ReactToStoryStoryObjRelInsertInput>;
  story_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

export type ReactToStoryReplyOnConflict = {
  constraint: ReactToStoryReplyConstraint;
  update_columns: Array<ReactToStoryReplyUpdateColumn>;
  where?: Maybe<ReactToStoryReplyBoolExp>;
};

export enum ReactToStoryReplyUpdateColumn {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PromptId = 'prompt_id',
  /** column name */
  StoryId = 'story_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type ReactToStoryResourceArrRelInsertInput = {
  data: Array<ReactToStoryResourceInsertInput>;
  on_conflict?: Maybe<ReactToStoryResourceOnConflict>;
};

export type ReactToStoryResourceBoolExp = {
  _and?: Maybe<Array<Maybe<ReactToStoryResourceBoolExp>>>;
  _not?: Maybe<ReactToStoryResourceBoolExp>;
  _or?: Maybe<Array<Maybe<ReactToStoryResourceBoolExp>>>;
  created_at?: Maybe<ReactToStoryTimestamptzComparisonExp>;
  id?: Maybe<ReactToStoryUuidComparisonExp>;
  resource_type?: Maybe<ReactToStoryResourceTypeBoolExp>;
  story?: Maybe<ReactToStoryStoryBoolExp>;
  story_id?: Maybe<ReactToStoryUuidComparisonExp>;
  type?: Maybe<ReactToStoryResourceTypeEnumComparisonExp>;
  updated_at?: Maybe<ReactToStoryTimestamptzComparisonExp>;
  url?: Maybe<ReactToStoryStringComparisonExp>;
};

export enum ReactToStoryResourceConstraint {
  /** unique or primary key constraint */
  ResourcePkey = 'resource_pkey',
  /** unique or primary key constraint */
  ResourceStoryIdKey = 'resource_story_id_key'
}

export type ReactToStoryResourceInsertInput = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  resource_type?: Maybe<ReactToStoryResourceTypeObjRelInsertInput>;
  story?: Maybe<ReactToStoryStoryObjRelInsertInput>;
  story_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<ReactToStoryResourceTypeEnum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

export type ReactToStoryResourceObjRelInsertInput = {
  data: ReactToStoryResourceInsertInput;
  on_conflict?: Maybe<ReactToStoryResourceOnConflict>;
};

export type ReactToStoryResourceOnConflict = {
  constraint: ReactToStoryResourceConstraint;
  update_columns: Array<ReactToStoryResourceUpdateColumn>;
  where?: Maybe<ReactToStoryResourceBoolExp>;
};

export type ReactToStoryResourceTypeBoolExp = {
  _and?: Maybe<Array<Maybe<ReactToStoryResourceTypeBoolExp>>>;
  _not?: Maybe<ReactToStoryResourceTypeBoolExp>;
  _or?: Maybe<Array<Maybe<ReactToStoryResourceTypeBoolExp>>>;
  resources?: Maybe<ReactToStoryResourceBoolExp>;
  value?: Maybe<ReactToStoryStringComparisonExp>;
};

export enum ReactToStoryResourceTypeConstraint {
  /** unique or primary key constraint */
  ResourceTypePkey = 'resource_type_pkey'
}

export enum ReactToStoryResourceTypeEnum {
  Audio = 'AUDIO',
  Image = 'IMAGE',
  Video = 'VIDEO'
}

export type ReactToStoryResourceTypeEnumComparisonExp = {
  _eq?: Maybe<ReactToStoryResourceTypeEnum>;
  _in?: Maybe<Array<ReactToStoryResourceTypeEnum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<ReactToStoryResourceTypeEnum>;
  _nin?: Maybe<Array<ReactToStoryResourceTypeEnum>>;
};

export type ReactToStoryResourceTypeInsertInput = {
  resources?: Maybe<ReactToStoryResourceArrRelInsertInput>;
  value?: Maybe<Scalars['String']>;
};

export type ReactToStoryResourceTypeObjRelInsertInput = {
  data: ReactToStoryResourceTypeInsertInput;
  on_conflict?: Maybe<ReactToStoryResourceTypeOnConflict>;
};

export type ReactToStoryResourceTypeOnConflict = {
  constraint: ReactToStoryResourceTypeConstraint;
  update_columns: Array<ReactToStoryResourceTypeUpdateColumn>;
  where?: Maybe<ReactToStoryResourceTypeBoolExp>;
};

export enum ReactToStoryResourceTypeUpdateColumn {
  /** column name */
  Value = 'value'
}

export enum ReactToStoryResourceUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  StoryId = 'story_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url'
}

export type ReactToStoryShareArrRelInsertInput = {
  data: Array<ReactToStoryShareInsertInput>;
  on_conflict?: Maybe<ReactToStoryShareOnConflict>;
};

export type ReactToStoryShareBoolExp = {
  _and?: Maybe<Array<Maybe<ReactToStoryShareBoolExp>>>;
  _not?: Maybe<ReactToStoryShareBoolExp>;
  _or?: Maybe<Array<Maybe<ReactToStoryShareBoolExp>>>;
  activity_type?: Maybe<ReactToStoryStringComparisonExp>;
  created_at?: Maybe<ReactToStoryTimestamptzComparisonExp>;
  id?: Maybe<ReactToStoryUuidComparisonExp>;
  story?: Maybe<ReactToStoryStoryBoolExp>;
  story_id?: Maybe<ReactToStoryUuidComparisonExp>;
  updated_at?: Maybe<ReactToStoryTimestamptzComparisonExp>;
  user?: Maybe<ReactToStoryUserBoolExp>;
  user_id?: Maybe<ReactToStoryUuidComparisonExp>;
};

export enum ReactToStoryShareConstraint {
  /** unique or primary key constraint */
  SharePkey = 'share_pkey'
}

export type ReactToStoryShareInsertInput = {
  activity_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  story?: Maybe<ReactToStoryStoryObjRelInsertInput>;
  story_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<ReactToStoryUserObjRelInsertInput>;
  user_id?: Maybe<Scalars['uuid']>;
};

export type ReactToStoryShareOnConflict = {
  constraint: ReactToStoryShareConstraint;
  update_columns: Array<ReactToStoryShareUpdateColumn>;
  where?: Maybe<ReactToStoryShareBoolExp>;
};

export enum ReactToStoryShareUpdateColumn {
  /** column name */
  ActivityType = 'activity_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  StoryId = 'story_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type ReactToStoryStoryArrRelInsertInput = {
  data: Array<ReactToStoryStoryInsertInput>;
  on_conflict?: Maybe<ReactToStoryStoryOnConflict>;
};

export type ReactToStoryStoryBoolExp = {
  _and?: Maybe<Array<Maybe<ReactToStoryStoryBoolExp>>>;
  _not?: Maybe<ReactToStoryStoryBoolExp>;
  _or?: Maybe<Array<Maybe<ReactToStoryStoryBoolExp>>>;
  caption?: Maybe<ReactToStoryStringComparisonExp>;
  content?: Maybe<ReactToStoryStringComparisonExp>;
  created_at?: Maybe<ReactToStoryTimestamptzComparisonExp>;
  first_submitted_at?: Maybe<ReactToStoryTimestamptzComparisonExp>;
  id?: Maybe<ReactToStoryUuidComparisonExp>;
  is_active?: Maybe<ReactToStoryBooleanComparisonExp>;
  is_name_included?: Maybe<ReactToStoryBooleanComparisonExp>;
  is_shareable?: Maybe<ReactToStoryBooleanComparisonExp>;
  last_approved_at?: Maybe<ReactToStoryTimestamptzComparisonExp>;
  last_rejected_at?: Maybe<ReactToStoryTimestamptzComparisonExp>;
  last_submitted_at?: Maybe<ReactToStoryTimestamptzComparisonExp>;
  moderator_notes?: Maybe<ReactToStoryModeratorNoteBoolExp>;
  number_of_approvals?: Maybe<ReactToStoryIntComparisonExp>;
  number_of_rejections?: Maybe<ReactToStoryIntComparisonExp>;
  published_at?: Maybe<ReactToStoryTimestamptzComparisonExp>;
  ranking?: Maybe<ReactToStoryIntComparisonExp>;
  reactions?: Maybe<ReactToStoryReactionBoolExp>;
  replies?: Maybe<ReactToStoryReplyBoolExp>;
  resource?: Maybe<ReactToStoryResourceBoolExp>;
  shares?: Maybe<ReactToStoryShareBoolExp>;
  show_suburb?: Maybe<ReactToStoryBooleanComparisonExp>;
  status?: Maybe<ReactToStoryStringComparisonExp>;
  story_saves?: Maybe<ReactToStoryStorySaveBoolExp>;
  story_violations?: Maybe<ReactToStoryStoryViolationBoolExp>;
  tags?: Maybe<ReactToStoryStoryTagBoolExp>;
  title?: Maybe<ReactToStoryStringComparisonExp>;
  type?: Maybe<ReactToStoryStringComparisonExp>;
  updated_at?: Maybe<ReactToStoryTimestamptzComparisonExp>;
  user?: Maybe<ReactToStoryUserBoolExp>;
  user_id?: Maybe<ReactToStoryUuidComparisonExp>;
  views?: Maybe<ReactToStoryIntComparisonExp>;
  violation_notes?: Maybe<ReactToStoryViolationNoteBoolExp>;
};

export enum ReactToStoryStoryConstraint {
  /** unique or primary key constraint */
  StoryPkey = 'story_pkey'
}

export type ReactToStoryStoryInsertInput = {
  caption?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  first_submitted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  is_active?: Maybe<Scalars['Boolean']>;
  is_name_included?: Maybe<Scalars['Boolean']>;
  is_shareable?: Maybe<Scalars['Boolean']>;
  last_approved_at?: Maybe<Scalars['timestamptz']>;
  last_rejected_at?: Maybe<Scalars['timestamptz']>;
  last_submitted_at?: Maybe<Scalars['timestamptz']>;
  moderator_notes?: Maybe<ReactToStoryModeratorNoteArrRelInsertInput>;
  number_of_approvals?: Maybe<Scalars['Int']>;
  number_of_rejections?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['timestamptz']>;
  ranking?: Maybe<Scalars['Int']>;
  reactions?: Maybe<ReactToStoryReactionArrRelInsertInput>;
  replies?: Maybe<ReactToStoryReplyArrRelInsertInput>;
  resource?: Maybe<ReactToStoryResourceObjRelInsertInput>;
  shares?: Maybe<ReactToStoryShareArrRelInsertInput>;
  show_suburb?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
  story_saves?: Maybe<ReactToStoryStorySaveArrRelInsertInput>;
  story_violations?: Maybe<ReactToStoryStoryViolationArrRelInsertInput>;
  tags?: Maybe<ReactToStoryStoryTagArrRelInsertInput>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<ReactToStoryUserObjRelInsertInput>;
  user_id?: Maybe<Scalars['uuid']>;
  views?: Maybe<Scalars['Int']>;
  violation_notes?: Maybe<ReactToStoryViolationNoteArrRelInsertInput>;
};

export type ReactToStoryStoryObjRelInsertInput = {
  data: ReactToStoryStoryInsertInput;
  on_conflict?: Maybe<ReactToStoryStoryOnConflict>;
};

export type ReactToStoryStoryOnConflict = {
  constraint: ReactToStoryStoryConstraint;
  update_columns: Array<ReactToStoryStoryUpdateColumn>;
  where?: Maybe<ReactToStoryStoryBoolExp>;
};

export type ReactToStoryStorySaveArrRelInsertInput = {
  data: Array<ReactToStoryStorySaveInsertInput>;
  on_conflict?: Maybe<ReactToStoryStorySaveOnConflict>;
};

export type ReactToStoryStorySaveBoolExp = {
  _and?: Maybe<Array<Maybe<ReactToStoryStorySaveBoolExp>>>;
  _not?: Maybe<ReactToStoryStorySaveBoolExp>;
  _or?: Maybe<Array<Maybe<ReactToStoryStorySaveBoolExp>>>;
  created_at?: Maybe<ReactToStoryTimestamptzComparisonExp>;
  id?: Maybe<ReactToStoryUuidComparisonExp>;
  story?: Maybe<ReactToStoryStoryBoolExp>;
  story_id?: Maybe<ReactToStoryUuidComparisonExp>;
  user?: Maybe<ReactToStoryUserBoolExp>;
  user_id?: Maybe<ReactToStoryUuidComparisonExp>;
};

export enum ReactToStoryStorySaveConstraint {
  /** unique or primary key constraint */
  StorySavePkey = 'story_save_pkey',
  /** unique or primary key constraint */
  UniqueUserIdStoryId = 'unique_user_id_story_id'
}

export type ReactToStoryStorySaveInsertInput = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  story?: Maybe<ReactToStoryStoryObjRelInsertInput>;
  story_id?: Maybe<Scalars['uuid']>;
  user?: Maybe<ReactToStoryUserObjRelInsertInput>;
  user_id?: Maybe<Scalars['uuid']>;
};

export type ReactToStoryStorySaveOnConflict = {
  constraint: ReactToStoryStorySaveConstraint;
  update_columns: Array<ReactToStoryStorySaveUpdateColumn>;
  where?: Maybe<ReactToStoryStorySaveBoolExp>;
};

export enum ReactToStoryStorySaveUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  StoryId = 'story_id',
  /** column name */
  UserId = 'user_id'
}

export type ReactToStoryStoryTagArrRelInsertInput = {
  data: Array<ReactToStoryStoryTagInsertInput>;
  on_conflict?: Maybe<ReactToStoryStoryTagOnConflict>;
};

export type ReactToStoryStoryTagBoolExp = {
  _and?: Maybe<Array<Maybe<ReactToStoryStoryTagBoolExp>>>;
  _not?: Maybe<ReactToStoryStoryTagBoolExp>;
  _or?: Maybe<Array<Maybe<ReactToStoryStoryTagBoolExp>>>;
  id?: Maybe<ReactToStoryUuidComparisonExp>;
  story?: Maybe<ReactToStoryStoryBoolExp>;
  story_id?: Maybe<ReactToStoryUuidComparisonExp>;
  tag?: Maybe<ReactToStoryTagBoolExp>;
  tag_id?: Maybe<ReactToStoryUuidComparisonExp>;
};

export enum ReactToStoryStoryTagConstraint {
  /** unique or primary key constraint */
  StoryTagPkey = 'story_tag_pkey',
  /** unique or primary key constraint */
  UniqueStoryIdTagId = 'unique_story_id_tag_id'
}

export type ReactToStoryStoryTagInsertInput = {
  id?: Maybe<Scalars['uuid']>;
  story?: Maybe<ReactToStoryStoryObjRelInsertInput>;
  story_id?: Maybe<Scalars['uuid']>;
  tag?: Maybe<ReactToStoryTagObjRelInsertInput>;
  tag_id?: Maybe<Scalars['uuid']>;
};

export type ReactToStoryStoryTagOnConflict = {
  constraint: ReactToStoryStoryTagConstraint;
  update_columns: Array<ReactToStoryStoryTagUpdateColumn>;
  where?: Maybe<ReactToStoryStoryTagBoolExp>;
};

export enum ReactToStoryStoryTagUpdateColumn {
  /** column name */
  Id = 'id',
  /** column name */
  StoryId = 'story_id',
  /** column name */
  TagId = 'tag_id'
}

export enum ReactToStoryStoryUpdateColumn {
  /** column name */
  Caption = 'caption',
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FirstSubmittedAt = 'first_submitted_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsNameIncluded = 'is_name_included',
  /** column name */
  IsShareable = 'is_shareable',
  /** column name */
  LastApprovedAt = 'last_approved_at',
  /** column name */
  LastRejectedAt = 'last_rejected_at',
  /** column name */
  LastSubmittedAt = 'last_submitted_at',
  /** column name */
  NumberOfApprovals = 'number_of_approvals',
  /** column name */
  NumberOfRejections = 'number_of_rejections',
  /** column name */
  PublishedAt = 'published_at',
  /** column name */
  Ranking = 'ranking',
  /** column name */
  ShowSuburb = 'show_suburb',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Views = 'views'
}

export type ReactToStoryStoryViolationArrRelInsertInput = {
  data: Array<ReactToStoryStoryViolationInsertInput>;
  on_conflict?: Maybe<ReactToStoryStoryViolationOnConflict>;
};

export type ReactToStoryStoryViolationBoolExp = {
  _and?: Maybe<Array<Maybe<ReactToStoryStoryViolationBoolExp>>>;
  _not?: Maybe<ReactToStoryStoryViolationBoolExp>;
  _or?: Maybe<Array<Maybe<ReactToStoryStoryViolationBoolExp>>>;
  created_at?: Maybe<ReactToStoryTimestamptzComparisonExp>;
  id?: Maybe<ReactToStoryUuidComparisonExp>;
  story?: Maybe<ReactToStoryStoryBoolExp>;
  story_id?: Maybe<ReactToStoryUuidComparisonExp>;
  user?: Maybe<ReactToStoryUserBoolExp>;
  user_id?: Maybe<ReactToStoryUuidComparisonExp>;
};

export enum ReactToStoryStoryViolationConstraint {
  /** unique or primary key constraint */
  StoryViolationPkey = 'story_violation_pkey'
}

export type ReactToStoryStoryViolationInsertInput = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  story?: Maybe<ReactToStoryStoryObjRelInsertInput>;
  story_id?: Maybe<Scalars['uuid']>;
  user?: Maybe<ReactToStoryUserObjRelInsertInput>;
  user_id?: Maybe<Scalars['uuid']>;
};

export type ReactToStoryStoryViolationOnConflict = {
  constraint: ReactToStoryStoryViolationConstraint;
  update_columns: Array<ReactToStoryStoryViolationUpdateColumn>;
  where?: Maybe<ReactToStoryStoryViolationBoolExp>;
};

export enum ReactToStoryStoryViolationUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  StoryId = 'story_id',
  /** column name */
  UserId = 'user_id'
}

export type ReactToStoryStringComparisonExp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  _nlike?: Maybe<Scalars['String']>;
  _nsimilar?: Maybe<Scalars['String']>;
  _similar?: Maybe<Scalars['String']>;
};

export type ReactToStorySupportGroupBoolExp = {
  _and?: Maybe<Array<Maybe<ReactToStorySupportGroupBoolExp>>>;
  _not?: Maybe<ReactToStorySupportGroupBoolExp>;
  _or?: Maybe<Array<Maybe<ReactToStorySupportGroupBoolExp>>>;
  support_groups?: Maybe<ReactToStoryUserSupportGroupBoolExp>;
  value?: Maybe<ReactToStoryStringComparisonExp>;
};

export enum ReactToStorySupportGroupConstraint {
  /** unique or primary key constraint */
  SupportGroupPkey = 'support_group_pkey'
}

export enum ReactToStorySupportGroupEnum {
  Coach = 'COACH',
  Family = 'FAMILY',
  Friends = 'FRIENDS',
  FriendsParent = 'FRIENDS_PARENT',
  Gp = 'GP',
  Neighbour = 'NEIGHBOUR',
  Psychologist = 'PSYCHOLOGIST',
  Teacher = 'TEACHER'
}

export type ReactToStorySupportGroupEnumComparisonExp = {
  _eq?: Maybe<ReactToStorySupportGroupEnum>;
  _in?: Maybe<Array<ReactToStorySupportGroupEnum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<ReactToStorySupportGroupEnum>;
  _nin?: Maybe<Array<ReactToStorySupportGroupEnum>>;
};

export type ReactToStorySupportGroupInsertInput = {
  support_groups?: Maybe<ReactToStoryUserSupportGroupArrRelInsertInput>;
  value?: Maybe<Scalars['String']>;
};

export type ReactToStorySupportGroupObjRelInsertInput = {
  data: ReactToStorySupportGroupInsertInput;
  on_conflict?: Maybe<ReactToStorySupportGroupOnConflict>;
};

export type ReactToStorySupportGroupOnConflict = {
  constraint: ReactToStorySupportGroupConstraint;
  update_columns: Array<ReactToStorySupportGroupUpdateColumn>;
  where?: Maybe<ReactToStorySupportGroupBoolExp>;
};

export enum ReactToStorySupportGroupUpdateColumn {
  /** column name */
  Value = 'value'
}

export type ReactToStoryTagArrRelInsertInput = {
  data: Array<ReactToStoryTagInsertInput>;
  on_conflict?: Maybe<ReactToStoryTagOnConflict>;
};

export type ReactToStoryTagBoolExp = {
  _and?: Maybe<Array<Maybe<ReactToStoryTagBoolExp>>>;
  _not?: Maybe<ReactToStoryTagBoolExp>;
  _or?: Maybe<Array<Maybe<ReactToStoryTagBoolExp>>>;
  created_at?: Maybe<ReactToStoryTimestamptzComparisonExp>;
  id?: Maybe<ReactToStoryUuidComparisonExp>;
  name?: Maybe<ReactToStoryStringComparisonExp>;
  tag_type?: Maybe<ReactToStoryTagTypeBoolExp>;
  tags?: Maybe<ReactToStoryStoryTagBoolExp>;
  type?: Maybe<ReactToStoryTagTypeEnumComparisonExp>;
  updated_at?: Maybe<ReactToStoryTimestamptzComparisonExp>;
  verified?: Maybe<ReactToStoryBooleanComparisonExp>;
};

export enum ReactToStoryTagConstraint {
  /** unique or primary key constraint */
  TagPkey = 'tag_pkey'
}

export type ReactToStoryTagInsertInput = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  tag_type?: Maybe<ReactToStoryTagTypeObjRelInsertInput>;
  tags?: Maybe<ReactToStoryStoryTagArrRelInsertInput>;
  type?: Maybe<ReactToStoryTagTypeEnum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  verified?: Maybe<Scalars['Boolean']>;
};

export type ReactToStoryTagObjRelInsertInput = {
  data: ReactToStoryTagInsertInput;
  on_conflict?: Maybe<ReactToStoryTagOnConflict>;
};

export type ReactToStoryTagOnConflict = {
  constraint: ReactToStoryTagConstraint;
  update_columns: Array<ReactToStoryTagUpdateColumn>;
  where?: Maybe<ReactToStoryTagBoolExp>;
};

export type ReactToStoryTagTypeBoolExp = {
  _and?: Maybe<Array<Maybe<ReactToStoryTagTypeBoolExp>>>;
  _not?: Maybe<ReactToStoryTagTypeBoolExp>;
  _or?: Maybe<Array<Maybe<ReactToStoryTagTypeBoolExp>>>;
  tags?: Maybe<ReactToStoryTagBoolExp>;
  value?: Maybe<ReactToStoryStringComparisonExp>;
};

export enum ReactToStoryTagTypeConstraint {
  /** unique or primary key constraint */
  TagTypePkey = 'tag_type_pkey'
}

export enum ReactToStoryTagTypeEnum {
  Experience = 'EXPERIENCE',
  Framing = 'FRAMING'
}

export type ReactToStoryTagTypeEnumComparisonExp = {
  _eq?: Maybe<ReactToStoryTagTypeEnum>;
  _in?: Maybe<Array<ReactToStoryTagTypeEnum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<ReactToStoryTagTypeEnum>;
  _nin?: Maybe<Array<ReactToStoryTagTypeEnum>>;
};

export type ReactToStoryTagTypeInsertInput = {
  tags?: Maybe<ReactToStoryTagArrRelInsertInput>;
  value?: Maybe<Scalars['String']>;
};

export type ReactToStoryTagTypeObjRelInsertInput = {
  data: ReactToStoryTagTypeInsertInput;
  on_conflict?: Maybe<ReactToStoryTagTypeOnConflict>;
};

export type ReactToStoryTagTypeOnConflict = {
  constraint: ReactToStoryTagTypeConstraint;
  update_columns: Array<ReactToStoryTagTypeUpdateColumn>;
  where?: Maybe<ReactToStoryTagTypeBoolExp>;
};

export enum ReactToStoryTagTypeUpdateColumn {
  /** column name */
  Value = 'value'
}

export enum ReactToStoryTagUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Verified = 'verified'
}

export type ReactToStoryTimestampComparisonExp = {
  _eq?: Maybe<Scalars['timestamp']>;
  _gt?: Maybe<Scalars['timestamp']>;
  _gte?: Maybe<Scalars['timestamp']>;
  _in?: Maybe<Array<Scalars['timestamp']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamp']>;
  _lte?: Maybe<Scalars['timestamp']>;
  _neq?: Maybe<Scalars['timestamp']>;
  _nin?: Maybe<Array<Scalars['timestamp']>>;
};

export type ReactToStoryTimestamptzComparisonExp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};

export type ReactToStoryUserBoolExp = {
  _and?: Maybe<Array<Maybe<ReactToStoryUserBoolExp>>>;
  _not?: Maybe<ReactToStoryUserBoolExp>;
  _or?: Maybe<Array<Maybe<ReactToStoryUserBoolExp>>>;
  accepts_email?: Maybe<ReactToStoryBooleanComparisonExp>;
  accepts_notification?: Maybe<ReactToStoryBooleanComparisonExp>;
  account?: Maybe<ReactToStoryAuthAccountsBoolExp>;
  avatar_url?: Maybe<ReactToStoryStringComparisonExp>;
  completed_profile_at?: Maybe<ReactToStoryTimestampComparisonExp>;
  created_at?: Maybe<ReactToStoryTimestamptzComparisonExp>;
  date_of_birth?: Maybe<ReactToStoryDateComparisonExp>;
  display_name?: Maybe<ReactToStoryStringComparisonExp>;
  employment_status?: Maybe<ReactToStoryStringComparisonExp>;
  favourite_animal?: Maybe<ReactToStoryStringComparisonExp>;
  favourite_memes?: Maybe<ReactToStoryStringComparisonExp>;
  favourite_place?: Maybe<ReactToStoryStringComparisonExp>;
  favourite_spotify_playlists?: Maybe<ReactToStoryStringComparisonExp>;
  feedbacks?: Maybe<ReactToStoryFeedbackBoolExp>;
  first_name?: Maybe<ReactToStoryStringComparisonExp>;
  flags?: Maybe<ReactToStoryUserFlagBoolExp>;
  gender?: Maybe<ReactToStoryStringComparisonExp>;
  id?: Maybe<ReactToStoryUuidComparisonExp>;
  moderator_notes?: Maybe<ReactToStoryModeratorNoteBoolExp>;
  personal_description?: Maybe<ReactToStoryStringComparisonExp>;
  reactions?: Maybe<ReactToStoryReactionBoolExp>;
  screener_score?: Maybe<ReactToStoryIntComparisonExp>;
  shares?: Maybe<ReactToStoryShareBoolExp>;
  status?: Maybe<ReactToStoryStringComparisonExp>;
  stories?: Maybe<ReactToStoryStoryBoolExp>;
  story_saves?: Maybe<ReactToStoryStorySaveBoolExp>;
  story_violations?: Maybe<ReactToStoryStoryViolationBoolExp>;
  suburb?: Maybe<ReactToStoryStringComparisonExp>;
  support_groups?: Maybe<ReactToStoryUserSupportGroupBoolExp>;
  updated_at?: Maybe<ReactToStoryTimestamptzComparisonExp>;
  violation_notes?: Maybe<ReactToStoryViolationNoteBoolExp>;
};

export enum ReactToStoryUserConstraint {
  /** unique or primary key constraint */
  UserPkey = 'user_pkey'
}

export type ReactToStoryUserFlagArrRelInsertInput = {
  data: Array<ReactToStoryUserFlagInsertInput>;
  on_conflict?: Maybe<ReactToStoryUserFlagOnConflict>;
};

export type ReactToStoryUserFlagBoolExp = {
  _and?: Maybe<Array<Maybe<ReactToStoryUserFlagBoolExp>>>;
  _not?: Maybe<ReactToStoryUserFlagBoolExp>;
  _or?: Maybe<Array<Maybe<ReactToStoryUserFlagBoolExp>>>;
  flag?: Maybe<ReactToStoryFlagBoolExp>;
  flag_id?: Maybe<ReactToStoryUuidComparisonExp>;
  id?: Maybe<ReactToStoryUuidComparisonExp>;
  user?: Maybe<ReactToStoryUserBoolExp>;
  user_id?: Maybe<ReactToStoryUuidComparisonExp>;
};

export enum ReactToStoryUserFlagConstraint {
  /** unique or primary key constraint */
  UserFlagPkey = 'user_flag_pkey'
}

export type ReactToStoryUserFlagInsertInput = {
  flag?: Maybe<ReactToStoryFlagObjRelInsertInput>;
  flag_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  user?: Maybe<ReactToStoryUserObjRelInsertInput>;
  user_id?: Maybe<Scalars['uuid']>;
};

export type ReactToStoryUserFlagOnConflict = {
  constraint: ReactToStoryUserFlagConstraint;
  update_columns: Array<ReactToStoryUserFlagUpdateColumn>;
  where?: Maybe<ReactToStoryUserFlagBoolExp>;
};

export enum ReactToStoryUserFlagUpdateColumn {
  /** column name */
  FlagId = 'flag_id',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id'
}

export type ReactToStoryUserInsertInput = {
  accepts_email?: Maybe<Scalars['Boolean']>;
  accepts_notification?: Maybe<Scalars['Boolean']>;
  account?: Maybe<ReactToStoryAuthAccountsObjRelInsertInput>;
  avatar_url?: Maybe<Scalars['String']>;
  completed_profile_at?: Maybe<Scalars['timestamp']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date_of_birth?: Maybe<Scalars['date']>;
  display_name?: Maybe<Scalars['String']>;
  employment_status?: Maybe<Scalars['String']>;
  favourite_animal?: Maybe<Scalars['String']>;
  favourite_memes?: Maybe<Scalars['String']>;
  favourite_place?: Maybe<Scalars['String']>;
  favourite_spotify_playlists?: Maybe<Scalars['String']>;
  feedbacks?: Maybe<ReactToStoryFeedbackArrRelInsertInput>;
  first_name?: Maybe<Scalars['String']>;
  flags?: Maybe<ReactToStoryUserFlagArrRelInsertInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  moderator_notes?: Maybe<ReactToStoryModeratorNoteArrRelInsertInput>;
  personal_description?: Maybe<Scalars['String']>;
  reactions?: Maybe<ReactToStoryReactionArrRelInsertInput>;
  screener_score?: Maybe<Scalars['Int']>;
  shares?: Maybe<ReactToStoryShareArrRelInsertInput>;
  status?: Maybe<Scalars['String']>;
  stories?: Maybe<ReactToStoryStoryArrRelInsertInput>;
  story_saves?: Maybe<ReactToStoryStorySaveArrRelInsertInput>;
  story_violations?: Maybe<ReactToStoryStoryViolationArrRelInsertInput>;
  suburb?: Maybe<Scalars['String']>;
  support_groups?: Maybe<ReactToStoryUserSupportGroupArrRelInsertInput>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  violation_notes?: Maybe<ReactToStoryViolationNoteArrRelInsertInput>;
};

export type ReactToStoryUserObjRelInsertInput = {
  data: ReactToStoryUserInsertInput;
  on_conflict?: Maybe<ReactToStoryUserOnConflict>;
};

export type ReactToStoryUserOnConflict = {
  constraint: ReactToStoryUserConstraint;
  update_columns: Array<ReactToStoryUserUpdateColumn>;
  where?: Maybe<ReactToStoryUserBoolExp>;
};

export type ReactToStoryUserSupportGroupArrRelInsertInput = {
  data: Array<ReactToStoryUserSupportGroupInsertInput>;
  on_conflict?: Maybe<ReactToStoryUserSupportGroupOnConflict>;
};

export type ReactToStoryUserSupportGroupBoolExp = {
  _and?: Maybe<Array<Maybe<ReactToStoryUserSupportGroupBoolExp>>>;
  _not?: Maybe<ReactToStoryUserSupportGroupBoolExp>;
  _or?: Maybe<Array<Maybe<ReactToStoryUserSupportGroupBoolExp>>>;
  id?: Maybe<ReactToStoryUuidComparisonExp>;
  supportGroupBySupportGroup?: Maybe<ReactToStorySupportGroupBoolExp>;
  support_group?: Maybe<ReactToStorySupportGroupEnumComparisonExp>;
  user?: Maybe<ReactToStoryUserBoolExp>;
  user_id?: Maybe<ReactToStoryUuidComparisonExp>;
};

export enum ReactToStoryUserSupportGroupConstraint {
  /** unique or primary key constraint */
  UserSupportGroupPkey = 'user_support_group_pkey'
}

export type ReactToStoryUserSupportGroupInsertInput = {
  id?: Maybe<Scalars['uuid']>;
  supportGroupBySupportGroup?: Maybe<ReactToStorySupportGroupObjRelInsertInput>;
  support_group?: Maybe<ReactToStorySupportGroupEnum>;
  user?: Maybe<ReactToStoryUserObjRelInsertInput>;
  user_id?: Maybe<Scalars['uuid']>;
};

export type ReactToStoryUserSupportGroupOnConflict = {
  constraint: ReactToStoryUserSupportGroupConstraint;
  update_columns: Array<ReactToStoryUserSupportGroupUpdateColumn>;
  where?: Maybe<ReactToStoryUserSupportGroupBoolExp>;
};

export enum ReactToStoryUserSupportGroupUpdateColumn {
  /** column name */
  Id = 'id',
  /** column name */
  SupportGroup = 'support_group',
  /** column name */
  UserId = 'user_id'
}

export enum ReactToStoryUserUpdateColumn {
  /** column name */
  AcceptsEmail = 'accepts_email',
  /** column name */
  AcceptsNotification = 'accepts_notification',
  /** column name */
  AvatarUrl = 'avatar_url',
  /** column name */
  CompletedProfileAt = 'completed_profile_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateOfBirth = 'date_of_birth',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  EmploymentStatus = 'employment_status',
  /** column name */
  FavouriteAnimal = 'favourite_animal',
  /** column name */
  FavouriteMemes = 'favourite_memes',
  /** column name */
  FavouritePlace = 'favourite_place',
  /** column name */
  FavouriteSpotifyPlaylists = 'favourite_spotify_playlists',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Gender = 'gender',
  /** column name */
  Id = 'id',
  /** column name */
  PersonalDescription = 'personal_description',
  /** column name */
  ScreenerScore = 'screener_score',
  /** column name */
  Status = 'status',
  /** column name */
  Suburb = 'suburb',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type ReactToStoryUuidComparisonExp = {
  _eq?: Maybe<Scalars['uuid']>;
  _gt?: Maybe<Scalars['uuid']>;
  _gte?: Maybe<Scalars['uuid']>;
  _in?: Maybe<Array<Scalars['uuid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['uuid']>;
  _lte?: Maybe<Scalars['uuid']>;
  _neq?: Maybe<Scalars['uuid']>;
  _nin?: Maybe<Array<Scalars['uuid']>>;
};

export type ReactToStoryViolationNoteArrRelInsertInput = {
  data: Array<ReactToStoryViolationNoteInsertInput>;
  on_conflict?: Maybe<ReactToStoryViolationNoteOnConflict>;
};

export type ReactToStoryViolationNoteBoolExp = {
  _and?: Maybe<Array<Maybe<ReactToStoryViolationNoteBoolExp>>>;
  _not?: Maybe<ReactToStoryViolationNoteBoolExp>;
  _or?: Maybe<Array<Maybe<ReactToStoryViolationNoteBoolExp>>>;
  action?: Maybe<ReactToStoryStringComparisonExp>;
  created_at?: Maybe<ReactToStoryTimestamptzComparisonExp>;
  id?: Maybe<ReactToStoryUuidComparisonExp>;
  note?: Maybe<ReactToStoryStringComparisonExp>;
  story?: Maybe<ReactToStoryStoryBoolExp>;
  story_id?: Maybe<ReactToStoryUuidComparisonExp>;
  user?: Maybe<ReactToStoryUserBoolExp>;
  user_id?: Maybe<ReactToStoryUuidComparisonExp>;
};

export enum ReactToStoryViolationNoteConstraint {
  /** unique or primary key constraint */
  ViolationNotePkey = 'violation_note_pkey'
}

export type ReactToStoryViolationNoteInsertInput = {
  action?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  story?: Maybe<ReactToStoryStoryObjRelInsertInput>;
  story_id?: Maybe<Scalars['uuid']>;
  user?: Maybe<ReactToStoryUserObjRelInsertInput>;
  user_id?: Maybe<Scalars['uuid']>;
};

export type ReactToStoryViolationNoteOnConflict = {
  constraint: ReactToStoryViolationNoteConstraint;
  update_columns: Array<ReactToStoryViolationNoteUpdateColumn>;
  where?: Maybe<ReactToStoryViolationNoteBoolExp>;
};

export enum ReactToStoryViolationNoteUpdateColumn {
  /** column name */
  Action = 'action',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Note = 'note',
  /** column name */
  StoryId = 'story_id',
  /** column name */
  UserId = 'user_id'
}

export type RemoveStoryReactionOutput = {
  __typename?: 'RemoveStoryReactionOutput';
  id: Scalars['uuid'];
  reaction_type: Scalars['String'];
  story_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

export type ReportStoryOutput = {
  __typename?: 'ReportStoryOutput';
  success: Scalars['Boolean'];
};

export type SavePublishedStoryOutput = {
  __typename?: 'SavePublishedStoryOutput';
  content?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  status: Scalars['String'];
};

export type SaveStoryAuthAccountProvidersArrRelInsertInput = {
  data: Array<SaveStoryAuthAccountProvidersInsertInput>;
  on_conflict?: Maybe<SaveStoryAuthAccountProvidersOnConflict>;
};

export type SaveStoryAuthAccountProvidersBoolExp = {
  _and?: Maybe<Array<Maybe<SaveStoryAuthAccountProvidersBoolExp>>>;
  _not?: Maybe<SaveStoryAuthAccountProvidersBoolExp>;
  _or?: Maybe<Array<Maybe<SaveStoryAuthAccountProvidersBoolExp>>>;
  account?: Maybe<SaveStoryAuthAccountsBoolExp>;
  account_id?: Maybe<SaveStoryUuidComparisonExp>;
  auth_provider?: Maybe<SaveStoryStringComparisonExp>;
  auth_provider_unique_id?: Maybe<SaveStoryStringComparisonExp>;
  created_at?: Maybe<SaveStoryTimestamptzComparisonExp>;
  id?: Maybe<SaveStoryUuidComparisonExp>;
  provider?: Maybe<SaveStoryAuthProvidersBoolExp>;
  updated_at?: Maybe<SaveStoryTimestamptzComparisonExp>;
};

export enum SaveStoryAuthAccountProvidersConstraint {
  /** unique or primary key constraint */
  AccountProvidersAccountIdAuthProviderKey = 'account_providers_account_id_auth_provider_key',
  /** unique or primary key constraint */
  AccountProvidersAuthProviderAuthProviderUniqueIdKey = 'account_providers_auth_provider_auth_provider_unique_id_key',
  /** unique or primary key constraint */
  AccountProvidersPkey = 'account_providers_pkey'
}

export type SaveStoryAuthAccountProvidersInsertInput = {
  account?: Maybe<SaveStoryAuthAccountsObjRelInsertInput>;
  account_id?: Maybe<Scalars['uuid']>;
  auth_provider?: Maybe<Scalars['String']>;
  auth_provider_unique_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  provider?: Maybe<SaveStoryAuthProvidersObjRelInsertInput>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

export type SaveStoryAuthAccountProvidersOnConflict = {
  constraint: SaveStoryAuthAccountProvidersConstraint;
  update_columns: Array<SaveStoryAuthAccountProvidersUpdateColumn>;
  where?: Maybe<SaveStoryAuthAccountProvidersBoolExp>;
};

export enum SaveStoryAuthAccountProvidersUpdateColumn {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  AuthProvider = 'auth_provider',
  /** column name */
  AuthProviderUniqueId = 'auth_provider_unique_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type SaveStoryAuthAccountRolesArrRelInsertInput = {
  data: Array<SaveStoryAuthAccountRolesInsertInput>;
  on_conflict?: Maybe<SaveStoryAuthAccountRolesOnConflict>;
};

export type SaveStoryAuthAccountRolesBoolExp = {
  _and?: Maybe<Array<Maybe<SaveStoryAuthAccountRolesBoolExp>>>;
  _not?: Maybe<SaveStoryAuthAccountRolesBoolExp>;
  _or?: Maybe<Array<Maybe<SaveStoryAuthAccountRolesBoolExp>>>;
  account?: Maybe<SaveStoryAuthAccountsBoolExp>;
  account_id?: Maybe<SaveStoryUuidComparisonExp>;
  created_at?: Maybe<SaveStoryTimestamptzComparisonExp>;
  id?: Maybe<SaveStoryUuidComparisonExp>;
  role?: Maybe<SaveStoryStringComparisonExp>;
  roleByRole?: Maybe<SaveStoryAuthRolesBoolExp>;
};

export enum SaveStoryAuthAccountRolesConstraint {
  /** unique or primary key constraint */
  AccountRolesPkey = 'account_roles_pkey',
  /** unique or primary key constraint */
  UserRolesAccountIdRoleKey = 'user_roles_account_id_role_key'
}

export type SaveStoryAuthAccountRolesInsertInput = {
  account?: Maybe<SaveStoryAuthAccountsObjRelInsertInput>;
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  role?: Maybe<Scalars['String']>;
  roleByRole?: Maybe<SaveStoryAuthRolesObjRelInsertInput>;
};

export type SaveStoryAuthAccountRolesOnConflict = {
  constraint: SaveStoryAuthAccountRolesConstraint;
  update_columns: Array<SaveStoryAuthAccountRolesUpdateColumn>;
  where?: Maybe<SaveStoryAuthAccountRolesBoolExp>;
};

export enum SaveStoryAuthAccountRolesUpdateColumn {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Role = 'role'
}

export type SaveStoryAuthAccountsArrRelInsertInput = {
  data: Array<SaveStoryAuthAccountsInsertInput>;
  on_conflict?: Maybe<SaveStoryAuthAccountsOnConflict>;
};

export type SaveStoryAuthAccountsBoolExp = {
  _and?: Maybe<Array<Maybe<SaveStoryAuthAccountsBoolExp>>>;
  _not?: Maybe<SaveStoryAuthAccountsBoolExp>;
  _or?: Maybe<Array<Maybe<SaveStoryAuthAccountsBoolExp>>>;
  account_providers?: Maybe<SaveStoryAuthAccountProvidersBoolExp>;
  account_roles?: Maybe<SaveStoryAuthAccountRolesBoolExp>;
  active?: Maybe<SaveStoryBooleanComparisonExp>;
  created_at?: Maybe<SaveStoryTimestamptzComparisonExp>;
  custom_register_data?: Maybe<SaveStoryJsonbComparisonExp>;
  default_role?: Maybe<SaveStoryStringComparisonExp>;
  email?: Maybe<SaveStoryCitextComparisonExp>;
  id?: Maybe<SaveStoryUuidComparisonExp>;
  is_anonymous?: Maybe<SaveStoryBooleanComparisonExp>;
  mfa_enabled?: Maybe<SaveStoryBooleanComparisonExp>;
  new_email?: Maybe<SaveStoryCitextComparisonExp>;
  otp_secret?: Maybe<SaveStoryStringComparisonExp>;
  password_hash?: Maybe<SaveStoryStringComparisonExp>;
  refresh_tokens?: Maybe<SaveStoryAuthRefreshTokensBoolExp>;
  role?: Maybe<SaveStoryAuthRolesBoolExp>;
  ticket?: Maybe<SaveStoryUuidComparisonExp>;
  ticket_expires_at?: Maybe<SaveStoryTimestamptzComparisonExp>;
  updated_at?: Maybe<SaveStoryTimestamptzComparisonExp>;
  user?: Maybe<SaveStoryUserBoolExp>;
  user_id?: Maybe<SaveStoryUuidComparisonExp>;
};

export enum SaveStoryAuthAccountsConstraint {
  /** unique or primary key constraint */
  AccountsEmailKey = 'accounts_email_key',
  /** unique or primary key constraint */
  AccountsNewEmailKey = 'accounts_new_email_key',
  /** unique or primary key constraint */
  AccountsPkey = 'accounts_pkey',
  /** unique or primary key constraint */
  AccountsUserIdKey = 'accounts_user_id_key'
}

export type SaveStoryAuthAccountsInsertInput = {
  account_providers?: Maybe<SaveStoryAuthAccountProvidersArrRelInsertInput>;
  account_roles?: Maybe<SaveStoryAuthAccountRolesArrRelInsertInput>;
  active?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  custom_register_data?: Maybe<Scalars['jsonb']>;
  default_role?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['citext']>;
  id?: Maybe<Scalars['uuid']>;
  is_anonymous?: Maybe<Scalars['Boolean']>;
  mfa_enabled?: Maybe<Scalars['Boolean']>;
  new_email?: Maybe<Scalars['citext']>;
  otp_secret?: Maybe<Scalars['String']>;
  password_hash?: Maybe<Scalars['String']>;
  refresh_tokens?: Maybe<SaveStoryAuthRefreshTokensArrRelInsertInput>;
  role?: Maybe<SaveStoryAuthRolesObjRelInsertInput>;
  ticket?: Maybe<Scalars['uuid']>;
  ticket_expires_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<SaveStoryUserObjRelInsertInput>;
  user_id?: Maybe<Scalars['uuid']>;
};

export type SaveStoryAuthAccountsObjRelInsertInput = {
  data: SaveStoryAuthAccountsInsertInput;
  on_conflict?: Maybe<SaveStoryAuthAccountsOnConflict>;
};

export type SaveStoryAuthAccountsOnConflict = {
  constraint: SaveStoryAuthAccountsConstraint;
  update_columns: Array<SaveStoryAuthAccountsUpdateColumn>;
  where?: Maybe<SaveStoryAuthAccountsBoolExp>;
};

export enum SaveStoryAuthAccountsUpdateColumn {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomRegisterData = 'custom_register_data',
  /** column name */
  DefaultRole = 'default_role',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  IsAnonymous = 'is_anonymous',
  /** column name */
  MfaEnabled = 'mfa_enabled',
  /** column name */
  NewEmail = 'new_email',
  /** column name */
  OtpSecret = 'otp_secret',
  /** column name */
  PasswordHash = 'password_hash',
  /** column name */
  Ticket = 'ticket',
  /** column name */
  TicketExpiresAt = 'ticket_expires_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type SaveStoryAuthProvidersBoolExp = {
  _and?: Maybe<Array<Maybe<SaveStoryAuthProvidersBoolExp>>>;
  _not?: Maybe<SaveStoryAuthProvidersBoolExp>;
  _or?: Maybe<Array<Maybe<SaveStoryAuthProvidersBoolExp>>>;
  account_providers?: Maybe<SaveStoryAuthAccountProvidersBoolExp>;
  provider?: Maybe<SaveStoryStringComparisonExp>;
};

export enum SaveStoryAuthProvidersConstraint {
  /** unique or primary key constraint */
  ProvidersPkey = 'providers_pkey'
}

export type SaveStoryAuthProvidersInsertInput = {
  account_providers?: Maybe<SaveStoryAuthAccountProvidersArrRelInsertInput>;
  provider?: Maybe<Scalars['String']>;
};

export type SaveStoryAuthProvidersObjRelInsertInput = {
  data: SaveStoryAuthProvidersInsertInput;
  on_conflict?: Maybe<SaveStoryAuthProvidersOnConflict>;
};

export type SaveStoryAuthProvidersOnConflict = {
  constraint: SaveStoryAuthProvidersConstraint;
  update_columns: Array<SaveStoryAuthProvidersUpdateColumn>;
  where?: Maybe<SaveStoryAuthProvidersBoolExp>;
};

export enum SaveStoryAuthProvidersUpdateColumn {
  /** column name */
  Provider = 'provider'
}

export type SaveStoryAuthRefreshTokensArrRelInsertInput = {
  data: Array<SaveStoryAuthRefreshTokensInsertInput>;
  on_conflict?: Maybe<SaveStoryAuthRefreshTokensOnConflict>;
};

export type SaveStoryAuthRefreshTokensBoolExp = {
  _and?: Maybe<Array<Maybe<SaveStoryAuthRefreshTokensBoolExp>>>;
  _not?: Maybe<SaveStoryAuthRefreshTokensBoolExp>;
  _or?: Maybe<Array<Maybe<SaveStoryAuthRefreshTokensBoolExp>>>;
  account?: Maybe<SaveStoryAuthAccountsBoolExp>;
  account_id?: Maybe<SaveStoryUuidComparisonExp>;
  created_at?: Maybe<SaveStoryTimestamptzComparisonExp>;
  expires_at?: Maybe<SaveStoryTimestamptzComparisonExp>;
  refresh_token?: Maybe<SaveStoryUuidComparisonExp>;
};

export enum SaveStoryAuthRefreshTokensConstraint {
  /** unique or primary key constraint */
  RefreshTokensPkey = 'refresh_tokens_pkey'
}

export type SaveStoryAuthRefreshTokensInsertInput = {
  account?: Maybe<SaveStoryAuthAccountsObjRelInsertInput>;
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  refresh_token?: Maybe<Scalars['uuid']>;
};

export type SaveStoryAuthRefreshTokensOnConflict = {
  constraint: SaveStoryAuthRefreshTokensConstraint;
  update_columns: Array<SaveStoryAuthRefreshTokensUpdateColumn>;
  where?: Maybe<SaveStoryAuthRefreshTokensBoolExp>;
};

export enum SaveStoryAuthRefreshTokensUpdateColumn {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  RefreshToken = 'refresh_token'
}

export type SaveStoryAuthRolesBoolExp = {
  _and?: Maybe<Array<Maybe<SaveStoryAuthRolesBoolExp>>>;
  _not?: Maybe<SaveStoryAuthRolesBoolExp>;
  _or?: Maybe<Array<Maybe<SaveStoryAuthRolesBoolExp>>>;
  account_roles?: Maybe<SaveStoryAuthAccountRolesBoolExp>;
  accounts?: Maybe<SaveStoryAuthAccountsBoolExp>;
  role?: Maybe<SaveStoryStringComparisonExp>;
};

export enum SaveStoryAuthRolesConstraint {
  /** unique or primary key constraint */
  RolesPkey = 'roles_pkey'
}

export type SaveStoryAuthRolesInsertInput = {
  account_roles?: Maybe<SaveStoryAuthAccountRolesArrRelInsertInput>;
  accounts?: Maybe<SaveStoryAuthAccountsArrRelInsertInput>;
  role?: Maybe<Scalars['String']>;
};

export type SaveStoryAuthRolesObjRelInsertInput = {
  data: SaveStoryAuthRolesInsertInput;
  on_conflict?: Maybe<SaveStoryAuthRolesOnConflict>;
};

export type SaveStoryAuthRolesOnConflict = {
  constraint: SaveStoryAuthRolesConstraint;
  update_columns: Array<SaveStoryAuthRolesUpdateColumn>;
  where?: Maybe<SaveStoryAuthRolesBoolExp>;
};

export enum SaveStoryAuthRolesUpdateColumn {
  /** column name */
  Role = 'role'
}

export type SaveStoryBooleanComparisonExp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

export type SaveStoryCitextComparisonExp = {
  _eq?: Maybe<Scalars['citext']>;
  _gt?: Maybe<Scalars['citext']>;
  _gte?: Maybe<Scalars['citext']>;
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['citext']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['citext']>;
  _lte?: Maybe<Scalars['citext']>;
  _neq?: Maybe<Scalars['citext']>;
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['citext']>>;
  _nlike?: Maybe<Scalars['String']>;
  _nsimilar?: Maybe<Scalars['String']>;
  _similar?: Maybe<Scalars['String']>;
};

export type SaveStoryDateComparisonExp = {
  _eq?: Maybe<Scalars['date']>;
  _gt?: Maybe<Scalars['date']>;
  _gte?: Maybe<Scalars['date']>;
  _in?: Maybe<Array<Scalars['date']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['date']>;
  _lte?: Maybe<Scalars['date']>;
  _neq?: Maybe<Scalars['date']>;
  _nin?: Maybe<Array<Scalars['date']>>;
};

export type SaveStoryFeedbackArrRelInsertInput = {
  data: Array<SaveStoryFeedbackInsertInput>;
  on_conflict?: Maybe<SaveStoryFeedbackOnConflict>;
};

export type SaveStoryFeedbackBoolExp = {
  _and?: Maybe<Array<Maybe<SaveStoryFeedbackBoolExp>>>;
  _not?: Maybe<SaveStoryFeedbackBoolExp>;
  _or?: Maybe<Array<Maybe<SaveStoryFeedbackBoolExp>>>;
  created_at?: Maybe<SaveStoryTimestamptzComparisonExp>;
  description?: Maybe<SaveStoryStringComparisonExp>;
  id?: Maybe<SaveStoryUuidComparisonExp>;
  updated_at?: Maybe<SaveStoryTimestamptzComparisonExp>;
  user?: Maybe<SaveStoryUserBoolExp>;
  user_id?: Maybe<SaveStoryUuidComparisonExp>;
};

export enum SaveStoryFeedbackConstraint {
  /** unique or primary key constraint */
  FeedbackPkey = 'feedback_pkey'
}

export type SaveStoryFeedbackInsertInput = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<SaveStoryUserObjRelInsertInput>;
  user_id?: Maybe<Scalars['uuid']>;
};

export type SaveStoryFeedbackOnConflict = {
  constraint: SaveStoryFeedbackConstraint;
  update_columns: Array<SaveStoryFeedbackUpdateColumn>;
  where?: Maybe<SaveStoryFeedbackBoolExp>;
};

export enum SaveStoryFeedbackUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type SaveStoryFlagBoolExp = {
  _and?: Maybe<Array<Maybe<SaveStoryFlagBoolExp>>>;
  _not?: Maybe<SaveStoryFlagBoolExp>;
  _or?: Maybe<Array<Maybe<SaveStoryFlagBoolExp>>>;
  component_name?: Maybe<SaveStoryStringComparisonExp>;
  display_name?: Maybe<SaveStoryStringComparisonExp>;
  file_name?: Maybe<SaveStoryStringComparisonExp>;
  flags?: Maybe<SaveStoryUserFlagBoolExp>;
  id?: Maybe<SaveStoryUuidComparisonExp>;
};

export enum SaveStoryFlagConstraint {
  /** unique or primary key constraint */
  FlagPkey = 'flag_pkey'
}

export type SaveStoryFlagInsertInput = {
  component_name?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  file_name?: Maybe<Scalars['String']>;
  flags?: Maybe<SaveStoryUserFlagArrRelInsertInput>;
  id?: Maybe<Scalars['uuid']>;
};

export type SaveStoryFlagObjRelInsertInput = {
  data: SaveStoryFlagInsertInput;
  on_conflict?: Maybe<SaveStoryFlagOnConflict>;
};

export type SaveStoryFlagOnConflict = {
  constraint: SaveStoryFlagConstraint;
  update_columns: Array<SaveStoryFlagUpdateColumn>;
  where?: Maybe<SaveStoryFlagBoolExp>;
};

export enum SaveStoryFlagUpdateColumn {
  /** column name */
  ComponentName = 'component_name',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  FileName = 'file_name',
  /** column name */
  Id = 'id'
}

export type SaveStoryIntComparisonExp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};

export type SaveStoryJsonbComparisonExp = {
  _contained_in?: Maybe<Scalars['jsonb']>;
  _contains?: Maybe<Scalars['jsonb']>;
  _eq?: Maybe<Scalars['jsonb']>;
  _gt?: Maybe<Scalars['jsonb']>;
  _gte?: Maybe<Scalars['jsonb']>;
  _has_key?: Maybe<Scalars['String']>;
  _has_keys_all?: Maybe<Array<Scalars['String']>>;
  _has_keys_any?: Maybe<Array<Scalars['String']>>;
  _in?: Maybe<Array<Scalars['jsonb']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['jsonb']>;
  _lte?: Maybe<Scalars['jsonb']>;
  _neq?: Maybe<Scalars['jsonb']>;
  _nin?: Maybe<Array<Scalars['jsonb']>>;
};

export type SaveStoryModeratorNoteArrRelInsertInput = {
  data: Array<SaveStoryModeratorNoteInsertInput>;
  on_conflict?: Maybe<SaveStoryModeratorNoteOnConflict>;
};

export type SaveStoryModeratorNoteBoolExp = {
  _and?: Maybe<Array<Maybe<SaveStoryModeratorNoteBoolExp>>>;
  _not?: Maybe<SaveStoryModeratorNoteBoolExp>;
  _or?: Maybe<Array<Maybe<SaveStoryModeratorNoteBoolExp>>>;
  action?: Maybe<SaveStoryStringComparisonExp>;
  created_at?: Maybe<SaveStoryTimestamptzComparisonExp>;
  id?: Maybe<SaveStoryUuidComparisonExp>;
  note?: Maybe<SaveStoryStringComparisonExp>;
  story?: Maybe<SaveStoryStoryBoolExp>;
  story_id?: Maybe<SaveStoryUuidComparisonExp>;
  updated_at?: Maybe<SaveStoryTimestamptzComparisonExp>;
  user?: Maybe<SaveStoryUserBoolExp>;
  user_id?: Maybe<SaveStoryUuidComparisonExp>;
};

export enum SaveStoryModeratorNoteConstraint {
  /** unique or primary key constraint */
  ModeratorNotePkey = 'moderator_note_pkey'
}

export type SaveStoryModeratorNoteInsertInput = {
  action?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  story?: Maybe<SaveStoryStoryObjRelInsertInput>;
  story_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<SaveStoryUserObjRelInsertInput>;
  user_id?: Maybe<Scalars['uuid']>;
};

export type SaveStoryModeratorNoteOnConflict = {
  constraint: SaveStoryModeratorNoteConstraint;
  update_columns: Array<SaveStoryModeratorNoteUpdateColumn>;
  where?: Maybe<SaveStoryModeratorNoteBoolExp>;
};

export enum SaveStoryModeratorNoteUpdateColumn {
  /** column name */
  Action = 'action',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Note = 'note',
  /** column name */
  StoryId = 'story_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type SaveStoryOutput = {
  __typename?: 'SaveStoryOutput';
  caption?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  title: Scalars['String'];
  type: Scalars['String'];
};

export type SaveStoryPromptBoolExp = {
  _and?: Maybe<Array<Maybe<SaveStoryPromptBoolExp>>>;
  _not?: Maybe<SaveStoryPromptBoolExp>;
  _or?: Maybe<Array<Maybe<SaveStoryPromptBoolExp>>>;
  created_at?: Maybe<SaveStoryTimestamptzComparisonExp>;
  description?: Maybe<SaveStoryStringComparisonExp>;
  id?: Maybe<SaveStoryUuidComparisonExp>;
  position?: Maybe<SaveStoryIntComparisonExp>;
  replies?: Maybe<SaveStoryReplyBoolExp>;
  title?: Maybe<SaveStoryStringComparisonExp>;
  updated_at?: Maybe<SaveStoryTimestamptzComparisonExp>;
};

export enum SaveStoryPromptConstraint {
  /** unique or primary key constraint */
  PromptPkey = 'prompt_pkey'
}

export type SaveStoryPromptInsertInput = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  position?: Maybe<Scalars['Int']>;
  replies?: Maybe<SaveStoryReplyArrRelInsertInput>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

export type SaveStoryPromptObjRelInsertInput = {
  data: SaveStoryPromptInsertInput;
  on_conflict?: Maybe<SaveStoryPromptOnConflict>;
};

export type SaveStoryPromptOnConflict = {
  constraint: SaveStoryPromptConstraint;
  update_columns: Array<SaveStoryPromptUpdateColumn>;
  where?: Maybe<SaveStoryPromptBoolExp>;
};

export enum SaveStoryPromptUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Position = 'position',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type SaveStoryReactionArrRelInsertInput = {
  data: Array<SaveStoryReactionInsertInput>;
  on_conflict?: Maybe<SaveStoryReactionOnConflict>;
};

export type SaveStoryReactionBoolExp = {
  _and?: Maybe<Array<Maybe<SaveStoryReactionBoolExp>>>;
  _not?: Maybe<SaveStoryReactionBoolExp>;
  _or?: Maybe<Array<Maybe<SaveStoryReactionBoolExp>>>;
  created_at?: Maybe<SaveStoryTimestamptzComparisonExp>;
  id?: Maybe<SaveStoryUuidComparisonExp>;
  reaction_type?: Maybe<SaveStoryStringComparisonExp>;
  story?: Maybe<SaveStoryStoryBoolExp>;
  story_id?: Maybe<SaveStoryUuidComparisonExp>;
  updated_at?: Maybe<SaveStoryTimestamptzComparisonExp>;
  user?: Maybe<SaveStoryUserBoolExp>;
  user_id?: Maybe<SaveStoryUuidComparisonExp>;
  viewed_at?: Maybe<SaveStoryTimestamptzComparisonExp>;
};

export enum SaveStoryReactionConstraint {
  /** unique or primary key constraint */
  ReactionPkey = 'reaction_pkey'
}

export type SaveStoryReactionInsertInput = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  reaction_type?: Maybe<Scalars['String']>;
  story?: Maybe<SaveStoryStoryObjRelInsertInput>;
  story_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<SaveStoryUserObjRelInsertInput>;
  user_id?: Maybe<Scalars['uuid']>;
  viewed_at?: Maybe<Scalars['timestamptz']>;
};

export type SaveStoryReactionOnConflict = {
  constraint: SaveStoryReactionConstraint;
  update_columns: Array<SaveStoryReactionUpdateColumn>;
  where?: Maybe<SaveStoryReactionBoolExp>;
};

export enum SaveStoryReactionUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ReactionType = 'reaction_type',
  /** column name */
  StoryId = 'story_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  ViewedAt = 'viewed_at'
}

export type SaveStoryReplyArrRelInsertInput = {
  data: Array<SaveStoryReplyInsertInput>;
  on_conflict?: Maybe<SaveStoryReplyOnConflict>;
};

export type SaveStoryReplyBoolExp = {
  _and?: Maybe<Array<Maybe<SaveStoryReplyBoolExp>>>;
  _not?: Maybe<SaveStoryReplyBoolExp>;
  _or?: Maybe<Array<Maybe<SaveStoryReplyBoolExp>>>;
  content?: Maybe<SaveStoryStringComparisonExp>;
  created_at?: Maybe<SaveStoryTimestamptzComparisonExp>;
  id?: Maybe<SaveStoryUuidComparisonExp>;
  prompt?: Maybe<SaveStoryPromptBoolExp>;
  prompt_id?: Maybe<SaveStoryUuidComparisonExp>;
  story?: Maybe<SaveStoryStoryBoolExp>;
  story_id?: Maybe<SaveStoryUuidComparisonExp>;
  updated_at?: Maybe<SaveStoryTimestamptzComparisonExp>;
};

export enum SaveStoryReplyConstraint {
  /** unique or primary key constraint */
  ReplyPkey = 'reply_pkey'
}

export type SaveStoryReplyInsertInput = {
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  prompt?: Maybe<SaveStoryPromptObjRelInsertInput>;
  prompt_id?: Maybe<Scalars['uuid']>;
  story?: Maybe<SaveStoryStoryObjRelInsertInput>;
  story_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

export type SaveStoryReplyOnConflict = {
  constraint: SaveStoryReplyConstraint;
  update_columns: Array<SaveStoryReplyUpdateColumn>;
  where?: Maybe<SaveStoryReplyBoolExp>;
};

export enum SaveStoryReplyUpdateColumn {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PromptId = 'prompt_id',
  /** column name */
  StoryId = 'story_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type SaveStoryResourceArrRelInsertInput = {
  data: Array<SaveStoryResourceInsertInput>;
  on_conflict?: Maybe<SaveStoryResourceOnConflict>;
};

export type SaveStoryResourceBoolExp = {
  _and?: Maybe<Array<Maybe<SaveStoryResourceBoolExp>>>;
  _not?: Maybe<SaveStoryResourceBoolExp>;
  _or?: Maybe<Array<Maybe<SaveStoryResourceBoolExp>>>;
  created_at?: Maybe<SaveStoryTimestamptzComparisonExp>;
  id?: Maybe<SaveStoryUuidComparisonExp>;
  resource_type?: Maybe<SaveStoryResourceTypeBoolExp>;
  story?: Maybe<SaveStoryStoryBoolExp>;
  story_id?: Maybe<SaveStoryUuidComparisonExp>;
  type?: Maybe<SaveStoryResourceTypeEnumComparisonExp>;
  updated_at?: Maybe<SaveStoryTimestamptzComparisonExp>;
  url?: Maybe<SaveStoryStringComparisonExp>;
};

export enum SaveStoryResourceConstraint {
  /** unique or primary key constraint */
  ResourcePkey = 'resource_pkey',
  /** unique or primary key constraint */
  ResourceStoryIdKey = 'resource_story_id_key'
}

export type SaveStoryResourceInsertInput = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  resource_type?: Maybe<SaveStoryResourceTypeObjRelInsertInput>;
  story?: Maybe<SaveStoryStoryObjRelInsertInput>;
  story_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<SaveStoryResourceTypeEnum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

export type SaveStoryResourceObjRelInsertInput = {
  data: SaveStoryResourceInsertInput;
  on_conflict?: Maybe<SaveStoryResourceOnConflict>;
};

export type SaveStoryResourceOnConflict = {
  constraint: SaveStoryResourceConstraint;
  update_columns: Array<SaveStoryResourceUpdateColumn>;
  where?: Maybe<SaveStoryResourceBoolExp>;
};

export type SaveStoryResourceTypeBoolExp = {
  _and?: Maybe<Array<Maybe<SaveStoryResourceTypeBoolExp>>>;
  _not?: Maybe<SaveStoryResourceTypeBoolExp>;
  _or?: Maybe<Array<Maybe<SaveStoryResourceTypeBoolExp>>>;
  resources?: Maybe<SaveStoryResourceBoolExp>;
  value?: Maybe<SaveStoryStringComparisonExp>;
};

export enum SaveStoryResourceTypeConstraint {
  /** unique or primary key constraint */
  ResourceTypePkey = 'resource_type_pkey'
}

export enum SaveStoryResourceTypeEnum {
  Audio = 'AUDIO',
  Image = 'IMAGE',
  Video = 'VIDEO'
}

export type SaveStoryResourceTypeEnumComparisonExp = {
  _eq?: Maybe<SaveStoryResourceTypeEnum>;
  _in?: Maybe<Array<SaveStoryResourceTypeEnum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<SaveStoryResourceTypeEnum>;
  _nin?: Maybe<Array<SaveStoryResourceTypeEnum>>;
};

export type SaveStoryResourceTypeInsertInput = {
  resources?: Maybe<SaveStoryResourceArrRelInsertInput>;
  value?: Maybe<Scalars['String']>;
};

export type SaveStoryResourceTypeObjRelInsertInput = {
  data: SaveStoryResourceTypeInsertInput;
  on_conflict?: Maybe<SaveStoryResourceTypeOnConflict>;
};

export type SaveStoryResourceTypeOnConflict = {
  constraint: SaveStoryResourceTypeConstraint;
  update_columns: Array<SaveStoryResourceTypeUpdateColumn>;
  where?: Maybe<SaveStoryResourceTypeBoolExp>;
};

export enum SaveStoryResourceTypeUpdateColumn {
  /** column name */
  Value = 'value'
}

export enum SaveStoryResourceUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  StoryId = 'story_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url'
}

export type SaveStoryShareArrRelInsertInput = {
  data: Array<SaveStoryShareInsertInput>;
  on_conflict?: Maybe<SaveStoryShareOnConflict>;
};

export type SaveStoryShareBoolExp = {
  _and?: Maybe<Array<Maybe<SaveStoryShareBoolExp>>>;
  _not?: Maybe<SaveStoryShareBoolExp>;
  _or?: Maybe<Array<Maybe<SaveStoryShareBoolExp>>>;
  activity_type?: Maybe<SaveStoryStringComparisonExp>;
  created_at?: Maybe<SaveStoryTimestamptzComparisonExp>;
  id?: Maybe<SaveStoryUuidComparisonExp>;
  story?: Maybe<SaveStoryStoryBoolExp>;
  story_id?: Maybe<SaveStoryUuidComparisonExp>;
  updated_at?: Maybe<SaveStoryTimestamptzComparisonExp>;
  user?: Maybe<SaveStoryUserBoolExp>;
  user_id?: Maybe<SaveStoryUuidComparisonExp>;
};

export enum SaveStoryShareConstraint {
  /** unique or primary key constraint */
  SharePkey = 'share_pkey'
}

export type SaveStoryShareInsertInput = {
  activity_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  story?: Maybe<SaveStoryStoryObjRelInsertInput>;
  story_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<SaveStoryUserObjRelInsertInput>;
  user_id?: Maybe<Scalars['uuid']>;
};

export type SaveStoryShareOnConflict = {
  constraint: SaveStoryShareConstraint;
  update_columns: Array<SaveStoryShareUpdateColumn>;
  where?: Maybe<SaveStoryShareBoolExp>;
};

export enum SaveStoryShareUpdateColumn {
  /** column name */
  ActivityType = 'activity_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  StoryId = 'story_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type SaveStoryStoryArrRelInsertInput = {
  data: Array<SaveStoryStoryInsertInput>;
  on_conflict?: Maybe<SaveStoryStoryOnConflict>;
};

export type SaveStoryStoryBoolExp = {
  _and?: Maybe<Array<Maybe<SaveStoryStoryBoolExp>>>;
  _not?: Maybe<SaveStoryStoryBoolExp>;
  _or?: Maybe<Array<Maybe<SaveStoryStoryBoolExp>>>;
  caption?: Maybe<SaveStoryStringComparisonExp>;
  content?: Maybe<SaveStoryStringComparisonExp>;
  created_at?: Maybe<SaveStoryTimestamptzComparisonExp>;
  first_submitted_at?: Maybe<SaveStoryTimestamptzComparisonExp>;
  id?: Maybe<SaveStoryUuidComparisonExp>;
  is_active?: Maybe<SaveStoryBooleanComparisonExp>;
  is_name_included?: Maybe<SaveStoryBooleanComparisonExp>;
  is_shareable?: Maybe<SaveStoryBooleanComparisonExp>;
  last_approved_at?: Maybe<SaveStoryTimestamptzComparisonExp>;
  last_rejected_at?: Maybe<SaveStoryTimestamptzComparisonExp>;
  last_submitted_at?: Maybe<SaveStoryTimestamptzComparisonExp>;
  moderator_notes?: Maybe<SaveStoryModeratorNoteBoolExp>;
  number_of_approvals?: Maybe<SaveStoryIntComparisonExp>;
  number_of_rejections?: Maybe<SaveStoryIntComparisonExp>;
  published_at?: Maybe<SaveStoryTimestamptzComparisonExp>;
  ranking?: Maybe<SaveStoryIntComparisonExp>;
  reactions?: Maybe<SaveStoryReactionBoolExp>;
  replies?: Maybe<SaveStoryReplyBoolExp>;
  resource?: Maybe<SaveStoryResourceBoolExp>;
  shares?: Maybe<SaveStoryShareBoolExp>;
  show_suburb?: Maybe<SaveStoryBooleanComparisonExp>;
  status?: Maybe<SaveStoryStringComparisonExp>;
  story_saves?: Maybe<SaveStoryStorySaveBoolExp>;
  story_violations?: Maybe<SaveStoryStoryViolationBoolExp>;
  tags?: Maybe<SaveStoryStoryTagBoolExp>;
  title?: Maybe<SaveStoryStringComparisonExp>;
  type?: Maybe<SaveStoryStringComparisonExp>;
  updated_at?: Maybe<SaveStoryTimestamptzComparisonExp>;
  user?: Maybe<SaveStoryUserBoolExp>;
  user_id?: Maybe<SaveStoryUuidComparisonExp>;
  views?: Maybe<SaveStoryIntComparisonExp>;
  violation_notes?: Maybe<SaveStoryViolationNoteBoolExp>;
};

export enum SaveStoryStoryConstraint {
  /** unique or primary key constraint */
  StoryPkey = 'story_pkey'
}

export type SaveStoryStoryInsertInput = {
  caption?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  first_submitted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  is_active?: Maybe<Scalars['Boolean']>;
  is_name_included?: Maybe<Scalars['Boolean']>;
  is_shareable?: Maybe<Scalars['Boolean']>;
  last_approved_at?: Maybe<Scalars['timestamptz']>;
  last_rejected_at?: Maybe<Scalars['timestamptz']>;
  last_submitted_at?: Maybe<Scalars['timestamptz']>;
  moderator_notes?: Maybe<SaveStoryModeratorNoteArrRelInsertInput>;
  number_of_approvals?: Maybe<Scalars['Int']>;
  number_of_rejections?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['timestamptz']>;
  ranking?: Maybe<Scalars['Int']>;
  reactions?: Maybe<SaveStoryReactionArrRelInsertInput>;
  replies?: Maybe<SaveStoryReplyArrRelInsertInput>;
  resource?: Maybe<SaveStoryResourceObjRelInsertInput>;
  shares?: Maybe<SaveStoryShareArrRelInsertInput>;
  show_suburb?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
  story_saves?: Maybe<SaveStoryStorySaveArrRelInsertInput>;
  story_violations?: Maybe<SaveStoryStoryViolationArrRelInsertInput>;
  tags?: Maybe<SaveStoryStoryTagArrRelInsertInput>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<SaveStoryUserObjRelInsertInput>;
  user_id?: Maybe<Scalars['uuid']>;
  views?: Maybe<Scalars['Int']>;
  violation_notes?: Maybe<SaveStoryViolationNoteArrRelInsertInput>;
};

export type SaveStoryStoryObjRelInsertInput = {
  data: SaveStoryStoryInsertInput;
  on_conflict?: Maybe<SaveStoryStoryOnConflict>;
};

export type SaveStoryStoryOnConflict = {
  constraint: SaveStoryStoryConstraint;
  update_columns: Array<SaveStoryStoryUpdateColumn>;
  where?: Maybe<SaveStoryStoryBoolExp>;
};

export type SaveStoryStorySaveArrRelInsertInput = {
  data: Array<SaveStoryStorySaveInsertInput>;
  on_conflict?: Maybe<SaveStoryStorySaveOnConflict>;
};

export type SaveStoryStorySaveBoolExp = {
  _and?: Maybe<Array<Maybe<SaveStoryStorySaveBoolExp>>>;
  _not?: Maybe<SaveStoryStorySaveBoolExp>;
  _or?: Maybe<Array<Maybe<SaveStoryStorySaveBoolExp>>>;
  created_at?: Maybe<SaveStoryTimestamptzComparisonExp>;
  id?: Maybe<SaveStoryUuidComparisonExp>;
  story?: Maybe<SaveStoryStoryBoolExp>;
  story_id?: Maybe<SaveStoryUuidComparisonExp>;
  user?: Maybe<SaveStoryUserBoolExp>;
  user_id?: Maybe<SaveStoryUuidComparisonExp>;
};

export enum SaveStoryStorySaveConstraint {
  /** unique or primary key constraint */
  StorySavePkey = 'story_save_pkey',
  /** unique or primary key constraint */
  UniqueUserIdStoryId = 'unique_user_id_story_id'
}

export type SaveStoryStorySaveInsertInput = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  story?: Maybe<SaveStoryStoryObjRelInsertInput>;
  story_id?: Maybe<Scalars['uuid']>;
  user?: Maybe<SaveStoryUserObjRelInsertInput>;
  user_id?: Maybe<Scalars['uuid']>;
};

export type SaveStoryStorySaveOnConflict = {
  constraint: SaveStoryStorySaveConstraint;
  update_columns: Array<SaveStoryStorySaveUpdateColumn>;
  where?: Maybe<SaveStoryStorySaveBoolExp>;
};

export enum SaveStoryStorySaveUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  StoryId = 'story_id',
  /** column name */
  UserId = 'user_id'
}

export type SaveStoryStoryTagArrRelInsertInput = {
  data: Array<SaveStoryStoryTagInsertInput>;
  on_conflict?: Maybe<SaveStoryStoryTagOnConflict>;
};

export type SaveStoryStoryTagBoolExp = {
  _and?: Maybe<Array<Maybe<SaveStoryStoryTagBoolExp>>>;
  _not?: Maybe<SaveStoryStoryTagBoolExp>;
  _or?: Maybe<Array<Maybe<SaveStoryStoryTagBoolExp>>>;
  id?: Maybe<SaveStoryUuidComparisonExp>;
  story?: Maybe<SaveStoryStoryBoolExp>;
  story_id?: Maybe<SaveStoryUuidComparisonExp>;
  tag?: Maybe<SaveStoryTagBoolExp>;
  tag_id?: Maybe<SaveStoryUuidComparisonExp>;
};

export enum SaveStoryStoryTagConstraint {
  /** unique or primary key constraint */
  StoryTagPkey = 'story_tag_pkey',
  /** unique or primary key constraint */
  UniqueStoryIdTagId = 'unique_story_id_tag_id'
}

export type SaveStoryStoryTagInsertInput = {
  id?: Maybe<Scalars['uuid']>;
  story?: Maybe<SaveStoryStoryObjRelInsertInput>;
  story_id?: Maybe<Scalars['uuid']>;
  tag?: Maybe<SaveStoryTagObjRelInsertInput>;
  tag_id?: Maybe<Scalars['uuid']>;
};

export type SaveStoryStoryTagOnConflict = {
  constraint: SaveStoryStoryTagConstraint;
  update_columns: Array<SaveStoryStoryTagUpdateColumn>;
  where?: Maybe<SaveStoryStoryTagBoolExp>;
};

export enum SaveStoryStoryTagUpdateColumn {
  /** column name */
  Id = 'id',
  /** column name */
  StoryId = 'story_id',
  /** column name */
  TagId = 'tag_id'
}

export enum SaveStoryStoryUpdateColumn {
  /** column name */
  Caption = 'caption',
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FirstSubmittedAt = 'first_submitted_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsNameIncluded = 'is_name_included',
  /** column name */
  IsShareable = 'is_shareable',
  /** column name */
  LastApprovedAt = 'last_approved_at',
  /** column name */
  LastRejectedAt = 'last_rejected_at',
  /** column name */
  LastSubmittedAt = 'last_submitted_at',
  /** column name */
  NumberOfApprovals = 'number_of_approvals',
  /** column name */
  NumberOfRejections = 'number_of_rejections',
  /** column name */
  PublishedAt = 'published_at',
  /** column name */
  Ranking = 'ranking',
  /** column name */
  ShowSuburb = 'show_suburb',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Views = 'views'
}

export type SaveStoryStoryViolationArrRelInsertInput = {
  data: Array<SaveStoryStoryViolationInsertInput>;
  on_conflict?: Maybe<SaveStoryStoryViolationOnConflict>;
};

export type SaveStoryStoryViolationBoolExp = {
  _and?: Maybe<Array<Maybe<SaveStoryStoryViolationBoolExp>>>;
  _not?: Maybe<SaveStoryStoryViolationBoolExp>;
  _or?: Maybe<Array<Maybe<SaveStoryStoryViolationBoolExp>>>;
  created_at?: Maybe<SaveStoryTimestamptzComparisonExp>;
  id?: Maybe<SaveStoryUuidComparisonExp>;
  story?: Maybe<SaveStoryStoryBoolExp>;
  story_id?: Maybe<SaveStoryUuidComparisonExp>;
  user?: Maybe<SaveStoryUserBoolExp>;
  user_id?: Maybe<SaveStoryUuidComparisonExp>;
};

export enum SaveStoryStoryViolationConstraint {
  /** unique or primary key constraint */
  StoryViolationPkey = 'story_violation_pkey'
}

export type SaveStoryStoryViolationInsertInput = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  story?: Maybe<SaveStoryStoryObjRelInsertInput>;
  story_id?: Maybe<Scalars['uuid']>;
  user?: Maybe<SaveStoryUserObjRelInsertInput>;
  user_id?: Maybe<Scalars['uuid']>;
};

export type SaveStoryStoryViolationOnConflict = {
  constraint: SaveStoryStoryViolationConstraint;
  update_columns: Array<SaveStoryStoryViolationUpdateColumn>;
  where?: Maybe<SaveStoryStoryViolationBoolExp>;
};

export enum SaveStoryStoryViolationUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  StoryId = 'story_id',
  /** column name */
  UserId = 'user_id'
}

export type SaveStoryStringComparisonExp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  _nlike?: Maybe<Scalars['String']>;
  _nsimilar?: Maybe<Scalars['String']>;
  _similar?: Maybe<Scalars['String']>;
};

export type SaveStorySupportGroupBoolExp = {
  _and?: Maybe<Array<Maybe<SaveStorySupportGroupBoolExp>>>;
  _not?: Maybe<SaveStorySupportGroupBoolExp>;
  _or?: Maybe<Array<Maybe<SaveStorySupportGroupBoolExp>>>;
  support_groups?: Maybe<SaveStoryUserSupportGroupBoolExp>;
  value?: Maybe<SaveStoryStringComparisonExp>;
};

export enum SaveStorySupportGroupConstraint {
  /** unique or primary key constraint */
  SupportGroupPkey = 'support_group_pkey'
}

export enum SaveStorySupportGroupEnum {
  Coach = 'COACH',
  Family = 'FAMILY',
  Friends = 'FRIENDS',
  FriendsParent = 'FRIENDS_PARENT',
  Gp = 'GP',
  Neighbour = 'NEIGHBOUR',
  Psychologist = 'PSYCHOLOGIST',
  Teacher = 'TEACHER'
}

export type SaveStorySupportGroupEnumComparisonExp = {
  _eq?: Maybe<SaveStorySupportGroupEnum>;
  _in?: Maybe<Array<SaveStorySupportGroupEnum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<SaveStorySupportGroupEnum>;
  _nin?: Maybe<Array<SaveStorySupportGroupEnum>>;
};

export type SaveStorySupportGroupInsertInput = {
  support_groups?: Maybe<SaveStoryUserSupportGroupArrRelInsertInput>;
  value?: Maybe<Scalars['String']>;
};

export type SaveStorySupportGroupObjRelInsertInput = {
  data: SaveStorySupportGroupInsertInput;
  on_conflict?: Maybe<SaveStorySupportGroupOnConflict>;
};

export type SaveStorySupportGroupOnConflict = {
  constraint: SaveStorySupportGroupConstraint;
  update_columns: Array<SaveStorySupportGroupUpdateColumn>;
  where?: Maybe<SaveStorySupportGroupBoolExp>;
};

export enum SaveStorySupportGroupUpdateColumn {
  /** column name */
  Value = 'value'
}

export type SaveStoryTagArrRelInsertInput = {
  data: Array<SaveStoryTagInsertInput>;
  on_conflict?: Maybe<SaveStoryTagOnConflict>;
};

export type SaveStoryTagBoolExp = {
  _and?: Maybe<Array<Maybe<SaveStoryTagBoolExp>>>;
  _not?: Maybe<SaveStoryTagBoolExp>;
  _or?: Maybe<Array<Maybe<SaveStoryTagBoolExp>>>;
  created_at?: Maybe<SaveStoryTimestamptzComparisonExp>;
  id?: Maybe<SaveStoryUuidComparisonExp>;
  name?: Maybe<SaveStoryStringComparisonExp>;
  tag_type?: Maybe<SaveStoryTagTypeBoolExp>;
  tags?: Maybe<SaveStoryStoryTagBoolExp>;
  type?: Maybe<SaveStoryTagTypeEnumComparisonExp>;
  updated_at?: Maybe<SaveStoryTimestamptzComparisonExp>;
  verified?: Maybe<SaveStoryBooleanComparisonExp>;
};

export enum SaveStoryTagConstraint {
  /** unique or primary key constraint */
  TagPkey = 'tag_pkey'
}

export type SaveStoryTagInsertInput = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  tag_type?: Maybe<SaveStoryTagTypeObjRelInsertInput>;
  tags?: Maybe<SaveStoryStoryTagArrRelInsertInput>;
  type?: Maybe<SaveStoryTagTypeEnum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  verified?: Maybe<Scalars['Boolean']>;
};

export type SaveStoryTagObjRelInsertInput = {
  data: SaveStoryTagInsertInput;
  on_conflict?: Maybe<SaveStoryTagOnConflict>;
};

export type SaveStoryTagOnConflict = {
  constraint: SaveStoryTagConstraint;
  update_columns: Array<SaveStoryTagUpdateColumn>;
  where?: Maybe<SaveStoryTagBoolExp>;
};

export type SaveStoryTagTypeBoolExp = {
  _and?: Maybe<Array<Maybe<SaveStoryTagTypeBoolExp>>>;
  _not?: Maybe<SaveStoryTagTypeBoolExp>;
  _or?: Maybe<Array<Maybe<SaveStoryTagTypeBoolExp>>>;
  tags?: Maybe<SaveStoryTagBoolExp>;
  value?: Maybe<SaveStoryStringComparisonExp>;
};

export enum SaveStoryTagTypeConstraint {
  /** unique or primary key constraint */
  TagTypePkey = 'tag_type_pkey'
}

export enum SaveStoryTagTypeEnum {
  Experience = 'EXPERIENCE',
  Framing = 'FRAMING'
}

export type SaveStoryTagTypeEnumComparisonExp = {
  _eq?: Maybe<SaveStoryTagTypeEnum>;
  _in?: Maybe<Array<SaveStoryTagTypeEnum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<SaveStoryTagTypeEnum>;
  _nin?: Maybe<Array<SaveStoryTagTypeEnum>>;
};

export type SaveStoryTagTypeInsertInput = {
  tags?: Maybe<SaveStoryTagArrRelInsertInput>;
  value?: Maybe<Scalars['String']>;
};

export type SaveStoryTagTypeObjRelInsertInput = {
  data: SaveStoryTagTypeInsertInput;
  on_conflict?: Maybe<SaveStoryTagTypeOnConflict>;
};

export type SaveStoryTagTypeOnConflict = {
  constraint: SaveStoryTagTypeConstraint;
  update_columns: Array<SaveStoryTagTypeUpdateColumn>;
  where?: Maybe<SaveStoryTagTypeBoolExp>;
};

export enum SaveStoryTagTypeUpdateColumn {
  /** column name */
  Value = 'value'
}

export enum SaveStoryTagUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Verified = 'verified'
}

export type SaveStoryTimestampComparisonExp = {
  _eq?: Maybe<Scalars['timestamp']>;
  _gt?: Maybe<Scalars['timestamp']>;
  _gte?: Maybe<Scalars['timestamp']>;
  _in?: Maybe<Array<Scalars['timestamp']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamp']>;
  _lte?: Maybe<Scalars['timestamp']>;
  _neq?: Maybe<Scalars['timestamp']>;
  _nin?: Maybe<Array<Scalars['timestamp']>>;
};

export type SaveStoryTimestamptzComparisonExp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};

export type SaveStoryUserBoolExp = {
  _and?: Maybe<Array<Maybe<SaveStoryUserBoolExp>>>;
  _not?: Maybe<SaveStoryUserBoolExp>;
  _or?: Maybe<Array<Maybe<SaveStoryUserBoolExp>>>;
  accepts_email?: Maybe<SaveStoryBooleanComparisonExp>;
  accepts_notification?: Maybe<SaveStoryBooleanComparisonExp>;
  account?: Maybe<SaveStoryAuthAccountsBoolExp>;
  avatar_url?: Maybe<SaveStoryStringComparisonExp>;
  completed_profile_at?: Maybe<SaveStoryTimestampComparisonExp>;
  created_at?: Maybe<SaveStoryTimestamptzComparisonExp>;
  date_of_birth?: Maybe<SaveStoryDateComparisonExp>;
  display_name?: Maybe<SaveStoryStringComparisonExp>;
  employment_status?: Maybe<SaveStoryStringComparisonExp>;
  favourite_animal?: Maybe<SaveStoryStringComparisonExp>;
  favourite_memes?: Maybe<SaveStoryStringComparisonExp>;
  favourite_place?: Maybe<SaveStoryStringComparisonExp>;
  favourite_spotify_playlists?: Maybe<SaveStoryStringComparisonExp>;
  feedbacks?: Maybe<SaveStoryFeedbackBoolExp>;
  first_name?: Maybe<SaveStoryStringComparisonExp>;
  flags?: Maybe<SaveStoryUserFlagBoolExp>;
  gender?: Maybe<SaveStoryStringComparisonExp>;
  id?: Maybe<SaveStoryUuidComparisonExp>;
  moderator_notes?: Maybe<SaveStoryModeratorNoteBoolExp>;
  personal_description?: Maybe<SaveStoryStringComparisonExp>;
  reactions?: Maybe<SaveStoryReactionBoolExp>;
  screener_score?: Maybe<SaveStoryIntComparisonExp>;
  shares?: Maybe<SaveStoryShareBoolExp>;
  status?: Maybe<SaveStoryStringComparisonExp>;
  stories?: Maybe<SaveStoryStoryBoolExp>;
  story_saves?: Maybe<SaveStoryStorySaveBoolExp>;
  story_violations?: Maybe<SaveStoryStoryViolationBoolExp>;
  suburb?: Maybe<SaveStoryStringComparisonExp>;
  support_groups?: Maybe<SaveStoryUserSupportGroupBoolExp>;
  updated_at?: Maybe<SaveStoryTimestamptzComparisonExp>;
  violation_notes?: Maybe<SaveStoryViolationNoteBoolExp>;
};

export enum SaveStoryUserConstraint {
  /** unique or primary key constraint */
  UserPkey = 'user_pkey'
}

export type SaveStoryUserFlagArrRelInsertInput = {
  data: Array<SaveStoryUserFlagInsertInput>;
  on_conflict?: Maybe<SaveStoryUserFlagOnConflict>;
};

export type SaveStoryUserFlagBoolExp = {
  _and?: Maybe<Array<Maybe<SaveStoryUserFlagBoolExp>>>;
  _not?: Maybe<SaveStoryUserFlagBoolExp>;
  _or?: Maybe<Array<Maybe<SaveStoryUserFlagBoolExp>>>;
  flag?: Maybe<SaveStoryFlagBoolExp>;
  flag_id?: Maybe<SaveStoryUuidComparisonExp>;
  id?: Maybe<SaveStoryUuidComparisonExp>;
  user?: Maybe<SaveStoryUserBoolExp>;
  user_id?: Maybe<SaveStoryUuidComparisonExp>;
};

export enum SaveStoryUserFlagConstraint {
  /** unique or primary key constraint */
  UserFlagPkey = 'user_flag_pkey'
}

export type SaveStoryUserFlagInsertInput = {
  flag?: Maybe<SaveStoryFlagObjRelInsertInput>;
  flag_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  user?: Maybe<SaveStoryUserObjRelInsertInput>;
  user_id?: Maybe<Scalars['uuid']>;
};

export type SaveStoryUserFlagOnConflict = {
  constraint: SaveStoryUserFlagConstraint;
  update_columns: Array<SaveStoryUserFlagUpdateColumn>;
  where?: Maybe<SaveStoryUserFlagBoolExp>;
};

export enum SaveStoryUserFlagUpdateColumn {
  /** column name */
  FlagId = 'flag_id',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id'
}

export type SaveStoryUserInsertInput = {
  accepts_email?: Maybe<Scalars['Boolean']>;
  accepts_notification?: Maybe<Scalars['Boolean']>;
  account?: Maybe<SaveStoryAuthAccountsObjRelInsertInput>;
  avatar_url?: Maybe<Scalars['String']>;
  completed_profile_at?: Maybe<Scalars['timestamp']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date_of_birth?: Maybe<Scalars['date']>;
  display_name?: Maybe<Scalars['String']>;
  employment_status?: Maybe<Scalars['String']>;
  favourite_animal?: Maybe<Scalars['String']>;
  favourite_memes?: Maybe<Scalars['String']>;
  favourite_place?: Maybe<Scalars['String']>;
  favourite_spotify_playlists?: Maybe<Scalars['String']>;
  feedbacks?: Maybe<SaveStoryFeedbackArrRelInsertInput>;
  first_name?: Maybe<Scalars['String']>;
  flags?: Maybe<SaveStoryUserFlagArrRelInsertInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  moderator_notes?: Maybe<SaveStoryModeratorNoteArrRelInsertInput>;
  personal_description?: Maybe<Scalars['String']>;
  reactions?: Maybe<SaveStoryReactionArrRelInsertInput>;
  screener_score?: Maybe<Scalars['Int']>;
  shares?: Maybe<SaveStoryShareArrRelInsertInput>;
  status?: Maybe<Scalars['String']>;
  stories?: Maybe<SaveStoryStoryArrRelInsertInput>;
  story_saves?: Maybe<SaveStoryStorySaveArrRelInsertInput>;
  story_violations?: Maybe<SaveStoryStoryViolationArrRelInsertInput>;
  suburb?: Maybe<Scalars['String']>;
  support_groups?: Maybe<SaveStoryUserSupportGroupArrRelInsertInput>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  violation_notes?: Maybe<SaveStoryViolationNoteArrRelInsertInput>;
};

export type SaveStoryUserObjRelInsertInput = {
  data: SaveStoryUserInsertInput;
  on_conflict?: Maybe<SaveStoryUserOnConflict>;
};

export type SaveStoryUserOnConflict = {
  constraint: SaveStoryUserConstraint;
  update_columns: Array<SaveStoryUserUpdateColumn>;
  where?: Maybe<SaveStoryUserBoolExp>;
};

export type SaveStoryUserSupportGroupArrRelInsertInput = {
  data: Array<SaveStoryUserSupportGroupInsertInput>;
  on_conflict?: Maybe<SaveStoryUserSupportGroupOnConflict>;
};

export type SaveStoryUserSupportGroupBoolExp = {
  _and?: Maybe<Array<Maybe<SaveStoryUserSupportGroupBoolExp>>>;
  _not?: Maybe<SaveStoryUserSupportGroupBoolExp>;
  _or?: Maybe<Array<Maybe<SaveStoryUserSupportGroupBoolExp>>>;
  id?: Maybe<SaveStoryUuidComparisonExp>;
  supportGroupBySupportGroup?: Maybe<SaveStorySupportGroupBoolExp>;
  support_group?: Maybe<SaveStorySupportGroupEnumComparisonExp>;
  user?: Maybe<SaveStoryUserBoolExp>;
  user_id?: Maybe<SaveStoryUuidComparisonExp>;
};

export enum SaveStoryUserSupportGroupConstraint {
  /** unique or primary key constraint */
  UserSupportGroupPkey = 'user_support_group_pkey'
}

export type SaveStoryUserSupportGroupInsertInput = {
  id?: Maybe<Scalars['uuid']>;
  supportGroupBySupportGroup?: Maybe<SaveStorySupportGroupObjRelInsertInput>;
  support_group?: Maybe<SaveStorySupportGroupEnum>;
  user?: Maybe<SaveStoryUserObjRelInsertInput>;
  user_id?: Maybe<Scalars['uuid']>;
};

export type SaveStoryUserSupportGroupOnConflict = {
  constraint: SaveStoryUserSupportGroupConstraint;
  update_columns: Array<SaveStoryUserSupportGroupUpdateColumn>;
  where?: Maybe<SaveStoryUserSupportGroupBoolExp>;
};

export enum SaveStoryUserSupportGroupUpdateColumn {
  /** column name */
  Id = 'id',
  /** column name */
  SupportGroup = 'support_group',
  /** column name */
  UserId = 'user_id'
}

export enum SaveStoryUserUpdateColumn {
  /** column name */
  AcceptsEmail = 'accepts_email',
  /** column name */
  AcceptsNotification = 'accepts_notification',
  /** column name */
  AvatarUrl = 'avatar_url',
  /** column name */
  CompletedProfileAt = 'completed_profile_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateOfBirth = 'date_of_birth',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  EmploymentStatus = 'employment_status',
  /** column name */
  FavouriteAnimal = 'favourite_animal',
  /** column name */
  FavouriteMemes = 'favourite_memes',
  /** column name */
  FavouritePlace = 'favourite_place',
  /** column name */
  FavouriteSpotifyPlaylists = 'favourite_spotify_playlists',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Gender = 'gender',
  /** column name */
  Id = 'id',
  /** column name */
  PersonalDescription = 'personal_description',
  /** column name */
  ScreenerScore = 'screener_score',
  /** column name */
  Status = 'status',
  /** column name */
  Suburb = 'suburb',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type SaveStoryUuidComparisonExp = {
  _eq?: Maybe<Scalars['uuid']>;
  _gt?: Maybe<Scalars['uuid']>;
  _gte?: Maybe<Scalars['uuid']>;
  _in?: Maybe<Array<Scalars['uuid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['uuid']>;
  _lte?: Maybe<Scalars['uuid']>;
  _neq?: Maybe<Scalars['uuid']>;
  _nin?: Maybe<Array<Scalars['uuid']>>;
};

export type SaveStoryViolationNoteArrRelInsertInput = {
  data: Array<SaveStoryViolationNoteInsertInput>;
  on_conflict?: Maybe<SaveStoryViolationNoteOnConflict>;
};

export type SaveStoryViolationNoteBoolExp = {
  _and?: Maybe<Array<Maybe<SaveStoryViolationNoteBoolExp>>>;
  _not?: Maybe<SaveStoryViolationNoteBoolExp>;
  _or?: Maybe<Array<Maybe<SaveStoryViolationNoteBoolExp>>>;
  action?: Maybe<SaveStoryStringComparisonExp>;
  created_at?: Maybe<SaveStoryTimestamptzComparisonExp>;
  id?: Maybe<SaveStoryUuidComparisonExp>;
  note?: Maybe<SaveStoryStringComparisonExp>;
  story?: Maybe<SaveStoryStoryBoolExp>;
  story_id?: Maybe<SaveStoryUuidComparisonExp>;
  user?: Maybe<SaveStoryUserBoolExp>;
  user_id?: Maybe<SaveStoryUuidComparisonExp>;
};

export enum SaveStoryViolationNoteConstraint {
  /** unique or primary key constraint */
  ViolationNotePkey = 'violation_note_pkey'
}

export type SaveStoryViolationNoteInsertInput = {
  action?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  story?: Maybe<SaveStoryStoryObjRelInsertInput>;
  story_id?: Maybe<Scalars['uuid']>;
  user?: Maybe<SaveStoryUserObjRelInsertInput>;
  user_id?: Maybe<Scalars['uuid']>;
};

export type SaveStoryViolationNoteOnConflict = {
  constraint: SaveStoryViolationNoteConstraint;
  update_columns: Array<SaveStoryViolationNoteUpdateColumn>;
  where?: Maybe<SaveStoryViolationNoteBoolExp>;
};

export enum SaveStoryViolationNoteUpdateColumn {
  /** column name */
  Action = 'action',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Note = 'note',
  /** column name */
  StoryId = 'story_id',
  /** column name */
  UserId = 'user_id'
}

export type SendEmailOutput = {
  __typename?: 'SendEmailOutput';
  success: Scalars['Boolean'];
};

export type SendPushOutput = {
  __typename?: 'SendPushOutput';
  success: Scalars['Boolean'];
};

export type SendPushToUserOutput = {
  __typename?: 'SendPushToUserOutput';
  success: Scalars['Boolean'];
};

export type SendPushToUsersOutput = {
  __typename?: 'SendPushToUsersOutput';
  success: Scalars['Boolean'];
};

export type SignedUrlOutput = {
  __typename?: 'SignedUrlOutput';
  content_type: Scalars['String'];
  resource_id: Scalars['uuid'];
  signed_url: Scalars['String'];
};

/** expression to compare columns of type String. All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  _nlike?: Maybe<Scalars['String']>;
  _nsimilar?: Maybe<Scalars['String']>;
  _similar?: Maybe<Scalars['String']>;
};

export type SubmitStoryOutput = {
  __typename?: 'SubmitStoryOutput';
  content?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  status: Scalars['String'];
};

export type UpdateSubmittedStoryOutput = {
  __typename?: 'UpdateSubmittedStoryOutput';
  content?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  status: Scalars['String'];
};

export type UpdateUserSubscriptionOutput = {
  __typename?: 'UpdateUserSubscriptionOutput';
  success: Scalars['Boolean'];
};

/** columns and relationships of "assessment_item" */
export type Assessment_Item = {
  __typename?: 'assessment_item';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  question: Scalars['String'];
  score: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "assessment_item" */
export type Assessment_Item_Aggregate = {
  __typename?: 'assessment_item_aggregate';
  aggregate?: Maybe<Assessment_Item_Aggregate_Fields>;
  nodes: Array<Assessment_Item>;
};

/** aggregate fields of "assessment_item" */
export type Assessment_Item_Aggregate_Fields = {
  __typename?: 'assessment_item_aggregate_fields';
  avg?: Maybe<Assessment_Item_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Assessment_Item_Max_Fields>;
  min?: Maybe<Assessment_Item_Min_Fields>;
  stddev?: Maybe<Assessment_Item_Stddev_Fields>;
  stddev_pop?: Maybe<Assessment_Item_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Assessment_Item_Stddev_Samp_Fields>;
  sum?: Maybe<Assessment_Item_Sum_Fields>;
  var_pop?: Maybe<Assessment_Item_Var_Pop_Fields>;
  var_samp?: Maybe<Assessment_Item_Var_Samp_Fields>;
  variance?: Maybe<Assessment_Item_Variance_Fields>;
};


/** aggregate fields of "assessment_item" */
export type Assessment_Item_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Assessment_Item_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "assessment_item" */
export type Assessment_Item_Aggregate_Order_By = {
  avg?: Maybe<Assessment_Item_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Assessment_Item_Max_Order_By>;
  min?: Maybe<Assessment_Item_Min_Order_By>;
  stddev?: Maybe<Assessment_Item_Stddev_Order_By>;
  stddev_pop?: Maybe<Assessment_Item_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Assessment_Item_Stddev_Samp_Order_By>;
  sum?: Maybe<Assessment_Item_Sum_Order_By>;
  var_pop?: Maybe<Assessment_Item_Var_Pop_Order_By>;
  var_samp?: Maybe<Assessment_Item_Var_Samp_Order_By>;
  variance?: Maybe<Assessment_Item_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "assessment_item" */
export type Assessment_Item_Arr_Rel_Insert_Input = {
  data: Array<Assessment_Item_Insert_Input>;
  on_conflict?: Maybe<Assessment_Item_On_Conflict>;
};

/** aggregate avg on columns */
export type Assessment_Item_Avg_Fields = {
  __typename?: 'assessment_item_avg_fields';
  score?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "assessment_item" */
export type Assessment_Item_Avg_Order_By = {
  score?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "assessment_item". All fields are combined with a logical 'AND'. */
export type Assessment_Item_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Assessment_Item_Bool_Exp>>>;
  _not?: Maybe<Assessment_Item_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Assessment_Item_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  question?: Maybe<String_Comparison_Exp>;
  score?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "assessment_item" */
export enum Assessment_Item_Constraint {
  /** unique or primary key constraint */
  AssessmentItemPkey = 'assessment_item_pkey'
}

/** input type for incrementing integer column in table "assessment_item" */
export type Assessment_Item_Inc_Input = {
  score?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "assessment_item" */
export type Assessment_Item_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  question?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Assessment_Item_Max_Fields = {
  __typename?: 'assessment_item_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  question?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "assessment_item" */
export type Assessment_Item_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  question?: Maybe<Order_By>;
  score?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Assessment_Item_Min_Fields = {
  __typename?: 'assessment_item_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  question?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "assessment_item" */
export type Assessment_Item_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  question?: Maybe<Order_By>;
  score?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "assessment_item" */
export type Assessment_Item_Mutation_Response = {
  __typename?: 'assessment_item_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Assessment_Item>;
};

/** input type for inserting object relation for remote table "assessment_item" */
export type Assessment_Item_Obj_Rel_Insert_Input = {
  data: Assessment_Item_Insert_Input;
  on_conflict?: Maybe<Assessment_Item_On_Conflict>;
};

/** on conflict condition type for table "assessment_item" */
export type Assessment_Item_On_Conflict = {
  constraint: Assessment_Item_Constraint;
  update_columns: Array<Assessment_Item_Update_Column>;
  where?: Maybe<Assessment_Item_Bool_Exp>;
};

/** ordering options when selecting data from "assessment_item" */
export type Assessment_Item_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  question?: Maybe<Order_By>;
  score?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "assessment_item" */
export type Assessment_Item_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "assessment_item" */
export enum Assessment_Item_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Question = 'question',
  /** column name */
  Score = 'score',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "assessment_item" */
export type Assessment_Item_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  question?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Assessment_Item_Stddev_Fields = {
  __typename?: 'assessment_item_stddev_fields';
  score?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "assessment_item" */
export type Assessment_Item_Stddev_Order_By = {
  score?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Assessment_Item_Stddev_Pop_Fields = {
  __typename?: 'assessment_item_stddev_pop_fields';
  score?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "assessment_item" */
export type Assessment_Item_Stddev_Pop_Order_By = {
  score?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Assessment_Item_Stddev_Samp_Fields = {
  __typename?: 'assessment_item_stddev_samp_fields';
  score?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "assessment_item" */
export type Assessment_Item_Stddev_Samp_Order_By = {
  score?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Assessment_Item_Sum_Fields = {
  __typename?: 'assessment_item_sum_fields';
  score?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "assessment_item" */
export type Assessment_Item_Sum_Order_By = {
  score?: Maybe<Order_By>;
};

/** update columns of table "assessment_item" */
export enum Assessment_Item_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Question = 'question',
  /** column name */
  Score = 'score',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Assessment_Item_Var_Pop_Fields = {
  __typename?: 'assessment_item_var_pop_fields';
  score?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "assessment_item" */
export type Assessment_Item_Var_Pop_Order_By = {
  score?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Assessment_Item_Var_Samp_Fields = {
  __typename?: 'assessment_item_var_samp_fields';
  score?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "assessment_item" */
export type Assessment_Item_Var_Samp_Order_By = {
  score?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Assessment_Item_Variance_Fields = {
  __typename?: 'assessment_item_variance_fields';
  score?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "assessment_item" */
export type Assessment_Item_Variance_Order_By = {
  score?: Maybe<Order_By>;
};

/** columns and relationships of "audience" */
export type Audience = {
  __typename?: 'audience';
  value: Scalars['String'];
};

/** aggregated selection of "audience" */
export type Audience_Aggregate = {
  __typename?: 'audience_aggregate';
  aggregate?: Maybe<Audience_Aggregate_Fields>;
  nodes: Array<Audience>;
};

/** aggregate fields of "audience" */
export type Audience_Aggregate_Fields = {
  __typename?: 'audience_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Audience_Max_Fields>;
  min?: Maybe<Audience_Min_Fields>;
};


/** aggregate fields of "audience" */
export type Audience_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Audience_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "audience" */
export type Audience_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Audience_Max_Order_By>;
  min?: Maybe<Audience_Min_Order_By>;
};

/** input type for inserting array relation for remote table "audience" */
export type Audience_Arr_Rel_Insert_Input = {
  data: Array<Audience_Insert_Input>;
  on_conflict?: Maybe<Audience_On_Conflict>;
};

/** Boolean expression to filter rows from the table "audience". All fields are combined with a logical 'AND'. */
export type Audience_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Audience_Bool_Exp>>>;
  _not?: Maybe<Audience_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Audience_Bool_Exp>>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "audience" */
export enum Audience_Constraint {
  /** unique or primary key constraint */
  AudiencePkey = 'audience_pkey'
}

/** input type for inserting data into table "audience" */
export type Audience_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Audience_Max_Fields = {
  __typename?: 'audience_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "audience" */
export type Audience_Max_Order_By = {
  value?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Audience_Min_Fields = {
  __typename?: 'audience_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "audience" */
export type Audience_Min_Order_By = {
  value?: Maybe<Order_By>;
};

/** response of any mutation on the table "audience" */
export type Audience_Mutation_Response = {
  __typename?: 'audience_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Audience>;
};

/** input type for inserting object relation for remote table "audience" */
export type Audience_Obj_Rel_Insert_Input = {
  data: Audience_Insert_Input;
  on_conflict?: Maybe<Audience_On_Conflict>;
};

/** on conflict condition type for table "audience" */
export type Audience_On_Conflict = {
  constraint: Audience_Constraint;
  update_columns: Array<Audience_Update_Column>;
  where?: Maybe<Audience_Bool_Exp>;
};

/** ordering options when selecting data from "audience" */
export type Audience_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: "audience" */
export type Audience_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "audience" */
export enum Audience_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "audience" */
export type Audience_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "audience" */
export enum Audience_Update_Column {
  /** column name */
  Value = 'value'
}

/** columns and relationships of "auth.account_providers" */
export type Auth_Account_Providers = {
  __typename?: 'auth_account_providers';
  /** An object relationship */
  account: Auth_Accounts;
  account_id: Scalars['uuid'];
  auth_provider: Scalars['String'];
  auth_provider_unique_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  provider: Auth_Providers;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "auth.account_providers" */
export type Auth_Account_Providers_Aggregate = {
  __typename?: 'auth_account_providers_aggregate';
  aggregate?: Maybe<Auth_Account_Providers_Aggregate_Fields>;
  nodes: Array<Auth_Account_Providers>;
};

/** aggregate fields of "auth.account_providers" */
export type Auth_Account_Providers_Aggregate_Fields = {
  __typename?: 'auth_account_providers_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Auth_Account_Providers_Max_Fields>;
  min?: Maybe<Auth_Account_Providers_Min_Fields>;
};


/** aggregate fields of "auth.account_providers" */
export type Auth_Account_Providers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Auth_Account_Providers_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "auth.account_providers" */
export type Auth_Account_Providers_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Auth_Account_Providers_Max_Order_By>;
  min?: Maybe<Auth_Account_Providers_Min_Order_By>;
};

/** input type for inserting array relation for remote table "auth.account_providers" */
export type Auth_Account_Providers_Arr_Rel_Insert_Input = {
  data: Array<Auth_Account_Providers_Insert_Input>;
  on_conflict?: Maybe<Auth_Account_Providers_On_Conflict>;
};

/** Boolean expression to filter rows from the table "auth.account_providers". All fields are combined with a logical 'AND'. */
export type Auth_Account_Providers_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Auth_Account_Providers_Bool_Exp>>>;
  _not?: Maybe<Auth_Account_Providers_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Auth_Account_Providers_Bool_Exp>>>;
  account?: Maybe<Auth_Accounts_Bool_Exp>;
  account_id?: Maybe<Uuid_Comparison_Exp>;
  auth_provider?: Maybe<String_Comparison_Exp>;
  auth_provider_unique_id?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  provider?: Maybe<Auth_Providers_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.account_providers" */
export enum Auth_Account_Providers_Constraint {
  /** unique or primary key constraint */
  AccountProvidersAccountIdAuthProviderKey = 'account_providers_account_id_auth_provider_key',
  /** unique or primary key constraint */
  AccountProvidersAuthProviderAuthProviderUniqueIdKey = 'account_providers_auth_provider_auth_provider_unique_id_key',
  /** unique or primary key constraint */
  AccountProvidersPkey = 'account_providers_pkey'
}

/** input type for inserting data into table "auth.account_providers" */
export type Auth_Account_Providers_Insert_Input = {
  account?: Maybe<Auth_Accounts_Obj_Rel_Insert_Input>;
  account_id?: Maybe<Scalars['uuid']>;
  auth_provider?: Maybe<Scalars['String']>;
  auth_provider_unique_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  provider?: Maybe<Auth_Providers_Obj_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Auth_Account_Providers_Max_Fields = {
  __typename?: 'auth_account_providers_max_fields';
  account_id?: Maybe<Scalars['uuid']>;
  auth_provider?: Maybe<Scalars['String']>;
  auth_provider_unique_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "auth.account_providers" */
export type Auth_Account_Providers_Max_Order_By = {
  account_id?: Maybe<Order_By>;
  auth_provider?: Maybe<Order_By>;
  auth_provider_unique_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Auth_Account_Providers_Min_Fields = {
  __typename?: 'auth_account_providers_min_fields';
  account_id?: Maybe<Scalars['uuid']>;
  auth_provider?: Maybe<Scalars['String']>;
  auth_provider_unique_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "auth.account_providers" */
export type Auth_Account_Providers_Min_Order_By = {
  account_id?: Maybe<Order_By>;
  auth_provider?: Maybe<Order_By>;
  auth_provider_unique_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "auth.account_providers" */
export type Auth_Account_Providers_Mutation_Response = {
  __typename?: 'auth_account_providers_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Auth_Account_Providers>;
};

/** input type for inserting object relation for remote table "auth.account_providers" */
export type Auth_Account_Providers_Obj_Rel_Insert_Input = {
  data: Auth_Account_Providers_Insert_Input;
  on_conflict?: Maybe<Auth_Account_Providers_On_Conflict>;
};

/** on conflict condition type for table "auth.account_providers" */
export type Auth_Account_Providers_On_Conflict = {
  constraint: Auth_Account_Providers_Constraint;
  update_columns: Array<Auth_Account_Providers_Update_Column>;
  where?: Maybe<Auth_Account_Providers_Bool_Exp>;
};

/** ordering options when selecting data from "auth.account_providers" */
export type Auth_Account_Providers_Order_By = {
  account?: Maybe<Auth_Accounts_Order_By>;
  account_id?: Maybe<Order_By>;
  auth_provider?: Maybe<Order_By>;
  auth_provider_unique_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  provider?: Maybe<Auth_Providers_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "auth.account_providers" */
export type Auth_Account_Providers_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "auth.account_providers" */
export enum Auth_Account_Providers_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  AuthProvider = 'auth_provider',
  /** column name */
  AuthProviderUniqueId = 'auth_provider_unique_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "auth.account_providers" */
export type Auth_Account_Providers_Set_Input = {
  account_id?: Maybe<Scalars['uuid']>;
  auth_provider?: Maybe<Scalars['String']>;
  auth_provider_unique_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "auth.account_providers" */
export enum Auth_Account_Providers_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  AuthProvider = 'auth_provider',
  /** column name */
  AuthProviderUniqueId = 'auth_provider_unique_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "auth.account_roles" */
export type Auth_Account_Roles = {
  __typename?: 'auth_account_roles';
  /** An object relationship */
  account: Auth_Accounts;
  account_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  role: Scalars['String'];
  /** An object relationship */
  roleByRole: Auth_Roles;
};

/** aggregated selection of "auth.account_roles" */
export type Auth_Account_Roles_Aggregate = {
  __typename?: 'auth_account_roles_aggregate';
  aggregate?: Maybe<Auth_Account_Roles_Aggregate_Fields>;
  nodes: Array<Auth_Account_Roles>;
};

/** aggregate fields of "auth.account_roles" */
export type Auth_Account_Roles_Aggregate_Fields = {
  __typename?: 'auth_account_roles_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Auth_Account_Roles_Max_Fields>;
  min?: Maybe<Auth_Account_Roles_Min_Fields>;
};


/** aggregate fields of "auth.account_roles" */
export type Auth_Account_Roles_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Auth_Account_Roles_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "auth.account_roles" */
export type Auth_Account_Roles_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Auth_Account_Roles_Max_Order_By>;
  min?: Maybe<Auth_Account_Roles_Min_Order_By>;
};

/** input type for inserting array relation for remote table "auth.account_roles" */
export type Auth_Account_Roles_Arr_Rel_Insert_Input = {
  data: Array<Auth_Account_Roles_Insert_Input>;
  on_conflict?: Maybe<Auth_Account_Roles_On_Conflict>;
};

/** Boolean expression to filter rows from the table "auth.account_roles". All fields are combined with a logical 'AND'. */
export type Auth_Account_Roles_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Auth_Account_Roles_Bool_Exp>>>;
  _not?: Maybe<Auth_Account_Roles_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Auth_Account_Roles_Bool_Exp>>>;
  account?: Maybe<Auth_Accounts_Bool_Exp>;
  account_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  role?: Maybe<String_Comparison_Exp>;
  roleByRole?: Maybe<Auth_Roles_Bool_Exp>;
};

/** unique or primary key constraints on table "auth.account_roles" */
export enum Auth_Account_Roles_Constraint {
  /** unique or primary key constraint */
  AccountRolesPkey = 'account_roles_pkey',
  /** unique or primary key constraint */
  UserRolesAccountIdRoleKey = 'user_roles_account_id_role_key'
}

/** input type for inserting data into table "auth.account_roles" */
export type Auth_Account_Roles_Insert_Input = {
  account?: Maybe<Auth_Accounts_Obj_Rel_Insert_Input>;
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  role?: Maybe<Scalars['String']>;
  roleByRole?: Maybe<Auth_Roles_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Auth_Account_Roles_Max_Fields = {
  __typename?: 'auth_account_roles_max_fields';
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  role?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "auth.account_roles" */
export type Auth_Account_Roles_Max_Order_By = {
  account_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Auth_Account_Roles_Min_Fields = {
  __typename?: 'auth_account_roles_min_fields';
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  role?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "auth.account_roles" */
export type Auth_Account_Roles_Min_Order_By = {
  account_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
};

/** response of any mutation on the table "auth.account_roles" */
export type Auth_Account_Roles_Mutation_Response = {
  __typename?: 'auth_account_roles_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Auth_Account_Roles>;
};

/** input type for inserting object relation for remote table "auth.account_roles" */
export type Auth_Account_Roles_Obj_Rel_Insert_Input = {
  data: Auth_Account_Roles_Insert_Input;
  on_conflict?: Maybe<Auth_Account_Roles_On_Conflict>;
};

/** on conflict condition type for table "auth.account_roles" */
export type Auth_Account_Roles_On_Conflict = {
  constraint: Auth_Account_Roles_Constraint;
  update_columns: Array<Auth_Account_Roles_Update_Column>;
  where?: Maybe<Auth_Account_Roles_Bool_Exp>;
};

/** ordering options when selecting data from "auth.account_roles" */
export type Auth_Account_Roles_Order_By = {
  account?: Maybe<Auth_Accounts_Order_By>;
  account_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
  roleByRole?: Maybe<Auth_Roles_Order_By>;
};

/** primary key columns input for table: "auth.account_roles" */
export type Auth_Account_Roles_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "auth.account_roles" */
export enum Auth_Account_Roles_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Role = 'role'
}

/** input type for updating data in table "auth.account_roles" */
export type Auth_Account_Roles_Set_Input = {
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  role?: Maybe<Scalars['String']>;
};

/** update columns of table "auth.account_roles" */
export enum Auth_Account_Roles_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Role = 'role'
}

/** columns and relationships of "auth.accounts" */
export type Auth_Accounts = {
  __typename?: 'auth_accounts';
  /** An array relationship */
  account_providers: Array<Auth_Account_Providers>;
  /** An aggregated array relationship */
  account_providers_aggregate: Auth_Account_Providers_Aggregate;
  /** An array relationship */
  account_roles: Array<Auth_Account_Roles>;
  /** An aggregated array relationship */
  account_roles_aggregate: Auth_Account_Roles_Aggregate;
  active: Scalars['Boolean'];
  created_at: Scalars['timestamptz'];
  custom_register_data?: Maybe<Scalars['jsonb']>;
  default_role: Scalars['String'];
  email?: Maybe<Scalars['citext']>;
  id: Scalars['uuid'];
  is_anonymous: Scalars['Boolean'];
  mfa_enabled: Scalars['Boolean'];
  new_email?: Maybe<Scalars['citext']>;
  otp_secret?: Maybe<Scalars['String']>;
  password_hash?: Maybe<Scalars['String']>;
  /** An array relationship */
  refresh_tokens: Array<Auth_Refresh_Tokens>;
  /** An aggregated array relationship */
  refresh_tokens_aggregate: Auth_Refresh_Tokens_Aggregate;
  /** An object relationship */
  role: Auth_Roles;
  ticket: Scalars['uuid'];
  ticket_expires_at: Scalars['timestamptz'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: User;
  user_id: Scalars['uuid'];
};


/** columns and relationships of "auth.accounts" */
export type Auth_AccountsAccount_ProvidersArgs = {
  distinct_on?: Maybe<Array<Auth_Account_Providers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Account_Providers_Order_By>>;
  where?: Maybe<Auth_Account_Providers_Bool_Exp>;
};


/** columns and relationships of "auth.accounts" */
export type Auth_AccountsAccount_Providers_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Account_Providers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Account_Providers_Order_By>>;
  where?: Maybe<Auth_Account_Providers_Bool_Exp>;
};


/** columns and relationships of "auth.accounts" */
export type Auth_AccountsAccount_RolesArgs = {
  distinct_on?: Maybe<Array<Auth_Account_Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Account_Roles_Order_By>>;
  where?: Maybe<Auth_Account_Roles_Bool_Exp>;
};


/** columns and relationships of "auth.accounts" */
export type Auth_AccountsAccount_Roles_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Account_Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Account_Roles_Order_By>>;
  where?: Maybe<Auth_Account_Roles_Bool_Exp>;
};


/** columns and relationships of "auth.accounts" */
export type Auth_AccountsCustom_Register_DataArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "auth.accounts" */
export type Auth_AccountsRefresh_TokensArgs = {
  distinct_on?: Maybe<Array<Auth_Refresh_Tokens_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Refresh_Tokens_Order_By>>;
  where?: Maybe<Auth_Refresh_Tokens_Bool_Exp>;
};


/** columns and relationships of "auth.accounts" */
export type Auth_AccountsRefresh_Tokens_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Refresh_Tokens_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Refresh_Tokens_Order_By>>;
  where?: Maybe<Auth_Refresh_Tokens_Bool_Exp>;
};

/** aggregated selection of "auth.accounts" */
export type Auth_Accounts_Aggregate = {
  __typename?: 'auth_accounts_aggregate';
  aggregate?: Maybe<Auth_Accounts_Aggregate_Fields>;
  nodes: Array<Auth_Accounts>;
};

/** aggregate fields of "auth.accounts" */
export type Auth_Accounts_Aggregate_Fields = {
  __typename?: 'auth_accounts_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Auth_Accounts_Max_Fields>;
  min?: Maybe<Auth_Accounts_Min_Fields>;
};


/** aggregate fields of "auth.accounts" */
export type Auth_Accounts_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Auth_Accounts_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "auth.accounts" */
export type Auth_Accounts_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Auth_Accounts_Max_Order_By>;
  min?: Maybe<Auth_Accounts_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Auth_Accounts_Append_Input = {
  custom_register_data?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "auth.accounts" */
export type Auth_Accounts_Arr_Rel_Insert_Input = {
  data: Array<Auth_Accounts_Insert_Input>;
  on_conflict?: Maybe<Auth_Accounts_On_Conflict>;
};

/** Boolean expression to filter rows from the table "auth.accounts". All fields are combined with a logical 'AND'. */
export type Auth_Accounts_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Auth_Accounts_Bool_Exp>>>;
  _not?: Maybe<Auth_Accounts_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Auth_Accounts_Bool_Exp>>>;
  account_providers?: Maybe<Auth_Account_Providers_Bool_Exp>;
  account_roles?: Maybe<Auth_Account_Roles_Bool_Exp>;
  active?: Maybe<Boolean_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  custom_register_data?: Maybe<Jsonb_Comparison_Exp>;
  default_role?: Maybe<String_Comparison_Exp>;
  email?: Maybe<Citext_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  is_anonymous?: Maybe<Boolean_Comparison_Exp>;
  mfa_enabled?: Maybe<Boolean_Comparison_Exp>;
  new_email?: Maybe<Citext_Comparison_Exp>;
  otp_secret?: Maybe<String_Comparison_Exp>;
  password_hash?: Maybe<String_Comparison_Exp>;
  refresh_tokens?: Maybe<Auth_Refresh_Tokens_Bool_Exp>;
  role?: Maybe<Auth_Roles_Bool_Exp>;
  ticket?: Maybe<Uuid_Comparison_Exp>;
  ticket_expires_at?: Maybe<Timestamptz_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<User_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.accounts" */
export enum Auth_Accounts_Constraint {
  /** unique or primary key constraint */
  AccountsEmailKey = 'accounts_email_key',
  /** unique or primary key constraint */
  AccountsNewEmailKey = 'accounts_new_email_key',
  /** unique or primary key constraint */
  AccountsPkey = 'accounts_pkey',
  /** unique or primary key constraint */
  AccountsUserIdKey = 'accounts_user_id_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Auth_Accounts_Delete_At_Path_Input = {
  custom_register_data?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Auth_Accounts_Delete_Elem_Input = {
  custom_register_data?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Auth_Accounts_Delete_Key_Input = {
  custom_register_data?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "auth.accounts" */
export type Auth_Accounts_Insert_Input = {
  account_providers?: Maybe<Auth_Account_Providers_Arr_Rel_Insert_Input>;
  account_roles?: Maybe<Auth_Account_Roles_Arr_Rel_Insert_Input>;
  active?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  custom_register_data?: Maybe<Scalars['jsonb']>;
  default_role?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['citext']>;
  id?: Maybe<Scalars['uuid']>;
  is_anonymous?: Maybe<Scalars['Boolean']>;
  mfa_enabled?: Maybe<Scalars['Boolean']>;
  new_email?: Maybe<Scalars['citext']>;
  otp_secret?: Maybe<Scalars['String']>;
  password_hash?: Maybe<Scalars['String']>;
  refresh_tokens?: Maybe<Auth_Refresh_Tokens_Arr_Rel_Insert_Input>;
  role?: Maybe<Auth_Roles_Obj_Rel_Insert_Input>;
  ticket?: Maybe<Scalars['uuid']>;
  ticket_expires_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<User_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Auth_Accounts_Max_Fields = {
  __typename?: 'auth_accounts_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  default_role?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['citext']>;
  id?: Maybe<Scalars['uuid']>;
  new_email?: Maybe<Scalars['citext']>;
  otp_secret?: Maybe<Scalars['String']>;
  password_hash?: Maybe<Scalars['String']>;
  ticket?: Maybe<Scalars['uuid']>;
  ticket_expires_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "auth.accounts" */
export type Auth_Accounts_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  default_role?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  new_email?: Maybe<Order_By>;
  otp_secret?: Maybe<Order_By>;
  password_hash?: Maybe<Order_By>;
  ticket?: Maybe<Order_By>;
  ticket_expires_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Auth_Accounts_Min_Fields = {
  __typename?: 'auth_accounts_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  default_role?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['citext']>;
  id?: Maybe<Scalars['uuid']>;
  new_email?: Maybe<Scalars['citext']>;
  otp_secret?: Maybe<Scalars['String']>;
  password_hash?: Maybe<Scalars['String']>;
  ticket?: Maybe<Scalars['uuid']>;
  ticket_expires_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "auth.accounts" */
export type Auth_Accounts_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  default_role?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  new_email?: Maybe<Order_By>;
  otp_secret?: Maybe<Order_By>;
  password_hash?: Maybe<Order_By>;
  ticket?: Maybe<Order_By>;
  ticket_expires_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "auth.accounts" */
export type Auth_Accounts_Mutation_Response = {
  __typename?: 'auth_accounts_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Auth_Accounts>;
};

/** input type for inserting object relation for remote table "auth.accounts" */
export type Auth_Accounts_Obj_Rel_Insert_Input = {
  data: Auth_Accounts_Insert_Input;
  on_conflict?: Maybe<Auth_Accounts_On_Conflict>;
};

/** on conflict condition type for table "auth.accounts" */
export type Auth_Accounts_On_Conflict = {
  constraint: Auth_Accounts_Constraint;
  update_columns: Array<Auth_Accounts_Update_Column>;
  where?: Maybe<Auth_Accounts_Bool_Exp>;
};

/** ordering options when selecting data from "auth.accounts" */
export type Auth_Accounts_Order_By = {
  account_providers_aggregate?: Maybe<Auth_Account_Providers_Aggregate_Order_By>;
  account_roles_aggregate?: Maybe<Auth_Account_Roles_Aggregate_Order_By>;
  active?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  custom_register_data?: Maybe<Order_By>;
  default_role?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_anonymous?: Maybe<Order_By>;
  mfa_enabled?: Maybe<Order_By>;
  new_email?: Maybe<Order_By>;
  otp_secret?: Maybe<Order_By>;
  password_hash?: Maybe<Order_By>;
  refresh_tokens_aggregate?: Maybe<Auth_Refresh_Tokens_Aggregate_Order_By>;
  role?: Maybe<Auth_Roles_Order_By>;
  ticket?: Maybe<Order_By>;
  ticket_expires_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<User_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "auth.accounts" */
export type Auth_Accounts_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Auth_Accounts_Prepend_Input = {
  custom_register_data?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "auth.accounts" */
export enum Auth_Accounts_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomRegisterData = 'custom_register_data',
  /** column name */
  DefaultRole = 'default_role',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  IsAnonymous = 'is_anonymous',
  /** column name */
  MfaEnabled = 'mfa_enabled',
  /** column name */
  NewEmail = 'new_email',
  /** column name */
  OtpSecret = 'otp_secret',
  /** column name */
  PasswordHash = 'password_hash',
  /** column name */
  Ticket = 'ticket',
  /** column name */
  TicketExpiresAt = 'ticket_expires_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "auth.accounts" */
export type Auth_Accounts_Set_Input = {
  active?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  custom_register_data?: Maybe<Scalars['jsonb']>;
  default_role?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['citext']>;
  id?: Maybe<Scalars['uuid']>;
  is_anonymous?: Maybe<Scalars['Boolean']>;
  mfa_enabled?: Maybe<Scalars['Boolean']>;
  new_email?: Maybe<Scalars['citext']>;
  otp_secret?: Maybe<Scalars['String']>;
  password_hash?: Maybe<Scalars['String']>;
  ticket?: Maybe<Scalars['uuid']>;
  ticket_expires_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "auth.accounts" */
export enum Auth_Accounts_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomRegisterData = 'custom_register_data',
  /** column name */
  DefaultRole = 'default_role',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  IsAnonymous = 'is_anonymous',
  /** column name */
  MfaEnabled = 'mfa_enabled',
  /** column name */
  NewEmail = 'new_email',
  /** column name */
  OtpSecret = 'otp_secret',
  /** column name */
  PasswordHash = 'password_hash',
  /** column name */
  Ticket = 'ticket',
  /** column name */
  TicketExpiresAt = 'ticket_expires_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** columns and relationships of "auth.providers" */
export type Auth_Providers = {
  __typename?: 'auth_providers';
  /** An array relationship */
  account_providers: Array<Auth_Account_Providers>;
  /** An aggregated array relationship */
  account_providers_aggregate: Auth_Account_Providers_Aggregate;
  provider: Scalars['String'];
};


/** columns and relationships of "auth.providers" */
export type Auth_ProvidersAccount_ProvidersArgs = {
  distinct_on?: Maybe<Array<Auth_Account_Providers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Account_Providers_Order_By>>;
  where?: Maybe<Auth_Account_Providers_Bool_Exp>;
};


/** columns and relationships of "auth.providers" */
export type Auth_ProvidersAccount_Providers_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Account_Providers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Account_Providers_Order_By>>;
  where?: Maybe<Auth_Account_Providers_Bool_Exp>;
};

/** aggregated selection of "auth.providers" */
export type Auth_Providers_Aggregate = {
  __typename?: 'auth_providers_aggregate';
  aggregate?: Maybe<Auth_Providers_Aggregate_Fields>;
  nodes: Array<Auth_Providers>;
};

/** aggregate fields of "auth.providers" */
export type Auth_Providers_Aggregate_Fields = {
  __typename?: 'auth_providers_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Auth_Providers_Max_Fields>;
  min?: Maybe<Auth_Providers_Min_Fields>;
};


/** aggregate fields of "auth.providers" */
export type Auth_Providers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Auth_Providers_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "auth.providers" */
export type Auth_Providers_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Auth_Providers_Max_Order_By>;
  min?: Maybe<Auth_Providers_Min_Order_By>;
};

/** input type for inserting array relation for remote table "auth.providers" */
export type Auth_Providers_Arr_Rel_Insert_Input = {
  data: Array<Auth_Providers_Insert_Input>;
  on_conflict?: Maybe<Auth_Providers_On_Conflict>;
};

/** Boolean expression to filter rows from the table "auth.providers". All fields are combined with a logical 'AND'. */
export type Auth_Providers_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Auth_Providers_Bool_Exp>>>;
  _not?: Maybe<Auth_Providers_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Auth_Providers_Bool_Exp>>>;
  account_providers?: Maybe<Auth_Account_Providers_Bool_Exp>;
  provider?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.providers" */
export enum Auth_Providers_Constraint {
  /** unique or primary key constraint */
  ProvidersPkey = 'providers_pkey'
}

/** input type for inserting data into table "auth.providers" */
export type Auth_Providers_Insert_Input = {
  account_providers?: Maybe<Auth_Account_Providers_Arr_Rel_Insert_Input>;
  provider?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Auth_Providers_Max_Fields = {
  __typename?: 'auth_providers_max_fields';
  provider?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "auth.providers" */
export type Auth_Providers_Max_Order_By = {
  provider?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Auth_Providers_Min_Fields = {
  __typename?: 'auth_providers_min_fields';
  provider?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "auth.providers" */
export type Auth_Providers_Min_Order_By = {
  provider?: Maybe<Order_By>;
};

/** response of any mutation on the table "auth.providers" */
export type Auth_Providers_Mutation_Response = {
  __typename?: 'auth_providers_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Auth_Providers>;
};

/** input type for inserting object relation for remote table "auth.providers" */
export type Auth_Providers_Obj_Rel_Insert_Input = {
  data: Auth_Providers_Insert_Input;
  on_conflict?: Maybe<Auth_Providers_On_Conflict>;
};

/** on conflict condition type for table "auth.providers" */
export type Auth_Providers_On_Conflict = {
  constraint: Auth_Providers_Constraint;
  update_columns: Array<Auth_Providers_Update_Column>;
  where?: Maybe<Auth_Providers_Bool_Exp>;
};

/** ordering options when selecting data from "auth.providers" */
export type Auth_Providers_Order_By = {
  account_providers_aggregate?: Maybe<Auth_Account_Providers_Aggregate_Order_By>;
  provider?: Maybe<Order_By>;
};

/** primary key columns input for table: "auth.providers" */
export type Auth_Providers_Pk_Columns_Input = {
  provider: Scalars['String'];
};

/** select columns of table "auth.providers" */
export enum Auth_Providers_Select_Column {
  /** column name */
  Provider = 'provider'
}

/** input type for updating data in table "auth.providers" */
export type Auth_Providers_Set_Input = {
  provider?: Maybe<Scalars['String']>;
};

/** update columns of table "auth.providers" */
export enum Auth_Providers_Update_Column {
  /** column name */
  Provider = 'provider'
}

/** columns and relationships of "auth.refresh_tokens" */
export type Auth_Refresh_Tokens = {
  __typename?: 'auth_refresh_tokens';
  /** An object relationship */
  account: Auth_Accounts;
  account_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  expires_at: Scalars['timestamptz'];
  refresh_token: Scalars['uuid'];
};

/** aggregated selection of "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Aggregate = {
  __typename?: 'auth_refresh_tokens_aggregate';
  aggregate?: Maybe<Auth_Refresh_Tokens_Aggregate_Fields>;
  nodes: Array<Auth_Refresh_Tokens>;
};

/** aggregate fields of "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Aggregate_Fields = {
  __typename?: 'auth_refresh_tokens_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Auth_Refresh_Tokens_Max_Fields>;
  min?: Maybe<Auth_Refresh_Tokens_Min_Fields>;
};


/** aggregate fields of "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Auth_Refresh_Tokens_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Auth_Refresh_Tokens_Max_Order_By>;
  min?: Maybe<Auth_Refresh_Tokens_Min_Order_By>;
};

/** input type for inserting array relation for remote table "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Arr_Rel_Insert_Input = {
  data: Array<Auth_Refresh_Tokens_Insert_Input>;
  on_conflict?: Maybe<Auth_Refresh_Tokens_On_Conflict>;
};

/** Boolean expression to filter rows from the table "auth.refresh_tokens". All fields are combined with a logical 'AND'. */
export type Auth_Refresh_Tokens_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Auth_Refresh_Tokens_Bool_Exp>>>;
  _not?: Maybe<Auth_Refresh_Tokens_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Auth_Refresh_Tokens_Bool_Exp>>>;
  account?: Maybe<Auth_Accounts_Bool_Exp>;
  account_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  expires_at?: Maybe<Timestamptz_Comparison_Exp>;
  refresh_token?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.refresh_tokens" */
export enum Auth_Refresh_Tokens_Constraint {
  /** unique or primary key constraint */
  RefreshTokensPkey = 'refresh_tokens_pkey'
}

/** input type for inserting data into table "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Insert_Input = {
  account?: Maybe<Auth_Accounts_Obj_Rel_Insert_Input>;
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  refresh_token?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Auth_Refresh_Tokens_Max_Fields = {
  __typename?: 'auth_refresh_tokens_max_fields';
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  refresh_token?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Max_Order_By = {
  account_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  expires_at?: Maybe<Order_By>;
  refresh_token?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Auth_Refresh_Tokens_Min_Fields = {
  __typename?: 'auth_refresh_tokens_min_fields';
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  refresh_token?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Min_Order_By = {
  account_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  expires_at?: Maybe<Order_By>;
  refresh_token?: Maybe<Order_By>;
};

/** response of any mutation on the table "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Mutation_Response = {
  __typename?: 'auth_refresh_tokens_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Auth_Refresh_Tokens>;
};

/** input type for inserting object relation for remote table "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Obj_Rel_Insert_Input = {
  data: Auth_Refresh_Tokens_Insert_Input;
  on_conflict?: Maybe<Auth_Refresh_Tokens_On_Conflict>;
};

/** on conflict condition type for table "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_On_Conflict = {
  constraint: Auth_Refresh_Tokens_Constraint;
  update_columns: Array<Auth_Refresh_Tokens_Update_Column>;
  where?: Maybe<Auth_Refresh_Tokens_Bool_Exp>;
};

/** ordering options when selecting data from "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Order_By = {
  account?: Maybe<Auth_Accounts_Order_By>;
  account_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  expires_at?: Maybe<Order_By>;
  refresh_token?: Maybe<Order_By>;
};

/** primary key columns input for table: "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Pk_Columns_Input = {
  refresh_token: Scalars['uuid'];
};

/** select columns of table "auth.refresh_tokens" */
export enum Auth_Refresh_Tokens_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  RefreshToken = 'refresh_token'
}

/** input type for updating data in table "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Set_Input = {
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  refresh_token?: Maybe<Scalars['uuid']>;
};

/** update columns of table "auth.refresh_tokens" */
export enum Auth_Refresh_Tokens_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  RefreshToken = 'refresh_token'
}

/** columns and relationships of "auth.roles" */
export type Auth_Roles = {
  __typename?: 'auth_roles';
  /** An array relationship */
  account_roles: Array<Auth_Account_Roles>;
  /** An aggregated array relationship */
  account_roles_aggregate: Auth_Account_Roles_Aggregate;
  /** An array relationship */
  accounts: Array<Auth_Accounts>;
  /** An aggregated array relationship */
  accounts_aggregate: Auth_Accounts_Aggregate;
  role: Scalars['String'];
};


/** columns and relationships of "auth.roles" */
export type Auth_RolesAccount_RolesArgs = {
  distinct_on?: Maybe<Array<Auth_Account_Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Account_Roles_Order_By>>;
  where?: Maybe<Auth_Account_Roles_Bool_Exp>;
};


/** columns and relationships of "auth.roles" */
export type Auth_RolesAccount_Roles_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Account_Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Account_Roles_Order_By>>;
  where?: Maybe<Auth_Account_Roles_Bool_Exp>;
};


/** columns and relationships of "auth.roles" */
export type Auth_RolesAccountsArgs = {
  distinct_on?: Maybe<Array<Auth_Accounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Accounts_Order_By>>;
  where?: Maybe<Auth_Accounts_Bool_Exp>;
};


/** columns and relationships of "auth.roles" */
export type Auth_RolesAccounts_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Accounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Accounts_Order_By>>;
  where?: Maybe<Auth_Accounts_Bool_Exp>;
};

/** aggregated selection of "auth.roles" */
export type Auth_Roles_Aggregate = {
  __typename?: 'auth_roles_aggregate';
  aggregate?: Maybe<Auth_Roles_Aggregate_Fields>;
  nodes: Array<Auth_Roles>;
};

/** aggregate fields of "auth.roles" */
export type Auth_Roles_Aggregate_Fields = {
  __typename?: 'auth_roles_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Auth_Roles_Max_Fields>;
  min?: Maybe<Auth_Roles_Min_Fields>;
};


/** aggregate fields of "auth.roles" */
export type Auth_Roles_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Auth_Roles_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "auth.roles" */
export type Auth_Roles_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Auth_Roles_Max_Order_By>;
  min?: Maybe<Auth_Roles_Min_Order_By>;
};

/** input type for inserting array relation for remote table "auth.roles" */
export type Auth_Roles_Arr_Rel_Insert_Input = {
  data: Array<Auth_Roles_Insert_Input>;
  on_conflict?: Maybe<Auth_Roles_On_Conflict>;
};

/** Boolean expression to filter rows from the table "auth.roles". All fields are combined with a logical 'AND'. */
export type Auth_Roles_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Auth_Roles_Bool_Exp>>>;
  _not?: Maybe<Auth_Roles_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Auth_Roles_Bool_Exp>>>;
  account_roles?: Maybe<Auth_Account_Roles_Bool_Exp>;
  accounts?: Maybe<Auth_Accounts_Bool_Exp>;
  role?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.roles" */
export enum Auth_Roles_Constraint {
  /** unique or primary key constraint */
  RolesPkey = 'roles_pkey'
}

/** input type for inserting data into table "auth.roles" */
export type Auth_Roles_Insert_Input = {
  account_roles?: Maybe<Auth_Account_Roles_Arr_Rel_Insert_Input>;
  accounts?: Maybe<Auth_Accounts_Arr_Rel_Insert_Input>;
  role?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Auth_Roles_Max_Fields = {
  __typename?: 'auth_roles_max_fields';
  role?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "auth.roles" */
export type Auth_Roles_Max_Order_By = {
  role?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Auth_Roles_Min_Fields = {
  __typename?: 'auth_roles_min_fields';
  role?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "auth.roles" */
export type Auth_Roles_Min_Order_By = {
  role?: Maybe<Order_By>;
};

/** response of any mutation on the table "auth.roles" */
export type Auth_Roles_Mutation_Response = {
  __typename?: 'auth_roles_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Auth_Roles>;
};

/** input type for inserting object relation for remote table "auth.roles" */
export type Auth_Roles_Obj_Rel_Insert_Input = {
  data: Auth_Roles_Insert_Input;
  on_conflict?: Maybe<Auth_Roles_On_Conflict>;
};

/** on conflict condition type for table "auth.roles" */
export type Auth_Roles_On_Conflict = {
  constraint: Auth_Roles_Constraint;
  update_columns: Array<Auth_Roles_Update_Column>;
  where?: Maybe<Auth_Roles_Bool_Exp>;
};

/** ordering options when selecting data from "auth.roles" */
export type Auth_Roles_Order_By = {
  account_roles_aggregate?: Maybe<Auth_Account_Roles_Aggregate_Order_By>;
  accounts_aggregate?: Maybe<Auth_Accounts_Aggregate_Order_By>;
  role?: Maybe<Order_By>;
};

/** primary key columns input for table: "auth.roles" */
export type Auth_Roles_Pk_Columns_Input = {
  role: Scalars['String'];
};

/** select columns of table "auth.roles" */
export enum Auth_Roles_Select_Column {
  /** column name */
  Role = 'role'
}

/** input type for updating data in table "auth.roles" */
export type Auth_Roles_Set_Input = {
  role?: Maybe<Scalars['String']>;
};

/** update columns of table "auth.roles" */
export enum Auth_Roles_Update_Column {
  /** column name */
  Role = 'role'
}


/** expression to compare columns of type citext. All fields are combined with logical 'AND'. */
export type Citext_Comparison_Exp = {
  _eq?: Maybe<Scalars['citext']>;
  _gt?: Maybe<Scalars['citext']>;
  _gte?: Maybe<Scalars['citext']>;
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['citext']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['citext']>;
  _lte?: Maybe<Scalars['citext']>;
  _neq?: Maybe<Scalars['citext']>;
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['citext']>>;
  _nlike?: Maybe<Scalars['String']>;
  _nsimilar?: Maybe<Scalars['String']>;
  _similar?: Maybe<Scalars['String']>;
};


/** expression to compare columns of type date. All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: Maybe<Scalars['date']>;
  _gt?: Maybe<Scalars['date']>;
  _gte?: Maybe<Scalars['date']>;
  _in?: Maybe<Array<Scalars['date']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['date']>;
  _lte?: Maybe<Scalars['date']>;
  _neq?: Maybe<Scalars['date']>;
  _nin?: Maybe<Array<Scalars['date']>>;
};

/** columns and relationships of "employment_status" */
export type Employment_Status = {
  __typename?: 'employment_status';
  value: Scalars['String'];
};

/** aggregated selection of "employment_status" */
export type Employment_Status_Aggregate = {
  __typename?: 'employment_status_aggregate';
  aggregate?: Maybe<Employment_Status_Aggregate_Fields>;
  nodes: Array<Employment_Status>;
};

/** aggregate fields of "employment_status" */
export type Employment_Status_Aggregate_Fields = {
  __typename?: 'employment_status_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Employment_Status_Max_Fields>;
  min?: Maybe<Employment_Status_Min_Fields>;
};


/** aggregate fields of "employment_status" */
export type Employment_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Employment_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "employment_status" */
export type Employment_Status_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Employment_Status_Max_Order_By>;
  min?: Maybe<Employment_Status_Min_Order_By>;
};

/** input type for inserting array relation for remote table "employment_status" */
export type Employment_Status_Arr_Rel_Insert_Input = {
  data: Array<Employment_Status_Insert_Input>;
  on_conflict?: Maybe<Employment_Status_On_Conflict>;
};

/** Boolean expression to filter rows from the table "employment_status". All fields are combined with a logical 'AND'. */
export type Employment_Status_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Employment_Status_Bool_Exp>>>;
  _not?: Maybe<Employment_Status_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Employment_Status_Bool_Exp>>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "employment_status" */
export enum Employment_Status_Constraint {
  /** unique or primary key constraint */
  EmploymentStatusPkey = 'employment_status_pkey'
}

/** input type for inserting data into table "employment_status" */
export type Employment_Status_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Employment_Status_Max_Fields = {
  __typename?: 'employment_status_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "employment_status" */
export type Employment_Status_Max_Order_By = {
  value?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Employment_Status_Min_Fields = {
  __typename?: 'employment_status_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "employment_status" */
export type Employment_Status_Min_Order_By = {
  value?: Maybe<Order_By>;
};

/** response of any mutation on the table "employment_status" */
export type Employment_Status_Mutation_Response = {
  __typename?: 'employment_status_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Employment_Status>;
};

/** input type for inserting object relation for remote table "employment_status" */
export type Employment_Status_Obj_Rel_Insert_Input = {
  data: Employment_Status_Insert_Input;
  on_conflict?: Maybe<Employment_Status_On_Conflict>;
};

/** on conflict condition type for table "employment_status" */
export type Employment_Status_On_Conflict = {
  constraint: Employment_Status_Constraint;
  update_columns: Array<Employment_Status_Update_Column>;
  where?: Maybe<Employment_Status_Bool_Exp>;
};

/** ordering options when selecting data from "employment_status" */
export type Employment_Status_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: "employment_status" */
export type Employment_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "employment_status" */
export enum Employment_Status_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "employment_status" */
export type Employment_Status_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "employment_status" */
export enum Employment_Status_Update_Column {
  /** column name */
  Value = 'value'
}

/** columns and relationships of "feedback" */
export type Feedback = {
  __typename?: 'feedback';
  created_at: Scalars['timestamptz'];
  description: Scalars['String'];
  id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: User;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "feedback" */
export type Feedback_Aggregate = {
  __typename?: 'feedback_aggregate';
  aggregate?: Maybe<Feedback_Aggregate_Fields>;
  nodes: Array<Feedback>;
};

/** aggregate fields of "feedback" */
export type Feedback_Aggregate_Fields = {
  __typename?: 'feedback_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Feedback_Max_Fields>;
  min?: Maybe<Feedback_Min_Fields>;
};


/** aggregate fields of "feedback" */
export type Feedback_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Feedback_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "feedback" */
export type Feedback_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Feedback_Max_Order_By>;
  min?: Maybe<Feedback_Min_Order_By>;
};

/** input type for inserting array relation for remote table "feedback" */
export type Feedback_Arr_Rel_Insert_Input = {
  data: Array<Feedback_Insert_Input>;
  on_conflict?: Maybe<Feedback_On_Conflict>;
};

/** Boolean expression to filter rows from the table "feedback". All fields are combined with a logical 'AND'. */
export type Feedback_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Feedback_Bool_Exp>>>;
  _not?: Maybe<Feedback_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Feedback_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<User_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "feedback" */
export enum Feedback_Constraint {
  /** unique or primary key constraint */
  FeedbackPkey = 'feedback_pkey'
}

/** input type for inserting data into table "feedback" */
export type Feedback_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<User_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Feedback_Max_Fields = {
  __typename?: 'feedback_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "feedback" */
export type Feedback_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Feedback_Min_Fields = {
  __typename?: 'feedback_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "feedback" */
export type Feedback_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "feedback" */
export type Feedback_Mutation_Response = {
  __typename?: 'feedback_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Feedback>;
};

/** input type for inserting object relation for remote table "feedback" */
export type Feedback_Obj_Rel_Insert_Input = {
  data: Feedback_Insert_Input;
  on_conflict?: Maybe<Feedback_On_Conflict>;
};

/** on conflict condition type for table "feedback" */
export type Feedback_On_Conflict = {
  constraint: Feedback_Constraint;
  update_columns: Array<Feedback_Update_Column>;
  where?: Maybe<Feedback_Bool_Exp>;
};

/** ordering options when selecting data from "feedback" */
export type Feedback_Order_By = {
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<User_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "feedback" */
export type Feedback_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "feedback" */
export enum Feedback_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "feedback" */
export type Feedback_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "feedback" */
export enum Feedback_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** columns and relationships of "flag" */
export type Flag = {
  __typename?: 'flag';
  component_name: Scalars['String'];
  display_name: Scalars['String'];
  file_name: Scalars['String'];
  /** An array relationship */
  flags: Array<User_Flag>;
  /** An aggregated array relationship */
  flags_aggregate: User_Flag_Aggregate;
  id: Scalars['uuid'];
};


/** columns and relationships of "flag" */
export type FlagFlagsArgs = {
  distinct_on?: Maybe<Array<User_Flag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Flag_Order_By>>;
  where?: Maybe<User_Flag_Bool_Exp>;
};


/** columns and relationships of "flag" */
export type FlagFlags_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Flag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Flag_Order_By>>;
  where?: Maybe<User_Flag_Bool_Exp>;
};

/** aggregated selection of "flag" */
export type Flag_Aggregate = {
  __typename?: 'flag_aggregate';
  aggregate?: Maybe<Flag_Aggregate_Fields>;
  nodes: Array<Flag>;
};

/** aggregate fields of "flag" */
export type Flag_Aggregate_Fields = {
  __typename?: 'flag_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Flag_Max_Fields>;
  min?: Maybe<Flag_Min_Fields>;
};


/** aggregate fields of "flag" */
export type Flag_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Flag_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "flag" */
export type Flag_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Flag_Max_Order_By>;
  min?: Maybe<Flag_Min_Order_By>;
};

/** input type for inserting array relation for remote table "flag" */
export type Flag_Arr_Rel_Insert_Input = {
  data: Array<Flag_Insert_Input>;
  on_conflict?: Maybe<Flag_On_Conflict>;
};

/** Boolean expression to filter rows from the table "flag". All fields are combined with a logical 'AND'. */
export type Flag_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Flag_Bool_Exp>>>;
  _not?: Maybe<Flag_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Flag_Bool_Exp>>>;
  component_name?: Maybe<String_Comparison_Exp>;
  display_name?: Maybe<String_Comparison_Exp>;
  file_name?: Maybe<String_Comparison_Exp>;
  flags?: Maybe<User_Flag_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "flag" */
export enum Flag_Constraint {
  /** unique or primary key constraint */
  FlagPkey = 'flag_pkey'
}

/** input type for inserting data into table "flag" */
export type Flag_Insert_Input = {
  component_name?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  file_name?: Maybe<Scalars['String']>;
  flags?: Maybe<User_Flag_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Flag_Max_Fields = {
  __typename?: 'flag_max_fields';
  component_name?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  file_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "flag" */
export type Flag_Max_Order_By = {
  component_name?: Maybe<Order_By>;
  display_name?: Maybe<Order_By>;
  file_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Flag_Min_Fields = {
  __typename?: 'flag_min_fields';
  component_name?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  file_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "flag" */
export type Flag_Min_Order_By = {
  component_name?: Maybe<Order_By>;
  display_name?: Maybe<Order_By>;
  file_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** response of any mutation on the table "flag" */
export type Flag_Mutation_Response = {
  __typename?: 'flag_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Flag>;
};

/** input type for inserting object relation for remote table "flag" */
export type Flag_Obj_Rel_Insert_Input = {
  data: Flag_Insert_Input;
  on_conflict?: Maybe<Flag_On_Conflict>;
};

/** on conflict condition type for table "flag" */
export type Flag_On_Conflict = {
  constraint: Flag_Constraint;
  update_columns: Array<Flag_Update_Column>;
  where?: Maybe<Flag_Bool_Exp>;
};

/** ordering options when selecting data from "flag" */
export type Flag_Order_By = {
  component_name?: Maybe<Order_By>;
  display_name?: Maybe<Order_By>;
  file_name?: Maybe<Order_By>;
  flags_aggregate?: Maybe<User_Flag_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
};

/** primary key columns input for table: "flag" */
export type Flag_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "flag" */
export enum Flag_Select_Column {
  /** column name */
  ComponentName = 'component_name',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  FileName = 'file_name',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "flag" */
export type Flag_Set_Input = {
  component_name?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  file_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "flag" */
export enum Flag_Update_Column {
  /** column name */
  ComponentName = 'component_name',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  FileName = 'file_name',
  /** column name */
  Id = 'id'
}

/** columns and relationships of "gender" */
export type Gender = {
  __typename?: 'gender';
  value: Scalars['String'];
};

/** aggregated selection of "gender" */
export type Gender_Aggregate = {
  __typename?: 'gender_aggregate';
  aggregate?: Maybe<Gender_Aggregate_Fields>;
  nodes: Array<Gender>;
};

/** aggregate fields of "gender" */
export type Gender_Aggregate_Fields = {
  __typename?: 'gender_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Gender_Max_Fields>;
  min?: Maybe<Gender_Min_Fields>;
};


/** aggregate fields of "gender" */
export type Gender_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Gender_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "gender" */
export type Gender_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Gender_Max_Order_By>;
  min?: Maybe<Gender_Min_Order_By>;
};

/** input type for inserting array relation for remote table "gender" */
export type Gender_Arr_Rel_Insert_Input = {
  data: Array<Gender_Insert_Input>;
  on_conflict?: Maybe<Gender_On_Conflict>;
};

/** Boolean expression to filter rows from the table "gender". All fields are combined with a logical 'AND'. */
export type Gender_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Gender_Bool_Exp>>>;
  _not?: Maybe<Gender_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Gender_Bool_Exp>>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "gender" */
export enum Gender_Constraint {
  /** unique or primary key constraint */
  GenderPkey = 'gender_pkey'
}

/** input type for inserting data into table "gender" */
export type Gender_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Gender_Max_Fields = {
  __typename?: 'gender_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "gender" */
export type Gender_Max_Order_By = {
  value?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Gender_Min_Fields = {
  __typename?: 'gender_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "gender" */
export type Gender_Min_Order_By = {
  value?: Maybe<Order_By>;
};

/** response of any mutation on the table "gender" */
export type Gender_Mutation_Response = {
  __typename?: 'gender_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Gender>;
};

/** input type for inserting object relation for remote table "gender" */
export type Gender_Obj_Rel_Insert_Input = {
  data: Gender_Insert_Input;
  on_conflict?: Maybe<Gender_On_Conflict>;
};

/** on conflict condition type for table "gender" */
export type Gender_On_Conflict = {
  constraint: Gender_Constraint;
  update_columns: Array<Gender_Update_Column>;
  where?: Maybe<Gender_Bool_Exp>;
};

/** ordering options when selecting data from "gender" */
export type Gender_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: "gender" */
export type Gender_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "gender" */
export enum Gender_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "gender" */
export type Gender_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "gender" */
export enum Gender_Update_Column {
  /** column name */
  Value = 'value'
}

/** columns and relationships of "info_tile" */
export type Info_Tile = {
  __typename?: 'info_tile';
  accessibility_text: Scalars['String'];
  action_url?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  image_url: Scalars['String'];
  /** An object relationship */
  info_tile_status_type: Info_Tile_Status_Type;
  order?: Maybe<Scalars['Int']>;
  status: Info_Tile_Status_Type_Enum;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "info_tile" */
export type Info_Tile_Aggregate = {
  __typename?: 'info_tile_aggregate';
  aggregate?: Maybe<Info_Tile_Aggregate_Fields>;
  nodes: Array<Info_Tile>;
};

/** aggregate fields of "info_tile" */
export type Info_Tile_Aggregate_Fields = {
  __typename?: 'info_tile_aggregate_fields';
  avg?: Maybe<Info_Tile_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Info_Tile_Max_Fields>;
  min?: Maybe<Info_Tile_Min_Fields>;
  stddev?: Maybe<Info_Tile_Stddev_Fields>;
  stddev_pop?: Maybe<Info_Tile_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Info_Tile_Stddev_Samp_Fields>;
  sum?: Maybe<Info_Tile_Sum_Fields>;
  var_pop?: Maybe<Info_Tile_Var_Pop_Fields>;
  var_samp?: Maybe<Info_Tile_Var_Samp_Fields>;
  variance?: Maybe<Info_Tile_Variance_Fields>;
};


/** aggregate fields of "info_tile" */
export type Info_Tile_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Info_Tile_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "info_tile" */
export type Info_Tile_Aggregate_Order_By = {
  avg?: Maybe<Info_Tile_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Info_Tile_Max_Order_By>;
  min?: Maybe<Info_Tile_Min_Order_By>;
  stddev?: Maybe<Info_Tile_Stddev_Order_By>;
  stddev_pop?: Maybe<Info_Tile_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Info_Tile_Stddev_Samp_Order_By>;
  sum?: Maybe<Info_Tile_Sum_Order_By>;
  var_pop?: Maybe<Info_Tile_Var_Pop_Order_By>;
  var_samp?: Maybe<Info_Tile_Var_Samp_Order_By>;
  variance?: Maybe<Info_Tile_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "info_tile" */
export type Info_Tile_Arr_Rel_Insert_Input = {
  data: Array<Info_Tile_Insert_Input>;
  on_conflict?: Maybe<Info_Tile_On_Conflict>;
};

/** aggregate avg on columns */
export type Info_Tile_Avg_Fields = {
  __typename?: 'info_tile_avg_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "info_tile" */
export type Info_Tile_Avg_Order_By = {
  order?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "info_tile". All fields are combined with a logical 'AND'. */
export type Info_Tile_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Info_Tile_Bool_Exp>>>;
  _not?: Maybe<Info_Tile_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Info_Tile_Bool_Exp>>>;
  accessibility_text?: Maybe<String_Comparison_Exp>;
  action_url?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  image_url?: Maybe<String_Comparison_Exp>;
  info_tile_status_type?: Maybe<Info_Tile_Status_Type_Bool_Exp>;
  order?: Maybe<Int_Comparison_Exp>;
  status?: Maybe<Info_Tile_Status_Type_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "info_tile" */
export enum Info_Tile_Constraint {
  /** unique or primary key constraint */
  InfoTilePkey = 'info_tile_pkey'
}

/** input type for incrementing integer column in table "info_tile" */
export type Info_Tile_Inc_Input = {
  order?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "info_tile" */
export type Info_Tile_Insert_Input = {
  accessibility_text?: Maybe<Scalars['String']>;
  action_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  image_url?: Maybe<Scalars['String']>;
  info_tile_status_type?: Maybe<Info_Tile_Status_Type_Obj_Rel_Insert_Input>;
  order?: Maybe<Scalars['Int']>;
  status?: Maybe<Info_Tile_Status_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Info_Tile_Max_Fields = {
  __typename?: 'info_tile_max_fields';
  accessibility_text?: Maybe<Scalars['String']>;
  action_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  image_url?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "info_tile" */
export type Info_Tile_Max_Order_By = {
  accessibility_text?: Maybe<Order_By>;
  action_url?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  image_url?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Info_Tile_Min_Fields = {
  __typename?: 'info_tile_min_fields';
  accessibility_text?: Maybe<Scalars['String']>;
  action_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  image_url?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "info_tile" */
export type Info_Tile_Min_Order_By = {
  accessibility_text?: Maybe<Order_By>;
  action_url?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  image_url?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "info_tile" */
export type Info_Tile_Mutation_Response = {
  __typename?: 'info_tile_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Info_Tile>;
};

/** input type for inserting object relation for remote table "info_tile" */
export type Info_Tile_Obj_Rel_Insert_Input = {
  data: Info_Tile_Insert_Input;
  on_conflict?: Maybe<Info_Tile_On_Conflict>;
};

/** on conflict condition type for table "info_tile" */
export type Info_Tile_On_Conflict = {
  constraint: Info_Tile_Constraint;
  update_columns: Array<Info_Tile_Update_Column>;
  where?: Maybe<Info_Tile_Bool_Exp>;
};

/** ordering options when selecting data from "info_tile" */
export type Info_Tile_Order_By = {
  accessibility_text?: Maybe<Order_By>;
  action_url?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  image_url?: Maybe<Order_By>;
  info_tile_status_type?: Maybe<Info_Tile_Status_Type_Order_By>;
  order?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "info_tile" */
export type Info_Tile_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "info_tile" */
export enum Info_Tile_Select_Column {
  /** column name */
  AccessibilityText = 'accessibility_text',
  /** column name */
  ActionUrl = 'action_url',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'image_url',
  /** column name */
  Order = 'order',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "info_tile" */
export type Info_Tile_Set_Input = {
  accessibility_text?: Maybe<Scalars['String']>;
  action_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  image_url?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  status?: Maybe<Info_Tile_Status_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "info_tile_status_type" */
export type Info_Tile_Status_Type = {
  __typename?: 'info_tile_status_type';
  /** An array relationship */
  info_tiles: Array<Info_Tile>;
  /** An aggregated array relationship */
  info_tiles_aggregate: Info_Tile_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "info_tile_status_type" */
export type Info_Tile_Status_TypeInfo_TilesArgs = {
  distinct_on?: Maybe<Array<Info_Tile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Info_Tile_Order_By>>;
  where?: Maybe<Info_Tile_Bool_Exp>;
};


/** columns and relationships of "info_tile_status_type" */
export type Info_Tile_Status_TypeInfo_Tiles_AggregateArgs = {
  distinct_on?: Maybe<Array<Info_Tile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Info_Tile_Order_By>>;
  where?: Maybe<Info_Tile_Bool_Exp>;
};

/** aggregated selection of "info_tile_status_type" */
export type Info_Tile_Status_Type_Aggregate = {
  __typename?: 'info_tile_status_type_aggregate';
  aggregate?: Maybe<Info_Tile_Status_Type_Aggregate_Fields>;
  nodes: Array<Info_Tile_Status_Type>;
};

/** aggregate fields of "info_tile_status_type" */
export type Info_Tile_Status_Type_Aggregate_Fields = {
  __typename?: 'info_tile_status_type_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Info_Tile_Status_Type_Max_Fields>;
  min?: Maybe<Info_Tile_Status_Type_Min_Fields>;
};


/** aggregate fields of "info_tile_status_type" */
export type Info_Tile_Status_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Info_Tile_Status_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "info_tile_status_type" */
export type Info_Tile_Status_Type_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Info_Tile_Status_Type_Max_Order_By>;
  min?: Maybe<Info_Tile_Status_Type_Min_Order_By>;
};

/** input type for inserting array relation for remote table "info_tile_status_type" */
export type Info_Tile_Status_Type_Arr_Rel_Insert_Input = {
  data: Array<Info_Tile_Status_Type_Insert_Input>;
  on_conflict?: Maybe<Info_Tile_Status_Type_On_Conflict>;
};

/** Boolean expression to filter rows from the table "info_tile_status_type". All fields are combined with a logical 'AND'. */
export type Info_Tile_Status_Type_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Info_Tile_Status_Type_Bool_Exp>>>;
  _not?: Maybe<Info_Tile_Status_Type_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Info_Tile_Status_Type_Bool_Exp>>>;
  info_tiles?: Maybe<Info_Tile_Bool_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "info_tile_status_type" */
export enum Info_Tile_Status_Type_Constraint {
  /** unique or primary key constraint */
  InfoTileStatusTypePkey = 'info_tile_status_type_pkey'
}

export enum Info_Tile_Status_Type_Enum {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED'
}

/** expression to compare columns of type info_tile_status_type_enum. All fields are combined with logical 'AND'. */
export type Info_Tile_Status_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Info_Tile_Status_Type_Enum>;
  _in?: Maybe<Array<Info_Tile_Status_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Info_Tile_Status_Type_Enum>;
  _nin?: Maybe<Array<Info_Tile_Status_Type_Enum>>;
};

/** input type for inserting data into table "info_tile_status_type" */
export type Info_Tile_Status_Type_Insert_Input = {
  info_tiles?: Maybe<Info_Tile_Arr_Rel_Insert_Input>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Info_Tile_Status_Type_Max_Fields = {
  __typename?: 'info_tile_status_type_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "info_tile_status_type" */
export type Info_Tile_Status_Type_Max_Order_By = {
  value?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Info_Tile_Status_Type_Min_Fields = {
  __typename?: 'info_tile_status_type_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "info_tile_status_type" */
export type Info_Tile_Status_Type_Min_Order_By = {
  value?: Maybe<Order_By>;
};

/** response of any mutation on the table "info_tile_status_type" */
export type Info_Tile_Status_Type_Mutation_Response = {
  __typename?: 'info_tile_status_type_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Info_Tile_Status_Type>;
};

/** input type for inserting object relation for remote table "info_tile_status_type" */
export type Info_Tile_Status_Type_Obj_Rel_Insert_Input = {
  data: Info_Tile_Status_Type_Insert_Input;
  on_conflict?: Maybe<Info_Tile_Status_Type_On_Conflict>;
};

/** on conflict condition type for table "info_tile_status_type" */
export type Info_Tile_Status_Type_On_Conflict = {
  constraint: Info_Tile_Status_Type_Constraint;
  update_columns: Array<Info_Tile_Status_Type_Update_Column>;
  where?: Maybe<Info_Tile_Status_Type_Bool_Exp>;
};

/** ordering options when selecting data from "info_tile_status_type" */
export type Info_Tile_Status_Type_Order_By = {
  info_tiles_aggregate?: Maybe<Info_Tile_Aggregate_Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: "info_tile_status_type" */
export type Info_Tile_Status_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "info_tile_status_type" */
export enum Info_Tile_Status_Type_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "info_tile_status_type" */
export type Info_Tile_Status_Type_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "info_tile_status_type" */
export enum Info_Tile_Status_Type_Update_Column {
  /** column name */
  Value = 'value'
}

/** aggregate stddev on columns */
export type Info_Tile_Stddev_Fields = {
  __typename?: 'info_tile_stddev_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "info_tile" */
export type Info_Tile_Stddev_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Info_Tile_Stddev_Pop_Fields = {
  __typename?: 'info_tile_stddev_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "info_tile" */
export type Info_Tile_Stddev_Pop_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Info_Tile_Stddev_Samp_Fields = {
  __typename?: 'info_tile_stddev_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "info_tile" */
export type Info_Tile_Stddev_Samp_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Info_Tile_Sum_Fields = {
  __typename?: 'info_tile_sum_fields';
  order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "info_tile" */
export type Info_Tile_Sum_Order_By = {
  order?: Maybe<Order_By>;
};

/** update columns of table "info_tile" */
export enum Info_Tile_Update_Column {
  /** column name */
  AccessibilityText = 'accessibility_text',
  /** column name */
  ActionUrl = 'action_url',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'image_url',
  /** column name */
  Order = 'order',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Info_Tile_Var_Pop_Fields = {
  __typename?: 'info_tile_var_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "info_tile" */
export type Info_Tile_Var_Pop_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Info_Tile_Var_Samp_Fields = {
  __typename?: 'info_tile_var_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "info_tile" */
export type Info_Tile_Var_Samp_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Info_Tile_Variance_Fields = {
  __typename?: 'info_tile_variance_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "info_tile" */
export type Info_Tile_Variance_Order_By = {
  order?: Maybe<Order_By>;
};


/** expression to compare columns of type json. All fields are combined with logical 'AND'. */
export type Json_Comparison_Exp = {
  _eq?: Maybe<Scalars['json']>;
  _gt?: Maybe<Scalars['json']>;
  _gte?: Maybe<Scalars['json']>;
  _in?: Maybe<Array<Scalars['json']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['json']>;
  _lte?: Maybe<Scalars['json']>;
  _neq?: Maybe<Scalars['json']>;
  _nin?: Maybe<Array<Scalars['json']>>;
};


/** expression to compare columns of type jsonb. All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  /** is the column contained in the given json value */
  _contained_in?: Maybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: Maybe<Scalars['jsonb']>;
  _eq?: Maybe<Scalars['jsonb']>;
  _gt?: Maybe<Scalars['jsonb']>;
  _gte?: Maybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: Maybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: Maybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: Maybe<Array<Scalars['String']>>;
  _in?: Maybe<Array<Scalars['jsonb']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['jsonb']>;
  _lte?: Maybe<Scalars['jsonb']>;
  _neq?: Maybe<Scalars['jsonb']>;
  _nin?: Maybe<Array<Scalars['jsonb']>>;
};

/** columns and relationships of "keyword" */
export type Keyword = {
  __typename?: 'keyword';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An array relationship */
  keyword_referrals: Array<Keyword_Referral>;
  /** An aggregated array relationship */
  keyword_referrals_aggregate: Keyword_Referral_Aggregate;
  /** An array relationship */
  keyword_topics: Array<Keyword_Topic>;
  /** An aggregated array relationship */
  keyword_topics_aggregate: Keyword_Topic_Aggregate;
  /** An object relationship */
  sentiment: Sentiment;
  sentiment_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  value: Scalars['String'];
};


/** columns and relationships of "keyword" */
export type KeywordKeyword_ReferralsArgs = {
  distinct_on?: Maybe<Array<Keyword_Referral_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Keyword_Referral_Order_By>>;
  where?: Maybe<Keyword_Referral_Bool_Exp>;
};


/** columns and relationships of "keyword" */
export type KeywordKeyword_Referrals_AggregateArgs = {
  distinct_on?: Maybe<Array<Keyword_Referral_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Keyword_Referral_Order_By>>;
  where?: Maybe<Keyword_Referral_Bool_Exp>;
};


/** columns and relationships of "keyword" */
export type KeywordKeyword_TopicsArgs = {
  distinct_on?: Maybe<Array<Keyword_Topic_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Keyword_Topic_Order_By>>;
  where?: Maybe<Keyword_Topic_Bool_Exp>;
};


/** columns and relationships of "keyword" */
export type KeywordKeyword_Topics_AggregateArgs = {
  distinct_on?: Maybe<Array<Keyword_Topic_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Keyword_Topic_Order_By>>;
  where?: Maybe<Keyword_Topic_Bool_Exp>;
};

/** aggregated selection of "keyword" */
export type Keyword_Aggregate = {
  __typename?: 'keyword_aggregate';
  aggregate?: Maybe<Keyword_Aggregate_Fields>;
  nodes: Array<Keyword>;
};

/** aggregate fields of "keyword" */
export type Keyword_Aggregate_Fields = {
  __typename?: 'keyword_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Keyword_Max_Fields>;
  min?: Maybe<Keyword_Min_Fields>;
};


/** aggregate fields of "keyword" */
export type Keyword_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Keyword_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "keyword" */
export type Keyword_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Keyword_Max_Order_By>;
  min?: Maybe<Keyword_Min_Order_By>;
};

/** input type for inserting array relation for remote table "keyword" */
export type Keyword_Arr_Rel_Insert_Input = {
  data: Array<Keyword_Insert_Input>;
  on_conflict?: Maybe<Keyword_On_Conflict>;
};

/** Boolean expression to filter rows from the table "keyword". All fields are combined with a logical 'AND'. */
export type Keyword_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Keyword_Bool_Exp>>>;
  _not?: Maybe<Keyword_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Keyword_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  keyword_referrals?: Maybe<Keyword_Referral_Bool_Exp>;
  keyword_topics?: Maybe<Keyword_Topic_Bool_Exp>;
  sentiment?: Maybe<Sentiment_Bool_Exp>;
  sentiment_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "keyword" */
export enum Keyword_Constraint {
  /** unique or primary key constraint */
  KeywordPkey = 'keyword_pkey'
}

/** input type for inserting data into table "keyword" */
export type Keyword_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  keyword_referrals?: Maybe<Keyword_Referral_Arr_Rel_Insert_Input>;
  keyword_topics?: Maybe<Keyword_Topic_Arr_Rel_Insert_Input>;
  sentiment?: Maybe<Sentiment_Obj_Rel_Insert_Input>;
  sentiment_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Keyword_Max_Fields = {
  __typename?: 'keyword_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  sentiment_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "keyword" */
export type Keyword_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  sentiment_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Keyword_Min_Fields = {
  __typename?: 'keyword_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  sentiment_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "keyword" */
export type Keyword_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  sentiment_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** response of any mutation on the table "keyword" */
export type Keyword_Mutation_Response = {
  __typename?: 'keyword_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Keyword>;
};

/** input type for inserting object relation for remote table "keyword" */
export type Keyword_Obj_Rel_Insert_Input = {
  data: Keyword_Insert_Input;
  on_conflict?: Maybe<Keyword_On_Conflict>;
};

/** on conflict condition type for table "keyword" */
export type Keyword_On_Conflict = {
  constraint: Keyword_Constraint;
  update_columns: Array<Keyword_Update_Column>;
  where?: Maybe<Keyword_Bool_Exp>;
};

/** ordering options when selecting data from "keyword" */
export type Keyword_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  keyword_referrals_aggregate?: Maybe<Keyword_Referral_Aggregate_Order_By>;
  keyword_topics_aggregate?: Maybe<Keyword_Topic_Aggregate_Order_By>;
  sentiment?: Maybe<Sentiment_Order_By>;
  sentiment_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: "keyword" */
export type Keyword_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** columns and relationships of "keyword_referral" */
export type Keyword_Referral = {
  __typename?: 'keyword_referral';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  keyword: Keyword;
  keyword_id: Scalars['uuid'];
  /** An object relationship */
  referral: Referral;
  referral_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "keyword_referral" */
export type Keyword_Referral_Aggregate = {
  __typename?: 'keyword_referral_aggregate';
  aggregate?: Maybe<Keyword_Referral_Aggregate_Fields>;
  nodes: Array<Keyword_Referral>;
};

/** aggregate fields of "keyword_referral" */
export type Keyword_Referral_Aggregate_Fields = {
  __typename?: 'keyword_referral_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Keyword_Referral_Max_Fields>;
  min?: Maybe<Keyword_Referral_Min_Fields>;
};


/** aggregate fields of "keyword_referral" */
export type Keyword_Referral_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Keyword_Referral_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "keyword_referral" */
export type Keyword_Referral_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Keyword_Referral_Max_Order_By>;
  min?: Maybe<Keyword_Referral_Min_Order_By>;
};

/** input type for inserting array relation for remote table "keyword_referral" */
export type Keyword_Referral_Arr_Rel_Insert_Input = {
  data: Array<Keyword_Referral_Insert_Input>;
  on_conflict?: Maybe<Keyword_Referral_On_Conflict>;
};

/** Boolean expression to filter rows from the table "keyword_referral". All fields are combined with a logical 'AND'. */
export type Keyword_Referral_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Keyword_Referral_Bool_Exp>>>;
  _not?: Maybe<Keyword_Referral_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Keyword_Referral_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  keyword?: Maybe<Keyword_Bool_Exp>;
  keyword_id?: Maybe<Uuid_Comparison_Exp>;
  referral?: Maybe<Referral_Bool_Exp>;
  referral_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "keyword_referral" */
export enum Keyword_Referral_Constraint {
  /** unique or primary key constraint */
  KeywordReferralKeywordIdReferralIdKey = 'keyword_referral_keyword_id_referral_id_key',
  /** unique or primary key constraint */
  KeywordReferralPkey = 'keyword_referral_pkey'
}

/** input type for inserting data into table "keyword_referral" */
export type Keyword_Referral_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  keyword?: Maybe<Keyword_Obj_Rel_Insert_Input>;
  keyword_id?: Maybe<Scalars['uuid']>;
  referral?: Maybe<Referral_Obj_Rel_Insert_Input>;
  referral_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Keyword_Referral_Max_Fields = {
  __typename?: 'keyword_referral_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  keyword_id?: Maybe<Scalars['uuid']>;
  referral_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "keyword_referral" */
export type Keyword_Referral_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  keyword_id?: Maybe<Order_By>;
  referral_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Keyword_Referral_Min_Fields = {
  __typename?: 'keyword_referral_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  keyword_id?: Maybe<Scalars['uuid']>;
  referral_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "keyword_referral" */
export type Keyword_Referral_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  keyword_id?: Maybe<Order_By>;
  referral_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "keyword_referral" */
export type Keyword_Referral_Mutation_Response = {
  __typename?: 'keyword_referral_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Keyword_Referral>;
};

/** input type for inserting object relation for remote table "keyword_referral" */
export type Keyword_Referral_Obj_Rel_Insert_Input = {
  data: Keyword_Referral_Insert_Input;
  on_conflict?: Maybe<Keyword_Referral_On_Conflict>;
};

/** on conflict condition type for table "keyword_referral" */
export type Keyword_Referral_On_Conflict = {
  constraint: Keyword_Referral_Constraint;
  update_columns: Array<Keyword_Referral_Update_Column>;
  where?: Maybe<Keyword_Referral_Bool_Exp>;
};

/** ordering options when selecting data from "keyword_referral" */
export type Keyword_Referral_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  keyword?: Maybe<Keyword_Order_By>;
  keyword_id?: Maybe<Order_By>;
  referral?: Maybe<Referral_Order_By>;
  referral_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "keyword_referral" */
export type Keyword_Referral_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "keyword_referral" */
export enum Keyword_Referral_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  KeywordId = 'keyword_id',
  /** column name */
  ReferralId = 'referral_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "keyword_referral" */
export type Keyword_Referral_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  keyword_id?: Maybe<Scalars['uuid']>;
  referral_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "keyword_referral" */
export enum Keyword_Referral_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  KeywordId = 'keyword_id',
  /** column name */
  ReferralId = 'referral_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select columns of table "keyword" */
export enum Keyword_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  SentimentId = 'sentiment_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "keyword" */
export type Keyword_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  sentiment_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** columns and relationships of "keyword_topic" */
export type Keyword_Topic = {
  __typename?: 'keyword_topic';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  keyword: Keyword;
  keyword_id: Scalars['uuid'];
  /** An object relationship */
  topic: Topic;
  topic_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "keyword_topic" */
export type Keyword_Topic_Aggregate = {
  __typename?: 'keyword_topic_aggregate';
  aggregate?: Maybe<Keyword_Topic_Aggregate_Fields>;
  nodes: Array<Keyword_Topic>;
};

/** aggregate fields of "keyword_topic" */
export type Keyword_Topic_Aggregate_Fields = {
  __typename?: 'keyword_topic_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Keyword_Topic_Max_Fields>;
  min?: Maybe<Keyword_Topic_Min_Fields>;
};


/** aggregate fields of "keyword_topic" */
export type Keyword_Topic_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Keyword_Topic_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "keyword_topic" */
export type Keyword_Topic_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Keyword_Topic_Max_Order_By>;
  min?: Maybe<Keyword_Topic_Min_Order_By>;
};

/** input type for inserting array relation for remote table "keyword_topic" */
export type Keyword_Topic_Arr_Rel_Insert_Input = {
  data: Array<Keyword_Topic_Insert_Input>;
  on_conflict?: Maybe<Keyword_Topic_On_Conflict>;
};

/** Boolean expression to filter rows from the table "keyword_topic". All fields are combined with a logical 'AND'. */
export type Keyword_Topic_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Keyword_Topic_Bool_Exp>>>;
  _not?: Maybe<Keyword_Topic_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Keyword_Topic_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  keyword?: Maybe<Keyword_Bool_Exp>;
  keyword_id?: Maybe<Uuid_Comparison_Exp>;
  topic?: Maybe<Topic_Bool_Exp>;
  topic_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "keyword_topic" */
export enum Keyword_Topic_Constraint {
  /** unique or primary key constraint */
  KeywordTopicKeywordIdTopicIdKey = 'keyword_topic_keyword_id_topic_id_key',
  /** unique or primary key constraint */
  KeywordTopicPkey = 'keyword_topic_pkey'
}

/** input type for inserting data into table "keyword_topic" */
export type Keyword_Topic_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  keyword?: Maybe<Keyword_Obj_Rel_Insert_Input>;
  keyword_id?: Maybe<Scalars['uuid']>;
  topic?: Maybe<Topic_Obj_Rel_Insert_Input>;
  topic_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Keyword_Topic_Max_Fields = {
  __typename?: 'keyword_topic_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  keyword_id?: Maybe<Scalars['uuid']>;
  topic_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "keyword_topic" */
export type Keyword_Topic_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  keyword_id?: Maybe<Order_By>;
  topic_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Keyword_Topic_Min_Fields = {
  __typename?: 'keyword_topic_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  keyword_id?: Maybe<Scalars['uuid']>;
  topic_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "keyword_topic" */
export type Keyword_Topic_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  keyword_id?: Maybe<Order_By>;
  topic_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "keyword_topic" */
export type Keyword_Topic_Mutation_Response = {
  __typename?: 'keyword_topic_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Keyword_Topic>;
};

/** input type for inserting object relation for remote table "keyword_topic" */
export type Keyword_Topic_Obj_Rel_Insert_Input = {
  data: Keyword_Topic_Insert_Input;
  on_conflict?: Maybe<Keyword_Topic_On_Conflict>;
};

/** on conflict condition type for table "keyword_topic" */
export type Keyword_Topic_On_Conflict = {
  constraint: Keyword_Topic_Constraint;
  update_columns: Array<Keyword_Topic_Update_Column>;
  where?: Maybe<Keyword_Topic_Bool_Exp>;
};

/** ordering options when selecting data from "keyword_topic" */
export type Keyword_Topic_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  keyword?: Maybe<Keyword_Order_By>;
  keyword_id?: Maybe<Order_By>;
  topic?: Maybe<Topic_Order_By>;
  topic_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "keyword_topic" */
export type Keyword_Topic_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "keyword_topic" */
export enum Keyword_Topic_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  KeywordId = 'keyword_id',
  /** column name */
  TopicId = 'topic_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "keyword_topic" */
export type Keyword_Topic_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  keyword_id?: Maybe<Scalars['uuid']>;
  topic_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "keyword_topic" */
export enum Keyword_Topic_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  KeywordId = 'keyword_id',
  /** column name */
  TopicId = 'topic_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** update columns of table "keyword" */
export enum Keyword_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  SentimentId = 'sentiment_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** columns and relationships of "moderator_action" */
export type Moderator_Action = {
  __typename?: 'moderator_action';
  value: Scalars['String'];
};

/** aggregated selection of "moderator_action" */
export type Moderator_Action_Aggregate = {
  __typename?: 'moderator_action_aggregate';
  aggregate?: Maybe<Moderator_Action_Aggregate_Fields>;
  nodes: Array<Moderator_Action>;
};

/** aggregate fields of "moderator_action" */
export type Moderator_Action_Aggregate_Fields = {
  __typename?: 'moderator_action_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Moderator_Action_Max_Fields>;
  min?: Maybe<Moderator_Action_Min_Fields>;
};


/** aggregate fields of "moderator_action" */
export type Moderator_Action_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Moderator_Action_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "moderator_action" */
export type Moderator_Action_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Moderator_Action_Max_Order_By>;
  min?: Maybe<Moderator_Action_Min_Order_By>;
};

/** input type for inserting array relation for remote table "moderator_action" */
export type Moderator_Action_Arr_Rel_Insert_Input = {
  data: Array<Moderator_Action_Insert_Input>;
  on_conflict?: Maybe<Moderator_Action_On_Conflict>;
};

/** Boolean expression to filter rows from the table "moderator_action". All fields are combined with a logical 'AND'. */
export type Moderator_Action_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Moderator_Action_Bool_Exp>>>;
  _not?: Maybe<Moderator_Action_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Moderator_Action_Bool_Exp>>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "moderator_action" */
export enum Moderator_Action_Constraint {
  /** unique or primary key constraint */
  ActionPkey = 'action_pkey'
}

/** input type for inserting data into table "moderator_action" */
export type Moderator_Action_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Moderator_Action_Max_Fields = {
  __typename?: 'moderator_action_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "moderator_action" */
export type Moderator_Action_Max_Order_By = {
  value?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Moderator_Action_Min_Fields = {
  __typename?: 'moderator_action_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "moderator_action" */
export type Moderator_Action_Min_Order_By = {
  value?: Maybe<Order_By>;
};

/** response of any mutation on the table "moderator_action" */
export type Moderator_Action_Mutation_Response = {
  __typename?: 'moderator_action_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Moderator_Action>;
};

/** input type for inserting object relation for remote table "moderator_action" */
export type Moderator_Action_Obj_Rel_Insert_Input = {
  data: Moderator_Action_Insert_Input;
  on_conflict?: Maybe<Moderator_Action_On_Conflict>;
};

/** on conflict condition type for table "moderator_action" */
export type Moderator_Action_On_Conflict = {
  constraint: Moderator_Action_Constraint;
  update_columns: Array<Moderator_Action_Update_Column>;
  where?: Maybe<Moderator_Action_Bool_Exp>;
};

/** ordering options when selecting data from "moderator_action" */
export type Moderator_Action_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: "moderator_action" */
export type Moderator_Action_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "moderator_action" */
export enum Moderator_Action_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "moderator_action" */
export type Moderator_Action_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "moderator_action" */
export enum Moderator_Action_Update_Column {
  /** column name */
  Value = 'value'
}

/** columns and relationships of "moderator_note" */
export type Moderator_Note = {
  __typename?: 'moderator_note';
  action: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  note: Scalars['String'];
  /** An object relationship */
  story: Story;
  story_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: User;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "moderator_note" */
export type Moderator_Note_Aggregate = {
  __typename?: 'moderator_note_aggregate';
  aggregate?: Maybe<Moderator_Note_Aggregate_Fields>;
  nodes: Array<Moderator_Note>;
};

/** aggregate fields of "moderator_note" */
export type Moderator_Note_Aggregate_Fields = {
  __typename?: 'moderator_note_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Moderator_Note_Max_Fields>;
  min?: Maybe<Moderator_Note_Min_Fields>;
};


/** aggregate fields of "moderator_note" */
export type Moderator_Note_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Moderator_Note_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "moderator_note" */
export type Moderator_Note_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Moderator_Note_Max_Order_By>;
  min?: Maybe<Moderator_Note_Min_Order_By>;
};

/** input type for inserting array relation for remote table "moderator_note" */
export type Moderator_Note_Arr_Rel_Insert_Input = {
  data: Array<Moderator_Note_Insert_Input>;
  on_conflict?: Maybe<Moderator_Note_On_Conflict>;
};

/** Boolean expression to filter rows from the table "moderator_note". All fields are combined with a logical 'AND'. */
export type Moderator_Note_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Moderator_Note_Bool_Exp>>>;
  _not?: Maybe<Moderator_Note_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Moderator_Note_Bool_Exp>>>;
  action?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  note?: Maybe<String_Comparison_Exp>;
  story?: Maybe<Story_Bool_Exp>;
  story_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<User_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "moderator_note" */
export enum Moderator_Note_Constraint {
  /** unique or primary key constraint */
  ModeratorNotePkey = 'moderator_note_pkey'
}

/** input type for inserting data into table "moderator_note" */
export type Moderator_Note_Insert_Input = {
  action?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  story?: Maybe<Story_Obj_Rel_Insert_Input>;
  story_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<User_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Moderator_Note_Max_Fields = {
  __typename?: 'moderator_note_max_fields';
  action?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  story_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "moderator_note" */
export type Moderator_Note_Max_Order_By = {
  action?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  note?: Maybe<Order_By>;
  story_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Moderator_Note_Min_Fields = {
  __typename?: 'moderator_note_min_fields';
  action?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  story_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "moderator_note" */
export type Moderator_Note_Min_Order_By = {
  action?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  note?: Maybe<Order_By>;
  story_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "moderator_note" */
export type Moderator_Note_Mutation_Response = {
  __typename?: 'moderator_note_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Moderator_Note>;
};

/** input type for inserting object relation for remote table "moderator_note" */
export type Moderator_Note_Obj_Rel_Insert_Input = {
  data: Moderator_Note_Insert_Input;
  on_conflict?: Maybe<Moderator_Note_On_Conflict>;
};

/** on conflict condition type for table "moderator_note" */
export type Moderator_Note_On_Conflict = {
  constraint: Moderator_Note_Constraint;
  update_columns: Array<Moderator_Note_Update_Column>;
  where?: Maybe<Moderator_Note_Bool_Exp>;
};

/** ordering options when selecting data from "moderator_note" */
export type Moderator_Note_Order_By = {
  action?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  note?: Maybe<Order_By>;
  story?: Maybe<Story_Order_By>;
  story_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<User_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "moderator_note" */
export type Moderator_Note_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "moderator_note" */
export enum Moderator_Note_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Note = 'note',
  /** column name */
  StoryId = 'story_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "moderator_note" */
export type Moderator_Note_Set_Input = {
  action?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  story_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "moderator_note" */
export enum Moderator_Note_Update_Column {
  /** column name */
  Action = 'action',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Note = 'note',
  /** column name */
  StoryId = 'story_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** perform the action: "DeleteStory" */
  DeleteStory?: Maybe<DeleteStoryOutput>;
  /** perform the action: "DeleteUser" */
  DeleteUser?: Maybe<DeleteUserOutput>;
  /** perform the action: "ReactToStory" */
  ReactToStory?: Maybe<ReactToStoryOutput>;
  /** perform the action: "RemoveStoryReaction" */
  RemoveStoryReaction?: Maybe<RemoveStoryReactionOutput>;
  /** perform the action: "ReportStory" */
  ReportStory?: Maybe<ReportStoryOutput>;
  /** perform the action: "SavePublishedStory" */
  SavePublishedStory?: Maybe<SavePublishedStoryOutput>;
  /** perform the action: "SaveStory" */
  SaveStory?: Maybe<SaveStoryOutput>;
  /** perform the action: "SendEmail" */
  SendEmail?: Maybe<SendEmailOutput>;
  /** perform the action: "SendPush" */
  SendPush?: Maybe<SendPushOutput>;
  /** perform the action: "SendPushToUser" */
  SendPushToUser?: Maybe<SendPushToUserOutput>;
  /** perform the action: "SendPushToUsers" */
  SendPushToUsers?: Maybe<SendPushToUsersOutput>;
  /** perform the action: "SubmitStory" */
  SubmitStory?: Maybe<SubmitStoryOutput>;
  /** perform the action: "UpdateSubmittedStory" */
  UpdateSubmittedStory?: Maybe<UpdateSubmittedStoryOutput>;
  /** perform the action: "UpdateUserSubscription" */
  UpdateUserSubscription?: Maybe<UpdateUserSubscriptionOutput>;
  /** delete data from the table: "assessment_item" */
  delete_assessment_item?: Maybe<Assessment_Item_Mutation_Response>;
  /** delete single row from the table: "assessment_item" */
  delete_assessment_item_by_pk?: Maybe<Assessment_Item>;
  /** delete data from the table: "audience" */
  delete_audience?: Maybe<Audience_Mutation_Response>;
  /** delete single row from the table: "audience" */
  delete_audience_by_pk?: Maybe<Audience>;
  /** delete data from the table: "auth.account_providers" */
  delete_auth_account_providers?: Maybe<Auth_Account_Providers_Mutation_Response>;
  /** delete single row from the table: "auth.account_providers" */
  delete_auth_account_providers_by_pk?: Maybe<Auth_Account_Providers>;
  /** delete data from the table: "auth.account_roles" */
  delete_auth_account_roles?: Maybe<Auth_Account_Roles_Mutation_Response>;
  /** delete single row from the table: "auth.account_roles" */
  delete_auth_account_roles_by_pk?: Maybe<Auth_Account_Roles>;
  /** delete data from the table: "auth.accounts" */
  delete_auth_accounts?: Maybe<Auth_Accounts_Mutation_Response>;
  /** delete single row from the table: "auth.accounts" */
  delete_auth_accounts_by_pk?: Maybe<Auth_Accounts>;
  /** delete data from the table: "auth.providers" */
  delete_auth_providers?: Maybe<Auth_Providers_Mutation_Response>;
  /** delete single row from the table: "auth.providers" */
  delete_auth_providers_by_pk?: Maybe<Auth_Providers>;
  /** delete data from the table: "auth.refresh_tokens" */
  delete_auth_refresh_tokens?: Maybe<Auth_Refresh_Tokens_Mutation_Response>;
  /** delete single row from the table: "auth.refresh_tokens" */
  delete_auth_refresh_tokens_by_pk?: Maybe<Auth_Refresh_Tokens>;
  /** delete data from the table: "auth.roles" */
  delete_auth_roles?: Maybe<Auth_Roles_Mutation_Response>;
  /** delete single row from the table: "auth.roles" */
  delete_auth_roles_by_pk?: Maybe<Auth_Roles>;
  /** delete data from the table: "employment_status" */
  delete_employment_status?: Maybe<Employment_Status_Mutation_Response>;
  /** delete single row from the table: "employment_status" */
  delete_employment_status_by_pk?: Maybe<Employment_Status>;
  /** delete data from the table: "feedback" */
  delete_feedback?: Maybe<Feedback_Mutation_Response>;
  /** delete single row from the table: "feedback" */
  delete_feedback_by_pk?: Maybe<Feedback>;
  /** delete data from the table: "flag" */
  delete_flag?: Maybe<Flag_Mutation_Response>;
  /** delete single row from the table: "flag" */
  delete_flag_by_pk?: Maybe<Flag>;
  /** delete data from the table: "gender" */
  delete_gender?: Maybe<Gender_Mutation_Response>;
  /** delete single row from the table: "gender" */
  delete_gender_by_pk?: Maybe<Gender>;
  /** delete data from the table: "info_tile" */
  delete_info_tile?: Maybe<Info_Tile_Mutation_Response>;
  /** delete single row from the table: "info_tile" */
  delete_info_tile_by_pk?: Maybe<Info_Tile>;
  /** delete data from the table: "info_tile_status_type" */
  delete_info_tile_status_type?: Maybe<Info_Tile_Status_Type_Mutation_Response>;
  /** delete single row from the table: "info_tile_status_type" */
  delete_info_tile_status_type_by_pk?: Maybe<Info_Tile_Status_Type>;
  /** delete data from the table: "keyword" */
  delete_keyword?: Maybe<Keyword_Mutation_Response>;
  /** delete single row from the table: "keyword" */
  delete_keyword_by_pk?: Maybe<Keyword>;
  /** delete data from the table: "keyword_referral" */
  delete_keyword_referral?: Maybe<Keyword_Referral_Mutation_Response>;
  /** delete single row from the table: "keyword_referral" */
  delete_keyword_referral_by_pk?: Maybe<Keyword_Referral>;
  /** delete data from the table: "keyword_topic" */
  delete_keyword_topic?: Maybe<Keyword_Topic_Mutation_Response>;
  /** delete single row from the table: "keyword_topic" */
  delete_keyword_topic_by_pk?: Maybe<Keyword_Topic>;
  /** delete data from the table: "moderator_action" */
  delete_moderator_action?: Maybe<Moderator_Action_Mutation_Response>;
  /** delete single row from the table: "moderator_action" */
  delete_moderator_action_by_pk?: Maybe<Moderator_Action>;
  /** delete data from the table: "moderator_note" */
  delete_moderator_note?: Maybe<Moderator_Note_Mutation_Response>;
  /** delete single row from the table: "moderator_note" */
  delete_moderator_note_by_pk?: Maybe<Moderator_Note>;
  /** delete data from the table: "prompt" */
  delete_prompt?: Maybe<Prompt_Mutation_Response>;
  /** delete single row from the table: "prompt" */
  delete_prompt_by_pk?: Maybe<Prompt>;
  /** delete data from the table: "reaction" */
  delete_reaction?: Maybe<Reaction_Mutation_Response>;
  /** delete single row from the table: "reaction" */
  delete_reaction_by_pk?: Maybe<Reaction>;
  /** delete data from the table: "reaction_type" */
  delete_reaction_type?: Maybe<Reaction_Type_Mutation_Response>;
  /** delete single row from the table: "reaction_type" */
  delete_reaction_type_by_pk?: Maybe<Reaction_Type>;
  /** delete data from the table: "referral" */
  delete_referral?: Maybe<Referral_Mutation_Response>;
  /** delete single row from the table: "referral" */
  delete_referral_by_pk?: Maybe<Referral>;
  /** delete data from the table: "reply" */
  delete_reply?: Maybe<Reply_Mutation_Response>;
  /** delete single row from the table: "reply" */
  delete_reply_by_pk?: Maybe<Reply>;
  /** delete data from the table: "resource" */
  delete_resource?: Maybe<Resource_Mutation_Response>;
  /** delete single row from the table: "resource" */
  delete_resource_by_pk?: Maybe<Resource>;
  /** delete data from the table: "resource_type" */
  delete_resource_type?: Maybe<Resource_Type_Mutation_Response>;
  /** delete single row from the table: "resource_type" */
  delete_resource_type_by_pk?: Maybe<Resource_Type>;
  /** delete data from the table: "sentiment" */
  delete_sentiment?: Maybe<Sentiment_Mutation_Response>;
  /** delete single row from the table: "sentiment" */
  delete_sentiment_by_pk?: Maybe<Sentiment>;
  /** delete data from the table: "share" */
  delete_share?: Maybe<Share_Mutation_Response>;
  /** delete single row from the table: "share" */
  delete_share_by_pk?: Maybe<Share>;
  /** delete data from the table: "story" */
  delete_story?: Maybe<Story_Mutation_Response>;
  /** delete single row from the table: "story" */
  delete_story_by_pk?: Maybe<Story>;
  /** delete data from the table: "story_save" */
  delete_story_save?: Maybe<Story_Save_Mutation_Response>;
  /** delete single row from the table: "story_save" */
  delete_story_save_by_pk?: Maybe<Story_Save>;
  /** delete data from the table: "story_status" */
  delete_story_status?: Maybe<Story_Status_Mutation_Response>;
  /** delete single row from the table: "story_status" */
  delete_story_status_by_pk?: Maybe<Story_Status>;
  /** delete data from the table: "story_tag" */
  delete_story_tag?: Maybe<Story_Tag_Mutation_Response>;
  /** delete single row from the table: "story_tag" */
  delete_story_tag_by_pk?: Maybe<Story_Tag>;
  /** delete data from the table: "story_type" */
  delete_story_type?: Maybe<Story_Type_Mutation_Response>;
  /** delete single row from the table: "story_type" */
  delete_story_type_by_pk?: Maybe<Story_Type>;
  /** delete data from the table: "story_violation" */
  delete_story_violation?: Maybe<Story_Violation_Mutation_Response>;
  /** delete single row from the table: "story_violation" */
  delete_story_violation_by_pk?: Maybe<Story_Violation>;
  /** delete data from the table: "suburb" */
  delete_suburb?: Maybe<Suburb_Mutation_Response>;
  /** delete single row from the table: "suburb" */
  delete_suburb_by_pk?: Maybe<Suburb>;
  /** delete data from the table: "support_group" */
  delete_support_group?: Maybe<Support_Group_Mutation_Response>;
  /** delete single row from the table: "support_group" */
  delete_support_group_by_pk?: Maybe<Support_Group>;
  /** delete data from the table: "tag" */
  delete_tag?: Maybe<Tag_Mutation_Response>;
  /** delete single row from the table: "tag" */
  delete_tag_by_pk?: Maybe<Tag>;
  /** delete data from the table: "tag_type" */
  delete_tag_type?: Maybe<Tag_Type_Mutation_Response>;
  /** delete single row from the table: "tag_type" */
  delete_tag_type_by_pk?: Maybe<Tag_Type>;
  /** delete data from the table: "topic" */
  delete_topic?: Maybe<Topic_Mutation_Response>;
  /** delete single row from the table: "topic" */
  delete_topic_by_pk?: Maybe<Topic>;
  /** delete data from the table: "user" */
  delete_user?: Maybe<User_Mutation_Response>;
  /** delete single row from the table: "user" */
  delete_user_by_pk?: Maybe<User>;
  /** delete data from the table: "user_flag" */
  delete_user_flag?: Maybe<User_Flag_Mutation_Response>;
  /** delete single row from the table: "user_flag" */
  delete_user_flag_by_pk?: Maybe<User_Flag>;
  /** delete data from the table: "user_status" */
  delete_user_status?: Maybe<User_Status_Mutation_Response>;
  /** delete single row from the table: "user_status" */
  delete_user_status_by_pk?: Maybe<User_Status>;
  /** delete data from the table: "user_support_group" */
  delete_user_support_group?: Maybe<User_Support_Group_Mutation_Response>;
  /** delete single row from the table: "user_support_group" */
  delete_user_support_group_by_pk?: Maybe<User_Support_Group>;
  /** delete data from the table: "violation_note" */
  delete_violation_note?: Maybe<Violation_Note_Mutation_Response>;
  /** delete single row from the table: "violation_note" */
  delete_violation_note_by_pk?: Maybe<Violation_Note>;
  /** insert data into the table: "assessment_item" */
  insert_assessment_item?: Maybe<Assessment_Item_Mutation_Response>;
  /** insert a single row into the table: "assessment_item" */
  insert_assessment_item_one?: Maybe<Assessment_Item>;
  /** insert data into the table: "audience" */
  insert_audience?: Maybe<Audience_Mutation_Response>;
  /** insert a single row into the table: "audience" */
  insert_audience_one?: Maybe<Audience>;
  /** insert data into the table: "auth.account_providers" */
  insert_auth_account_providers?: Maybe<Auth_Account_Providers_Mutation_Response>;
  /** insert a single row into the table: "auth.account_providers" */
  insert_auth_account_providers_one?: Maybe<Auth_Account_Providers>;
  /** insert data into the table: "auth.account_roles" */
  insert_auth_account_roles?: Maybe<Auth_Account_Roles_Mutation_Response>;
  /** insert a single row into the table: "auth.account_roles" */
  insert_auth_account_roles_one?: Maybe<Auth_Account_Roles>;
  /** insert data into the table: "auth.accounts" */
  insert_auth_accounts?: Maybe<Auth_Accounts_Mutation_Response>;
  /** insert a single row into the table: "auth.accounts" */
  insert_auth_accounts_one?: Maybe<Auth_Accounts>;
  /** insert data into the table: "auth.providers" */
  insert_auth_providers?: Maybe<Auth_Providers_Mutation_Response>;
  /** insert a single row into the table: "auth.providers" */
  insert_auth_providers_one?: Maybe<Auth_Providers>;
  /** insert data into the table: "auth.refresh_tokens" */
  insert_auth_refresh_tokens?: Maybe<Auth_Refresh_Tokens_Mutation_Response>;
  /** insert a single row into the table: "auth.refresh_tokens" */
  insert_auth_refresh_tokens_one?: Maybe<Auth_Refresh_Tokens>;
  /** insert data into the table: "auth.roles" */
  insert_auth_roles?: Maybe<Auth_Roles_Mutation_Response>;
  /** insert a single row into the table: "auth.roles" */
  insert_auth_roles_one?: Maybe<Auth_Roles>;
  /** insert data into the table: "employment_status" */
  insert_employment_status?: Maybe<Employment_Status_Mutation_Response>;
  /** insert a single row into the table: "employment_status" */
  insert_employment_status_one?: Maybe<Employment_Status>;
  /** insert data into the table: "feedback" */
  insert_feedback?: Maybe<Feedback_Mutation_Response>;
  /** insert a single row into the table: "feedback" */
  insert_feedback_one?: Maybe<Feedback>;
  /** insert data into the table: "flag" */
  insert_flag?: Maybe<Flag_Mutation_Response>;
  /** insert a single row into the table: "flag" */
  insert_flag_one?: Maybe<Flag>;
  /** insert data into the table: "gender" */
  insert_gender?: Maybe<Gender_Mutation_Response>;
  /** insert a single row into the table: "gender" */
  insert_gender_one?: Maybe<Gender>;
  /** insert data into the table: "info_tile" */
  insert_info_tile?: Maybe<Info_Tile_Mutation_Response>;
  /** insert a single row into the table: "info_tile" */
  insert_info_tile_one?: Maybe<Info_Tile>;
  /** insert data into the table: "info_tile_status_type" */
  insert_info_tile_status_type?: Maybe<Info_Tile_Status_Type_Mutation_Response>;
  /** insert a single row into the table: "info_tile_status_type" */
  insert_info_tile_status_type_one?: Maybe<Info_Tile_Status_Type>;
  /** insert data into the table: "keyword" */
  insert_keyword?: Maybe<Keyword_Mutation_Response>;
  /** insert a single row into the table: "keyword" */
  insert_keyword_one?: Maybe<Keyword>;
  /** insert data into the table: "keyword_referral" */
  insert_keyword_referral?: Maybe<Keyword_Referral_Mutation_Response>;
  /** insert a single row into the table: "keyword_referral" */
  insert_keyword_referral_one?: Maybe<Keyword_Referral>;
  /** insert data into the table: "keyword_topic" */
  insert_keyword_topic?: Maybe<Keyword_Topic_Mutation_Response>;
  /** insert a single row into the table: "keyword_topic" */
  insert_keyword_topic_one?: Maybe<Keyword_Topic>;
  /** insert data into the table: "moderator_action" */
  insert_moderator_action?: Maybe<Moderator_Action_Mutation_Response>;
  /** insert a single row into the table: "moderator_action" */
  insert_moderator_action_one?: Maybe<Moderator_Action>;
  /** insert data into the table: "moderator_note" */
  insert_moderator_note?: Maybe<Moderator_Note_Mutation_Response>;
  /** insert a single row into the table: "moderator_note" */
  insert_moderator_note_one?: Maybe<Moderator_Note>;
  /** insert data into the table: "prompt" */
  insert_prompt?: Maybe<Prompt_Mutation_Response>;
  /** insert a single row into the table: "prompt" */
  insert_prompt_one?: Maybe<Prompt>;
  /** insert data into the table: "reaction" */
  insert_reaction?: Maybe<Reaction_Mutation_Response>;
  /** insert a single row into the table: "reaction" */
  insert_reaction_one?: Maybe<Reaction>;
  /** insert data into the table: "reaction_type" */
  insert_reaction_type?: Maybe<Reaction_Type_Mutation_Response>;
  /** insert a single row into the table: "reaction_type" */
  insert_reaction_type_one?: Maybe<Reaction_Type>;
  /** insert data into the table: "referral" */
  insert_referral?: Maybe<Referral_Mutation_Response>;
  /** insert a single row into the table: "referral" */
  insert_referral_one?: Maybe<Referral>;
  /** insert data into the table: "reply" */
  insert_reply?: Maybe<Reply_Mutation_Response>;
  /** insert a single row into the table: "reply" */
  insert_reply_one?: Maybe<Reply>;
  /** insert data into the table: "resource" */
  insert_resource?: Maybe<Resource_Mutation_Response>;
  /** insert a single row into the table: "resource" */
  insert_resource_one?: Maybe<Resource>;
  /** insert data into the table: "resource_type" */
  insert_resource_type?: Maybe<Resource_Type_Mutation_Response>;
  /** insert a single row into the table: "resource_type" */
  insert_resource_type_one?: Maybe<Resource_Type>;
  /** insert data into the table: "sentiment" */
  insert_sentiment?: Maybe<Sentiment_Mutation_Response>;
  /** insert a single row into the table: "sentiment" */
  insert_sentiment_one?: Maybe<Sentiment>;
  /** insert data into the table: "share" */
  insert_share?: Maybe<Share_Mutation_Response>;
  /** insert a single row into the table: "share" */
  insert_share_one?: Maybe<Share>;
  /** insert data into the table: "story" */
  insert_story?: Maybe<Story_Mutation_Response>;
  /** insert a single row into the table: "story" */
  insert_story_one?: Maybe<Story>;
  /** insert data into the table: "story_save" */
  insert_story_save?: Maybe<Story_Save_Mutation_Response>;
  /** insert a single row into the table: "story_save" */
  insert_story_save_one?: Maybe<Story_Save>;
  /** insert data into the table: "story_status" */
  insert_story_status?: Maybe<Story_Status_Mutation_Response>;
  /** insert a single row into the table: "story_status" */
  insert_story_status_one?: Maybe<Story_Status>;
  /** insert data into the table: "story_tag" */
  insert_story_tag?: Maybe<Story_Tag_Mutation_Response>;
  /** insert a single row into the table: "story_tag" */
  insert_story_tag_one?: Maybe<Story_Tag>;
  /** insert data into the table: "story_type" */
  insert_story_type?: Maybe<Story_Type_Mutation_Response>;
  /** insert a single row into the table: "story_type" */
  insert_story_type_one?: Maybe<Story_Type>;
  /** insert data into the table: "story_violation" */
  insert_story_violation?: Maybe<Story_Violation_Mutation_Response>;
  /** insert a single row into the table: "story_violation" */
  insert_story_violation_one?: Maybe<Story_Violation>;
  /** insert data into the table: "suburb" */
  insert_suburb?: Maybe<Suburb_Mutation_Response>;
  /** insert a single row into the table: "suburb" */
  insert_suburb_one?: Maybe<Suburb>;
  /** insert data into the table: "support_group" */
  insert_support_group?: Maybe<Support_Group_Mutation_Response>;
  /** insert a single row into the table: "support_group" */
  insert_support_group_one?: Maybe<Support_Group>;
  /** insert data into the table: "tag" */
  insert_tag?: Maybe<Tag_Mutation_Response>;
  /** insert a single row into the table: "tag" */
  insert_tag_one?: Maybe<Tag>;
  /** insert data into the table: "tag_type" */
  insert_tag_type?: Maybe<Tag_Type_Mutation_Response>;
  /** insert a single row into the table: "tag_type" */
  insert_tag_type_one?: Maybe<Tag_Type>;
  /** insert data into the table: "topic" */
  insert_topic?: Maybe<Topic_Mutation_Response>;
  /** insert a single row into the table: "topic" */
  insert_topic_one?: Maybe<Topic>;
  /** insert data into the table: "user" */
  insert_user?: Maybe<User_Mutation_Response>;
  /** insert data into the table: "user_flag" */
  insert_user_flag?: Maybe<User_Flag_Mutation_Response>;
  /** insert a single row into the table: "user_flag" */
  insert_user_flag_one?: Maybe<User_Flag>;
  /** insert a single row into the table: "user" */
  insert_user_one?: Maybe<User>;
  /** insert data into the table: "user_status" */
  insert_user_status?: Maybe<User_Status_Mutation_Response>;
  /** insert a single row into the table: "user_status" */
  insert_user_status_one?: Maybe<User_Status>;
  /** insert data into the table: "user_support_group" */
  insert_user_support_group?: Maybe<User_Support_Group_Mutation_Response>;
  /** insert a single row into the table: "user_support_group" */
  insert_user_support_group_one?: Maybe<User_Support_Group>;
  /** insert data into the table: "violation_note" */
  insert_violation_note?: Maybe<Violation_Note_Mutation_Response>;
  /** insert a single row into the table: "violation_note" */
  insert_violation_note_one?: Maybe<Violation_Note>;
  /** update data of the table: "assessment_item" */
  update_assessment_item?: Maybe<Assessment_Item_Mutation_Response>;
  /** update single row of the table: "assessment_item" */
  update_assessment_item_by_pk?: Maybe<Assessment_Item>;
  /** update data of the table: "audience" */
  update_audience?: Maybe<Audience_Mutation_Response>;
  /** update single row of the table: "audience" */
  update_audience_by_pk?: Maybe<Audience>;
  /** update data of the table: "auth.account_providers" */
  update_auth_account_providers?: Maybe<Auth_Account_Providers_Mutation_Response>;
  /** update single row of the table: "auth.account_providers" */
  update_auth_account_providers_by_pk?: Maybe<Auth_Account_Providers>;
  /** update data of the table: "auth.account_roles" */
  update_auth_account_roles?: Maybe<Auth_Account_Roles_Mutation_Response>;
  /** update single row of the table: "auth.account_roles" */
  update_auth_account_roles_by_pk?: Maybe<Auth_Account_Roles>;
  /** update data of the table: "auth.accounts" */
  update_auth_accounts?: Maybe<Auth_Accounts_Mutation_Response>;
  /** update single row of the table: "auth.accounts" */
  update_auth_accounts_by_pk?: Maybe<Auth_Accounts>;
  /** update data of the table: "auth.providers" */
  update_auth_providers?: Maybe<Auth_Providers_Mutation_Response>;
  /** update single row of the table: "auth.providers" */
  update_auth_providers_by_pk?: Maybe<Auth_Providers>;
  /** update data of the table: "auth.refresh_tokens" */
  update_auth_refresh_tokens?: Maybe<Auth_Refresh_Tokens_Mutation_Response>;
  /** update single row of the table: "auth.refresh_tokens" */
  update_auth_refresh_tokens_by_pk?: Maybe<Auth_Refresh_Tokens>;
  /** update data of the table: "auth.roles" */
  update_auth_roles?: Maybe<Auth_Roles_Mutation_Response>;
  /** update single row of the table: "auth.roles" */
  update_auth_roles_by_pk?: Maybe<Auth_Roles>;
  /** update data of the table: "employment_status" */
  update_employment_status?: Maybe<Employment_Status_Mutation_Response>;
  /** update single row of the table: "employment_status" */
  update_employment_status_by_pk?: Maybe<Employment_Status>;
  /** update data of the table: "feedback" */
  update_feedback?: Maybe<Feedback_Mutation_Response>;
  /** update single row of the table: "feedback" */
  update_feedback_by_pk?: Maybe<Feedback>;
  /** update data of the table: "flag" */
  update_flag?: Maybe<Flag_Mutation_Response>;
  /** update single row of the table: "flag" */
  update_flag_by_pk?: Maybe<Flag>;
  /** update data of the table: "gender" */
  update_gender?: Maybe<Gender_Mutation_Response>;
  /** update single row of the table: "gender" */
  update_gender_by_pk?: Maybe<Gender>;
  /** update data of the table: "info_tile" */
  update_info_tile?: Maybe<Info_Tile_Mutation_Response>;
  /** update single row of the table: "info_tile" */
  update_info_tile_by_pk?: Maybe<Info_Tile>;
  /** update data of the table: "info_tile_status_type" */
  update_info_tile_status_type?: Maybe<Info_Tile_Status_Type_Mutation_Response>;
  /** update single row of the table: "info_tile_status_type" */
  update_info_tile_status_type_by_pk?: Maybe<Info_Tile_Status_Type>;
  /** update data of the table: "keyword" */
  update_keyword?: Maybe<Keyword_Mutation_Response>;
  /** update single row of the table: "keyword" */
  update_keyword_by_pk?: Maybe<Keyword>;
  /** update data of the table: "keyword_referral" */
  update_keyword_referral?: Maybe<Keyword_Referral_Mutation_Response>;
  /** update single row of the table: "keyword_referral" */
  update_keyword_referral_by_pk?: Maybe<Keyword_Referral>;
  /** update data of the table: "keyword_topic" */
  update_keyword_topic?: Maybe<Keyword_Topic_Mutation_Response>;
  /** update single row of the table: "keyword_topic" */
  update_keyword_topic_by_pk?: Maybe<Keyword_Topic>;
  /** update data of the table: "moderator_action" */
  update_moderator_action?: Maybe<Moderator_Action_Mutation_Response>;
  /** update single row of the table: "moderator_action" */
  update_moderator_action_by_pk?: Maybe<Moderator_Action>;
  /** update data of the table: "moderator_note" */
  update_moderator_note?: Maybe<Moderator_Note_Mutation_Response>;
  /** update single row of the table: "moderator_note" */
  update_moderator_note_by_pk?: Maybe<Moderator_Note>;
  /** update data of the table: "prompt" */
  update_prompt?: Maybe<Prompt_Mutation_Response>;
  /** update single row of the table: "prompt" */
  update_prompt_by_pk?: Maybe<Prompt>;
  /** update data of the table: "reaction" */
  update_reaction?: Maybe<Reaction_Mutation_Response>;
  /** update single row of the table: "reaction" */
  update_reaction_by_pk?: Maybe<Reaction>;
  /** update data of the table: "reaction_type" */
  update_reaction_type?: Maybe<Reaction_Type_Mutation_Response>;
  /** update single row of the table: "reaction_type" */
  update_reaction_type_by_pk?: Maybe<Reaction_Type>;
  /** update data of the table: "referral" */
  update_referral?: Maybe<Referral_Mutation_Response>;
  /** update single row of the table: "referral" */
  update_referral_by_pk?: Maybe<Referral>;
  /** update data of the table: "reply" */
  update_reply?: Maybe<Reply_Mutation_Response>;
  /** update single row of the table: "reply" */
  update_reply_by_pk?: Maybe<Reply>;
  /** update data of the table: "resource" */
  update_resource?: Maybe<Resource_Mutation_Response>;
  /** update single row of the table: "resource" */
  update_resource_by_pk?: Maybe<Resource>;
  /** update data of the table: "resource_type" */
  update_resource_type?: Maybe<Resource_Type_Mutation_Response>;
  /** update single row of the table: "resource_type" */
  update_resource_type_by_pk?: Maybe<Resource_Type>;
  /** update data of the table: "sentiment" */
  update_sentiment?: Maybe<Sentiment_Mutation_Response>;
  /** update single row of the table: "sentiment" */
  update_sentiment_by_pk?: Maybe<Sentiment>;
  /** update data of the table: "share" */
  update_share?: Maybe<Share_Mutation_Response>;
  /** update single row of the table: "share" */
  update_share_by_pk?: Maybe<Share>;
  /** update data of the table: "story" */
  update_story?: Maybe<Story_Mutation_Response>;
  /** update single row of the table: "story" */
  update_story_by_pk?: Maybe<Story>;
  /** update data of the table: "story_save" */
  update_story_save?: Maybe<Story_Save_Mutation_Response>;
  /** update single row of the table: "story_save" */
  update_story_save_by_pk?: Maybe<Story_Save>;
  /** update data of the table: "story_status" */
  update_story_status?: Maybe<Story_Status_Mutation_Response>;
  /** update single row of the table: "story_status" */
  update_story_status_by_pk?: Maybe<Story_Status>;
  /** update data of the table: "story_tag" */
  update_story_tag?: Maybe<Story_Tag_Mutation_Response>;
  /** update single row of the table: "story_tag" */
  update_story_tag_by_pk?: Maybe<Story_Tag>;
  /** update data of the table: "story_type" */
  update_story_type?: Maybe<Story_Type_Mutation_Response>;
  /** update single row of the table: "story_type" */
  update_story_type_by_pk?: Maybe<Story_Type>;
  /** update data of the table: "story_violation" */
  update_story_violation?: Maybe<Story_Violation_Mutation_Response>;
  /** update single row of the table: "story_violation" */
  update_story_violation_by_pk?: Maybe<Story_Violation>;
  /** update data of the table: "suburb" */
  update_suburb?: Maybe<Suburb_Mutation_Response>;
  /** update single row of the table: "suburb" */
  update_suburb_by_pk?: Maybe<Suburb>;
  /** update data of the table: "support_group" */
  update_support_group?: Maybe<Support_Group_Mutation_Response>;
  /** update single row of the table: "support_group" */
  update_support_group_by_pk?: Maybe<Support_Group>;
  /** update data of the table: "tag" */
  update_tag?: Maybe<Tag_Mutation_Response>;
  /** update single row of the table: "tag" */
  update_tag_by_pk?: Maybe<Tag>;
  /** update data of the table: "tag_type" */
  update_tag_type?: Maybe<Tag_Type_Mutation_Response>;
  /** update single row of the table: "tag_type" */
  update_tag_type_by_pk?: Maybe<Tag_Type>;
  /** update data of the table: "topic" */
  update_topic?: Maybe<Topic_Mutation_Response>;
  /** update single row of the table: "topic" */
  update_topic_by_pk?: Maybe<Topic>;
  /** update data of the table: "user" */
  update_user?: Maybe<User_Mutation_Response>;
  /** update single row of the table: "user" */
  update_user_by_pk?: Maybe<User>;
  /** update data of the table: "user_flag" */
  update_user_flag?: Maybe<User_Flag_Mutation_Response>;
  /** update single row of the table: "user_flag" */
  update_user_flag_by_pk?: Maybe<User_Flag>;
  /** update data of the table: "user_status" */
  update_user_status?: Maybe<User_Status_Mutation_Response>;
  /** update single row of the table: "user_status" */
  update_user_status_by_pk?: Maybe<User_Status>;
  /** update data of the table: "user_support_group" */
  update_user_support_group?: Maybe<User_Support_Group_Mutation_Response>;
  /** update single row of the table: "user_support_group" */
  update_user_support_group_by_pk?: Maybe<User_Support_Group>;
  /** update data of the table: "violation_note" */
  update_violation_note?: Maybe<Violation_Note_Mutation_Response>;
  /** update single row of the table: "violation_note" */
  update_violation_note_by_pk?: Maybe<Violation_Note>;
};


/** mutation root */
export type Mutation_RootDeleteStoryArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDeleteUserArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootReactToStoryArgs = {
  reaction: ReactToStoryReactionInsertInput;
};


/** mutation root */
export type Mutation_RootRemoveStoryReactionArgs = {
  reactionId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootReportStoryArgs = {
  storyId: Scalars['uuid'];
  userId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootSavePublishedStoryArgs = {
  content: Scalars['String'];
  storyId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootSaveStoryArgs = {
  replies: Array<SaveStoryReplyInsertInput>;
  resource: Array<SaveStoryResourceInsertInput>;
  story: SaveStoryStoryInsertInput;
  storyId: Scalars['uuid'];
  tagIds?: Maybe<Array<Scalars['uuid']>>;
  tags: Array<SaveStoryStoryTagInsertInput>;
  userTags: Array<SaveStoryTagInsertInput>;
};


/** mutation root */
export type Mutation_RootSendEmailArgs = {
  email: Scalars['String'];
  tags: Scalars['String'];
  template: Scalars['String'];
};


/** mutation root */
export type Mutation_RootSendPushArgs = {
  message: Scalars['String'];
  token: Scalars['String'];
};


/** mutation root */
export type Mutation_RootSendPushToUserArgs = {
  message: Scalars['String'];
  userId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootSendPushToUsersArgs = {
  message: Scalars['String'];
  userIds?: Maybe<Array<Scalars['String']>>;
};


/** mutation root */
export type Mutation_RootSubmitStoryArgs = {
  content: Scalars['String'];
  storyId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootUpdateSubmittedStoryArgs = {
  content: Scalars['String'];
  status: Scalars['String'];
  storyId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootUpdateUserSubscriptionArgs = {
  dq5?: Maybe<Scalars['Int']>;
  email: Scalars['String'];
  published?: Maybe<Scalars['Boolean']>;
  submitted?: Maybe<Scalars['Boolean']>;
};


/** mutation root */
export type Mutation_RootDelete_Assessment_ItemArgs = {
  where: Assessment_Item_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Assessment_Item_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_AudienceArgs = {
  where: Audience_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Audience_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Auth_Account_ProvidersArgs = {
  where: Auth_Account_Providers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Auth_Account_Providers_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Auth_Account_RolesArgs = {
  where: Auth_Account_Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Auth_Account_Roles_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Auth_AccountsArgs = {
  where: Auth_Accounts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Auth_Accounts_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Auth_ProvidersArgs = {
  where: Auth_Providers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Auth_Providers_By_PkArgs = {
  provider: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Auth_Refresh_TokensArgs = {
  where: Auth_Refresh_Tokens_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Auth_Refresh_Tokens_By_PkArgs = {
  refresh_token: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Auth_RolesArgs = {
  where: Auth_Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Auth_Roles_By_PkArgs = {
  role: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Employment_StatusArgs = {
  where: Employment_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Employment_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_FeedbackArgs = {
  where: Feedback_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Feedback_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_FlagArgs = {
  where: Flag_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Flag_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_GenderArgs = {
  where: Gender_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Gender_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Info_TileArgs = {
  where: Info_Tile_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Info_Tile_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Info_Tile_Status_TypeArgs = {
  where: Info_Tile_Status_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Info_Tile_Status_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_KeywordArgs = {
  where: Keyword_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Keyword_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Keyword_ReferralArgs = {
  where: Keyword_Referral_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Keyword_Referral_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Keyword_TopicArgs = {
  where: Keyword_Topic_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Keyword_Topic_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Moderator_ActionArgs = {
  where: Moderator_Action_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Moderator_Action_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Moderator_NoteArgs = {
  where: Moderator_Note_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Moderator_Note_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_PromptArgs = {
  where: Prompt_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Prompt_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ReactionArgs = {
  where: Reaction_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Reaction_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Reaction_TypeArgs = {
  where: Reaction_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Reaction_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_ReferralArgs = {
  where: Referral_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Referral_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ReplyArgs = {
  where: Reply_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Reply_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ResourceArgs = {
  where: Resource_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Resource_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Resource_TypeArgs = {
  where: Resource_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Resource_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_SentimentArgs = {
  where: Sentiment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Sentiment_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ShareArgs = {
  where: Share_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Share_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_StoryArgs = {
  where: Story_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Story_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Story_SaveArgs = {
  where: Story_Save_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Story_Save_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Story_StatusArgs = {
  where: Story_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Story_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Story_TagArgs = {
  where: Story_Tag_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Story_Tag_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Story_TypeArgs = {
  where: Story_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Story_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Story_ViolationArgs = {
  where: Story_Violation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Story_Violation_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_SuburbArgs = {
  where: Suburb_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Suburb_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Support_GroupArgs = {
  where: Support_Group_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Support_Group_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_TagArgs = {
  where: Tag_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tag_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Tag_TypeArgs = {
  where: Tag_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tag_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_TopicArgs = {
  where: Topic_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Topic_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_UserArgs = {
  where: User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_FlagArgs = {
  where: User_Flag_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Flag_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_StatusArgs = {
  where: User_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_User_Support_GroupArgs = {
  where: User_Support_Group_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Support_Group_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Violation_NoteArgs = {
  where: Violation_Note_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Violation_Note_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootInsert_Assessment_ItemArgs = {
  objects: Array<Assessment_Item_Insert_Input>;
  on_conflict?: Maybe<Assessment_Item_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Assessment_Item_OneArgs = {
  object: Assessment_Item_Insert_Input;
  on_conflict?: Maybe<Assessment_Item_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AudienceArgs = {
  objects: Array<Audience_Insert_Input>;
  on_conflict?: Maybe<Audience_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Audience_OneArgs = {
  object: Audience_Insert_Input;
  on_conflict?: Maybe<Audience_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Account_ProvidersArgs = {
  objects: Array<Auth_Account_Providers_Insert_Input>;
  on_conflict?: Maybe<Auth_Account_Providers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Account_Providers_OneArgs = {
  object: Auth_Account_Providers_Insert_Input;
  on_conflict?: Maybe<Auth_Account_Providers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Account_RolesArgs = {
  objects: Array<Auth_Account_Roles_Insert_Input>;
  on_conflict?: Maybe<Auth_Account_Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Account_Roles_OneArgs = {
  object: Auth_Account_Roles_Insert_Input;
  on_conflict?: Maybe<Auth_Account_Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_AccountsArgs = {
  objects: Array<Auth_Accounts_Insert_Input>;
  on_conflict?: Maybe<Auth_Accounts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Accounts_OneArgs = {
  object: Auth_Accounts_Insert_Input;
  on_conflict?: Maybe<Auth_Accounts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_ProvidersArgs = {
  objects: Array<Auth_Providers_Insert_Input>;
  on_conflict?: Maybe<Auth_Providers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Providers_OneArgs = {
  object: Auth_Providers_Insert_Input;
  on_conflict?: Maybe<Auth_Providers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Refresh_TokensArgs = {
  objects: Array<Auth_Refresh_Tokens_Insert_Input>;
  on_conflict?: Maybe<Auth_Refresh_Tokens_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Refresh_Tokens_OneArgs = {
  object: Auth_Refresh_Tokens_Insert_Input;
  on_conflict?: Maybe<Auth_Refresh_Tokens_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_RolesArgs = {
  objects: Array<Auth_Roles_Insert_Input>;
  on_conflict?: Maybe<Auth_Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Roles_OneArgs = {
  object: Auth_Roles_Insert_Input;
  on_conflict?: Maybe<Auth_Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Employment_StatusArgs = {
  objects: Array<Employment_Status_Insert_Input>;
  on_conflict?: Maybe<Employment_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Employment_Status_OneArgs = {
  object: Employment_Status_Insert_Input;
  on_conflict?: Maybe<Employment_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FeedbackArgs = {
  objects: Array<Feedback_Insert_Input>;
  on_conflict?: Maybe<Feedback_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Feedback_OneArgs = {
  object: Feedback_Insert_Input;
  on_conflict?: Maybe<Feedback_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FlagArgs = {
  objects: Array<Flag_Insert_Input>;
  on_conflict?: Maybe<Flag_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Flag_OneArgs = {
  object: Flag_Insert_Input;
  on_conflict?: Maybe<Flag_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_GenderArgs = {
  objects: Array<Gender_Insert_Input>;
  on_conflict?: Maybe<Gender_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Gender_OneArgs = {
  object: Gender_Insert_Input;
  on_conflict?: Maybe<Gender_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Info_TileArgs = {
  objects: Array<Info_Tile_Insert_Input>;
  on_conflict?: Maybe<Info_Tile_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Info_Tile_OneArgs = {
  object: Info_Tile_Insert_Input;
  on_conflict?: Maybe<Info_Tile_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Info_Tile_Status_TypeArgs = {
  objects: Array<Info_Tile_Status_Type_Insert_Input>;
  on_conflict?: Maybe<Info_Tile_Status_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Info_Tile_Status_Type_OneArgs = {
  object: Info_Tile_Status_Type_Insert_Input;
  on_conflict?: Maybe<Info_Tile_Status_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_KeywordArgs = {
  objects: Array<Keyword_Insert_Input>;
  on_conflict?: Maybe<Keyword_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Keyword_OneArgs = {
  object: Keyword_Insert_Input;
  on_conflict?: Maybe<Keyword_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Keyword_ReferralArgs = {
  objects: Array<Keyword_Referral_Insert_Input>;
  on_conflict?: Maybe<Keyword_Referral_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Keyword_Referral_OneArgs = {
  object: Keyword_Referral_Insert_Input;
  on_conflict?: Maybe<Keyword_Referral_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Keyword_TopicArgs = {
  objects: Array<Keyword_Topic_Insert_Input>;
  on_conflict?: Maybe<Keyword_Topic_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Keyword_Topic_OneArgs = {
  object: Keyword_Topic_Insert_Input;
  on_conflict?: Maybe<Keyword_Topic_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Moderator_ActionArgs = {
  objects: Array<Moderator_Action_Insert_Input>;
  on_conflict?: Maybe<Moderator_Action_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Moderator_Action_OneArgs = {
  object: Moderator_Action_Insert_Input;
  on_conflict?: Maybe<Moderator_Action_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Moderator_NoteArgs = {
  objects: Array<Moderator_Note_Insert_Input>;
  on_conflict?: Maybe<Moderator_Note_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Moderator_Note_OneArgs = {
  object: Moderator_Note_Insert_Input;
  on_conflict?: Maybe<Moderator_Note_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PromptArgs = {
  objects: Array<Prompt_Insert_Input>;
  on_conflict?: Maybe<Prompt_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Prompt_OneArgs = {
  object: Prompt_Insert_Input;
  on_conflict?: Maybe<Prompt_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ReactionArgs = {
  objects: Array<Reaction_Insert_Input>;
  on_conflict?: Maybe<Reaction_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reaction_OneArgs = {
  object: Reaction_Insert_Input;
  on_conflict?: Maybe<Reaction_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reaction_TypeArgs = {
  objects: Array<Reaction_Type_Insert_Input>;
  on_conflict?: Maybe<Reaction_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reaction_Type_OneArgs = {
  object: Reaction_Type_Insert_Input;
  on_conflict?: Maybe<Reaction_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ReferralArgs = {
  objects: Array<Referral_Insert_Input>;
  on_conflict?: Maybe<Referral_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Referral_OneArgs = {
  object: Referral_Insert_Input;
  on_conflict?: Maybe<Referral_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ReplyArgs = {
  objects: Array<Reply_Insert_Input>;
  on_conflict?: Maybe<Reply_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reply_OneArgs = {
  object: Reply_Insert_Input;
  on_conflict?: Maybe<Reply_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ResourceArgs = {
  objects: Array<Resource_Insert_Input>;
  on_conflict?: Maybe<Resource_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Resource_OneArgs = {
  object: Resource_Insert_Input;
  on_conflict?: Maybe<Resource_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Resource_TypeArgs = {
  objects: Array<Resource_Type_Insert_Input>;
  on_conflict?: Maybe<Resource_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Resource_Type_OneArgs = {
  object: Resource_Type_Insert_Input;
  on_conflict?: Maybe<Resource_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SentimentArgs = {
  objects: Array<Sentiment_Insert_Input>;
  on_conflict?: Maybe<Sentiment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Sentiment_OneArgs = {
  object: Sentiment_Insert_Input;
  on_conflict?: Maybe<Sentiment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ShareArgs = {
  objects: Array<Share_Insert_Input>;
  on_conflict?: Maybe<Share_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Share_OneArgs = {
  object: Share_Insert_Input;
  on_conflict?: Maybe<Share_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_StoryArgs = {
  objects: Array<Story_Insert_Input>;
  on_conflict?: Maybe<Story_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Story_OneArgs = {
  object: Story_Insert_Input;
  on_conflict?: Maybe<Story_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Story_SaveArgs = {
  objects: Array<Story_Save_Insert_Input>;
  on_conflict?: Maybe<Story_Save_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Story_Save_OneArgs = {
  object: Story_Save_Insert_Input;
  on_conflict?: Maybe<Story_Save_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Story_StatusArgs = {
  objects: Array<Story_Status_Insert_Input>;
  on_conflict?: Maybe<Story_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Story_Status_OneArgs = {
  object: Story_Status_Insert_Input;
  on_conflict?: Maybe<Story_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Story_TagArgs = {
  objects: Array<Story_Tag_Insert_Input>;
  on_conflict?: Maybe<Story_Tag_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Story_Tag_OneArgs = {
  object: Story_Tag_Insert_Input;
  on_conflict?: Maybe<Story_Tag_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Story_TypeArgs = {
  objects: Array<Story_Type_Insert_Input>;
  on_conflict?: Maybe<Story_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Story_Type_OneArgs = {
  object: Story_Type_Insert_Input;
  on_conflict?: Maybe<Story_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Story_ViolationArgs = {
  objects: Array<Story_Violation_Insert_Input>;
  on_conflict?: Maybe<Story_Violation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Story_Violation_OneArgs = {
  object: Story_Violation_Insert_Input;
  on_conflict?: Maybe<Story_Violation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SuburbArgs = {
  objects: Array<Suburb_Insert_Input>;
  on_conflict?: Maybe<Suburb_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Suburb_OneArgs = {
  object: Suburb_Insert_Input;
  on_conflict?: Maybe<Suburb_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Support_GroupArgs = {
  objects: Array<Support_Group_Insert_Input>;
  on_conflict?: Maybe<Support_Group_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Support_Group_OneArgs = {
  object: Support_Group_Insert_Input;
  on_conflict?: Maybe<Support_Group_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TagArgs = {
  objects: Array<Tag_Insert_Input>;
  on_conflict?: Maybe<Tag_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tag_OneArgs = {
  object: Tag_Insert_Input;
  on_conflict?: Maybe<Tag_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tag_TypeArgs = {
  objects: Array<Tag_Type_Insert_Input>;
  on_conflict?: Maybe<Tag_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tag_Type_OneArgs = {
  object: Tag_Type_Insert_Input;
  on_conflict?: Maybe<Tag_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TopicArgs = {
  objects: Array<Topic_Insert_Input>;
  on_conflict?: Maybe<Topic_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Topic_OneArgs = {
  object: Topic_Insert_Input;
  on_conflict?: Maybe<Topic_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserArgs = {
  objects: Array<User_Insert_Input>;
  on_conflict?: Maybe<User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_FlagArgs = {
  objects: Array<User_Flag_Insert_Input>;
  on_conflict?: Maybe<User_Flag_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Flag_OneArgs = {
  object: User_Flag_Insert_Input;
  on_conflict?: Maybe<User_Flag_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_OneArgs = {
  object: User_Insert_Input;
  on_conflict?: Maybe<User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_StatusArgs = {
  objects: Array<User_Status_Insert_Input>;
  on_conflict?: Maybe<User_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Status_OneArgs = {
  object: User_Status_Insert_Input;
  on_conflict?: Maybe<User_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Support_GroupArgs = {
  objects: Array<User_Support_Group_Insert_Input>;
  on_conflict?: Maybe<User_Support_Group_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Support_Group_OneArgs = {
  object: User_Support_Group_Insert_Input;
  on_conflict?: Maybe<User_Support_Group_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Violation_NoteArgs = {
  objects: Array<Violation_Note_Insert_Input>;
  on_conflict?: Maybe<Violation_Note_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Violation_Note_OneArgs = {
  object: Violation_Note_Insert_Input;
  on_conflict?: Maybe<Violation_Note_On_Conflict>;
};


/** mutation root */
export type Mutation_RootUpdate_Assessment_ItemArgs = {
  _inc?: Maybe<Assessment_Item_Inc_Input>;
  _set?: Maybe<Assessment_Item_Set_Input>;
  where: Assessment_Item_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Assessment_Item_By_PkArgs = {
  _inc?: Maybe<Assessment_Item_Inc_Input>;
  _set?: Maybe<Assessment_Item_Set_Input>;
  pk_columns: Assessment_Item_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_AudienceArgs = {
  _set?: Maybe<Audience_Set_Input>;
  where: Audience_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Audience_By_PkArgs = {
  _set?: Maybe<Audience_Set_Input>;
  pk_columns: Audience_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Account_ProvidersArgs = {
  _set?: Maybe<Auth_Account_Providers_Set_Input>;
  where: Auth_Account_Providers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Account_Providers_By_PkArgs = {
  _set?: Maybe<Auth_Account_Providers_Set_Input>;
  pk_columns: Auth_Account_Providers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Account_RolesArgs = {
  _set?: Maybe<Auth_Account_Roles_Set_Input>;
  where: Auth_Account_Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Account_Roles_By_PkArgs = {
  _set?: Maybe<Auth_Account_Roles_Set_Input>;
  pk_columns: Auth_Account_Roles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_AccountsArgs = {
  _append?: Maybe<Auth_Accounts_Append_Input>;
  _delete_at_path?: Maybe<Auth_Accounts_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Auth_Accounts_Delete_Elem_Input>;
  _delete_key?: Maybe<Auth_Accounts_Delete_Key_Input>;
  _prepend?: Maybe<Auth_Accounts_Prepend_Input>;
  _set?: Maybe<Auth_Accounts_Set_Input>;
  where: Auth_Accounts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Accounts_By_PkArgs = {
  _append?: Maybe<Auth_Accounts_Append_Input>;
  _delete_at_path?: Maybe<Auth_Accounts_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Auth_Accounts_Delete_Elem_Input>;
  _delete_key?: Maybe<Auth_Accounts_Delete_Key_Input>;
  _prepend?: Maybe<Auth_Accounts_Prepend_Input>;
  _set?: Maybe<Auth_Accounts_Set_Input>;
  pk_columns: Auth_Accounts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_ProvidersArgs = {
  _set?: Maybe<Auth_Providers_Set_Input>;
  where: Auth_Providers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Providers_By_PkArgs = {
  _set?: Maybe<Auth_Providers_Set_Input>;
  pk_columns: Auth_Providers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Refresh_TokensArgs = {
  _set?: Maybe<Auth_Refresh_Tokens_Set_Input>;
  where: Auth_Refresh_Tokens_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Refresh_Tokens_By_PkArgs = {
  _set?: Maybe<Auth_Refresh_Tokens_Set_Input>;
  pk_columns: Auth_Refresh_Tokens_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_RolesArgs = {
  _set?: Maybe<Auth_Roles_Set_Input>;
  where: Auth_Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Roles_By_PkArgs = {
  _set?: Maybe<Auth_Roles_Set_Input>;
  pk_columns: Auth_Roles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Employment_StatusArgs = {
  _set?: Maybe<Employment_Status_Set_Input>;
  where: Employment_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Employment_Status_By_PkArgs = {
  _set?: Maybe<Employment_Status_Set_Input>;
  pk_columns: Employment_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_FeedbackArgs = {
  _set?: Maybe<Feedback_Set_Input>;
  where: Feedback_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Feedback_By_PkArgs = {
  _set?: Maybe<Feedback_Set_Input>;
  pk_columns: Feedback_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_FlagArgs = {
  _set?: Maybe<Flag_Set_Input>;
  where: Flag_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Flag_By_PkArgs = {
  _set?: Maybe<Flag_Set_Input>;
  pk_columns: Flag_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_GenderArgs = {
  _set?: Maybe<Gender_Set_Input>;
  where: Gender_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Gender_By_PkArgs = {
  _set?: Maybe<Gender_Set_Input>;
  pk_columns: Gender_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Info_TileArgs = {
  _inc?: Maybe<Info_Tile_Inc_Input>;
  _set?: Maybe<Info_Tile_Set_Input>;
  where: Info_Tile_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Info_Tile_By_PkArgs = {
  _inc?: Maybe<Info_Tile_Inc_Input>;
  _set?: Maybe<Info_Tile_Set_Input>;
  pk_columns: Info_Tile_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Info_Tile_Status_TypeArgs = {
  _set?: Maybe<Info_Tile_Status_Type_Set_Input>;
  where: Info_Tile_Status_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Info_Tile_Status_Type_By_PkArgs = {
  _set?: Maybe<Info_Tile_Status_Type_Set_Input>;
  pk_columns: Info_Tile_Status_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_KeywordArgs = {
  _set?: Maybe<Keyword_Set_Input>;
  where: Keyword_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Keyword_By_PkArgs = {
  _set?: Maybe<Keyword_Set_Input>;
  pk_columns: Keyword_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Keyword_ReferralArgs = {
  _set?: Maybe<Keyword_Referral_Set_Input>;
  where: Keyword_Referral_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Keyword_Referral_By_PkArgs = {
  _set?: Maybe<Keyword_Referral_Set_Input>;
  pk_columns: Keyword_Referral_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Keyword_TopicArgs = {
  _set?: Maybe<Keyword_Topic_Set_Input>;
  where: Keyword_Topic_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Keyword_Topic_By_PkArgs = {
  _set?: Maybe<Keyword_Topic_Set_Input>;
  pk_columns: Keyword_Topic_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Moderator_ActionArgs = {
  _set?: Maybe<Moderator_Action_Set_Input>;
  where: Moderator_Action_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Moderator_Action_By_PkArgs = {
  _set?: Maybe<Moderator_Action_Set_Input>;
  pk_columns: Moderator_Action_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Moderator_NoteArgs = {
  _set?: Maybe<Moderator_Note_Set_Input>;
  where: Moderator_Note_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Moderator_Note_By_PkArgs = {
  _set?: Maybe<Moderator_Note_Set_Input>;
  pk_columns: Moderator_Note_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PromptArgs = {
  _inc?: Maybe<Prompt_Inc_Input>;
  _set?: Maybe<Prompt_Set_Input>;
  where: Prompt_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Prompt_By_PkArgs = {
  _inc?: Maybe<Prompt_Inc_Input>;
  _set?: Maybe<Prompt_Set_Input>;
  pk_columns: Prompt_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ReactionArgs = {
  _set?: Maybe<Reaction_Set_Input>;
  where: Reaction_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Reaction_By_PkArgs = {
  _set?: Maybe<Reaction_Set_Input>;
  pk_columns: Reaction_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Reaction_TypeArgs = {
  _set?: Maybe<Reaction_Type_Set_Input>;
  where: Reaction_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Reaction_Type_By_PkArgs = {
  _set?: Maybe<Reaction_Type_Set_Input>;
  pk_columns: Reaction_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ReferralArgs = {
  _set?: Maybe<Referral_Set_Input>;
  where: Referral_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Referral_By_PkArgs = {
  _set?: Maybe<Referral_Set_Input>;
  pk_columns: Referral_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ReplyArgs = {
  _set?: Maybe<Reply_Set_Input>;
  where: Reply_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Reply_By_PkArgs = {
  _set?: Maybe<Reply_Set_Input>;
  pk_columns: Reply_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ResourceArgs = {
  _set?: Maybe<Resource_Set_Input>;
  where: Resource_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Resource_By_PkArgs = {
  _set?: Maybe<Resource_Set_Input>;
  pk_columns: Resource_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Resource_TypeArgs = {
  _set?: Maybe<Resource_Type_Set_Input>;
  where: Resource_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Resource_Type_By_PkArgs = {
  _set?: Maybe<Resource_Type_Set_Input>;
  pk_columns: Resource_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SentimentArgs = {
  _set?: Maybe<Sentiment_Set_Input>;
  where: Sentiment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Sentiment_By_PkArgs = {
  _set?: Maybe<Sentiment_Set_Input>;
  pk_columns: Sentiment_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ShareArgs = {
  _set?: Maybe<Share_Set_Input>;
  where: Share_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Share_By_PkArgs = {
  _set?: Maybe<Share_Set_Input>;
  pk_columns: Share_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_StoryArgs = {
  _inc?: Maybe<Story_Inc_Input>;
  _set?: Maybe<Story_Set_Input>;
  where: Story_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Story_By_PkArgs = {
  _inc?: Maybe<Story_Inc_Input>;
  _set?: Maybe<Story_Set_Input>;
  pk_columns: Story_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Story_SaveArgs = {
  _set?: Maybe<Story_Save_Set_Input>;
  where: Story_Save_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Story_Save_By_PkArgs = {
  _set?: Maybe<Story_Save_Set_Input>;
  pk_columns: Story_Save_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Story_StatusArgs = {
  _set?: Maybe<Story_Status_Set_Input>;
  where: Story_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Story_Status_By_PkArgs = {
  _set?: Maybe<Story_Status_Set_Input>;
  pk_columns: Story_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Story_TagArgs = {
  _set?: Maybe<Story_Tag_Set_Input>;
  where: Story_Tag_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Story_Tag_By_PkArgs = {
  _set?: Maybe<Story_Tag_Set_Input>;
  pk_columns: Story_Tag_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Story_TypeArgs = {
  _set?: Maybe<Story_Type_Set_Input>;
  where: Story_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Story_Type_By_PkArgs = {
  _set?: Maybe<Story_Type_Set_Input>;
  pk_columns: Story_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Story_ViolationArgs = {
  _set?: Maybe<Story_Violation_Set_Input>;
  where: Story_Violation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Story_Violation_By_PkArgs = {
  _set?: Maybe<Story_Violation_Set_Input>;
  pk_columns: Story_Violation_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SuburbArgs = {
  _set?: Maybe<Suburb_Set_Input>;
  where: Suburb_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Suburb_By_PkArgs = {
  _set?: Maybe<Suburb_Set_Input>;
  pk_columns: Suburb_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Support_GroupArgs = {
  _set?: Maybe<Support_Group_Set_Input>;
  where: Support_Group_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Support_Group_By_PkArgs = {
  _set?: Maybe<Support_Group_Set_Input>;
  pk_columns: Support_Group_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_TagArgs = {
  _inc?: Maybe<Tag_Inc_Input>;
  _set?: Maybe<Tag_Set_Input>;
  where: Tag_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tag_By_PkArgs = {
  _inc?: Maybe<Tag_Inc_Input>;
  _set?: Maybe<Tag_Set_Input>;
  pk_columns: Tag_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tag_TypeArgs = {
  _set?: Maybe<Tag_Type_Set_Input>;
  where: Tag_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tag_Type_By_PkArgs = {
  _set?: Maybe<Tag_Type_Set_Input>;
  pk_columns: Tag_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_TopicArgs = {
  _set?: Maybe<Topic_Set_Input>;
  where: Topic_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Topic_By_PkArgs = {
  _set?: Maybe<Topic_Set_Input>;
  pk_columns: Topic_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UserArgs = {
  _inc?: Maybe<User_Inc_Input>;
  _set?: Maybe<User_Set_Input>;
  where: User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_By_PkArgs = {
  _inc?: Maybe<User_Inc_Input>;
  _set?: Maybe<User_Set_Input>;
  pk_columns: User_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_FlagArgs = {
  _set?: Maybe<User_Flag_Set_Input>;
  where: User_Flag_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Flag_By_PkArgs = {
  _set?: Maybe<User_Flag_Set_Input>;
  pk_columns: User_Flag_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_StatusArgs = {
  _set?: Maybe<User_Status_Set_Input>;
  where: User_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Status_By_PkArgs = {
  _set?: Maybe<User_Status_Set_Input>;
  pk_columns: User_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Support_GroupArgs = {
  _set?: Maybe<User_Support_Group_Set_Input>;
  where: User_Support_Group_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Support_Group_By_PkArgs = {
  _set?: Maybe<User_Support_Group_Set_Input>;
  pk_columns: User_Support_Group_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Violation_NoteArgs = {
  _set?: Maybe<Violation_Note_Set_Input>;
  where: Violation_Note_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Violation_Note_By_PkArgs = {
  _set?: Maybe<Violation_Note_Set_Input>;
  pk_columns: Violation_Note_Pk_Columns_Input;
};

/** column ordering options */
export enum Order_By {
  /** in the ascending order, nulls last */
  Asc = 'asc',
  /** in the ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in the ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in the descending order, nulls first */
  Desc = 'desc',
  /** in the descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in the descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "prompt" */
export type Prompt = {
  __typename?: 'prompt';
  created_at: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  position: Scalars['Int'];
  /** An array relationship */
  replies: Array<Reply>;
  /** An aggregated array relationship */
  replies_aggregate: Reply_Aggregate;
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "prompt" */
export type PromptRepliesArgs = {
  distinct_on?: Maybe<Array<Reply_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reply_Order_By>>;
  where?: Maybe<Reply_Bool_Exp>;
};


/** columns and relationships of "prompt" */
export type PromptReplies_AggregateArgs = {
  distinct_on?: Maybe<Array<Reply_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reply_Order_By>>;
  where?: Maybe<Reply_Bool_Exp>;
};

/** aggregated selection of "prompt" */
export type Prompt_Aggregate = {
  __typename?: 'prompt_aggregate';
  aggregate?: Maybe<Prompt_Aggregate_Fields>;
  nodes: Array<Prompt>;
};

/** aggregate fields of "prompt" */
export type Prompt_Aggregate_Fields = {
  __typename?: 'prompt_aggregate_fields';
  avg?: Maybe<Prompt_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Prompt_Max_Fields>;
  min?: Maybe<Prompt_Min_Fields>;
  stddev?: Maybe<Prompt_Stddev_Fields>;
  stddev_pop?: Maybe<Prompt_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Prompt_Stddev_Samp_Fields>;
  sum?: Maybe<Prompt_Sum_Fields>;
  var_pop?: Maybe<Prompt_Var_Pop_Fields>;
  var_samp?: Maybe<Prompt_Var_Samp_Fields>;
  variance?: Maybe<Prompt_Variance_Fields>;
};


/** aggregate fields of "prompt" */
export type Prompt_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Prompt_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "prompt" */
export type Prompt_Aggregate_Order_By = {
  avg?: Maybe<Prompt_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Prompt_Max_Order_By>;
  min?: Maybe<Prompt_Min_Order_By>;
  stddev?: Maybe<Prompt_Stddev_Order_By>;
  stddev_pop?: Maybe<Prompt_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Prompt_Stddev_Samp_Order_By>;
  sum?: Maybe<Prompt_Sum_Order_By>;
  var_pop?: Maybe<Prompt_Var_Pop_Order_By>;
  var_samp?: Maybe<Prompt_Var_Samp_Order_By>;
  variance?: Maybe<Prompt_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "prompt" */
export type Prompt_Arr_Rel_Insert_Input = {
  data: Array<Prompt_Insert_Input>;
  on_conflict?: Maybe<Prompt_On_Conflict>;
};

/** aggregate avg on columns */
export type Prompt_Avg_Fields = {
  __typename?: 'prompt_avg_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "prompt" */
export type Prompt_Avg_Order_By = {
  position?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "prompt". All fields are combined with a logical 'AND'. */
export type Prompt_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Prompt_Bool_Exp>>>;
  _not?: Maybe<Prompt_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Prompt_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  position?: Maybe<Int_Comparison_Exp>;
  replies?: Maybe<Reply_Bool_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "prompt" */
export enum Prompt_Constraint {
  /** unique or primary key constraint */
  PromptPkey = 'prompt_pkey'
}

/** input type for incrementing integer column in table "prompt" */
export type Prompt_Inc_Input = {
  position?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "prompt" */
export type Prompt_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  position?: Maybe<Scalars['Int']>;
  replies?: Maybe<Reply_Arr_Rel_Insert_Input>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Prompt_Max_Fields = {
  __typename?: 'prompt_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  position?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "prompt" */
export type Prompt_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Prompt_Min_Fields = {
  __typename?: 'prompt_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  position?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "prompt" */
export type Prompt_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "prompt" */
export type Prompt_Mutation_Response = {
  __typename?: 'prompt_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Prompt>;
};

/** input type for inserting object relation for remote table "prompt" */
export type Prompt_Obj_Rel_Insert_Input = {
  data: Prompt_Insert_Input;
  on_conflict?: Maybe<Prompt_On_Conflict>;
};

/** on conflict condition type for table "prompt" */
export type Prompt_On_Conflict = {
  constraint: Prompt_Constraint;
  update_columns: Array<Prompt_Update_Column>;
  where?: Maybe<Prompt_Bool_Exp>;
};

/** ordering options when selecting data from "prompt" */
export type Prompt_Order_By = {
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
  replies_aggregate?: Maybe<Reply_Aggregate_Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "prompt" */
export type Prompt_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "prompt" */
export enum Prompt_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Position = 'position',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "prompt" */
export type Prompt_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  position?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Prompt_Stddev_Fields = {
  __typename?: 'prompt_stddev_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "prompt" */
export type Prompt_Stddev_Order_By = {
  position?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Prompt_Stddev_Pop_Fields = {
  __typename?: 'prompt_stddev_pop_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "prompt" */
export type Prompt_Stddev_Pop_Order_By = {
  position?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Prompt_Stddev_Samp_Fields = {
  __typename?: 'prompt_stddev_samp_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "prompt" */
export type Prompt_Stddev_Samp_Order_By = {
  position?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Prompt_Sum_Fields = {
  __typename?: 'prompt_sum_fields';
  position?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "prompt" */
export type Prompt_Sum_Order_By = {
  position?: Maybe<Order_By>;
};

/** update columns of table "prompt" */
export enum Prompt_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Position = 'position',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Prompt_Var_Pop_Fields = {
  __typename?: 'prompt_var_pop_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "prompt" */
export type Prompt_Var_Pop_Order_By = {
  position?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Prompt_Var_Samp_Fields = {
  __typename?: 'prompt_var_samp_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "prompt" */
export type Prompt_Var_Samp_Order_By = {
  position?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Prompt_Variance_Fields = {
  __typename?: 'prompt_variance_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "prompt" */
export type Prompt_Variance_Order_By = {
  position?: Maybe<Order_By>;
};

/** query root */
export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "assessment_item" */
  assessment_item: Array<Assessment_Item>;
  /** fetch aggregated fields from the table: "assessment_item" */
  assessment_item_aggregate: Assessment_Item_Aggregate;
  /** fetch data from the table: "assessment_item" using primary key columns */
  assessment_item_by_pk?: Maybe<Assessment_Item>;
  /** fetch data from the table: "audience" */
  audience: Array<Audience>;
  /** fetch aggregated fields from the table: "audience" */
  audience_aggregate: Audience_Aggregate;
  /** fetch data from the table: "audience" using primary key columns */
  audience_by_pk?: Maybe<Audience>;
  /** fetch data from the table: "auth.account_providers" */
  auth_account_providers: Array<Auth_Account_Providers>;
  /** fetch aggregated fields from the table: "auth.account_providers" */
  auth_account_providers_aggregate: Auth_Account_Providers_Aggregate;
  /** fetch data from the table: "auth.account_providers" using primary key columns */
  auth_account_providers_by_pk?: Maybe<Auth_Account_Providers>;
  /** fetch data from the table: "auth.account_roles" */
  auth_account_roles: Array<Auth_Account_Roles>;
  /** fetch aggregated fields from the table: "auth.account_roles" */
  auth_account_roles_aggregate: Auth_Account_Roles_Aggregate;
  /** fetch data from the table: "auth.account_roles" using primary key columns */
  auth_account_roles_by_pk?: Maybe<Auth_Account_Roles>;
  /** fetch data from the table: "auth.accounts" */
  auth_accounts: Array<Auth_Accounts>;
  /** fetch aggregated fields from the table: "auth.accounts" */
  auth_accounts_aggregate: Auth_Accounts_Aggregate;
  /** fetch data from the table: "auth.accounts" using primary key columns */
  auth_accounts_by_pk?: Maybe<Auth_Accounts>;
  /** fetch data from the table: "auth.providers" */
  auth_providers: Array<Auth_Providers>;
  /** fetch aggregated fields from the table: "auth.providers" */
  auth_providers_aggregate: Auth_Providers_Aggregate;
  /** fetch data from the table: "auth.providers" using primary key columns */
  auth_providers_by_pk?: Maybe<Auth_Providers>;
  /** fetch data from the table: "auth.refresh_tokens" */
  auth_refresh_tokens: Array<Auth_Refresh_Tokens>;
  /** fetch aggregated fields from the table: "auth.refresh_tokens" */
  auth_refresh_tokens_aggregate: Auth_Refresh_Tokens_Aggregate;
  /** fetch data from the table: "auth.refresh_tokens" using primary key columns */
  auth_refresh_tokens_by_pk?: Maybe<Auth_Refresh_Tokens>;
  /** fetch data from the table: "auth.roles" */
  auth_roles: Array<Auth_Roles>;
  /** fetch aggregated fields from the table: "auth.roles" */
  auth_roles_aggregate: Auth_Roles_Aggregate;
  /** fetch data from the table: "auth.roles" using primary key columns */
  auth_roles_by_pk?: Maybe<Auth_Roles>;
  /** fetch data from the table: "employment_status" */
  employment_status: Array<Employment_Status>;
  /** fetch aggregated fields from the table: "employment_status" */
  employment_status_aggregate: Employment_Status_Aggregate;
  /** fetch data from the table: "employment_status" using primary key columns */
  employment_status_by_pk?: Maybe<Employment_Status>;
  /** fetch data from the table: "feedback" */
  feedback: Array<Feedback>;
  /** fetch aggregated fields from the table: "feedback" */
  feedback_aggregate: Feedback_Aggregate;
  /** fetch data from the table: "feedback" using primary key columns */
  feedback_by_pk?: Maybe<Feedback>;
  /** fetch data from the table: "flag" */
  flag: Array<Flag>;
  /** fetch aggregated fields from the table: "flag" */
  flag_aggregate: Flag_Aggregate;
  /** fetch data from the table: "flag" using primary key columns */
  flag_by_pk?: Maybe<Flag>;
  /** fetch data from the table: "gender" */
  gender: Array<Gender>;
  /** fetch aggregated fields from the table: "gender" */
  gender_aggregate: Gender_Aggregate;
  /** fetch data from the table: "gender" using primary key columns */
  gender_by_pk?: Maybe<Gender>;
  /** perform the action: "generateSignedUrl" */
  generateSignedUrl?: Maybe<SignedUrlOutput>;
  /** perform the action: "getInfoTileUploadUrl" */
  getInfoTileUploadUrl?: Maybe<InfoTileUrlOutput>;
  /** fetch data from the table: "info_tile" */
  info_tile: Array<Info_Tile>;
  /** fetch aggregated fields from the table: "info_tile" */
  info_tile_aggregate: Info_Tile_Aggregate;
  /** fetch data from the table: "info_tile" using primary key columns */
  info_tile_by_pk?: Maybe<Info_Tile>;
  /** fetch data from the table: "info_tile_status_type" */
  info_tile_status_type: Array<Info_Tile_Status_Type>;
  /** fetch aggregated fields from the table: "info_tile_status_type" */
  info_tile_status_type_aggregate: Info_Tile_Status_Type_Aggregate;
  /** fetch data from the table: "info_tile_status_type" using primary key columns */
  info_tile_status_type_by_pk?: Maybe<Info_Tile_Status_Type>;
  /** fetch data from the table: "keyword" */
  keyword: Array<Keyword>;
  /** fetch aggregated fields from the table: "keyword" */
  keyword_aggregate: Keyword_Aggregate;
  /** fetch data from the table: "keyword" using primary key columns */
  keyword_by_pk?: Maybe<Keyword>;
  /** fetch data from the table: "keyword_referral" */
  keyword_referral: Array<Keyword_Referral>;
  /** fetch aggregated fields from the table: "keyword_referral" */
  keyword_referral_aggregate: Keyword_Referral_Aggregate;
  /** fetch data from the table: "keyword_referral" using primary key columns */
  keyword_referral_by_pk?: Maybe<Keyword_Referral>;
  /** fetch data from the table: "keyword_topic" */
  keyword_topic: Array<Keyword_Topic>;
  /** fetch aggregated fields from the table: "keyword_topic" */
  keyword_topic_aggregate: Keyword_Topic_Aggregate;
  /** fetch data from the table: "keyword_topic" using primary key columns */
  keyword_topic_by_pk?: Maybe<Keyword_Topic>;
  /** fetch data from the table: "moderator_action" */
  moderator_action: Array<Moderator_Action>;
  /** fetch aggregated fields from the table: "moderator_action" */
  moderator_action_aggregate: Moderator_Action_Aggregate;
  /** fetch data from the table: "moderator_action" using primary key columns */
  moderator_action_by_pk?: Maybe<Moderator_Action>;
  /** fetch data from the table: "moderator_note" */
  moderator_note: Array<Moderator_Note>;
  /** fetch aggregated fields from the table: "moderator_note" */
  moderator_note_aggregate: Moderator_Note_Aggregate;
  /** fetch data from the table: "moderator_note" using primary key columns */
  moderator_note_by_pk?: Maybe<Moderator_Note>;
  /** fetch data from the table: "prompt" */
  prompt: Array<Prompt>;
  /** fetch aggregated fields from the table: "prompt" */
  prompt_aggregate: Prompt_Aggregate;
  /** fetch data from the table: "prompt" using primary key columns */
  prompt_by_pk?: Maybe<Prompt>;
  /** fetch data from the table: "reaction" */
  reaction: Array<Reaction>;
  /** fetch aggregated fields from the table: "reaction" */
  reaction_aggregate: Reaction_Aggregate;
  /** fetch data from the table: "reaction" using primary key columns */
  reaction_by_pk?: Maybe<Reaction>;
  /** fetch data from the table: "reaction_type" */
  reaction_type: Array<Reaction_Type>;
  /** fetch aggregated fields from the table: "reaction_type" */
  reaction_type_aggregate: Reaction_Type_Aggregate;
  /** fetch data from the table: "reaction_type" using primary key columns */
  reaction_type_by_pk?: Maybe<Reaction_Type>;
  /** fetch data from the table: "referral" */
  referral: Array<Referral>;
  /** fetch aggregated fields from the table: "referral" */
  referral_aggregate: Referral_Aggregate;
  /** fetch data from the table: "referral" using primary key columns */
  referral_by_pk?: Maybe<Referral>;
  /** fetch data from the table: "reply" */
  reply: Array<Reply>;
  /** fetch aggregated fields from the table: "reply" */
  reply_aggregate: Reply_Aggregate;
  /** fetch data from the table: "reply" using primary key columns */
  reply_by_pk?: Maybe<Reply>;
  /** fetch data from the table: "resource" */
  resource: Array<Resource>;
  /** fetch aggregated fields from the table: "resource" */
  resource_aggregate: Resource_Aggregate;
  /** fetch data from the table: "resource" using primary key columns */
  resource_by_pk?: Maybe<Resource>;
  /** fetch data from the table: "resource_type" */
  resource_type: Array<Resource_Type>;
  /** fetch aggregated fields from the table: "resource_type" */
  resource_type_aggregate: Resource_Type_Aggregate;
  /** fetch data from the table: "resource_type" using primary key columns */
  resource_type_by_pk?: Maybe<Resource_Type>;
  /** fetch data from the table: "sentiment" */
  sentiment: Array<Sentiment>;
  /** fetch aggregated fields from the table: "sentiment" */
  sentiment_aggregate: Sentiment_Aggregate;
  /** fetch data from the table: "sentiment" using primary key columns */
  sentiment_by_pk?: Maybe<Sentiment>;
  /** fetch data from the table: "share" */
  share: Array<Share>;
  /** fetch aggregated fields from the table: "share" */
  share_aggregate: Share_Aggregate;
  /** fetch data from the table: "share" using primary key columns */
  share_by_pk?: Maybe<Share>;
  /** fetch data from the table: "story" */
  story: Array<Story>;
  /** fetch aggregated fields from the table: "story" */
  story_aggregate: Story_Aggregate;
  /** fetch data from the table: "story" using primary key columns */
  story_by_pk?: Maybe<Story>;
  /** fetch data from the table: "story_save" */
  story_save: Array<Story_Save>;
  /** fetch aggregated fields from the table: "story_save" */
  story_save_aggregate: Story_Save_Aggregate;
  /** fetch data from the table: "story_save" using primary key columns */
  story_save_by_pk?: Maybe<Story_Save>;
  /** fetch data from the table: "story_status" */
  story_status: Array<Story_Status>;
  /** fetch aggregated fields from the table: "story_status" */
  story_status_aggregate: Story_Status_Aggregate;
  /** fetch data from the table: "story_status" using primary key columns */
  story_status_by_pk?: Maybe<Story_Status>;
  /** fetch data from the table: "story_tag" */
  story_tag: Array<Story_Tag>;
  /** fetch aggregated fields from the table: "story_tag" */
  story_tag_aggregate: Story_Tag_Aggregate;
  /** fetch data from the table: "story_tag" using primary key columns */
  story_tag_by_pk?: Maybe<Story_Tag>;
  /** fetch data from the table: "story_type" */
  story_type: Array<Story_Type>;
  /** fetch aggregated fields from the table: "story_type" */
  story_type_aggregate: Story_Type_Aggregate;
  /** fetch data from the table: "story_type" using primary key columns */
  story_type_by_pk?: Maybe<Story_Type>;
  /** fetch data from the table: "story_violation" */
  story_violation: Array<Story_Violation>;
  /** fetch aggregated fields from the table: "story_violation" */
  story_violation_aggregate: Story_Violation_Aggregate;
  /** fetch data from the table: "story_violation" using primary key columns */
  story_violation_by_pk?: Maybe<Story_Violation>;
  /** fetch data from the table: "suburb" */
  suburb: Array<Suburb>;
  /** fetch aggregated fields from the table: "suburb" */
  suburb_aggregate: Suburb_Aggregate;
  /** fetch data from the table: "suburb" using primary key columns */
  suburb_by_pk?: Maybe<Suburb>;
  /** fetch data from the table: "support_group" */
  support_group: Array<Support_Group>;
  /** fetch aggregated fields from the table: "support_group" */
  support_group_aggregate: Support_Group_Aggregate;
  /** fetch data from the table: "support_group" using primary key columns */
  support_group_by_pk?: Maybe<Support_Group>;
  /** fetch data from the table: "tag" */
  tag: Array<Tag>;
  /** fetch aggregated fields from the table: "tag" */
  tag_aggregate: Tag_Aggregate;
  /** fetch data from the table: "tag" using primary key columns */
  tag_by_pk?: Maybe<Tag>;
  /** fetch data from the table: "tag_type" */
  tag_type: Array<Tag_Type>;
  /** fetch aggregated fields from the table: "tag_type" */
  tag_type_aggregate: Tag_Type_Aggregate;
  /** fetch data from the table: "tag_type" using primary key columns */
  tag_type_by_pk?: Maybe<Tag_Type>;
  /** fetch data from the table: "topic" */
  topic: Array<Topic>;
  /** fetch aggregated fields from the table: "topic" */
  topic_aggregate: Topic_Aggregate;
  /** fetch data from the table: "topic" using primary key columns */
  topic_by_pk?: Maybe<Topic>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
  /** fetch data from the table: "user_flag" */
  user_flag: Array<User_Flag>;
  /** fetch aggregated fields from the table: "user_flag" */
  user_flag_aggregate: User_Flag_Aggregate;
  /** fetch data from the table: "user_flag" using primary key columns */
  user_flag_by_pk?: Maybe<User_Flag>;
  /** fetch data from the table: "user_status" */
  user_status: Array<User_Status>;
  /** fetch aggregated fields from the table: "user_status" */
  user_status_aggregate: User_Status_Aggregate;
  /** fetch data from the table: "user_status" using primary key columns */
  user_status_by_pk?: Maybe<User_Status>;
  /** fetch data from the table: "user_support_group" */
  user_support_group: Array<User_Support_Group>;
  /** fetch aggregated fields from the table: "user_support_group" */
  user_support_group_aggregate: User_Support_Group_Aggregate;
  /** fetch data from the table: "user_support_group" using primary key columns */
  user_support_group_by_pk?: Maybe<User_Support_Group>;
  /** fetch data from the table: "violation_note" */
  violation_note: Array<Violation_Note>;
  /** fetch aggregated fields from the table: "violation_note" */
  violation_note_aggregate: Violation_Note_Aggregate;
  /** fetch data from the table: "violation_note" using primary key columns */
  violation_note_by_pk?: Maybe<Violation_Note>;
};


/** query root */
export type Query_RootAssessment_ItemArgs = {
  distinct_on?: Maybe<Array<Assessment_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Assessment_Item_Order_By>>;
  where?: Maybe<Assessment_Item_Bool_Exp>;
};


/** query root */
export type Query_RootAssessment_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Assessment_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Assessment_Item_Order_By>>;
  where?: Maybe<Assessment_Item_Bool_Exp>;
};


/** query root */
export type Query_RootAssessment_Item_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootAudienceArgs = {
  distinct_on?: Maybe<Array<Audience_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audience_Order_By>>;
  where?: Maybe<Audience_Bool_Exp>;
};


/** query root */
export type Query_RootAudience_AggregateArgs = {
  distinct_on?: Maybe<Array<Audience_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audience_Order_By>>;
  where?: Maybe<Audience_Bool_Exp>;
};


/** query root */
export type Query_RootAudience_By_PkArgs = {
  value: Scalars['String'];
};


/** query root */
export type Query_RootAuth_Account_ProvidersArgs = {
  distinct_on?: Maybe<Array<Auth_Account_Providers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Account_Providers_Order_By>>;
  where?: Maybe<Auth_Account_Providers_Bool_Exp>;
};


/** query root */
export type Query_RootAuth_Account_Providers_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Account_Providers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Account_Providers_Order_By>>;
  where?: Maybe<Auth_Account_Providers_Bool_Exp>;
};


/** query root */
export type Query_RootAuth_Account_Providers_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootAuth_Account_RolesArgs = {
  distinct_on?: Maybe<Array<Auth_Account_Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Account_Roles_Order_By>>;
  where?: Maybe<Auth_Account_Roles_Bool_Exp>;
};


/** query root */
export type Query_RootAuth_Account_Roles_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Account_Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Account_Roles_Order_By>>;
  where?: Maybe<Auth_Account_Roles_Bool_Exp>;
};


/** query root */
export type Query_RootAuth_Account_Roles_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootAuth_AccountsArgs = {
  distinct_on?: Maybe<Array<Auth_Accounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Accounts_Order_By>>;
  where?: Maybe<Auth_Accounts_Bool_Exp>;
};


/** query root */
export type Query_RootAuth_Accounts_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Accounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Accounts_Order_By>>;
  where?: Maybe<Auth_Accounts_Bool_Exp>;
};


/** query root */
export type Query_RootAuth_Accounts_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootAuth_ProvidersArgs = {
  distinct_on?: Maybe<Array<Auth_Providers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Providers_Order_By>>;
  where?: Maybe<Auth_Providers_Bool_Exp>;
};


/** query root */
export type Query_RootAuth_Providers_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Providers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Providers_Order_By>>;
  where?: Maybe<Auth_Providers_Bool_Exp>;
};


/** query root */
export type Query_RootAuth_Providers_By_PkArgs = {
  provider: Scalars['String'];
};


/** query root */
export type Query_RootAuth_Refresh_TokensArgs = {
  distinct_on?: Maybe<Array<Auth_Refresh_Tokens_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Refresh_Tokens_Order_By>>;
  where?: Maybe<Auth_Refresh_Tokens_Bool_Exp>;
};


/** query root */
export type Query_RootAuth_Refresh_Tokens_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Refresh_Tokens_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Refresh_Tokens_Order_By>>;
  where?: Maybe<Auth_Refresh_Tokens_Bool_Exp>;
};


/** query root */
export type Query_RootAuth_Refresh_Tokens_By_PkArgs = {
  refresh_token: Scalars['uuid'];
};


/** query root */
export type Query_RootAuth_RolesArgs = {
  distinct_on?: Maybe<Array<Auth_Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Roles_Order_By>>;
  where?: Maybe<Auth_Roles_Bool_Exp>;
};


/** query root */
export type Query_RootAuth_Roles_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Roles_Order_By>>;
  where?: Maybe<Auth_Roles_Bool_Exp>;
};


/** query root */
export type Query_RootAuth_Roles_By_PkArgs = {
  role: Scalars['String'];
};


/** query root */
export type Query_RootEmployment_StatusArgs = {
  distinct_on?: Maybe<Array<Employment_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Employment_Status_Order_By>>;
  where?: Maybe<Employment_Status_Bool_Exp>;
};


/** query root */
export type Query_RootEmployment_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Employment_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Employment_Status_Order_By>>;
  where?: Maybe<Employment_Status_Bool_Exp>;
};


/** query root */
export type Query_RootEmployment_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** query root */
export type Query_RootFeedbackArgs = {
  distinct_on?: Maybe<Array<Feedback_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Feedback_Order_By>>;
  where?: Maybe<Feedback_Bool_Exp>;
};


/** query root */
export type Query_RootFeedback_AggregateArgs = {
  distinct_on?: Maybe<Array<Feedback_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Feedback_Order_By>>;
  where?: Maybe<Feedback_Bool_Exp>;
};


/** query root */
export type Query_RootFeedback_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootFlagArgs = {
  distinct_on?: Maybe<Array<Flag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Flag_Order_By>>;
  where?: Maybe<Flag_Bool_Exp>;
};


/** query root */
export type Query_RootFlag_AggregateArgs = {
  distinct_on?: Maybe<Array<Flag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Flag_Order_By>>;
  where?: Maybe<Flag_Bool_Exp>;
};


/** query root */
export type Query_RootFlag_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootGenderArgs = {
  distinct_on?: Maybe<Array<Gender_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gender_Order_By>>;
  where?: Maybe<Gender_Bool_Exp>;
};


/** query root */
export type Query_RootGender_AggregateArgs = {
  distinct_on?: Maybe<Array<Gender_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gender_Order_By>>;
  where?: Maybe<Gender_Bool_Exp>;
};


/** query root */
export type Query_RootGender_By_PkArgs = {
  value: Scalars['String'];
};


/** query root */
export type Query_RootGenerateSignedUrlArgs = {
  resourceType: Scalars['String'];
};


/** query root */
export type Query_RootInfo_TileArgs = {
  distinct_on?: Maybe<Array<Info_Tile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Info_Tile_Order_By>>;
  where?: Maybe<Info_Tile_Bool_Exp>;
};


/** query root */
export type Query_RootInfo_Tile_AggregateArgs = {
  distinct_on?: Maybe<Array<Info_Tile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Info_Tile_Order_By>>;
  where?: Maybe<Info_Tile_Bool_Exp>;
};


/** query root */
export type Query_RootInfo_Tile_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootInfo_Tile_Status_TypeArgs = {
  distinct_on?: Maybe<Array<Info_Tile_Status_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Info_Tile_Status_Type_Order_By>>;
  where?: Maybe<Info_Tile_Status_Type_Bool_Exp>;
};


/** query root */
export type Query_RootInfo_Tile_Status_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Info_Tile_Status_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Info_Tile_Status_Type_Order_By>>;
  where?: Maybe<Info_Tile_Status_Type_Bool_Exp>;
};


/** query root */
export type Query_RootInfo_Tile_Status_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** query root */
export type Query_RootKeywordArgs = {
  distinct_on?: Maybe<Array<Keyword_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Keyword_Order_By>>;
  where?: Maybe<Keyword_Bool_Exp>;
};


/** query root */
export type Query_RootKeyword_AggregateArgs = {
  distinct_on?: Maybe<Array<Keyword_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Keyword_Order_By>>;
  where?: Maybe<Keyword_Bool_Exp>;
};


/** query root */
export type Query_RootKeyword_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootKeyword_ReferralArgs = {
  distinct_on?: Maybe<Array<Keyword_Referral_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Keyword_Referral_Order_By>>;
  where?: Maybe<Keyword_Referral_Bool_Exp>;
};


/** query root */
export type Query_RootKeyword_Referral_AggregateArgs = {
  distinct_on?: Maybe<Array<Keyword_Referral_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Keyword_Referral_Order_By>>;
  where?: Maybe<Keyword_Referral_Bool_Exp>;
};


/** query root */
export type Query_RootKeyword_Referral_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootKeyword_TopicArgs = {
  distinct_on?: Maybe<Array<Keyword_Topic_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Keyword_Topic_Order_By>>;
  where?: Maybe<Keyword_Topic_Bool_Exp>;
};


/** query root */
export type Query_RootKeyword_Topic_AggregateArgs = {
  distinct_on?: Maybe<Array<Keyword_Topic_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Keyword_Topic_Order_By>>;
  where?: Maybe<Keyword_Topic_Bool_Exp>;
};


/** query root */
export type Query_RootKeyword_Topic_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootModerator_ActionArgs = {
  distinct_on?: Maybe<Array<Moderator_Action_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Moderator_Action_Order_By>>;
  where?: Maybe<Moderator_Action_Bool_Exp>;
};


/** query root */
export type Query_RootModerator_Action_AggregateArgs = {
  distinct_on?: Maybe<Array<Moderator_Action_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Moderator_Action_Order_By>>;
  where?: Maybe<Moderator_Action_Bool_Exp>;
};


/** query root */
export type Query_RootModerator_Action_By_PkArgs = {
  value: Scalars['String'];
};


/** query root */
export type Query_RootModerator_NoteArgs = {
  distinct_on?: Maybe<Array<Moderator_Note_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Moderator_Note_Order_By>>;
  where?: Maybe<Moderator_Note_Bool_Exp>;
};


/** query root */
export type Query_RootModerator_Note_AggregateArgs = {
  distinct_on?: Maybe<Array<Moderator_Note_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Moderator_Note_Order_By>>;
  where?: Maybe<Moderator_Note_Bool_Exp>;
};


/** query root */
export type Query_RootModerator_Note_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootPromptArgs = {
  distinct_on?: Maybe<Array<Prompt_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Prompt_Order_By>>;
  where?: Maybe<Prompt_Bool_Exp>;
};


/** query root */
export type Query_RootPrompt_AggregateArgs = {
  distinct_on?: Maybe<Array<Prompt_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Prompt_Order_By>>;
  where?: Maybe<Prompt_Bool_Exp>;
};


/** query root */
export type Query_RootPrompt_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootReactionArgs = {
  distinct_on?: Maybe<Array<Reaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reaction_Order_By>>;
  where?: Maybe<Reaction_Bool_Exp>;
};


/** query root */
export type Query_RootReaction_AggregateArgs = {
  distinct_on?: Maybe<Array<Reaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reaction_Order_By>>;
  where?: Maybe<Reaction_Bool_Exp>;
};


/** query root */
export type Query_RootReaction_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootReaction_TypeArgs = {
  distinct_on?: Maybe<Array<Reaction_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reaction_Type_Order_By>>;
  where?: Maybe<Reaction_Type_Bool_Exp>;
};


/** query root */
export type Query_RootReaction_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Reaction_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reaction_Type_Order_By>>;
  where?: Maybe<Reaction_Type_Bool_Exp>;
};


/** query root */
export type Query_RootReaction_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** query root */
export type Query_RootReferralArgs = {
  distinct_on?: Maybe<Array<Referral_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Referral_Order_By>>;
  where?: Maybe<Referral_Bool_Exp>;
};


/** query root */
export type Query_RootReferral_AggregateArgs = {
  distinct_on?: Maybe<Array<Referral_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Referral_Order_By>>;
  where?: Maybe<Referral_Bool_Exp>;
};


/** query root */
export type Query_RootReferral_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootReplyArgs = {
  distinct_on?: Maybe<Array<Reply_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reply_Order_By>>;
  where?: Maybe<Reply_Bool_Exp>;
};


/** query root */
export type Query_RootReply_AggregateArgs = {
  distinct_on?: Maybe<Array<Reply_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reply_Order_By>>;
  where?: Maybe<Reply_Bool_Exp>;
};


/** query root */
export type Query_RootReply_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootResourceArgs = {
  distinct_on?: Maybe<Array<Resource_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Resource_Order_By>>;
  where?: Maybe<Resource_Bool_Exp>;
};


/** query root */
export type Query_RootResource_AggregateArgs = {
  distinct_on?: Maybe<Array<Resource_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Resource_Order_By>>;
  where?: Maybe<Resource_Bool_Exp>;
};


/** query root */
export type Query_RootResource_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootResource_TypeArgs = {
  distinct_on?: Maybe<Array<Resource_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Resource_Type_Order_By>>;
  where?: Maybe<Resource_Type_Bool_Exp>;
};


/** query root */
export type Query_RootResource_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Resource_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Resource_Type_Order_By>>;
  where?: Maybe<Resource_Type_Bool_Exp>;
};


/** query root */
export type Query_RootResource_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** query root */
export type Query_RootSentimentArgs = {
  distinct_on?: Maybe<Array<Sentiment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sentiment_Order_By>>;
  where?: Maybe<Sentiment_Bool_Exp>;
};


/** query root */
export type Query_RootSentiment_AggregateArgs = {
  distinct_on?: Maybe<Array<Sentiment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sentiment_Order_By>>;
  where?: Maybe<Sentiment_Bool_Exp>;
};


/** query root */
export type Query_RootSentiment_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootShareArgs = {
  distinct_on?: Maybe<Array<Share_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Share_Order_By>>;
  where?: Maybe<Share_Bool_Exp>;
};


/** query root */
export type Query_RootShare_AggregateArgs = {
  distinct_on?: Maybe<Array<Share_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Share_Order_By>>;
  where?: Maybe<Share_Bool_Exp>;
};


/** query root */
export type Query_RootShare_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootStoryArgs = {
  distinct_on?: Maybe<Array<Story_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Story_Order_By>>;
  where?: Maybe<Story_Bool_Exp>;
};


/** query root */
export type Query_RootStory_AggregateArgs = {
  distinct_on?: Maybe<Array<Story_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Story_Order_By>>;
  where?: Maybe<Story_Bool_Exp>;
};


/** query root */
export type Query_RootStory_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootStory_SaveArgs = {
  distinct_on?: Maybe<Array<Story_Save_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Story_Save_Order_By>>;
  where?: Maybe<Story_Save_Bool_Exp>;
};


/** query root */
export type Query_RootStory_Save_AggregateArgs = {
  distinct_on?: Maybe<Array<Story_Save_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Story_Save_Order_By>>;
  where?: Maybe<Story_Save_Bool_Exp>;
};


/** query root */
export type Query_RootStory_Save_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootStory_StatusArgs = {
  distinct_on?: Maybe<Array<Story_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Story_Status_Order_By>>;
  where?: Maybe<Story_Status_Bool_Exp>;
};


/** query root */
export type Query_RootStory_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Story_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Story_Status_Order_By>>;
  where?: Maybe<Story_Status_Bool_Exp>;
};


/** query root */
export type Query_RootStory_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** query root */
export type Query_RootStory_TagArgs = {
  distinct_on?: Maybe<Array<Story_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Story_Tag_Order_By>>;
  where?: Maybe<Story_Tag_Bool_Exp>;
};


/** query root */
export type Query_RootStory_Tag_AggregateArgs = {
  distinct_on?: Maybe<Array<Story_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Story_Tag_Order_By>>;
  where?: Maybe<Story_Tag_Bool_Exp>;
};


/** query root */
export type Query_RootStory_Tag_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootStory_TypeArgs = {
  distinct_on?: Maybe<Array<Story_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Story_Type_Order_By>>;
  where?: Maybe<Story_Type_Bool_Exp>;
};


/** query root */
export type Query_RootStory_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Story_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Story_Type_Order_By>>;
  where?: Maybe<Story_Type_Bool_Exp>;
};


/** query root */
export type Query_RootStory_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** query root */
export type Query_RootStory_ViolationArgs = {
  distinct_on?: Maybe<Array<Story_Violation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Story_Violation_Order_By>>;
  where?: Maybe<Story_Violation_Bool_Exp>;
};


/** query root */
export type Query_RootStory_Violation_AggregateArgs = {
  distinct_on?: Maybe<Array<Story_Violation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Story_Violation_Order_By>>;
  where?: Maybe<Story_Violation_Bool_Exp>;
};


/** query root */
export type Query_RootStory_Violation_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootSuburbArgs = {
  distinct_on?: Maybe<Array<Suburb_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Suburb_Order_By>>;
  where?: Maybe<Suburb_Bool_Exp>;
};


/** query root */
export type Query_RootSuburb_AggregateArgs = {
  distinct_on?: Maybe<Array<Suburb_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Suburb_Order_By>>;
  where?: Maybe<Suburb_Bool_Exp>;
};


/** query root */
export type Query_RootSuburb_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootSupport_GroupArgs = {
  distinct_on?: Maybe<Array<Support_Group_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Support_Group_Order_By>>;
  where?: Maybe<Support_Group_Bool_Exp>;
};


/** query root */
export type Query_RootSupport_Group_AggregateArgs = {
  distinct_on?: Maybe<Array<Support_Group_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Support_Group_Order_By>>;
  where?: Maybe<Support_Group_Bool_Exp>;
};


/** query root */
export type Query_RootSupport_Group_By_PkArgs = {
  value: Scalars['String'];
};


/** query root */
export type Query_RootTagArgs = {
  distinct_on?: Maybe<Array<Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tag_Order_By>>;
  where?: Maybe<Tag_Bool_Exp>;
};


/** query root */
export type Query_RootTag_AggregateArgs = {
  distinct_on?: Maybe<Array<Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tag_Order_By>>;
  where?: Maybe<Tag_Bool_Exp>;
};


/** query root */
export type Query_RootTag_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootTag_TypeArgs = {
  distinct_on?: Maybe<Array<Tag_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tag_Type_Order_By>>;
  where?: Maybe<Tag_Type_Bool_Exp>;
};


/** query root */
export type Query_RootTag_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Tag_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tag_Type_Order_By>>;
  where?: Maybe<Tag_Type_Bool_Exp>;
};


/** query root */
export type Query_RootTag_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** query root */
export type Query_RootTopicArgs = {
  distinct_on?: Maybe<Array<Topic_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Topic_Order_By>>;
  where?: Maybe<Topic_Bool_Exp>;
};


/** query root */
export type Query_RootTopic_AggregateArgs = {
  distinct_on?: Maybe<Array<Topic_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Topic_Order_By>>;
  where?: Maybe<Topic_Bool_Exp>;
};


/** query root */
export type Query_RootTopic_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootUserArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


/** query root */
export type Query_RootUser_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


/** query root */
export type Query_RootUser_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootUser_FlagArgs = {
  distinct_on?: Maybe<Array<User_Flag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Flag_Order_By>>;
  where?: Maybe<User_Flag_Bool_Exp>;
};


/** query root */
export type Query_RootUser_Flag_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Flag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Flag_Order_By>>;
  where?: Maybe<User_Flag_Bool_Exp>;
};


/** query root */
export type Query_RootUser_Flag_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootUser_StatusArgs = {
  distinct_on?: Maybe<Array<User_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Status_Order_By>>;
  where?: Maybe<User_Status_Bool_Exp>;
};


/** query root */
export type Query_RootUser_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Status_Order_By>>;
  where?: Maybe<User_Status_Bool_Exp>;
};


/** query root */
export type Query_RootUser_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** query root */
export type Query_RootUser_Support_GroupArgs = {
  distinct_on?: Maybe<Array<User_Support_Group_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Support_Group_Order_By>>;
  where?: Maybe<User_Support_Group_Bool_Exp>;
};


/** query root */
export type Query_RootUser_Support_Group_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Support_Group_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Support_Group_Order_By>>;
  where?: Maybe<User_Support_Group_Bool_Exp>;
};


/** query root */
export type Query_RootUser_Support_Group_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootViolation_NoteArgs = {
  distinct_on?: Maybe<Array<Violation_Note_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Violation_Note_Order_By>>;
  where?: Maybe<Violation_Note_Bool_Exp>;
};


/** query root */
export type Query_RootViolation_Note_AggregateArgs = {
  distinct_on?: Maybe<Array<Violation_Note_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Violation_Note_Order_By>>;
  where?: Maybe<Violation_Note_Bool_Exp>;
};


/** query root */
export type Query_RootViolation_Note_By_PkArgs = {
  id: Scalars['uuid'];
};

/** columns and relationships of "reaction" */
export type Reaction = {
  __typename?: 'reaction';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  reaction_type: Scalars['String'];
  /** An object relationship */
  story: Story;
  story_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: User;
  user_id: Scalars['uuid'];
  viewed_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "reaction" */
export type Reaction_Aggregate = {
  __typename?: 'reaction_aggregate';
  aggregate?: Maybe<Reaction_Aggregate_Fields>;
  nodes: Array<Reaction>;
};

/** aggregate fields of "reaction" */
export type Reaction_Aggregate_Fields = {
  __typename?: 'reaction_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Reaction_Max_Fields>;
  min?: Maybe<Reaction_Min_Fields>;
};


/** aggregate fields of "reaction" */
export type Reaction_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Reaction_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "reaction" */
export type Reaction_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Reaction_Max_Order_By>;
  min?: Maybe<Reaction_Min_Order_By>;
};

/** input type for inserting array relation for remote table "reaction" */
export type Reaction_Arr_Rel_Insert_Input = {
  data: Array<Reaction_Insert_Input>;
  on_conflict?: Maybe<Reaction_On_Conflict>;
};

/** Boolean expression to filter rows from the table "reaction". All fields are combined with a logical 'AND'. */
export type Reaction_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Reaction_Bool_Exp>>>;
  _not?: Maybe<Reaction_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Reaction_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  reaction_type?: Maybe<String_Comparison_Exp>;
  story?: Maybe<Story_Bool_Exp>;
  story_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<User_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
  viewed_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "reaction" */
export enum Reaction_Constraint {
  /** unique or primary key constraint */
  ReactionPkey = 'reaction_pkey'
}

/** input type for inserting data into table "reaction" */
export type Reaction_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  reaction_type?: Maybe<Scalars['String']>;
  story?: Maybe<Story_Obj_Rel_Insert_Input>;
  story_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<User_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
  viewed_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Reaction_Max_Fields = {
  __typename?: 'reaction_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  reaction_type?: Maybe<Scalars['String']>;
  story_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  viewed_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "reaction" */
export type Reaction_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  reaction_type?: Maybe<Order_By>;
  story_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  viewed_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Reaction_Min_Fields = {
  __typename?: 'reaction_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  reaction_type?: Maybe<Scalars['String']>;
  story_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  viewed_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "reaction" */
export type Reaction_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  reaction_type?: Maybe<Order_By>;
  story_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  viewed_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "reaction" */
export type Reaction_Mutation_Response = {
  __typename?: 'reaction_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Reaction>;
};

/** input type for inserting object relation for remote table "reaction" */
export type Reaction_Obj_Rel_Insert_Input = {
  data: Reaction_Insert_Input;
  on_conflict?: Maybe<Reaction_On_Conflict>;
};

/** on conflict condition type for table "reaction" */
export type Reaction_On_Conflict = {
  constraint: Reaction_Constraint;
  update_columns: Array<Reaction_Update_Column>;
  where?: Maybe<Reaction_Bool_Exp>;
};

/** ordering options when selecting data from "reaction" */
export type Reaction_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  reaction_type?: Maybe<Order_By>;
  story?: Maybe<Story_Order_By>;
  story_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<User_Order_By>;
  user_id?: Maybe<Order_By>;
  viewed_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "reaction" */
export type Reaction_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "reaction" */
export enum Reaction_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ReactionType = 'reaction_type',
  /** column name */
  StoryId = 'story_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  ViewedAt = 'viewed_at'
}

/** input type for updating data in table "reaction" */
export type Reaction_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  reaction_type?: Maybe<Scalars['String']>;
  story_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  viewed_at?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "reaction_type" */
export type Reaction_Type = {
  __typename?: 'reaction_type';
  value: Scalars['String'];
};

/** aggregated selection of "reaction_type" */
export type Reaction_Type_Aggregate = {
  __typename?: 'reaction_type_aggregate';
  aggregate?: Maybe<Reaction_Type_Aggregate_Fields>;
  nodes: Array<Reaction_Type>;
};

/** aggregate fields of "reaction_type" */
export type Reaction_Type_Aggregate_Fields = {
  __typename?: 'reaction_type_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Reaction_Type_Max_Fields>;
  min?: Maybe<Reaction_Type_Min_Fields>;
};


/** aggregate fields of "reaction_type" */
export type Reaction_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Reaction_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "reaction_type" */
export type Reaction_Type_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Reaction_Type_Max_Order_By>;
  min?: Maybe<Reaction_Type_Min_Order_By>;
};

/** input type for inserting array relation for remote table "reaction_type" */
export type Reaction_Type_Arr_Rel_Insert_Input = {
  data: Array<Reaction_Type_Insert_Input>;
  on_conflict?: Maybe<Reaction_Type_On_Conflict>;
};

/** Boolean expression to filter rows from the table "reaction_type". All fields are combined with a logical 'AND'. */
export type Reaction_Type_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Reaction_Type_Bool_Exp>>>;
  _not?: Maybe<Reaction_Type_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Reaction_Type_Bool_Exp>>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "reaction_type" */
export enum Reaction_Type_Constraint {
  /** unique or primary key constraint */
  ReactionTypePkey = 'reaction_type_pkey'
}

/** input type for inserting data into table "reaction_type" */
export type Reaction_Type_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Reaction_Type_Max_Fields = {
  __typename?: 'reaction_type_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "reaction_type" */
export type Reaction_Type_Max_Order_By = {
  value?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Reaction_Type_Min_Fields = {
  __typename?: 'reaction_type_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "reaction_type" */
export type Reaction_Type_Min_Order_By = {
  value?: Maybe<Order_By>;
};

/** response of any mutation on the table "reaction_type" */
export type Reaction_Type_Mutation_Response = {
  __typename?: 'reaction_type_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Reaction_Type>;
};

/** input type for inserting object relation for remote table "reaction_type" */
export type Reaction_Type_Obj_Rel_Insert_Input = {
  data: Reaction_Type_Insert_Input;
  on_conflict?: Maybe<Reaction_Type_On_Conflict>;
};

/** on conflict condition type for table "reaction_type" */
export type Reaction_Type_On_Conflict = {
  constraint: Reaction_Type_Constraint;
  update_columns: Array<Reaction_Type_Update_Column>;
  where?: Maybe<Reaction_Type_Bool_Exp>;
};

/** ordering options when selecting data from "reaction_type" */
export type Reaction_Type_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: "reaction_type" */
export type Reaction_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "reaction_type" */
export enum Reaction_Type_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "reaction_type" */
export type Reaction_Type_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "reaction_type" */
export enum Reaction_Type_Update_Column {
  /** column name */
  Value = 'value'
}

/** update columns of table "reaction" */
export enum Reaction_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ReactionType = 'reaction_type',
  /** column name */
  StoryId = 'story_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  ViewedAt = 'viewed_at'
}

/** columns and relationships of "referral" */
export type Referral = {
  __typename?: 'referral';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An array relationship */
  keyword_referrals: Array<Keyword_Referral>;
  /** An aggregated array relationship */
  keyword_referrals_aggregate: Keyword_Referral_Aggregate;
  updated_at: Scalars['timestamptz'];
  value: Scalars['String'];
};


/** columns and relationships of "referral" */
export type ReferralKeyword_ReferralsArgs = {
  distinct_on?: Maybe<Array<Keyword_Referral_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Keyword_Referral_Order_By>>;
  where?: Maybe<Keyword_Referral_Bool_Exp>;
};


/** columns and relationships of "referral" */
export type ReferralKeyword_Referrals_AggregateArgs = {
  distinct_on?: Maybe<Array<Keyword_Referral_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Keyword_Referral_Order_By>>;
  where?: Maybe<Keyword_Referral_Bool_Exp>;
};

/** aggregated selection of "referral" */
export type Referral_Aggregate = {
  __typename?: 'referral_aggregate';
  aggregate?: Maybe<Referral_Aggregate_Fields>;
  nodes: Array<Referral>;
};

/** aggregate fields of "referral" */
export type Referral_Aggregate_Fields = {
  __typename?: 'referral_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Referral_Max_Fields>;
  min?: Maybe<Referral_Min_Fields>;
};


/** aggregate fields of "referral" */
export type Referral_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Referral_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "referral" */
export type Referral_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Referral_Max_Order_By>;
  min?: Maybe<Referral_Min_Order_By>;
};

/** input type for inserting array relation for remote table "referral" */
export type Referral_Arr_Rel_Insert_Input = {
  data: Array<Referral_Insert_Input>;
  on_conflict?: Maybe<Referral_On_Conflict>;
};

/** Boolean expression to filter rows from the table "referral". All fields are combined with a logical 'AND'. */
export type Referral_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Referral_Bool_Exp>>>;
  _not?: Maybe<Referral_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Referral_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  keyword_referrals?: Maybe<Keyword_Referral_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "referral" */
export enum Referral_Constraint {
  /** unique or primary key constraint */
  ReferralPkey = 'referral_pkey'
}

/** input type for inserting data into table "referral" */
export type Referral_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  keyword_referrals?: Maybe<Keyword_Referral_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Referral_Max_Fields = {
  __typename?: 'referral_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "referral" */
export type Referral_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Referral_Min_Fields = {
  __typename?: 'referral_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "referral" */
export type Referral_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** response of any mutation on the table "referral" */
export type Referral_Mutation_Response = {
  __typename?: 'referral_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Referral>;
};

/** input type for inserting object relation for remote table "referral" */
export type Referral_Obj_Rel_Insert_Input = {
  data: Referral_Insert_Input;
  on_conflict?: Maybe<Referral_On_Conflict>;
};

/** on conflict condition type for table "referral" */
export type Referral_On_Conflict = {
  constraint: Referral_Constraint;
  update_columns: Array<Referral_Update_Column>;
  where?: Maybe<Referral_Bool_Exp>;
};

/** ordering options when selecting data from "referral" */
export type Referral_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  keyword_referrals_aggregate?: Maybe<Keyword_Referral_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: "referral" */
export type Referral_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "referral" */
export enum Referral_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "referral" */
export type Referral_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "referral" */
export enum Referral_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** columns and relationships of "reply" */
export type Reply = {
  __typename?: 'reply';
  content: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  prompt: Prompt;
  prompt_id: Scalars['uuid'];
  /** An object relationship */
  story: Story;
  story_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "reply" */
export type Reply_Aggregate = {
  __typename?: 'reply_aggregate';
  aggregate?: Maybe<Reply_Aggregate_Fields>;
  nodes: Array<Reply>;
};

/** aggregate fields of "reply" */
export type Reply_Aggregate_Fields = {
  __typename?: 'reply_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Reply_Max_Fields>;
  min?: Maybe<Reply_Min_Fields>;
};


/** aggregate fields of "reply" */
export type Reply_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Reply_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "reply" */
export type Reply_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Reply_Max_Order_By>;
  min?: Maybe<Reply_Min_Order_By>;
};

/** input type for inserting array relation for remote table "reply" */
export type Reply_Arr_Rel_Insert_Input = {
  data: Array<Reply_Insert_Input>;
  on_conflict?: Maybe<Reply_On_Conflict>;
};

/** Boolean expression to filter rows from the table "reply". All fields are combined with a logical 'AND'. */
export type Reply_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Reply_Bool_Exp>>>;
  _not?: Maybe<Reply_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Reply_Bool_Exp>>>;
  content?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  prompt?: Maybe<Prompt_Bool_Exp>;
  prompt_id?: Maybe<Uuid_Comparison_Exp>;
  story?: Maybe<Story_Bool_Exp>;
  story_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "reply" */
export enum Reply_Constraint {
  /** unique or primary key constraint */
  ReplyPkey = 'reply_pkey'
}

/** input type for inserting data into table "reply" */
export type Reply_Insert_Input = {
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  prompt?: Maybe<Prompt_Obj_Rel_Insert_Input>;
  prompt_id?: Maybe<Scalars['uuid']>;
  story?: Maybe<Story_Obj_Rel_Insert_Input>;
  story_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Reply_Max_Fields = {
  __typename?: 'reply_max_fields';
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  prompt_id?: Maybe<Scalars['uuid']>;
  story_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "reply" */
export type Reply_Max_Order_By = {
  content?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  prompt_id?: Maybe<Order_By>;
  story_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Reply_Min_Fields = {
  __typename?: 'reply_min_fields';
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  prompt_id?: Maybe<Scalars['uuid']>;
  story_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "reply" */
export type Reply_Min_Order_By = {
  content?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  prompt_id?: Maybe<Order_By>;
  story_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "reply" */
export type Reply_Mutation_Response = {
  __typename?: 'reply_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Reply>;
};

/** input type for inserting object relation for remote table "reply" */
export type Reply_Obj_Rel_Insert_Input = {
  data: Reply_Insert_Input;
  on_conflict?: Maybe<Reply_On_Conflict>;
};

/** on conflict condition type for table "reply" */
export type Reply_On_Conflict = {
  constraint: Reply_Constraint;
  update_columns: Array<Reply_Update_Column>;
  where?: Maybe<Reply_Bool_Exp>;
};

/** ordering options when selecting data from "reply" */
export type Reply_Order_By = {
  content?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  prompt?: Maybe<Prompt_Order_By>;
  prompt_id?: Maybe<Order_By>;
  story?: Maybe<Story_Order_By>;
  story_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "reply" */
export type Reply_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "reply" */
export enum Reply_Select_Column {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PromptId = 'prompt_id',
  /** column name */
  StoryId = 'story_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "reply" */
export type Reply_Set_Input = {
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  prompt_id?: Maybe<Scalars['uuid']>;
  story_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "reply" */
export enum Reply_Update_Column {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PromptId = 'prompt_id',
  /** column name */
  StoryId = 'story_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "resource" */
export type Resource = {
  __typename?: 'resource';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  resource_type: Resource_Type;
  /** An object relationship */
  story: Story;
  story_id: Scalars['uuid'];
  type: Resource_Type_Enum;
  updated_at: Scalars['timestamptz'];
  url: Scalars['String'];
};

/** aggregated selection of "resource" */
export type Resource_Aggregate = {
  __typename?: 'resource_aggregate';
  aggregate?: Maybe<Resource_Aggregate_Fields>;
  nodes: Array<Resource>;
};

/** aggregate fields of "resource" */
export type Resource_Aggregate_Fields = {
  __typename?: 'resource_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Resource_Max_Fields>;
  min?: Maybe<Resource_Min_Fields>;
};


/** aggregate fields of "resource" */
export type Resource_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Resource_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "resource" */
export type Resource_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Resource_Max_Order_By>;
  min?: Maybe<Resource_Min_Order_By>;
};

/** input type for inserting array relation for remote table "resource" */
export type Resource_Arr_Rel_Insert_Input = {
  data: Array<Resource_Insert_Input>;
  on_conflict?: Maybe<Resource_On_Conflict>;
};

/** Boolean expression to filter rows from the table "resource". All fields are combined with a logical 'AND'. */
export type Resource_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Resource_Bool_Exp>>>;
  _not?: Maybe<Resource_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Resource_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  resource_type?: Maybe<Resource_Type_Bool_Exp>;
  story?: Maybe<Story_Bool_Exp>;
  story_id?: Maybe<Uuid_Comparison_Exp>;
  type?: Maybe<Resource_Type_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  url?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "resource" */
export enum Resource_Constraint {
  /** unique or primary key constraint */
  ResourcePkey = 'resource_pkey',
  /** unique or primary key constraint */
  ResourceStoryIdKey = 'resource_story_id_key'
}

/** input type for inserting data into table "resource" */
export type Resource_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  resource_type?: Maybe<Resource_Type_Obj_Rel_Insert_Input>;
  story?: Maybe<Story_Obj_Rel_Insert_Input>;
  story_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Resource_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Resource_Max_Fields = {
  __typename?: 'resource_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  story_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "resource" */
export type Resource_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  story_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Resource_Min_Fields = {
  __typename?: 'resource_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  story_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "resource" */
export type Resource_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  story_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
};

/** response of any mutation on the table "resource" */
export type Resource_Mutation_Response = {
  __typename?: 'resource_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Resource>;
};

/** input type for inserting object relation for remote table "resource" */
export type Resource_Obj_Rel_Insert_Input = {
  data: Resource_Insert_Input;
  on_conflict?: Maybe<Resource_On_Conflict>;
};

/** on conflict condition type for table "resource" */
export type Resource_On_Conflict = {
  constraint: Resource_Constraint;
  update_columns: Array<Resource_Update_Column>;
  where?: Maybe<Resource_Bool_Exp>;
};

/** ordering options when selecting data from "resource" */
export type Resource_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  resource_type?: Maybe<Resource_Type_Order_By>;
  story?: Maybe<Story_Order_By>;
  story_id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
};

/** primary key columns input for table: "resource" */
export type Resource_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "resource" */
export enum Resource_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  StoryId = 'story_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url'
}

/** input type for updating data in table "resource" */
export type Resource_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  story_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Resource_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** columns and relationships of "resource_type" */
export type Resource_Type = {
  __typename?: 'resource_type';
  /** An array relationship */
  resources: Array<Resource>;
  /** An aggregated array relationship */
  resources_aggregate: Resource_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "resource_type" */
export type Resource_TypeResourcesArgs = {
  distinct_on?: Maybe<Array<Resource_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Resource_Order_By>>;
  where?: Maybe<Resource_Bool_Exp>;
};


/** columns and relationships of "resource_type" */
export type Resource_TypeResources_AggregateArgs = {
  distinct_on?: Maybe<Array<Resource_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Resource_Order_By>>;
  where?: Maybe<Resource_Bool_Exp>;
};

/** aggregated selection of "resource_type" */
export type Resource_Type_Aggregate = {
  __typename?: 'resource_type_aggregate';
  aggregate?: Maybe<Resource_Type_Aggregate_Fields>;
  nodes: Array<Resource_Type>;
};

/** aggregate fields of "resource_type" */
export type Resource_Type_Aggregate_Fields = {
  __typename?: 'resource_type_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Resource_Type_Max_Fields>;
  min?: Maybe<Resource_Type_Min_Fields>;
};


/** aggregate fields of "resource_type" */
export type Resource_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Resource_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "resource_type" */
export type Resource_Type_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Resource_Type_Max_Order_By>;
  min?: Maybe<Resource_Type_Min_Order_By>;
};

/** input type for inserting array relation for remote table "resource_type" */
export type Resource_Type_Arr_Rel_Insert_Input = {
  data: Array<Resource_Type_Insert_Input>;
  on_conflict?: Maybe<Resource_Type_On_Conflict>;
};

/** Boolean expression to filter rows from the table "resource_type". All fields are combined with a logical 'AND'. */
export type Resource_Type_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Resource_Type_Bool_Exp>>>;
  _not?: Maybe<Resource_Type_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Resource_Type_Bool_Exp>>>;
  resources?: Maybe<Resource_Bool_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "resource_type" */
export enum Resource_Type_Constraint {
  /** unique or primary key constraint */
  ResourceTypePkey = 'resource_type_pkey'
}

export enum Resource_Type_Enum {
  Audio = 'AUDIO',
  Image = 'IMAGE',
  Video = 'VIDEO'
}

/** expression to compare columns of type resource_type_enum. All fields are combined with logical 'AND'. */
export type Resource_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Resource_Type_Enum>;
  _in?: Maybe<Array<Resource_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Resource_Type_Enum>;
  _nin?: Maybe<Array<Resource_Type_Enum>>;
};

/** input type for inserting data into table "resource_type" */
export type Resource_Type_Insert_Input = {
  resources?: Maybe<Resource_Arr_Rel_Insert_Input>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Resource_Type_Max_Fields = {
  __typename?: 'resource_type_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "resource_type" */
export type Resource_Type_Max_Order_By = {
  value?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Resource_Type_Min_Fields = {
  __typename?: 'resource_type_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "resource_type" */
export type Resource_Type_Min_Order_By = {
  value?: Maybe<Order_By>;
};

/** response of any mutation on the table "resource_type" */
export type Resource_Type_Mutation_Response = {
  __typename?: 'resource_type_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Resource_Type>;
};

/** input type for inserting object relation for remote table "resource_type" */
export type Resource_Type_Obj_Rel_Insert_Input = {
  data: Resource_Type_Insert_Input;
  on_conflict?: Maybe<Resource_Type_On_Conflict>;
};

/** on conflict condition type for table "resource_type" */
export type Resource_Type_On_Conflict = {
  constraint: Resource_Type_Constraint;
  update_columns: Array<Resource_Type_Update_Column>;
  where?: Maybe<Resource_Type_Bool_Exp>;
};

/** ordering options when selecting data from "resource_type" */
export type Resource_Type_Order_By = {
  resources_aggregate?: Maybe<Resource_Aggregate_Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: "resource_type" */
export type Resource_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "resource_type" */
export enum Resource_Type_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "resource_type" */
export type Resource_Type_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "resource_type" */
export enum Resource_Type_Update_Column {
  /** column name */
  Value = 'value'
}

/** update columns of table "resource" */
export enum Resource_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  StoryId = 'story_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url'
}

/** columns and relationships of "sentiment" */
export type Sentiment = {
  __typename?: 'sentiment';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An array relationship */
  keywords: Array<Keyword>;
  /** An aggregated array relationship */
  keywords_aggregate: Keyword_Aggregate;
  updated_at: Scalars['timestamptz'];
  value: Scalars['String'];
};


/** columns and relationships of "sentiment" */
export type SentimentKeywordsArgs = {
  distinct_on?: Maybe<Array<Keyword_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Keyword_Order_By>>;
  where?: Maybe<Keyword_Bool_Exp>;
};


/** columns and relationships of "sentiment" */
export type SentimentKeywords_AggregateArgs = {
  distinct_on?: Maybe<Array<Keyword_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Keyword_Order_By>>;
  where?: Maybe<Keyword_Bool_Exp>;
};

/** aggregated selection of "sentiment" */
export type Sentiment_Aggregate = {
  __typename?: 'sentiment_aggregate';
  aggregate?: Maybe<Sentiment_Aggregate_Fields>;
  nodes: Array<Sentiment>;
};

/** aggregate fields of "sentiment" */
export type Sentiment_Aggregate_Fields = {
  __typename?: 'sentiment_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Sentiment_Max_Fields>;
  min?: Maybe<Sentiment_Min_Fields>;
};


/** aggregate fields of "sentiment" */
export type Sentiment_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sentiment_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "sentiment" */
export type Sentiment_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Sentiment_Max_Order_By>;
  min?: Maybe<Sentiment_Min_Order_By>;
};

/** input type for inserting array relation for remote table "sentiment" */
export type Sentiment_Arr_Rel_Insert_Input = {
  data: Array<Sentiment_Insert_Input>;
  on_conflict?: Maybe<Sentiment_On_Conflict>;
};

/** Boolean expression to filter rows from the table "sentiment". All fields are combined with a logical 'AND'. */
export type Sentiment_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Sentiment_Bool_Exp>>>;
  _not?: Maybe<Sentiment_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Sentiment_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  keywords?: Maybe<Keyword_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "sentiment" */
export enum Sentiment_Constraint {
  /** unique or primary key constraint */
  SentimentPkey = 'sentiment_pkey'
}

/** input type for inserting data into table "sentiment" */
export type Sentiment_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  keywords?: Maybe<Keyword_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Sentiment_Max_Fields = {
  __typename?: 'sentiment_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "sentiment" */
export type Sentiment_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Sentiment_Min_Fields = {
  __typename?: 'sentiment_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "sentiment" */
export type Sentiment_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** response of any mutation on the table "sentiment" */
export type Sentiment_Mutation_Response = {
  __typename?: 'sentiment_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Sentiment>;
};

/** input type for inserting object relation for remote table "sentiment" */
export type Sentiment_Obj_Rel_Insert_Input = {
  data: Sentiment_Insert_Input;
  on_conflict?: Maybe<Sentiment_On_Conflict>;
};

/** on conflict condition type for table "sentiment" */
export type Sentiment_On_Conflict = {
  constraint: Sentiment_Constraint;
  update_columns: Array<Sentiment_Update_Column>;
  where?: Maybe<Sentiment_Bool_Exp>;
};

/** ordering options when selecting data from "sentiment" */
export type Sentiment_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  keywords_aggregate?: Maybe<Keyword_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: "sentiment" */
export type Sentiment_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "sentiment" */
export enum Sentiment_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "sentiment" */
export type Sentiment_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "sentiment" */
export enum Sentiment_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** columns and relationships of "share" */
export type Share = {
  __typename?: 'share';
  activity_type?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  story: Story;
  story_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: User;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "share" */
export type Share_Aggregate = {
  __typename?: 'share_aggregate';
  aggregate?: Maybe<Share_Aggregate_Fields>;
  nodes: Array<Share>;
};

/** aggregate fields of "share" */
export type Share_Aggregate_Fields = {
  __typename?: 'share_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Share_Max_Fields>;
  min?: Maybe<Share_Min_Fields>;
};


/** aggregate fields of "share" */
export type Share_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Share_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "share" */
export type Share_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Share_Max_Order_By>;
  min?: Maybe<Share_Min_Order_By>;
};

/** input type for inserting array relation for remote table "share" */
export type Share_Arr_Rel_Insert_Input = {
  data: Array<Share_Insert_Input>;
  on_conflict?: Maybe<Share_On_Conflict>;
};

/** Boolean expression to filter rows from the table "share". All fields are combined with a logical 'AND'. */
export type Share_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Share_Bool_Exp>>>;
  _not?: Maybe<Share_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Share_Bool_Exp>>>;
  activity_type?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  story?: Maybe<Story_Bool_Exp>;
  story_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<User_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "share" */
export enum Share_Constraint {
  /** unique or primary key constraint */
  SharePkey = 'share_pkey'
}

/** input type for inserting data into table "share" */
export type Share_Insert_Input = {
  activity_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  story?: Maybe<Story_Obj_Rel_Insert_Input>;
  story_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<User_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Share_Max_Fields = {
  __typename?: 'share_max_fields';
  activity_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  story_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "share" */
export type Share_Max_Order_By = {
  activity_type?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  story_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Share_Min_Fields = {
  __typename?: 'share_min_fields';
  activity_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  story_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "share" */
export type Share_Min_Order_By = {
  activity_type?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  story_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "share" */
export type Share_Mutation_Response = {
  __typename?: 'share_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Share>;
};

/** input type for inserting object relation for remote table "share" */
export type Share_Obj_Rel_Insert_Input = {
  data: Share_Insert_Input;
  on_conflict?: Maybe<Share_On_Conflict>;
};

/** on conflict condition type for table "share" */
export type Share_On_Conflict = {
  constraint: Share_Constraint;
  update_columns: Array<Share_Update_Column>;
  where?: Maybe<Share_Bool_Exp>;
};

/** ordering options when selecting data from "share" */
export type Share_Order_By = {
  activity_type?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  story?: Maybe<Story_Order_By>;
  story_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<User_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "share" */
export type Share_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "share" */
export enum Share_Select_Column {
  /** column name */
  ActivityType = 'activity_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  StoryId = 'story_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "share" */
export type Share_Set_Input = {
  activity_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  story_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "share" */
export enum Share_Update_Column {
  /** column name */
  ActivityType = 'activity_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  StoryId = 'story_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** columns and relationships of "story" */
export type Story = {
  __typename?: 'story';
  caption?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  first_submitted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  is_active: Scalars['Boolean'];
  is_name_included: Scalars['Boolean'];
  is_shareable: Scalars['Boolean'];
  last_approved_at?: Maybe<Scalars['timestamptz']>;
  last_rejected_at?: Maybe<Scalars['timestamptz']>;
  last_reported_at?: Maybe<Scalars['timestamptz']>;
  last_submitted_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  moderator_notes: Array<Moderator_Note>;
  /** An aggregated array relationship */
  moderator_notes_aggregate: Moderator_Note_Aggregate;
  number_of_approvals: Scalars['Int'];
  /** A computed field, executes function "story_reaction_claps" */
  number_of_claps?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "story_reaction_loves" */
  number_of_loves?: Maybe<Scalars['Int']>;
  number_of_rejections: Scalars['Int'];
  /** A computed field, executes function "story_reaction_relates" */
  number_of_relates?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "story_reaction_shares" */
  number_of_shares?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['timestamptz']>;
  ranking: Scalars['Int'];
  /** An array relationship */
  reactions: Array<Reaction>;
  /** An aggregated array relationship */
  reactions_aggregate: Reaction_Aggregate;
  /** An array relationship */
  replies: Array<Reply>;
  /** An aggregated array relationship */
  replies_aggregate: Reply_Aggregate;
  /** An object relationship */
  resource?: Maybe<Resource>;
  /** An array relationship */
  shares: Array<Share>;
  /** An aggregated array relationship */
  shares_aggregate: Share_Aggregate;
  show_suburb: Scalars['Boolean'];
  status: Scalars['String'];
  /** An array relationship */
  story_saves: Array<Story_Save>;
  /** An aggregated array relationship */
  story_saves_aggregate: Story_Save_Aggregate;
  /** An array relationship */
  story_violations: Array<Story_Violation>;
  /** An aggregated array relationship */
  story_violations_aggregate: Story_Violation_Aggregate;
  /** An array relationship */
  tags: Array<Story_Tag>;
  /** An aggregated array relationship */
  tags_aggregate: Story_Tag_Aggregate;
  title: Scalars['String'];
  transcription?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: User;
  user_id: Scalars['uuid'];
  views: Scalars['Int'];
  /** An array relationship */
  violation_notes: Array<Violation_Note>;
  /** An aggregated array relationship */
  violation_notes_aggregate: Violation_Note_Aggregate;
};


/** columns and relationships of "story" */
export type StoryModerator_NotesArgs = {
  distinct_on?: Maybe<Array<Moderator_Note_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Moderator_Note_Order_By>>;
  where?: Maybe<Moderator_Note_Bool_Exp>;
};


/** columns and relationships of "story" */
export type StoryModerator_Notes_AggregateArgs = {
  distinct_on?: Maybe<Array<Moderator_Note_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Moderator_Note_Order_By>>;
  where?: Maybe<Moderator_Note_Bool_Exp>;
};


/** columns and relationships of "story" */
export type StoryReactionsArgs = {
  distinct_on?: Maybe<Array<Reaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reaction_Order_By>>;
  where?: Maybe<Reaction_Bool_Exp>;
};


/** columns and relationships of "story" */
export type StoryReactions_AggregateArgs = {
  distinct_on?: Maybe<Array<Reaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reaction_Order_By>>;
  where?: Maybe<Reaction_Bool_Exp>;
};


/** columns and relationships of "story" */
export type StoryRepliesArgs = {
  distinct_on?: Maybe<Array<Reply_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reply_Order_By>>;
  where?: Maybe<Reply_Bool_Exp>;
};


/** columns and relationships of "story" */
export type StoryReplies_AggregateArgs = {
  distinct_on?: Maybe<Array<Reply_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reply_Order_By>>;
  where?: Maybe<Reply_Bool_Exp>;
};


/** columns and relationships of "story" */
export type StorySharesArgs = {
  distinct_on?: Maybe<Array<Share_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Share_Order_By>>;
  where?: Maybe<Share_Bool_Exp>;
};


/** columns and relationships of "story" */
export type StoryShares_AggregateArgs = {
  distinct_on?: Maybe<Array<Share_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Share_Order_By>>;
  where?: Maybe<Share_Bool_Exp>;
};


/** columns and relationships of "story" */
export type StoryStory_SavesArgs = {
  distinct_on?: Maybe<Array<Story_Save_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Story_Save_Order_By>>;
  where?: Maybe<Story_Save_Bool_Exp>;
};


/** columns and relationships of "story" */
export type StoryStory_Saves_AggregateArgs = {
  distinct_on?: Maybe<Array<Story_Save_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Story_Save_Order_By>>;
  where?: Maybe<Story_Save_Bool_Exp>;
};


/** columns and relationships of "story" */
export type StoryStory_ViolationsArgs = {
  distinct_on?: Maybe<Array<Story_Violation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Story_Violation_Order_By>>;
  where?: Maybe<Story_Violation_Bool_Exp>;
};


/** columns and relationships of "story" */
export type StoryStory_Violations_AggregateArgs = {
  distinct_on?: Maybe<Array<Story_Violation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Story_Violation_Order_By>>;
  where?: Maybe<Story_Violation_Bool_Exp>;
};


/** columns and relationships of "story" */
export type StoryTagsArgs = {
  distinct_on?: Maybe<Array<Story_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Story_Tag_Order_By>>;
  where?: Maybe<Story_Tag_Bool_Exp>;
};


/** columns and relationships of "story" */
export type StoryTags_AggregateArgs = {
  distinct_on?: Maybe<Array<Story_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Story_Tag_Order_By>>;
  where?: Maybe<Story_Tag_Bool_Exp>;
};


/** columns and relationships of "story" */
export type StoryViolation_NotesArgs = {
  distinct_on?: Maybe<Array<Violation_Note_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Violation_Note_Order_By>>;
  where?: Maybe<Violation_Note_Bool_Exp>;
};


/** columns and relationships of "story" */
export type StoryViolation_Notes_AggregateArgs = {
  distinct_on?: Maybe<Array<Violation_Note_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Violation_Note_Order_By>>;
  where?: Maybe<Violation_Note_Bool_Exp>;
};

/** aggregated selection of "story" */
export type Story_Aggregate = {
  __typename?: 'story_aggregate';
  aggregate?: Maybe<Story_Aggregate_Fields>;
  nodes: Array<Story>;
};

/** aggregate fields of "story" */
export type Story_Aggregate_Fields = {
  __typename?: 'story_aggregate_fields';
  avg?: Maybe<Story_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Story_Max_Fields>;
  min?: Maybe<Story_Min_Fields>;
  stddev?: Maybe<Story_Stddev_Fields>;
  stddev_pop?: Maybe<Story_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Story_Stddev_Samp_Fields>;
  sum?: Maybe<Story_Sum_Fields>;
  var_pop?: Maybe<Story_Var_Pop_Fields>;
  var_samp?: Maybe<Story_Var_Samp_Fields>;
  variance?: Maybe<Story_Variance_Fields>;
};


/** aggregate fields of "story" */
export type Story_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Story_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "story" */
export type Story_Aggregate_Order_By = {
  avg?: Maybe<Story_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Story_Max_Order_By>;
  min?: Maybe<Story_Min_Order_By>;
  stddev?: Maybe<Story_Stddev_Order_By>;
  stddev_pop?: Maybe<Story_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Story_Stddev_Samp_Order_By>;
  sum?: Maybe<Story_Sum_Order_By>;
  var_pop?: Maybe<Story_Var_Pop_Order_By>;
  var_samp?: Maybe<Story_Var_Samp_Order_By>;
  variance?: Maybe<Story_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "story" */
export type Story_Arr_Rel_Insert_Input = {
  data: Array<Story_Insert_Input>;
  on_conflict?: Maybe<Story_On_Conflict>;
};

/** aggregate avg on columns */
export type Story_Avg_Fields = {
  __typename?: 'story_avg_fields';
  number_of_approvals?: Maybe<Scalars['Float']>;
  number_of_rejections?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  ranking?: Maybe<Scalars['Float']>;
  views?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "story" */
export type Story_Avg_Order_By = {
  number_of_approvals?: Maybe<Order_By>;
  number_of_rejections?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  ranking?: Maybe<Order_By>;
  views?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "story". All fields are combined with a logical 'AND'. */
export type Story_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Story_Bool_Exp>>>;
  _not?: Maybe<Story_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Story_Bool_Exp>>>;
  caption?: Maybe<String_Comparison_Exp>;
  content?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  first_submitted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  is_active?: Maybe<Boolean_Comparison_Exp>;
  is_name_included?: Maybe<Boolean_Comparison_Exp>;
  is_shareable?: Maybe<Boolean_Comparison_Exp>;
  last_approved_at?: Maybe<Timestamptz_Comparison_Exp>;
  last_rejected_at?: Maybe<Timestamptz_Comparison_Exp>;
  last_reported_at?: Maybe<Timestamptz_Comparison_Exp>;
  last_submitted_at?: Maybe<Timestamptz_Comparison_Exp>;
  moderator_notes?: Maybe<Moderator_Note_Bool_Exp>;
  number_of_approvals?: Maybe<Int_Comparison_Exp>;
  number_of_rejections?: Maybe<Int_Comparison_Exp>;
  order?: Maybe<Int_Comparison_Exp>;
  published_at?: Maybe<Timestamptz_Comparison_Exp>;
  ranking?: Maybe<Int_Comparison_Exp>;
  reactions?: Maybe<Reaction_Bool_Exp>;
  replies?: Maybe<Reply_Bool_Exp>;
  resource?: Maybe<Resource_Bool_Exp>;
  shares?: Maybe<Share_Bool_Exp>;
  show_suburb?: Maybe<Boolean_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  story_saves?: Maybe<Story_Save_Bool_Exp>;
  story_violations?: Maybe<Story_Violation_Bool_Exp>;
  tags?: Maybe<Story_Tag_Bool_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  transcription?: Maybe<String_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<User_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
  views?: Maybe<Int_Comparison_Exp>;
  violation_notes?: Maybe<Violation_Note_Bool_Exp>;
};

/** unique or primary key constraints on table "story" */
export enum Story_Constraint {
  /** unique or primary key constraint */
  StoryPkey = 'story_pkey'
}

/** input type for incrementing integer column in table "story" */
export type Story_Inc_Input = {
  number_of_approvals?: Maybe<Scalars['Int']>;
  number_of_rejections?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  ranking?: Maybe<Scalars['Int']>;
  views?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "story" */
export type Story_Insert_Input = {
  caption?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  first_submitted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  is_active?: Maybe<Scalars['Boolean']>;
  is_name_included?: Maybe<Scalars['Boolean']>;
  is_shareable?: Maybe<Scalars['Boolean']>;
  last_approved_at?: Maybe<Scalars['timestamptz']>;
  last_rejected_at?: Maybe<Scalars['timestamptz']>;
  last_reported_at?: Maybe<Scalars['timestamptz']>;
  last_submitted_at?: Maybe<Scalars['timestamptz']>;
  moderator_notes?: Maybe<Moderator_Note_Arr_Rel_Insert_Input>;
  number_of_approvals?: Maybe<Scalars['Int']>;
  number_of_rejections?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['timestamptz']>;
  ranking?: Maybe<Scalars['Int']>;
  reactions?: Maybe<Reaction_Arr_Rel_Insert_Input>;
  replies?: Maybe<Reply_Arr_Rel_Insert_Input>;
  resource?: Maybe<Resource_Obj_Rel_Insert_Input>;
  shares?: Maybe<Share_Arr_Rel_Insert_Input>;
  show_suburb?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
  story_saves?: Maybe<Story_Save_Arr_Rel_Insert_Input>;
  story_violations?: Maybe<Story_Violation_Arr_Rel_Insert_Input>;
  tags?: Maybe<Story_Tag_Arr_Rel_Insert_Input>;
  title?: Maybe<Scalars['String']>;
  transcription?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<User_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
  views?: Maybe<Scalars['Int']>;
  violation_notes?: Maybe<Violation_Note_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Story_Max_Fields = {
  __typename?: 'story_max_fields';
  caption?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  first_submitted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  last_approved_at?: Maybe<Scalars['timestamptz']>;
  last_rejected_at?: Maybe<Scalars['timestamptz']>;
  last_reported_at?: Maybe<Scalars['timestamptz']>;
  last_submitted_at?: Maybe<Scalars['timestamptz']>;
  number_of_approvals?: Maybe<Scalars['Int']>;
  number_of_rejections?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['timestamptz']>;
  ranking?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  transcription?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  views?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "story" */
export type Story_Max_Order_By = {
  caption?: Maybe<Order_By>;
  content?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  first_submitted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_approved_at?: Maybe<Order_By>;
  last_rejected_at?: Maybe<Order_By>;
  last_reported_at?: Maybe<Order_By>;
  last_submitted_at?: Maybe<Order_By>;
  number_of_approvals?: Maybe<Order_By>;
  number_of_rejections?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  published_at?: Maybe<Order_By>;
  ranking?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  transcription?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  views?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Story_Min_Fields = {
  __typename?: 'story_min_fields';
  caption?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  first_submitted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  last_approved_at?: Maybe<Scalars['timestamptz']>;
  last_rejected_at?: Maybe<Scalars['timestamptz']>;
  last_reported_at?: Maybe<Scalars['timestamptz']>;
  last_submitted_at?: Maybe<Scalars['timestamptz']>;
  number_of_approvals?: Maybe<Scalars['Int']>;
  number_of_rejections?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['timestamptz']>;
  ranking?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  transcription?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  views?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "story" */
export type Story_Min_Order_By = {
  caption?: Maybe<Order_By>;
  content?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  first_submitted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_approved_at?: Maybe<Order_By>;
  last_rejected_at?: Maybe<Order_By>;
  last_reported_at?: Maybe<Order_By>;
  last_submitted_at?: Maybe<Order_By>;
  number_of_approvals?: Maybe<Order_By>;
  number_of_rejections?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  published_at?: Maybe<Order_By>;
  ranking?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  transcription?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  views?: Maybe<Order_By>;
};

/** response of any mutation on the table "story" */
export type Story_Mutation_Response = {
  __typename?: 'story_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Story>;
};

/** input type for inserting object relation for remote table "story" */
export type Story_Obj_Rel_Insert_Input = {
  data: Story_Insert_Input;
  on_conflict?: Maybe<Story_On_Conflict>;
};

/** on conflict condition type for table "story" */
export type Story_On_Conflict = {
  constraint: Story_Constraint;
  update_columns: Array<Story_Update_Column>;
  where?: Maybe<Story_Bool_Exp>;
};

/** ordering options when selecting data from "story" */
export type Story_Order_By = {
  caption?: Maybe<Order_By>;
  content?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  first_submitted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_active?: Maybe<Order_By>;
  is_name_included?: Maybe<Order_By>;
  is_shareable?: Maybe<Order_By>;
  last_approved_at?: Maybe<Order_By>;
  last_rejected_at?: Maybe<Order_By>;
  last_reported_at?: Maybe<Order_By>;
  last_submitted_at?: Maybe<Order_By>;
  moderator_notes_aggregate?: Maybe<Moderator_Note_Aggregate_Order_By>;
  number_of_approvals?: Maybe<Order_By>;
  number_of_rejections?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  published_at?: Maybe<Order_By>;
  ranking?: Maybe<Order_By>;
  reactions_aggregate?: Maybe<Reaction_Aggregate_Order_By>;
  replies_aggregate?: Maybe<Reply_Aggregate_Order_By>;
  resource?: Maybe<Resource_Order_By>;
  shares_aggregate?: Maybe<Share_Aggregate_Order_By>;
  show_suburb?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  story_saves_aggregate?: Maybe<Story_Save_Aggregate_Order_By>;
  story_violations_aggregate?: Maybe<Story_Violation_Aggregate_Order_By>;
  tags_aggregate?: Maybe<Story_Tag_Aggregate_Order_By>;
  title?: Maybe<Order_By>;
  transcription?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<User_Order_By>;
  user_id?: Maybe<Order_By>;
  views?: Maybe<Order_By>;
  violation_notes_aggregate?: Maybe<Violation_Note_Aggregate_Order_By>;
};

/** primary key columns input for table: "story" */
export type Story_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** columns and relationships of "story_save" */
export type Story_Save = {
  __typename?: 'story_save';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  story: Story;
  story_id: Scalars['uuid'];
  /** An object relationship */
  user: User;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "story_save" */
export type Story_Save_Aggregate = {
  __typename?: 'story_save_aggregate';
  aggregate?: Maybe<Story_Save_Aggregate_Fields>;
  nodes: Array<Story_Save>;
};

/** aggregate fields of "story_save" */
export type Story_Save_Aggregate_Fields = {
  __typename?: 'story_save_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Story_Save_Max_Fields>;
  min?: Maybe<Story_Save_Min_Fields>;
};


/** aggregate fields of "story_save" */
export type Story_Save_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Story_Save_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "story_save" */
export type Story_Save_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Story_Save_Max_Order_By>;
  min?: Maybe<Story_Save_Min_Order_By>;
};

/** input type for inserting array relation for remote table "story_save" */
export type Story_Save_Arr_Rel_Insert_Input = {
  data: Array<Story_Save_Insert_Input>;
  on_conflict?: Maybe<Story_Save_On_Conflict>;
};

/** Boolean expression to filter rows from the table "story_save". All fields are combined with a logical 'AND'. */
export type Story_Save_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Story_Save_Bool_Exp>>>;
  _not?: Maybe<Story_Save_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Story_Save_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  story?: Maybe<Story_Bool_Exp>;
  story_id?: Maybe<Uuid_Comparison_Exp>;
  user?: Maybe<User_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "story_save" */
export enum Story_Save_Constraint {
  /** unique or primary key constraint */
  StorySavePkey = 'story_save_pkey',
  /** unique or primary key constraint */
  UniqueUserIdStoryId = 'unique_user_id_story_id'
}

/** input type for inserting data into table "story_save" */
export type Story_Save_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  story?: Maybe<Story_Obj_Rel_Insert_Input>;
  story_id?: Maybe<Scalars['uuid']>;
  user?: Maybe<User_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Story_Save_Max_Fields = {
  __typename?: 'story_save_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  story_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "story_save" */
export type Story_Save_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  story_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Story_Save_Min_Fields = {
  __typename?: 'story_save_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  story_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "story_save" */
export type Story_Save_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  story_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "story_save" */
export type Story_Save_Mutation_Response = {
  __typename?: 'story_save_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Story_Save>;
};

/** input type for inserting object relation for remote table "story_save" */
export type Story_Save_Obj_Rel_Insert_Input = {
  data: Story_Save_Insert_Input;
  on_conflict?: Maybe<Story_Save_On_Conflict>;
};

/** on conflict condition type for table "story_save" */
export type Story_Save_On_Conflict = {
  constraint: Story_Save_Constraint;
  update_columns: Array<Story_Save_Update_Column>;
  where?: Maybe<Story_Save_Bool_Exp>;
};

/** ordering options when selecting data from "story_save" */
export type Story_Save_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  story?: Maybe<Story_Order_By>;
  story_id?: Maybe<Order_By>;
  user?: Maybe<User_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "story_save" */
export type Story_Save_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "story_save" */
export enum Story_Save_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  StoryId = 'story_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "story_save" */
export type Story_Save_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  story_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "story_save" */
export enum Story_Save_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  StoryId = 'story_id',
  /** column name */
  UserId = 'user_id'
}

/** select columns of table "story" */
export enum Story_Select_Column {
  /** column name */
  Caption = 'caption',
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FirstSubmittedAt = 'first_submitted_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsNameIncluded = 'is_name_included',
  /** column name */
  IsShareable = 'is_shareable',
  /** column name */
  LastApprovedAt = 'last_approved_at',
  /** column name */
  LastRejectedAt = 'last_rejected_at',
  /** column name */
  LastReportedAt = 'last_reported_at',
  /** column name */
  LastSubmittedAt = 'last_submitted_at',
  /** column name */
  NumberOfApprovals = 'number_of_approvals',
  /** column name */
  NumberOfRejections = 'number_of_rejections',
  /** column name */
  Order = 'order',
  /** column name */
  PublishedAt = 'published_at',
  /** column name */
  Ranking = 'ranking',
  /** column name */
  ShowSuburb = 'show_suburb',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title',
  /** column name */
  Transcription = 'transcription',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Views = 'views'
}

/** input type for updating data in table "story" */
export type Story_Set_Input = {
  caption?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  first_submitted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  is_active?: Maybe<Scalars['Boolean']>;
  is_name_included?: Maybe<Scalars['Boolean']>;
  is_shareable?: Maybe<Scalars['Boolean']>;
  last_approved_at?: Maybe<Scalars['timestamptz']>;
  last_rejected_at?: Maybe<Scalars['timestamptz']>;
  last_reported_at?: Maybe<Scalars['timestamptz']>;
  last_submitted_at?: Maybe<Scalars['timestamptz']>;
  number_of_approvals?: Maybe<Scalars['Int']>;
  number_of_rejections?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['timestamptz']>;
  ranking?: Maybe<Scalars['Int']>;
  show_suburb?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  transcription?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  views?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "story_status" */
export type Story_Status = {
  __typename?: 'story_status';
  value: Scalars['String'];
};

/** aggregated selection of "story_status" */
export type Story_Status_Aggregate = {
  __typename?: 'story_status_aggregate';
  aggregate?: Maybe<Story_Status_Aggregate_Fields>;
  nodes: Array<Story_Status>;
};

/** aggregate fields of "story_status" */
export type Story_Status_Aggregate_Fields = {
  __typename?: 'story_status_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Story_Status_Max_Fields>;
  min?: Maybe<Story_Status_Min_Fields>;
};


/** aggregate fields of "story_status" */
export type Story_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Story_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "story_status" */
export type Story_Status_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Story_Status_Max_Order_By>;
  min?: Maybe<Story_Status_Min_Order_By>;
};

/** input type for inserting array relation for remote table "story_status" */
export type Story_Status_Arr_Rel_Insert_Input = {
  data: Array<Story_Status_Insert_Input>;
  on_conflict?: Maybe<Story_Status_On_Conflict>;
};

/** Boolean expression to filter rows from the table "story_status". All fields are combined with a logical 'AND'. */
export type Story_Status_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Story_Status_Bool_Exp>>>;
  _not?: Maybe<Story_Status_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Story_Status_Bool_Exp>>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "story_status" */
export enum Story_Status_Constraint {
  /** unique or primary key constraint */
  StoryStatusPkey = 'story_status_pkey'
}

/** input type for inserting data into table "story_status" */
export type Story_Status_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Story_Status_Max_Fields = {
  __typename?: 'story_status_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "story_status" */
export type Story_Status_Max_Order_By = {
  value?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Story_Status_Min_Fields = {
  __typename?: 'story_status_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "story_status" */
export type Story_Status_Min_Order_By = {
  value?: Maybe<Order_By>;
};

/** response of any mutation on the table "story_status" */
export type Story_Status_Mutation_Response = {
  __typename?: 'story_status_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Story_Status>;
};

/** input type for inserting object relation for remote table "story_status" */
export type Story_Status_Obj_Rel_Insert_Input = {
  data: Story_Status_Insert_Input;
  on_conflict?: Maybe<Story_Status_On_Conflict>;
};

/** on conflict condition type for table "story_status" */
export type Story_Status_On_Conflict = {
  constraint: Story_Status_Constraint;
  update_columns: Array<Story_Status_Update_Column>;
  where?: Maybe<Story_Status_Bool_Exp>;
};

/** ordering options when selecting data from "story_status" */
export type Story_Status_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: "story_status" */
export type Story_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "story_status" */
export enum Story_Status_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "story_status" */
export type Story_Status_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "story_status" */
export enum Story_Status_Update_Column {
  /** column name */
  Value = 'value'
}

/** aggregate stddev on columns */
export type Story_Stddev_Fields = {
  __typename?: 'story_stddev_fields';
  number_of_approvals?: Maybe<Scalars['Float']>;
  number_of_rejections?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  ranking?: Maybe<Scalars['Float']>;
  views?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "story" */
export type Story_Stddev_Order_By = {
  number_of_approvals?: Maybe<Order_By>;
  number_of_rejections?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  ranking?: Maybe<Order_By>;
  views?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Story_Stddev_Pop_Fields = {
  __typename?: 'story_stddev_pop_fields';
  number_of_approvals?: Maybe<Scalars['Float']>;
  number_of_rejections?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  ranking?: Maybe<Scalars['Float']>;
  views?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "story" */
export type Story_Stddev_Pop_Order_By = {
  number_of_approvals?: Maybe<Order_By>;
  number_of_rejections?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  ranking?: Maybe<Order_By>;
  views?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Story_Stddev_Samp_Fields = {
  __typename?: 'story_stddev_samp_fields';
  number_of_approvals?: Maybe<Scalars['Float']>;
  number_of_rejections?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  ranking?: Maybe<Scalars['Float']>;
  views?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "story" */
export type Story_Stddev_Samp_Order_By = {
  number_of_approvals?: Maybe<Order_By>;
  number_of_rejections?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  ranking?: Maybe<Order_By>;
  views?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Story_Sum_Fields = {
  __typename?: 'story_sum_fields';
  number_of_approvals?: Maybe<Scalars['Int']>;
  number_of_rejections?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  ranking?: Maybe<Scalars['Int']>;
  views?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "story" */
export type Story_Sum_Order_By = {
  number_of_approvals?: Maybe<Order_By>;
  number_of_rejections?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  ranking?: Maybe<Order_By>;
  views?: Maybe<Order_By>;
};

/** columns and relationships of "story_tag" */
export type Story_Tag = {
  __typename?: 'story_tag';
  id: Scalars['uuid'];
  /** An object relationship */
  story: Story;
  story_id: Scalars['uuid'];
  /** An object relationship */
  tag: Tag;
  tag_id: Scalars['uuid'];
};

/** aggregated selection of "story_tag" */
export type Story_Tag_Aggregate = {
  __typename?: 'story_tag_aggregate';
  aggregate?: Maybe<Story_Tag_Aggregate_Fields>;
  nodes: Array<Story_Tag>;
};

/** aggregate fields of "story_tag" */
export type Story_Tag_Aggregate_Fields = {
  __typename?: 'story_tag_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Story_Tag_Max_Fields>;
  min?: Maybe<Story_Tag_Min_Fields>;
};


/** aggregate fields of "story_tag" */
export type Story_Tag_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Story_Tag_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "story_tag" */
export type Story_Tag_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Story_Tag_Max_Order_By>;
  min?: Maybe<Story_Tag_Min_Order_By>;
};

/** input type for inserting array relation for remote table "story_tag" */
export type Story_Tag_Arr_Rel_Insert_Input = {
  data: Array<Story_Tag_Insert_Input>;
  on_conflict?: Maybe<Story_Tag_On_Conflict>;
};

/** Boolean expression to filter rows from the table "story_tag". All fields are combined with a logical 'AND'. */
export type Story_Tag_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Story_Tag_Bool_Exp>>>;
  _not?: Maybe<Story_Tag_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Story_Tag_Bool_Exp>>>;
  id?: Maybe<Uuid_Comparison_Exp>;
  story?: Maybe<Story_Bool_Exp>;
  story_id?: Maybe<Uuid_Comparison_Exp>;
  tag?: Maybe<Tag_Bool_Exp>;
  tag_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "story_tag" */
export enum Story_Tag_Constraint {
  /** unique or primary key constraint */
  StoryTagPkey = 'story_tag_pkey',
  /** unique or primary key constraint */
  UniqueStoryIdTagId = 'unique_story_id_tag_id'
}

/** input type for inserting data into table "story_tag" */
export type Story_Tag_Insert_Input = {
  id?: Maybe<Scalars['uuid']>;
  story?: Maybe<Story_Obj_Rel_Insert_Input>;
  story_id?: Maybe<Scalars['uuid']>;
  tag?: Maybe<Tag_Obj_Rel_Insert_Input>;
  tag_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Story_Tag_Max_Fields = {
  __typename?: 'story_tag_max_fields';
  id?: Maybe<Scalars['uuid']>;
  story_id?: Maybe<Scalars['uuid']>;
  tag_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "story_tag" */
export type Story_Tag_Max_Order_By = {
  id?: Maybe<Order_By>;
  story_id?: Maybe<Order_By>;
  tag_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Story_Tag_Min_Fields = {
  __typename?: 'story_tag_min_fields';
  id?: Maybe<Scalars['uuid']>;
  story_id?: Maybe<Scalars['uuid']>;
  tag_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "story_tag" */
export type Story_Tag_Min_Order_By = {
  id?: Maybe<Order_By>;
  story_id?: Maybe<Order_By>;
  tag_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "story_tag" */
export type Story_Tag_Mutation_Response = {
  __typename?: 'story_tag_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Story_Tag>;
};

/** input type for inserting object relation for remote table "story_tag" */
export type Story_Tag_Obj_Rel_Insert_Input = {
  data: Story_Tag_Insert_Input;
  on_conflict?: Maybe<Story_Tag_On_Conflict>;
};

/** on conflict condition type for table "story_tag" */
export type Story_Tag_On_Conflict = {
  constraint: Story_Tag_Constraint;
  update_columns: Array<Story_Tag_Update_Column>;
  where?: Maybe<Story_Tag_Bool_Exp>;
};

/** ordering options when selecting data from "story_tag" */
export type Story_Tag_Order_By = {
  id?: Maybe<Order_By>;
  story?: Maybe<Story_Order_By>;
  story_id?: Maybe<Order_By>;
  tag?: Maybe<Tag_Order_By>;
  tag_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "story_tag" */
export type Story_Tag_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "story_tag" */
export enum Story_Tag_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  StoryId = 'story_id',
  /** column name */
  TagId = 'tag_id'
}

/** input type for updating data in table "story_tag" */
export type Story_Tag_Set_Input = {
  id?: Maybe<Scalars['uuid']>;
  story_id?: Maybe<Scalars['uuid']>;
  tag_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "story_tag" */
export enum Story_Tag_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  StoryId = 'story_id',
  /** column name */
  TagId = 'tag_id'
}

/** columns and relationships of "story_type" */
export type Story_Type = {
  __typename?: 'story_type';
  value: Scalars['String'];
};

/** aggregated selection of "story_type" */
export type Story_Type_Aggregate = {
  __typename?: 'story_type_aggregate';
  aggregate?: Maybe<Story_Type_Aggregate_Fields>;
  nodes: Array<Story_Type>;
};

/** aggregate fields of "story_type" */
export type Story_Type_Aggregate_Fields = {
  __typename?: 'story_type_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Story_Type_Max_Fields>;
  min?: Maybe<Story_Type_Min_Fields>;
};


/** aggregate fields of "story_type" */
export type Story_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Story_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "story_type" */
export type Story_Type_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Story_Type_Max_Order_By>;
  min?: Maybe<Story_Type_Min_Order_By>;
};

/** input type for inserting array relation for remote table "story_type" */
export type Story_Type_Arr_Rel_Insert_Input = {
  data: Array<Story_Type_Insert_Input>;
  on_conflict?: Maybe<Story_Type_On_Conflict>;
};

/** Boolean expression to filter rows from the table "story_type". All fields are combined with a logical 'AND'. */
export type Story_Type_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Story_Type_Bool_Exp>>>;
  _not?: Maybe<Story_Type_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Story_Type_Bool_Exp>>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "story_type" */
export enum Story_Type_Constraint {
  /** unique or primary key constraint */
  StoryTypePkey = 'story_type_pkey'
}

/** input type for inserting data into table "story_type" */
export type Story_Type_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Story_Type_Max_Fields = {
  __typename?: 'story_type_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "story_type" */
export type Story_Type_Max_Order_By = {
  value?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Story_Type_Min_Fields = {
  __typename?: 'story_type_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "story_type" */
export type Story_Type_Min_Order_By = {
  value?: Maybe<Order_By>;
};

/** response of any mutation on the table "story_type" */
export type Story_Type_Mutation_Response = {
  __typename?: 'story_type_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Story_Type>;
};

/** input type for inserting object relation for remote table "story_type" */
export type Story_Type_Obj_Rel_Insert_Input = {
  data: Story_Type_Insert_Input;
  on_conflict?: Maybe<Story_Type_On_Conflict>;
};

/** on conflict condition type for table "story_type" */
export type Story_Type_On_Conflict = {
  constraint: Story_Type_Constraint;
  update_columns: Array<Story_Type_Update_Column>;
  where?: Maybe<Story_Type_Bool_Exp>;
};

/** ordering options when selecting data from "story_type" */
export type Story_Type_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: "story_type" */
export type Story_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "story_type" */
export enum Story_Type_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "story_type" */
export type Story_Type_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "story_type" */
export enum Story_Type_Update_Column {
  /** column name */
  Value = 'value'
}

/** update columns of table "story" */
export enum Story_Update_Column {
  /** column name */
  Caption = 'caption',
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FirstSubmittedAt = 'first_submitted_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsNameIncluded = 'is_name_included',
  /** column name */
  IsShareable = 'is_shareable',
  /** column name */
  LastApprovedAt = 'last_approved_at',
  /** column name */
  LastRejectedAt = 'last_rejected_at',
  /** column name */
  LastReportedAt = 'last_reported_at',
  /** column name */
  LastSubmittedAt = 'last_submitted_at',
  /** column name */
  NumberOfApprovals = 'number_of_approvals',
  /** column name */
  NumberOfRejections = 'number_of_rejections',
  /** column name */
  Order = 'order',
  /** column name */
  PublishedAt = 'published_at',
  /** column name */
  Ranking = 'ranking',
  /** column name */
  ShowSuburb = 'show_suburb',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title',
  /** column name */
  Transcription = 'transcription',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Views = 'views'
}

/** aggregate var_pop on columns */
export type Story_Var_Pop_Fields = {
  __typename?: 'story_var_pop_fields';
  number_of_approvals?: Maybe<Scalars['Float']>;
  number_of_rejections?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  ranking?: Maybe<Scalars['Float']>;
  views?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "story" */
export type Story_Var_Pop_Order_By = {
  number_of_approvals?: Maybe<Order_By>;
  number_of_rejections?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  ranking?: Maybe<Order_By>;
  views?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Story_Var_Samp_Fields = {
  __typename?: 'story_var_samp_fields';
  number_of_approvals?: Maybe<Scalars['Float']>;
  number_of_rejections?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  ranking?: Maybe<Scalars['Float']>;
  views?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "story" */
export type Story_Var_Samp_Order_By = {
  number_of_approvals?: Maybe<Order_By>;
  number_of_rejections?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  ranking?: Maybe<Order_By>;
  views?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Story_Variance_Fields = {
  __typename?: 'story_variance_fields';
  number_of_approvals?: Maybe<Scalars['Float']>;
  number_of_rejections?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  ranking?: Maybe<Scalars['Float']>;
  views?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "story" */
export type Story_Variance_Order_By = {
  number_of_approvals?: Maybe<Order_By>;
  number_of_rejections?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  ranking?: Maybe<Order_By>;
  views?: Maybe<Order_By>;
};

/** columns and relationships of "story_violation" */
export type Story_Violation = {
  __typename?: 'story_violation';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  story: Story;
  story_id: Scalars['uuid'];
  /** An object relationship */
  user: User;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "story_violation" */
export type Story_Violation_Aggregate = {
  __typename?: 'story_violation_aggregate';
  aggregate?: Maybe<Story_Violation_Aggregate_Fields>;
  nodes: Array<Story_Violation>;
};

/** aggregate fields of "story_violation" */
export type Story_Violation_Aggregate_Fields = {
  __typename?: 'story_violation_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Story_Violation_Max_Fields>;
  min?: Maybe<Story_Violation_Min_Fields>;
};


/** aggregate fields of "story_violation" */
export type Story_Violation_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Story_Violation_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "story_violation" */
export type Story_Violation_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Story_Violation_Max_Order_By>;
  min?: Maybe<Story_Violation_Min_Order_By>;
};

/** input type for inserting array relation for remote table "story_violation" */
export type Story_Violation_Arr_Rel_Insert_Input = {
  data: Array<Story_Violation_Insert_Input>;
  on_conflict?: Maybe<Story_Violation_On_Conflict>;
};

/** Boolean expression to filter rows from the table "story_violation". All fields are combined with a logical 'AND'. */
export type Story_Violation_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Story_Violation_Bool_Exp>>>;
  _not?: Maybe<Story_Violation_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Story_Violation_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  story?: Maybe<Story_Bool_Exp>;
  story_id?: Maybe<Uuid_Comparison_Exp>;
  user?: Maybe<User_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "story_violation" */
export enum Story_Violation_Constraint {
  /** unique or primary key constraint */
  StoryViolationPkey = 'story_violation_pkey'
}

/** input type for inserting data into table "story_violation" */
export type Story_Violation_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  story?: Maybe<Story_Obj_Rel_Insert_Input>;
  story_id?: Maybe<Scalars['uuid']>;
  user?: Maybe<User_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Story_Violation_Max_Fields = {
  __typename?: 'story_violation_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  story_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "story_violation" */
export type Story_Violation_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  story_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Story_Violation_Min_Fields = {
  __typename?: 'story_violation_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  story_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "story_violation" */
export type Story_Violation_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  story_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "story_violation" */
export type Story_Violation_Mutation_Response = {
  __typename?: 'story_violation_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Story_Violation>;
};

/** input type for inserting object relation for remote table "story_violation" */
export type Story_Violation_Obj_Rel_Insert_Input = {
  data: Story_Violation_Insert_Input;
  on_conflict?: Maybe<Story_Violation_On_Conflict>;
};

/** on conflict condition type for table "story_violation" */
export type Story_Violation_On_Conflict = {
  constraint: Story_Violation_Constraint;
  update_columns: Array<Story_Violation_Update_Column>;
  where?: Maybe<Story_Violation_Bool_Exp>;
};

/** ordering options when selecting data from "story_violation" */
export type Story_Violation_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  story?: Maybe<Story_Order_By>;
  story_id?: Maybe<Order_By>;
  user?: Maybe<User_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "story_violation" */
export type Story_Violation_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "story_violation" */
export enum Story_Violation_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  StoryId = 'story_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "story_violation" */
export type Story_Violation_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  story_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "story_violation" */
export enum Story_Violation_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  StoryId = 'story_id',
  /** column name */
  UserId = 'user_id'
}

/** subscription root */
export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "assessment_item" */
  assessment_item: Array<Assessment_Item>;
  /** fetch aggregated fields from the table: "assessment_item" */
  assessment_item_aggregate: Assessment_Item_Aggregate;
  /** fetch data from the table: "assessment_item" using primary key columns */
  assessment_item_by_pk?: Maybe<Assessment_Item>;
  /** fetch data from the table: "audience" */
  audience: Array<Audience>;
  /** fetch aggregated fields from the table: "audience" */
  audience_aggregate: Audience_Aggregate;
  /** fetch data from the table: "audience" using primary key columns */
  audience_by_pk?: Maybe<Audience>;
  /** fetch data from the table: "auth.account_providers" */
  auth_account_providers: Array<Auth_Account_Providers>;
  /** fetch aggregated fields from the table: "auth.account_providers" */
  auth_account_providers_aggregate: Auth_Account_Providers_Aggregate;
  /** fetch data from the table: "auth.account_providers" using primary key columns */
  auth_account_providers_by_pk?: Maybe<Auth_Account_Providers>;
  /** fetch data from the table: "auth.account_roles" */
  auth_account_roles: Array<Auth_Account_Roles>;
  /** fetch aggregated fields from the table: "auth.account_roles" */
  auth_account_roles_aggregate: Auth_Account_Roles_Aggregate;
  /** fetch data from the table: "auth.account_roles" using primary key columns */
  auth_account_roles_by_pk?: Maybe<Auth_Account_Roles>;
  /** fetch data from the table: "auth.accounts" */
  auth_accounts: Array<Auth_Accounts>;
  /** fetch aggregated fields from the table: "auth.accounts" */
  auth_accounts_aggregate: Auth_Accounts_Aggregate;
  /** fetch data from the table: "auth.accounts" using primary key columns */
  auth_accounts_by_pk?: Maybe<Auth_Accounts>;
  /** fetch data from the table: "auth.providers" */
  auth_providers: Array<Auth_Providers>;
  /** fetch aggregated fields from the table: "auth.providers" */
  auth_providers_aggregate: Auth_Providers_Aggregate;
  /** fetch data from the table: "auth.providers" using primary key columns */
  auth_providers_by_pk?: Maybe<Auth_Providers>;
  /** fetch data from the table: "auth.refresh_tokens" */
  auth_refresh_tokens: Array<Auth_Refresh_Tokens>;
  /** fetch aggregated fields from the table: "auth.refresh_tokens" */
  auth_refresh_tokens_aggregate: Auth_Refresh_Tokens_Aggregate;
  /** fetch data from the table: "auth.refresh_tokens" using primary key columns */
  auth_refresh_tokens_by_pk?: Maybe<Auth_Refresh_Tokens>;
  /** fetch data from the table: "auth.roles" */
  auth_roles: Array<Auth_Roles>;
  /** fetch aggregated fields from the table: "auth.roles" */
  auth_roles_aggregate: Auth_Roles_Aggregate;
  /** fetch data from the table: "auth.roles" using primary key columns */
  auth_roles_by_pk?: Maybe<Auth_Roles>;
  /** fetch data from the table: "employment_status" */
  employment_status: Array<Employment_Status>;
  /** fetch aggregated fields from the table: "employment_status" */
  employment_status_aggregate: Employment_Status_Aggregate;
  /** fetch data from the table: "employment_status" using primary key columns */
  employment_status_by_pk?: Maybe<Employment_Status>;
  /** fetch data from the table: "feedback" */
  feedback: Array<Feedback>;
  /** fetch aggregated fields from the table: "feedback" */
  feedback_aggregate: Feedback_Aggregate;
  /** fetch data from the table: "feedback" using primary key columns */
  feedback_by_pk?: Maybe<Feedback>;
  /** fetch data from the table: "flag" */
  flag: Array<Flag>;
  /** fetch aggregated fields from the table: "flag" */
  flag_aggregate: Flag_Aggregate;
  /** fetch data from the table: "flag" using primary key columns */
  flag_by_pk?: Maybe<Flag>;
  /** fetch data from the table: "gender" */
  gender: Array<Gender>;
  /** fetch aggregated fields from the table: "gender" */
  gender_aggregate: Gender_Aggregate;
  /** fetch data from the table: "gender" using primary key columns */
  gender_by_pk?: Maybe<Gender>;
  /** perform the action: "generateSignedUrl" */
  generateSignedUrl?: Maybe<SignedUrlOutput>;
  /** perform the action: "getInfoTileUploadUrl" */
  getInfoTileUploadUrl?: Maybe<InfoTileUrlOutput>;
  /** fetch data from the table: "info_tile" */
  info_tile: Array<Info_Tile>;
  /** fetch aggregated fields from the table: "info_tile" */
  info_tile_aggregate: Info_Tile_Aggregate;
  /** fetch data from the table: "info_tile" using primary key columns */
  info_tile_by_pk?: Maybe<Info_Tile>;
  /** fetch data from the table: "info_tile_status_type" */
  info_tile_status_type: Array<Info_Tile_Status_Type>;
  /** fetch aggregated fields from the table: "info_tile_status_type" */
  info_tile_status_type_aggregate: Info_Tile_Status_Type_Aggregate;
  /** fetch data from the table: "info_tile_status_type" using primary key columns */
  info_tile_status_type_by_pk?: Maybe<Info_Tile_Status_Type>;
  /** fetch data from the table: "keyword" */
  keyword: Array<Keyword>;
  /** fetch aggregated fields from the table: "keyword" */
  keyword_aggregate: Keyword_Aggregate;
  /** fetch data from the table: "keyword" using primary key columns */
  keyword_by_pk?: Maybe<Keyword>;
  /** fetch data from the table: "keyword_referral" */
  keyword_referral: Array<Keyword_Referral>;
  /** fetch aggregated fields from the table: "keyword_referral" */
  keyword_referral_aggregate: Keyword_Referral_Aggregate;
  /** fetch data from the table: "keyword_referral" using primary key columns */
  keyword_referral_by_pk?: Maybe<Keyword_Referral>;
  /** fetch data from the table: "keyword_topic" */
  keyword_topic: Array<Keyword_Topic>;
  /** fetch aggregated fields from the table: "keyword_topic" */
  keyword_topic_aggregate: Keyword_Topic_Aggregate;
  /** fetch data from the table: "keyword_topic" using primary key columns */
  keyword_topic_by_pk?: Maybe<Keyword_Topic>;
  /** fetch data from the table: "moderator_action" */
  moderator_action: Array<Moderator_Action>;
  /** fetch aggregated fields from the table: "moderator_action" */
  moderator_action_aggregate: Moderator_Action_Aggregate;
  /** fetch data from the table: "moderator_action" using primary key columns */
  moderator_action_by_pk?: Maybe<Moderator_Action>;
  /** fetch data from the table: "moderator_note" */
  moderator_note: Array<Moderator_Note>;
  /** fetch aggregated fields from the table: "moderator_note" */
  moderator_note_aggregate: Moderator_Note_Aggregate;
  /** fetch data from the table: "moderator_note" using primary key columns */
  moderator_note_by_pk?: Maybe<Moderator_Note>;
  /** fetch data from the table: "prompt" */
  prompt: Array<Prompt>;
  /** fetch aggregated fields from the table: "prompt" */
  prompt_aggregate: Prompt_Aggregate;
  /** fetch data from the table: "prompt" using primary key columns */
  prompt_by_pk?: Maybe<Prompt>;
  /** fetch data from the table: "reaction" */
  reaction: Array<Reaction>;
  /** fetch aggregated fields from the table: "reaction" */
  reaction_aggregate: Reaction_Aggregate;
  /** fetch data from the table: "reaction" using primary key columns */
  reaction_by_pk?: Maybe<Reaction>;
  /** fetch data from the table: "reaction_type" */
  reaction_type: Array<Reaction_Type>;
  /** fetch aggregated fields from the table: "reaction_type" */
  reaction_type_aggregate: Reaction_Type_Aggregate;
  /** fetch data from the table: "reaction_type" using primary key columns */
  reaction_type_by_pk?: Maybe<Reaction_Type>;
  /** fetch data from the table: "referral" */
  referral: Array<Referral>;
  /** fetch aggregated fields from the table: "referral" */
  referral_aggregate: Referral_Aggregate;
  /** fetch data from the table: "referral" using primary key columns */
  referral_by_pk?: Maybe<Referral>;
  /** fetch data from the table: "reply" */
  reply: Array<Reply>;
  /** fetch aggregated fields from the table: "reply" */
  reply_aggregate: Reply_Aggregate;
  /** fetch data from the table: "reply" using primary key columns */
  reply_by_pk?: Maybe<Reply>;
  /** fetch data from the table: "resource" */
  resource: Array<Resource>;
  /** fetch aggregated fields from the table: "resource" */
  resource_aggregate: Resource_Aggregate;
  /** fetch data from the table: "resource" using primary key columns */
  resource_by_pk?: Maybe<Resource>;
  /** fetch data from the table: "resource_type" */
  resource_type: Array<Resource_Type>;
  /** fetch aggregated fields from the table: "resource_type" */
  resource_type_aggregate: Resource_Type_Aggregate;
  /** fetch data from the table: "resource_type" using primary key columns */
  resource_type_by_pk?: Maybe<Resource_Type>;
  /** fetch data from the table: "sentiment" */
  sentiment: Array<Sentiment>;
  /** fetch aggregated fields from the table: "sentiment" */
  sentiment_aggregate: Sentiment_Aggregate;
  /** fetch data from the table: "sentiment" using primary key columns */
  sentiment_by_pk?: Maybe<Sentiment>;
  /** fetch data from the table: "share" */
  share: Array<Share>;
  /** fetch aggregated fields from the table: "share" */
  share_aggregate: Share_Aggregate;
  /** fetch data from the table: "share" using primary key columns */
  share_by_pk?: Maybe<Share>;
  /** fetch data from the table: "story" */
  story: Array<Story>;
  /** fetch aggregated fields from the table: "story" */
  story_aggregate: Story_Aggregate;
  /** fetch data from the table: "story" using primary key columns */
  story_by_pk?: Maybe<Story>;
  /** fetch data from the table: "story_save" */
  story_save: Array<Story_Save>;
  /** fetch aggregated fields from the table: "story_save" */
  story_save_aggregate: Story_Save_Aggregate;
  /** fetch data from the table: "story_save" using primary key columns */
  story_save_by_pk?: Maybe<Story_Save>;
  /** fetch data from the table: "story_status" */
  story_status: Array<Story_Status>;
  /** fetch aggregated fields from the table: "story_status" */
  story_status_aggregate: Story_Status_Aggregate;
  /** fetch data from the table: "story_status" using primary key columns */
  story_status_by_pk?: Maybe<Story_Status>;
  /** fetch data from the table: "story_tag" */
  story_tag: Array<Story_Tag>;
  /** fetch aggregated fields from the table: "story_tag" */
  story_tag_aggregate: Story_Tag_Aggregate;
  /** fetch data from the table: "story_tag" using primary key columns */
  story_tag_by_pk?: Maybe<Story_Tag>;
  /** fetch data from the table: "story_type" */
  story_type: Array<Story_Type>;
  /** fetch aggregated fields from the table: "story_type" */
  story_type_aggregate: Story_Type_Aggregate;
  /** fetch data from the table: "story_type" using primary key columns */
  story_type_by_pk?: Maybe<Story_Type>;
  /** fetch data from the table: "story_violation" */
  story_violation: Array<Story_Violation>;
  /** fetch aggregated fields from the table: "story_violation" */
  story_violation_aggregate: Story_Violation_Aggregate;
  /** fetch data from the table: "story_violation" using primary key columns */
  story_violation_by_pk?: Maybe<Story_Violation>;
  /** fetch data from the table: "suburb" */
  suburb: Array<Suburb>;
  /** fetch aggregated fields from the table: "suburb" */
  suburb_aggregate: Suburb_Aggregate;
  /** fetch data from the table: "suburb" using primary key columns */
  suburb_by_pk?: Maybe<Suburb>;
  /** fetch data from the table: "support_group" */
  support_group: Array<Support_Group>;
  /** fetch aggregated fields from the table: "support_group" */
  support_group_aggregate: Support_Group_Aggregate;
  /** fetch data from the table: "support_group" using primary key columns */
  support_group_by_pk?: Maybe<Support_Group>;
  /** fetch data from the table: "tag" */
  tag: Array<Tag>;
  /** fetch aggregated fields from the table: "tag" */
  tag_aggregate: Tag_Aggregate;
  /** fetch data from the table: "tag" using primary key columns */
  tag_by_pk?: Maybe<Tag>;
  /** fetch data from the table: "tag_type" */
  tag_type: Array<Tag_Type>;
  /** fetch aggregated fields from the table: "tag_type" */
  tag_type_aggregate: Tag_Type_Aggregate;
  /** fetch data from the table: "tag_type" using primary key columns */
  tag_type_by_pk?: Maybe<Tag_Type>;
  /** fetch data from the table: "topic" */
  topic: Array<Topic>;
  /** fetch aggregated fields from the table: "topic" */
  topic_aggregate: Topic_Aggregate;
  /** fetch data from the table: "topic" using primary key columns */
  topic_by_pk?: Maybe<Topic>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
  /** fetch data from the table: "user_flag" */
  user_flag: Array<User_Flag>;
  /** fetch aggregated fields from the table: "user_flag" */
  user_flag_aggregate: User_Flag_Aggregate;
  /** fetch data from the table: "user_flag" using primary key columns */
  user_flag_by_pk?: Maybe<User_Flag>;
  /** fetch data from the table: "user_status" */
  user_status: Array<User_Status>;
  /** fetch aggregated fields from the table: "user_status" */
  user_status_aggregate: User_Status_Aggregate;
  /** fetch data from the table: "user_status" using primary key columns */
  user_status_by_pk?: Maybe<User_Status>;
  /** fetch data from the table: "user_support_group" */
  user_support_group: Array<User_Support_Group>;
  /** fetch aggregated fields from the table: "user_support_group" */
  user_support_group_aggregate: User_Support_Group_Aggregate;
  /** fetch data from the table: "user_support_group" using primary key columns */
  user_support_group_by_pk?: Maybe<User_Support_Group>;
  /** fetch data from the table: "violation_note" */
  violation_note: Array<Violation_Note>;
  /** fetch aggregated fields from the table: "violation_note" */
  violation_note_aggregate: Violation_Note_Aggregate;
  /** fetch data from the table: "violation_note" using primary key columns */
  violation_note_by_pk?: Maybe<Violation_Note>;
};


/** subscription root */
export type Subscription_RootAssessment_ItemArgs = {
  distinct_on?: Maybe<Array<Assessment_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Assessment_Item_Order_By>>;
  where?: Maybe<Assessment_Item_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAssessment_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Assessment_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Assessment_Item_Order_By>>;
  where?: Maybe<Assessment_Item_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAssessment_Item_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootAudienceArgs = {
  distinct_on?: Maybe<Array<Audience_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audience_Order_By>>;
  where?: Maybe<Audience_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAudience_AggregateArgs = {
  distinct_on?: Maybe<Array<Audience_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audience_Order_By>>;
  where?: Maybe<Audience_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAudience_By_PkArgs = {
  value: Scalars['String'];
};


/** subscription root */
export type Subscription_RootAuth_Account_ProvidersArgs = {
  distinct_on?: Maybe<Array<Auth_Account_Providers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Account_Providers_Order_By>>;
  where?: Maybe<Auth_Account_Providers_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAuth_Account_Providers_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Account_Providers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Account_Providers_Order_By>>;
  where?: Maybe<Auth_Account_Providers_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAuth_Account_Providers_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootAuth_Account_RolesArgs = {
  distinct_on?: Maybe<Array<Auth_Account_Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Account_Roles_Order_By>>;
  where?: Maybe<Auth_Account_Roles_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAuth_Account_Roles_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Account_Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Account_Roles_Order_By>>;
  where?: Maybe<Auth_Account_Roles_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAuth_Account_Roles_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootAuth_AccountsArgs = {
  distinct_on?: Maybe<Array<Auth_Accounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Accounts_Order_By>>;
  where?: Maybe<Auth_Accounts_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAuth_Accounts_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Accounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Accounts_Order_By>>;
  where?: Maybe<Auth_Accounts_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAuth_Accounts_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootAuth_ProvidersArgs = {
  distinct_on?: Maybe<Array<Auth_Providers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Providers_Order_By>>;
  where?: Maybe<Auth_Providers_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAuth_Providers_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Providers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Providers_Order_By>>;
  where?: Maybe<Auth_Providers_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAuth_Providers_By_PkArgs = {
  provider: Scalars['String'];
};


/** subscription root */
export type Subscription_RootAuth_Refresh_TokensArgs = {
  distinct_on?: Maybe<Array<Auth_Refresh_Tokens_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Refresh_Tokens_Order_By>>;
  where?: Maybe<Auth_Refresh_Tokens_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAuth_Refresh_Tokens_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Refresh_Tokens_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Refresh_Tokens_Order_By>>;
  where?: Maybe<Auth_Refresh_Tokens_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAuth_Refresh_Tokens_By_PkArgs = {
  refresh_token: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootAuth_RolesArgs = {
  distinct_on?: Maybe<Array<Auth_Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Roles_Order_By>>;
  where?: Maybe<Auth_Roles_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAuth_Roles_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Roles_Order_By>>;
  where?: Maybe<Auth_Roles_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAuth_Roles_By_PkArgs = {
  role: Scalars['String'];
};


/** subscription root */
export type Subscription_RootEmployment_StatusArgs = {
  distinct_on?: Maybe<Array<Employment_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Employment_Status_Order_By>>;
  where?: Maybe<Employment_Status_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEmployment_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Employment_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Employment_Status_Order_By>>;
  where?: Maybe<Employment_Status_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEmployment_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** subscription root */
export type Subscription_RootFeedbackArgs = {
  distinct_on?: Maybe<Array<Feedback_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Feedback_Order_By>>;
  where?: Maybe<Feedback_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootFeedback_AggregateArgs = {
  distinct_on?: Maybe<Array<Feedback_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Feedback_Order_By>>;
  where?: Maybe<Feedback_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootFeedback_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootFlagArgs = {
  distinct_on?: Maybe<Array<Flag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Flag_Order_By>>;
  where?: Maybe<Flag_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootFlag_AggregateArgs = {
  distinct_on?: Maybe<Array<Flag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Flag_Order_By>>;
  where?: Maybe<Flag_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootFlag_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootGenderArgs = {
  distinct_on?: Maybe<Array<Gender_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gender_Order_By>>;
  where?: Maybe<Gender_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootGender_AggregateArgs = {
  distinct_on?: Maybe<Array<Gender_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gender_Order_By>>;
  where?: Maybe<Gender_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootGender_By_PkArgs = {
  value: Scalars['String'];
};


/** subscription root */
export type Subscription_RootGenerateSignedUrlArgs = {
  resourceType: Scalars['String'];
};


/** subscription root */
export type Subscription_RootInfo_TileArgs = {
  distinct_on?: Maybe<Array<Info_Tile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Info_Tile_Order_By>>;
  where?: Maybe<Info_Tile_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootInfo_Tile_AggregateArgs = {
  distinct_on?: Maybe<Array<Info_Tile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Info_Tile_Order_By>>;
  where?: Maybe<Info_Tile_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootInfo_Tile_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootInfo_Tile_Status_TypeArgs = {
  distinct_on?: Maybe<Array<Info_Tile_Status_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Info_Tile_Status_Type_Order_By>>;
  where?: Maybe<Info_Tile_Status_Type_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootInfo_Tile_Status_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Info_Tile_Status_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Info_Tile_Status_Type_Order_By>>;
  where?: Maybe<Info_Tile_Status_Type_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootInfo_Tile_Status_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** subscription root */
export type Subscription_RootKeywordArgs = {
  distinct_on?: Maybe<Array<Keyword_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Keyword_Order_By>>;
  where?: Maybe<Keyword_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootKeyword_AggregateArgs = {
  distinct_on?: Maybe<Array<Keyword_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Keyword_Order_By>>;
  where?: Maybe<Keyword_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootKeyword_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootKeyword_ReferralArgs = {
  distinct_on?: Maybe<Array<Keyword_Referral_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Keyword_Referral_Order_By>>;
  where?: Maybe<Keyword_Referral_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootKeyword_Referral_AggregateArgs = {
  distinct_on?: Maybe<Array<Keyword_Referral_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Keyword_Referral_Order_By>>;
  where?: Maybe<Keyword_Referral_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootKeyword_Referral_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootKeyword_TopicArgs = {
  distinct_on?: Maybe<Array<Keyword_Topic_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Keyword_Topic_Order_By>>;
  where?: Maybe<Keyword_Topic_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootKeyword_Topic_AggregateArgs = {
  distinct_on?: Maybe<Array<Keyword_Topic_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Keyword_Topic_Order_By>>;
  where?: Maybe<Keyword_Topic_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootKeyword_Topic_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootModerator_ActionArgs = {
  distinct_on?: Maybe<Array<Moderator_Action_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Moderator_Action_Order_By>>;
  where?: Maybe<Moderator_Action_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootModerator_Action_AggregateArgs = {
  distinct_on?: Maybe<Array<Moderator_Action_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Moderator_Action_Order_By>>;
  where?: Maybe<Moderator_Action_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootModerator_Action_By_PkArgs = {
  value: Scalars['String'];
};


/** subscription root */
export type Subscription_RootModerator_NoteArgs = {
  distinct_on?: Maybe<Array<Moderator_Note_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Moderator_Note_Order_By>>;
  where?: Maybe<Moderator_Note_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootModerator_Note_AggregateArgs = {
  distinct_on?: Maybe<Array<Moderator_Note_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Moderator_Note_Order_By>>;
  where?: Maybe<Moderator_Note_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootModerator_Note_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootPromptArgs = {
  distinct_on?: Maybe<Array<Prompt_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Prompt_Order_By>>;
  where?: Maybe<Prompt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPrompt_AggregateArgs = {
  distinct_on?: Maybe<Array<Prompt_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Prompt_Order_By>>;
  where?: Maybe<Prompt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPrompt_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootReactionArgs = {
  distinct_on?: Maybe<Array<Reaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reaction_Order_By>>;
  where?: Maybe<Reaction_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootReaction_AggregateArgs = {
  distinct_on?: Maybe<Array<Reaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reaction_Order_By>>;
  where?: Maybe<Reaction_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootReaction_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootReaction_TypeArgs = {
  distinct_on?: Maybe<Array<Reaction_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reaction_Type_Order_By>>;
  where?: Maybe<Reaction_Type_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootReaction_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Reaction_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reaction_Type_Order_By>>;
  where?: Maybe<Reaction_Type_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootReaction_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** subscription root */
export type Subscription_RootReferralArgs = {
  distinct_on?: Maybe<Array<Referral_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Referral_Order_By>>;
  where?: Maybe<Referral_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootReferral_AggregateArgs = {
  distinct_on?: Maybe<Array<Referral_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Referral_Order_By>>;
  where?: Maybe<Referral_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootReferral_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootReplyArgs = {
  distinct_on?: Maybe<Array<Reply_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reply_Order_By>>;
  where?: Maybe<Reply_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootReply_AggregateArgs = {
  distinct_on?: Maybe<Array<Reply_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reply_Order_By>>;
  where?: Maybe<Reply_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootReply_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootResourceArgs = {
  distinct_on?: Maybe<Array<Resource_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Resource_Order_By>>;
  where?: Maybe<Resource_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootResource_AggregateArgs = {
  distinct_on?: Maybe<Array<Resource_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Resource_Order_By>>;
  where?: Maybe<Resource_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootResource_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootResource_TypeArgs = {
  distinct_on?: Maybe<Array<Resource_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Resource_Type_Order_By>>;
  where?: Maybe<Resource_Type_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootResource_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Resource_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Resource_Type_Order_By>>;
  where?: Maybe<Resource_Type_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootResource_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** subscription root */
export type Subscription_RootSentimentArgs = {
  distinct_on?: Maybe<Array<Sentiment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sentiment_Order_By>>;
  where?: Maybe<Sentiment_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSentiment_AggregateArgs = {
  distinct_on?: Maybe<Array<Sentiment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sentiment_Order_By>>;
  where?: Maybe<Sentiment_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSentiment_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootShareArgs = {
  distinct_on?: Maybe<Array<Share_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Share_Order_By>>;
  where?: Maybe<Share_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootShare_AggregateArgs = {
  distinct_on?: Maybe<Array<Share_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Share_Order_By>>;
  where?: Maybe<Share_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootShare_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootStoryArgs = {
  distinct_on?: Maybe<Array<Story_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Story_Order_By>>;
  where?: Maybe<Story_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootStory_AggregateArgs = {
  distinct_on?: Maybe<Array<Story_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Story_Order_By>>;
  where?: Maybe<Story_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootStory_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootStory_SaveArgs = {
  distinct_on?: Maybe<Array<Story_Save_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Story_Save_Order_By>>;
  where?: Maybe<Story_Save_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootStory_Save_AggregateArgs = {
  distinct_on?: Maybe<Array<Story_Save_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Story_Save_Order_By>>;
  where?: Maybe<Story_Save_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootStory_Save_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootStory_StatusArgs = {
  distinct_on?: Maybe<Array<Story_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Story_Status_Order_By>>;
  where?: Maybe<Story_Status_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootStory_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Story_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Story_Status_Order_By>>;
  where?: Maybe<Story_Status_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootStory_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** subscription root */
export type Subscription_RootStory_TagArgs = {
  distinct_on?: Maybe<Array<Story_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Story_Tag_Order_By>>;
  where?: Maybe<Story_Tag_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootStory_Tag_AggregateArgs = {
  distinct_on?: Maybe<Array<Story_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Story_Tag_Order_By>>;
  where?: Maybe<Story_Tag_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootStory_Tag_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootStory_TypeArgs = {
  distinct_on?: Maybe<Array<Story_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Story_Type_Order_By>>;
  where?: Maybe<Story_Type_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootStory_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Story_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Story_Type_Order_By>>;
  where?: Maybe<Story_Type_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootStory_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** subscription root */
export type Subscription_RootStory_ViolationArgs = {
  distinct_on?: Maybe<Array<Story_Violation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Story_Violation_Order_By>>;
  where?: Maybe<Story_Violation_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootStory_Violation_AggregateArgs = {
  distinct_on?: Maybe<Array<Story_Violation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Story_Violation_Order_By>>;
  where?: Maybe<Story_Violation_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootStory_Violation_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootSuburbArgs = {
  distinct_on?: Maybe<Array<Suburb_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Suburb_Order_By>>;
  where?: Maybe<Suburb_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSuburb_AggregateArgs = {
  distinct_on?: Maybe<Array<Suburb_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Suburb_Order_By>>;
  where?: Maybe<Suburb_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSuburb_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootSupport_GroupArgs = {
  distinct_on?: Maybe<Array<Support_Group_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Support_Group_Order_By>>;
  where?: Maybe<Support_Group_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSupport_Group_AggregateArgs = {
  distinct_on?: Maybe<Array<Support_Group_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Support_Group_Order_By>>;
  where?: Maybe<Support_Group_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSupport_Group_By_PkArgs = {
  value: Scalars['String'];
};


/** subscription root */
export type Subscription_RootTagArgs = {
  distinct_on?: Maybe<Array<Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tag_Order_By>>;
  where?: Maybe<Tag_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootTag_AggregateArgs = {
  distinct_on?: Maybe<Array<Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tag_Order_By>>;
  where?: Maybe<Tag_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootTag_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootTag_TypeArgs = {
  distinct_on?: Maybe<Array<Tag_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tag_Type_Order_By>>;
  where?: Maybe<Tag_Type_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootTag_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Tag_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tag_Type_Order_By>>;
  where?: Maybe<Tag_Type_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootTag_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** subscription root */
export type Subscription_RootTopicArgs = {
  distinct_on?: Maybe<Array<Topic_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Topic_Order_By>>;
  where?: Maybe<Topic_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootTopic_AggregateArgs = {
  distinct_on?: Maybe<Array<Topic_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Topic_Order_By>>;
  where?: Maybe<Topic_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootTopic_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootUserArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUser_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUser_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootUser_FlagArgs = {
  distinct_on?: Maybe<Array<User_Flag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Flag_Order_By>>;
  where?: Maybe<User_Flag_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUser_Flag_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Flag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Flag_Order_By>>;
  where?: Maybe<User_Flag_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUser_Flag_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootUser_StatusArgs = {
  distinct_on?: Maybe<Array<User_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Status_Order_By>>;
  where?: Maybe<User_Status_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUser_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Status_Order_By>>;
  where?: Maybe<User_Status_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUser_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** subscription root */
export type Subscription_RootUser_Support_GroupArgs = {
  distinct_on?: Maybe<Array<User_Support_Group_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Support_Group_Order_By>>;
  where?: Maybe<User_Support_Group_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUser_Support_Group_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Support_Group_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Support_Group_Order_By>>;
  where?: Maybe<User_Support_Group_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUser_Support_Group_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootViolation_NoteArgs = {
  distinct_on?: Maybe<Array<Violation_Note_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Violation_Note_Order_By>>;
  where?: Maybe<Violation_Note_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootViolation_Note_AggregateArgs = {
  distinct_on?: Maybe<Array<Violation_Note_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Violation_Note_Order_By>>;
  where?: Maybe<Violation_Note_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootViolation_Note_By_PkArgs = {
  id: Scalars['uuid'];
};

/** columns and relationships of "suburb" */
export type Suburb = {
  __typename?: 'suburb';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  post_code: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "suburb" */
export type Suburb_Aggregate = {
  __typename?: 'suburb_aggregate';
  aggregate?: Maybe<Suburb_Aggregate_Fields>;
  nodes: Array<Suburb>;
};

/** aggregate fields of "suburb" */
export type Suburb_Aggregate_Fields = {
  __typename?: 'suburb_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Suburb_Max_Fields>;
  min?: Maybe<Suburb_Min_Fields>;
};


/** aggregate fields of "suburb" */
export type Suburb_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Suburb_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "suburb" */
export type Suburb_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Suburb_Max_Order_By>;
  min?: Maybe<Suburb_Min_Order_By>;
};

/** input type for inserting array relation for remote table "suburb" */
export type Suburb_Arr_Rel_Insert_Input = {
  data: Array<Suburb_Insert_Input>;
  on_conflict?: Maybe<Suburb_On_Conflict>;
};

/** Boolean expression to filter rows from the table "suburb". All fields are combined with a logical 'AND'. */
export type Suburb_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Suburb_Bool_Exp>>>;
  _not?: Maybe<Suburb_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Suburb_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  post_code?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "suburb" */
export enum Suburb_Constraint {
  /** unique or primary key constraint */
  SuburbPkey = 'suburb_pkey'
}

/** input type for inserting data into table "suburb" */
export type Suburb_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  post_code?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Suburb_Max_Fields = {
  __typename?: 'suburb_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  post_code?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "suburb" */
export type Suburb_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  post_code?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Suburb_Min_Fields = {
  __typename?: 'suburb_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  post_code?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "suburb" */
export type Suburb_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  post_code?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "suburb" */
export type Suburb_Mutation_Response = {
  __typename?: 'suburb_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Suburb>;
};

/** input type for inserting object relation for remote table "suburb" */
export type Suburb_Obj_Rel_Insert_Input = {
  data: Suburb_Insert_Input;
  on_conflict?: Maybe<Suburb_On_Conflict>;
};

/** on conflict condition type for table "suburb" */
export type Suburb_On_Conflict = {
  constraint: Suburb_Constraint;
  update_columns: Array<Suburb_Update_Column>;
  where?: Maybe<Suburb_Bool_Exp>;
};

/** ordering options when selecting data from "suburb" */
export type Suburb_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  post_code?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "suburb" */
export type Suburb_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "suburb" */
export enum Suburb_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PostCode = 'post_code',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "suburb" */
export type Suburb_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  post_code?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "suburb" */
export enum Suburb_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PostCode = 'post_code',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "support_group" */
export type Support_Group = {
  __typename?: 'support_group';
  /** An array relationship */
  support_groups: Array<User_Support_Group>;
  /** An aggregated array relationship */
  support_groups_aggregate: User_Support_Group_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "support_group" */
export type Support_GroupSupport_GroupsArgs = {
  distinct_on?: Maybe<Array<User_Support_Group_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Support_Group_Order_By>>;
  where?: Maybe<User_Support_Group_Bool_Exp>;
};


/** columns and relationships of "support_group" */
export type Support_GroupSupport_Groups_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Support_Group_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Support_Group_Order_By>>;
  where?: Maybe<User_Support_Group_Bool_Exp>;
};

/** aggregated selection of "support_group" */
export type Support_Group_Aggregate = {
  __typename?: 'support_group_aggregate';
  aggregate?: Maybe<Support_Group_Aggregate_Fields>;
  nodes: Array<Support_Group>;
};

/** aggregate fields of "support_group" */
export type Support_Group_Aggregate_Fields = {
  __typename?: 'support_group_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Support_Group_Max_Fields>;
  min?: Maybe<Support_Group_Min_Fields>;
};


/** aggregate fields of "support_group" */
export type Support_Group_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Support_Group_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "support_group" */
export type Support_Group_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Support_Group_Max_Order_By>;
  min?: Maybe<Support_Group_Min_Order_By>;
};

/** input type for inserting array relation for remote table "support_group" */
export type Support_Group_Arr_Rel_Insert_Input = {
  data: Array<Support_Group_Insert_Input>;
  on_conflict?: Maybe<Support_Group_On_Conflict>;
};

/** Boolean expression to filter rows from the table "support_group". All fields are combined with a logical 'AND'. */
export type Support_Group_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Support_Group_Bool_Exp>>>;
  _not?: Maybe<Support_Group_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Support_Group_Bool_Exp>>>;
  support_groups?: Maybe<User_Support_Group_Bool_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "support_group" */
export enum Support_Group_Constraint {
  /** unique or primary key constraint */
  SupportGroupPkey = 'support_group_pkey'
}

export enum Support_Group_Enum {
  Coach = 'COACH',
  Family = 'FAMILY',
  Friends = 'FRIENDS',
  FriendsParent = 'FRIENDS_PARENT',
  Gp = 'GP',
  Neighbour = 'NEIGHBOUR',
  Psychologist = 'PSYCHOLOGIST',
  Teacher = 'TEACHER'
}

/** expression to compare columns of type support_group_enum. All fields are combined with logical 'AND'. */
export type Support_Group_Enum_Comparison_Exp = {
  _eq?: Maybe<Support_Group_Enum>;
  _in?: Maybe<Array<Support_Group_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Support_Group_Enum>;
  _nin?: Maybe<Array<Support_Group_Enum>>;
};

/** input type for inserting data into table "support_group" */
export type Support_Group_Insert_Input = {
  support_groups?: Maybe<User_Support_Group_Arr_Rel_Insert_Input>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Support_Group_Max_Fields = {
  __typename?: 'support_group_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "support_group" */
export type Support_Group_Max_Order_By = {
  value?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Support_Group_Min_Fields = {
  __typename?: 'support_group_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "support_group" */
export type Support_Group_Min_Order_By = {
  value?: Maybe<Order_By>;
};

/** response of any mutation on the table "support_group" */
export type Support_Group_Mutation_Response = {
  __typename?: 'support_group_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Support_Group>;
};

/** input type for inserting object relation for remote table "support_group" */
export type Support_Group_Obj_Rel_Insert_Input = {
  data: Support_Group_Insert_Input;
  on_conflict?: Maybe<Support_Group_On_Conflict>;
};

/** on conflict condition type for table "support_group" */
export type Support_Group_On_Conflict = {
  constraint: Support_Group_Constraint;
  update_columns: Array<Support_Group_Update_Column>;
  where?: Maybe<Support_Group_Bool_Exp>;
};

/** ordering options when selecting data from "support_group" */
export type Support_Group_Order_By = {
  support_groups_aggregate?: Maybe<User_Support_Group_Aggregate_Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: "support_group" */
export type Support_Group_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "support_group" */
export enum Support_Group_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "support_group" */
export type Support_Group_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "support_group" */
export enum Support_Group_Update_Column {
  /** column name */
  Value = 'value'
}

/** columns and relationships of "tag" */
export type Tag = {
  __typename?: 'tag';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  /** An object relationship */
  tag_type: Tag_Type;
  /** An array relationship */
  tags: Array<Story_Tag>;
  /** An aggregated array relationship */
  tags_aggregate: Story_Tag_Aggregate;
  type: Tag_Type_Enum;
  updated_at: Scalars['timestamptz'];
  verified: Scalars['Boolean'];
};


/** columns and relationships of "tag" */
export type TagTagsArgs = {
  distinct_on?: Maybe<Array<Story_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Story_Tag_Order_By>>;
  where?: Maybe<Story_Tag_Bool_Exp>;
};


/** columns and relationships of "tag" */
export type TagTags_AggregateArgs = {
  distinct_on?: Maybe<Array<Story_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Story_Tag_Order_By>>;
  where?: Maybe<Story_Tag_Bool_Exp>;
};

/** aggregated selection of "tag" */
export type Tag_Aggregate = {
  __typename?: 'tag_aggregate';
  aggregate?: Maybe<Tag_Aggregate_Fields>;
  nodes: Array<Tag>;
};

/** aggregate fields of "tag" */
export type Tag_Aggregate_Fields = {
  __typename?: 'tag_aggregate_fields';
  avg?: Maybe<Tag_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Tag_Max_Fields>;
  min?: Maybe<Tag_Min_Fields>;
  stddev?: Maybe<Tag_Stddev_Fields>;
  stddev_pop?: Maybe<Tag_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tag_Stddev_Samp_Fields>;
  sum?: Maybe<Tag_Sum_Fields>;
  var_pop?: Maybe<Tag_Var_Pop_Fields>;
  var_samp?: Maybe<Tag_Var_Samp_Fields>;
  variance?: Maybe<Tag_Variance_Fields>;
};


/** aggregate fields of "tag" */
export type Tag_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Tag_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "tag" */
export type Tag_Aggregate_Order_By = {
  avg?: Maybe<Tag_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Tag_Max_Order_By>;
  min?: Maybe<Tag_Min_Order_By>;
  stddev?: Maybe<Tag_Stddev_Order_By>;
  stddev_pop?: Maybe<Tag_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Tag_Stddev_Samp_Order_By>;
  sum?: Maybe<Tag_Sum_Order_By>;
  var_pop?: Maybe<Tag_Var_Pop_Order_By>;
  var_samp?: Maybe<Tag_Var_Samp_Order_By>;
  variance?: Maybe<Tag_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "tag" */
export type Tag_Arr_Rel_Insert_Input = {
  data: Array<Tag_Insert_Input>;
  on_conflict?: Maybe<Tag_On_Conflict>;
};

/** aggregate avg on columns */
export type Tag_Avg_Fields = {
  __typename?: 'tag_avg_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "tag" */
export type Tag_Avg_Order_By = {
  order?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "tag". All fields are combined with a logical 'AND'. */
export type Tag_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Tag_Bool_Exp>>>;
  _not?: Maybe<Tag_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Tag_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  order?: Maybe<Int_Comparison_Exp>;
  tag_type?: Maybe<Tag_Type_Bool_Exp>;
  tags?: Maybe<Story_Tag_Bool_Exp>;
  type?: Maybe<Tag_Type_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  verified?: Maybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "tag" */
export enum Tag_Constraint {
  /** unique or primary key constraint */
  TagPkey = 'tag_pkey'
}

/** input type for incrementing integer column in table "tag" */
export type Tag_Inc_Input = {
  order?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "tag" */
export type Tag_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  tag_type?: Maybe<Tag_Type_Obj_Rel_Insert_Input>;
  tags?: Maybe<Story_Tag_Arr_Rel_Insert_Input>;
  type?: Maybe<Tag_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  verified?: Maybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type Tag_Max_Fields = {
  __typename?: 'tag_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "tag" */
export type Tag_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Tag_Min_Fields = {
  __typename?: 'tag_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "tag" */
export type Tag_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "tag" */
export type Tag_Mutation_Response = {
  __typename?: 'tag_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Tag>;
};

/** input type for inserting object relation for remote table "tag" */
export type Tag_Obj_Rel_Insert_Input = {
  data: Tag_Insert_Input;
  on_conflict?: Maybe<Tag_On_Conflict>;
};

/** on conflict condition type for table "tag" */
export type Tag_On_Conflict = {
  constraint: Tag_Constraint;
  update_columns: Array<Tag_Update_Column>;
  where?: Maybe<Tag_Bool_Exp>;
};

/** ordering options when selecting data from "tag" */
export type Tag_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  tag_type?: Maybe<Tag_Type_Order_By>;
  tags_aggregate?: Maybe<Story_Tag_Aggregate_Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  verified?: Maybe<Order_By>;
};

/** primary key columns input for table: "tag" */
export type Tag_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "tag" */
export enum Tag_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Verified = 'verified'
}

/** input type for updating data in table "tag" */
export type Tag_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  type?: Maybe<Tag_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  verified?: Maybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type Tag_Stddev_Fields = {
  __typename?: 'tag_stddev_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "tag" */
export type Tag_Stddev_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Tag_Stddev_Pop_Fields = {
  __typename?: 'tag_stddev_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "tag" */
export type Tag_Stddev_Pop_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Tag_Stddev_Samp_Fields = {
  __typename?: 'tag_stddev_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "tag" */
export type Tag_Stddev_Samp_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Tag_Sum_Fields = {
  __typename?: 'tag_sum_fields';
  order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "tag" */
export type Tag_Sum_Order_By = {
  order?: Maybe<Order_By>;
};

/** columns and relationships of "tag_type" */
export type Tag_Type = {
  __typename?: 'tag_type';
  /** An array relationship */
  tags: Array<Tag>;
  /** An aggregated array relationship */
  tags_aggregate: Tag_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "tag_type" */
export type Tag_TypeTagsArgs = {
  distinct_on?: Maybe<Array<Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tag_Order_By>>;
  where?: Maybe<Tag_Bool_Exp>;
};


/** columns and relationships of "tag_type" */
export type Tag_TypeTags_AggregateArgs = {
  distinct_on?: Maybe<Array<Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tag_Order_By>>;
  where?: Maybe<Tag_Bool_Exp>;
};

/** aggregated selection of "tag_type" */
export type Tag_Type_Aggregate = {
  __typename?: 'tag_type_aggregate';
  aggregate?: Maybe<Tag_Type_Aggregate_Fields>;
  nodes: Array<Tag_Type>;
};

/** aggregate fields of "tag_type" */
export type Tag_Type_Aggregate_Fields = {
  __typename?: 'tag_type_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Tag_Type_Max_Fields>;
  min?: Maybe<Tag_Type_Min_Fields>;
};


/** aggregate fields of "tag_type" */
export type Tag_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Tag_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "tag_type" */
export type Tag_Type_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Tag_Type_Max_Order_By>;
  min?: Maybe<Tag_Type_Min_Order_By>;
};

/** input type for inserting array relation for remote table "tag_type" */
export type Tag_Type_Arr_Rel_Insert_Input = {
  data: Array<Tag_Type_Insert_Input>;
  on_conflict?: Maybe<Tag_Type_On_Conflict>;
};

/** Boolean expression to filter rows from the table "tag_type". All fields are combined with a logical 'AND'. */
export type Tag_Type_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Tag_Type_Bool_Exp>>>;
  _not?: Maybe<Tag_Type_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Tag_Type_Bool_Exp>>>;
  tags?: Maybe<Tag_Bool_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "tag_type" */
export enum Tag_Type_Constraint {
  /** unique or primary key constraint */
  TagTypePkey = 'tag_type_pkey'
}

export enum Tag_Type_Enum {
  Experience = 'EXPERIENCE',
  Framing = 'FRAMING'
}

/** expression to compare columns of type tag_type_enum. All fields are combined with logical 'AND'. */
export type Tag_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Tag_Type_Enum>;
  _in?: Maybe<Array<Tag_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Tag_Type_Enum>;
  _nin?: Maybe<Array<Tag_Type_Enum>>;
};

/** input type for inserting data into table "tag_type" */
export type Tag_Type_Insert_Input = {
  tags?: Maybe<Tag_Arr_Rel_Insert_Input>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Tag_Type_Max_Fields = {
  __typename?: 'tag_type_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "tag_type" */
export type Tag_Type_Max_Order_By = {
  value?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Tag_Type_Min_Fields = {
  __typename?: 'tag_type_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "tag_type" */
export type Tag_Type_Min_Order_By = {
  value?: Maybe<Order_By>;
};

/** response of any mutation on the table "tag_type" */
export type Tag_Type_Mutation_Response = {
  __typename?: 'tag_type_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Tag_Type>;
};

/** input type for inserting object relation for remote table "tag_type" */
export type Tag_Type_Obj_Rel_Insert_Input = {
  data: Tag_Type_Insert_Input;
  on_conflict?: Maybe<Tag_Type_On_Conflict>;
};

/** on conflict condition type for table "tag_type" */
export type Tag_Type_On_Conflict = {
  constraint: Tag_Type_Constraint;
  update_columns: Array<Tag_Type_Update_Column>;
  where?: Maybe<Tag_Type_Bool_Exp>;
};

/** ordering options when selecting data from "tag_type" */
export type Tag_Type_Order_By = {
  tags_aggregate?: Maybe<Tag_Aggregate_Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: "tag_type" */
export type Tag_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "tag_type" */
export enum Tag_Type_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "tag_type" */
export type Tag_Type_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "tag_type" */
export enum Tag_Type_Update_Column {
  /** column name */
  Value = 'value'
}

/** update columns of table "tag" */
export enum Tag_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Verified = 'verified'
}

/** aggregate var_pop on columns */
export type Tag_Var_Pop_Fields = {
  __typename?: 'tag_var_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "tag" */
export type Tag_Var_Pop_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Tag_Var_Samp_Fields = {
  __typename?: 'tag_var_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "tag" */
export type Tag_Var_Samp_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Tag_Variance_Fields = {
  __typename?: 'tag_variance_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "tag" */
export type Tag_Variance_Order_By = {
  order?: Maybe<Order_By>;
};


/** expression to compare columns of type timestamp. All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamp']>;
  _gt?: Maybe<Scalars['timestamp']>;
  _gte?: Maybe<Scalars['timestamp']>;
  _in?: Maybe<Array<Scalars['timestamp']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamp']>;
  _lte?: Maybe<Scalars['timestamp']>;
  _neq?: Maybe<Scalars['timestamp']>;
  _nin?: Maybe<Array<Scalars['timestamp']>>;
};


/** expression to compare columns of type timestamptz. All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "topic" */
export type Topic = {
  __typename?: 'topic';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An array relationship */
  keyword_topics: Array<Keyword_Topic>;
  /** An aggregated array relationship */
  keyword_topics_aggregate: Keyword_Topic_Aggregate;
  updated_at: Scalars['timestamptz'];
  value: Scalars['String'];
};


/** columns and relationships of "topic" */
export type TopicKeyword_TopicsArgs = {
  distinct_on?: Maybe<Array<Keyword_Topic_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Keyword_Topic_Order_By>>;
  where?: Maybe<Keyword_Topic_Bool_Exp>;
};


/** columns and relationships of "topic" */
export type TopicKeyword_Topics_AggregateArgs = {
  distinct_on?: Maybe<Array<Keyword_Topic_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Keyword_Topic_Order_By>>;
  where?: Maybe<Keyword_Topic_Bool_Exp>;
};

/** aggregated selection of "topic" */
export type Topic_Aggregate = {
  __typename?: 'topic_aggregate';
  aggregate?: Maybe<Topic_Aggregate_Fields>;
  nodes: Array<Topic>;
};

/** aggregate fields of "topic" */
export type Topic_Aggregate_Fields = {
  __typename?: 'topic_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Topic_Max_Fields>;
  min?: Maybe<Topic_Min_Fields>;
};


/** aggregate fields of "topic" */
export type Topic_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Topic_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "topic" */
export type Topic_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Topic_Max_Order_By>;
  min?: Maybe<Topic_Min_Order_By>;
};

/** input type for inserting array relation for remote table "topic" */
export type Topic_Arr_Rel_Insert_Input = {
  data: Array<Topic_Insert_Input>;
  on_conflict?: Maybe<Topic_On_Conflict>;
};

/** Boolean expression to filter rows from the table "topic". All fields are combined with a logical 'AND'. */
export type Topic_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Topic_Bool_Exp>>>;
  _not?: Maybe<Topic_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Topic_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  keyword_topics?: Maybe<Keyword_Topic_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "topic" */
export enum Topic_Constraint {
  /** unique or primary key constraint */
  TopicPkey = 'topic_pkey'
}

/** input type for inserting data into table "topic" */
export type Topic_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  keyword_topics?: Maybe<Keyword_Topic_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Topic_Max_Fields = {
  __typename?: 'topic_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "topic" */
export type Topic_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Topic_Min_Fields = {
  __typename?: 'topic_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "topic" */
export type Topic_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** response of any mutation on the table "topic" */
export type Topic_Mutation_Response = {
  __typename?: 'topic_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Topic>;
};

/** input type for inserting object relation for remote table "topic" */
export type Topic_Obj_Rel_Insert_Input = {
  data: Topic_Insert_Input;
  on_conflict?: Maybe<Topic_On_Conflict>;
};

/** on conflict condition type for table "topic" */
export type Topic_On_Conflict = {
  constraint: Topic_Constraint;
  update_columns: Array<Topic_Update_Column>;
  where?: Maybe<Topic_Bool_Exp>;
};

/** ordering options when selecting data from "topic" */
export type Topic_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  keyword_topics_aggregate?: Maybe<Keyword_Topic_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: "topic" */
export type Topic_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "topic" */
export enum Topic_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "topic" */
export type Topic_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "topic" */
export enum Topic_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** columns and relationships of "user" */
export type User = {
  __typename?: 'user';
  accepts_email: Scalars['Boolean'];
  accepts_notification: Scalars['Boolean'];
  /** An object relationship */
  account?: Maybe<Auth_Accounts>;
  avatar_url?: Maybe<Scalars['String']>;
  completed_profile_at?: Maybe<Scalars['timestamp']>;
  created_at: Scalars['timestamptz'];
  date_of_birth?: Maybe<Scalars['date']>;
  display_name?: Maybe<Scalars['String']>;
  employment_status?: Maybe<Scalars['String']>;
  favourite_animal?: Maybe<Scalars['String']>;
  favourite_memes?: Maybe<Scalars['String']>;
  favourite_place?: Maybe<Scalars['String']>;
  favourite_spotify_playlists?: Maybe<Scalars['String']>;
  /** An array relationship */
  feedbacks: Array<Feedback>;
  /** An aggregated array relationship */
  feedbacks_aggregate: Feedback_Aggregate;
  first_name?: Maybe<Scalars['String']>;
  /** An array relationship */
  flags: Array<User_Flag>;
  /** An aggregated array relationship */
  flags_aggregate: User_Flag_Aggregate;
  gender?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  just_get_clear_anything_bad_happened?: Maybe<Scalars['String']>;
  just_get_clear_anything_bad_happened_description?: Maybe<Scalars['String']>;
  just_get_clear_feeling?: Maybe<Scalars['String']>;
  just_get_clear_feeling_description?: Maybe<Scalars['String']>;
  just_get_clear_how_long_has_this_been_going?: Maybe<Scalars['String']>;
  just_get_clear_shared_with_anyone?: Maybe<Scalars['String']>;
  mobile_number?: Maybe<Scalars['String']>;
  /** An array relationship */
  moderator_notes: Array<Moderator_Note>;
  /** An aggregated array relationship */
  moderator_notes_aggregate: Moderator_Note_Aggregate;
  personal_description?: Maybe<Scalars['String']>;
  personal_notes?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  push_new_story: Scalars['Boolean'];
  push_story_approved: Scalars['Boolean'];
  push_story_liked: Scalars['Boolean'];
  push_story_saved: Scalars['Boolean'];
  push_story_shared: Scalars['Boolean'];
  push_token?: Maybe<Scalars['String']>;
  pushed_new_story_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  reactions: Array<Reaction>;
  /** An aggregated array relationship */
  reactions_aggregate: Reaction_Aggregate;
  screener_score?: Maybe<Scalars['Int']>;
  /** An array relationship */
  shares: Array<Share>;
  /** An aggregated array relationship */
  shares_aggregate: Share_Aggregate;
  show_intro: Scalars['Boolean'];
  state?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  /** An array relationship */
  stories: Array<Story>;
  /** An aggregated array relationship */
  stories_aggregate: Story_Aggregate;
  /** An array relationship */
  story_saves: Array<Story_Save>;
  /** An aggregated array relationship */
  story_saves_aggregate: Story_Save_Aggregate;
  /** An array relationship */
  story_violations: Array<Story_Violation>;
  /** An aggregated array relationship */
  story_violations_aggregate: Story_Violation_Aggregate;
  suburb?: Maybe<Scalars['String']>;
  /** An array relationship */
  support_groups: Array<User_Support_Group>;
  /** An aggregated array relationship */
  support_groups_aggregate: User_Support_Group_Aggregate;
  updated_at: Scalars['timestamptz'];
  verification_token?: Maybe<Scalars['String']>;
  verified_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  violation_notes: Array<Violation_Note>;
  /** An aggregated array relationship */
  violation_notes_aggregate: Violation_Note_Aggregate;
};


/** columns and relationships of "user" */
export type UserFeedbacksArgs = {
  distinct_on?: Maybe<Array<Feedback_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Feedback_Order_By>>;
  where?: Maybe<Feedback_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserFeedbacks_AggregateArgs = {
  distinct_on?: Maybe<Array<Feedback_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Feedback_Order_By>>;
  where?: Maybe<Feedback_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserFlagsArgs = {
  distinct_on?: Maybe<Array<User_Flag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Flag_Order_By>>;
  where?: Maybe<User_Flag_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserFlags_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Flag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Flag_Order_By>>;
  where?: Maybe<User_Flag_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserModerator_NotesArgs = {
  distinct_on?: Maybe<Array<Moderator_Note_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Moderator_Note_Order_By>>;
  where?: Maybe<Moderator_Note_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserModerator_Notes_AggregateArgs = {
  distinct_on?: Maybe<Array<Moderator_Note_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Moderator_Note_Order_By>>;
  where?: Maybe<Moderator_Note_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserReactionsArgs = {
  distinct_on?: Maybe<Array<Reaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reaction_Order_By>>;
  where?: Maybe<Reaction_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserReactions_AggregateArgs = {
  distinct_on?: Maybe<Array<Reaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reaction_Order_By>>;
  where?: Maybe<Reaction_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserSharesArgs = {
  distinct_on?: Maybe<Array<Share_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Share_Order_By>>;
  where?: Maybe<Share_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserShares_AggregateArgs = {
  distinct_on?: Maybe<Array<Share_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Share_Order_By>>;
  where?: Maybe<Share_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserStoriesArgs = {
  distinct_on?: Maybe<Array<Story_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Story_Order_By>>;
  where?: Maybe<Story_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserStories_AggregateArgs = {
  distinct_on?: Maybe<Array<Story_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Story_Order_By>>;
  where?: Maybe<Story_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserStory_SavesArgs = {
  distinct_on?: Maybe<Array<Story_Save_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Story_Save_Order_By>>;
  where?: Maybe<Story_Save_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserStory_Saves_AggregateArgs = {
  distinct_on?: Maybe<Array<Story_Save_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Story_Save_Order_By>>;
  where?: Maybe<Story_Save_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserStory_ViolationsArgs = {
  distinct_on?: Maybe<Array<Story_Violation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Story_Violation_Order_By>>;
  where?: Maybe<Story_Violation_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserStory_Violations_AggregateArgs = {
  distinct_on?: Maybe<Array<Story_Violation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Story_Violation_Order_By>>;
  where?: Maybe<Story_Violation_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserSupport_GroupsArgs = {
  distinct_on?: Maybe<Array<User_Support_Group_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Support_Group_Order_By>>;
  where?: Maybe<User_Support_Group_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserSupport_Groups_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Support_Group_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Support_Group_Order_By>>;
  where?: Maybe<User_Support_Group_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserViolation_NotesArgs = {
  distinct_on?: Maybe<Array<Violation_Note_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Violation_Note_Order_By>>;
  where?: Maybe<Violation_Note_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserViolation_Notes_AggregateArgs = {
  distinct_on?: Maybe<Array<Violation_Note_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Violation_Note_Order_By>>;
  where?: Maybe<Violation_Note_Bool_Exp>;
};

/** aggregated selection of "user" */
export type User_Aggregate = {
  __typename?: 'user_aggregate';
  aggregate?: Maybe<User_Aggregate_Fields>;
  nodes: Array<User>;
};

/** aggregate fields of "user" */
export type User_Aggregate_Fields = {
  __typename?: 'user_aggregate_fields';
  avg?: Maybe<User_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<User_Max_Fields>;
  min?: Maybe<User_Min_Fields>;
  stddev?: Maybe<User_Stddev_Fields>;
  stddev_pop?: Maybe<User_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Stddev_Samp_Fields>;
  sum?: Maybe<User_Sum_Fields>;
  var_pop?: Maybe<User_Var_Pop_Fields>;
  var_samp?: Maybe<User_Var_Samp_Fields>;
  variance?: Maybe<User_Variance_Fields>;
};


/** aggregate fields of "user" */
export type User_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user" */
export type User_Aggregate_Order_By = {
  avg?: Maybe<User_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<User_Max_Order_By>;
  min?: Maybe<User_Min_Order_By>;
  stddev?: Maybe<User_Stddev_Order_By>;
  stddev_pop?: Maybe<User_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<User_Stddev_Samp_Order_By>;
  sum?: Maybe<User_Sum_Order_By>;
  var_pop?: Maybe<User_Var_Pop_Order_By>;
  var_samp?: Maybe<User_Var_Samp_Order_By>;
  variance?: Maybe<User_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "user" */
export type User_Arr_Rel_Insert_Input = {
  data: Array<User_Insert_Input>;
  on_conflict?: Maybe<User_On_Conflict>;
};

/** aggregate avg on columns */
export type User_Avg_Fields = {
  __typename?: 'user_avg_fields';
  screener_score?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "user" */
export type User_Avg_Order_By = {
  screener_score?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "user". All fields are combined with a logical 'AND'. */
export type User_Bool_Exp = {
  _and?: Maybe<Array<Maybe<User_Bool_Exp>>>;
  _not?: Maybe<User_Bool_Exp>;
  _or?: Maybe<Array<Maybe<User_Bool_Exp>>>;
  accepts_email?: Maybe<Boolean_Comparison_Exp>;
  accepts_notification?: Maybe<Boolean_Comparison_Exp>;
  account?: Maybe<Auth_Accounts_Bool_Exp>;
  avatar_url?: Maybe<String_Comparison_Exp>;
  completed_profile_at?: Maybe<Timestamp_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  date_of_birth?: Maybe<Date_Comparison_Exp>;
  display_name?: Maybe<String_Comparison_Exp>;
  employment_status?: Maybe<String_Comparison_Exp>;
  favourite_animal?: Maybe<String_Comparison_Exp>;
  favourite_memes?: Maybe<String_Comparison_Exp>;
  favourite_place?: Maybe<String_Comparison_Exp>;
  favourite_spotify_playlists?: Maybe<String_Comparison_Exp>;
  feedbacks?: Maybe<Feedback_Bool_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  flags?: Maybe<User_Flag_Bool_Exp>;
  gender?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  just_get_clear_anything_bad_happened?: Maybe<String_Comparison_Exp>;
  just_get_clear_anything_bad_happened_description?: Maybe<String_Comparison_Exp>;
  just_get_clear_feeling?: Maybe<String_Comparison_Exp>;
  just_get_clear_feeling_description?: Maybe<String_Comparison_Exp>;
  just_get_clear_how_long_has_this_been_going?: Maybe<String_Comparison_Exp>;
  just_get_clear_shared_with_anyone?: Maybe<String_Comparison_Exp>;
  mobile_number?: Maybe<String_Comparison_Exp>;
  moderator_notes?: Maybe<Moderator_Note_Bool_Exp>;
  personal_description?: Maybe<String_Comparison_Exp>;
  personal_notes?: Maybe<String_Comparison_Exp>;
  postal_code?: Maybe<String_Comparison_Exp>;
  push_new_story?: Maybe<Boolean_Comparison_Exp>;
  push_story_approved?: Maybe<Boolean_Comparison_Exp>;
  push_story_liked?: Maybe<Boolean_Comparison_Exp>;
  push_story_saved?: Maybe<Boolean_Comparison_Exp>;
  push_story_shared?: Maybe<Boolean_Comparison_Exp>;
  push_token?: Maybe<String_Comparison_Exp>;
  pushed_new_story_at?: Maybe<Timestamptz_Comparison_Exp>;
  reactions?: Maybe<Reaction_Bool_Exp>;
  screener_score?: Maybe<Int_Comparison_Exp>;
  shares?: Maybe<Share_Bool_Exp>;
  show_intro?: Maybe<Boolean_Comparison_Exp>;
  state?: Maybe<String_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  stories?: Maybe<Story_Bool_Exp>;
  story_saves?: Maybe<Story_Save_Bool_Exp>;
  story_violations?: Maybe<Story_Violation_Bool_Exp>;
  suburb?: Maybe<String_Comparison_Exp>;
  support_groups?: Maybe<User_Support_Group_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  verification_token?: Maybe<String_Comparison_Exp>;
  verified_at?: Maybe<Timestamptz_Comparison_Exp>;
  violation_notes?: Maybe<Violation_Note_Bool_Exp>;
};

/** unique or primary key constraints on table "user" */
export enum User_Constraint {
  /** unique or primary key constraint */
  UserPkey = 'user_pkey'
}

/** columns and relationships of "user_flag" */
export type User_Flag = {
  __typename?: 'user_flag';
  /** An object relationship */
  flag: Flag;
  flag_id: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  user: User;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "user_flag" */
export type User_Flag_Aggregate = {
  __typename?: 'user_flag_aggregate';
  aggregate?: Maybe<User_Flag_Aggregate_Fields>;
  nodes: Array<User_Flag>;
};

/** aggregate fields of "user_flag" */
export type User_Flag_Aggregate_Fields = {
  __typename?: 'user_flag_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<User_Flag_Max_Fields>;
  min?: Maybe<User_Flag_Min_Fields>;
};


/** aggregate fields of "user_flag" */
export type User_Flag_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Flag_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_flag" */
export type User_Flag_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<User_Flag_Max_Order_By>;
  min?: Maybe<User_Flag_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_flag" */
export type User_Flag_Arr_Rel_Insert_Input = {
  data: Array<User_Flag_Insert_Input>;
  on_conflict?: Maybe<User_Flag_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_flag". All fields are combined with a logical 'AND'. */
export type User_Flag_Bool_Exp = {
  _and?: Maybe<Array<Maybe<User_Flag_Bool_Exp>>>;
  _not?: Maybe<User_Flag_Bool_Exp>;
  _or?: Maybe<Array<Maybe<User_Flag_Bool_Exp>>>;
  flag?: Maybe<Flag_Bool_Exp>;
  flag_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  user?: Maybe<User_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_flag" */
export enum User_Flag_Constraint {
  /** unique or primary key constraint */
  UserFlagPkey = 'user_flag_pkey'
}

/** input type for inserting data into table "user_flag" */
export type User_Flag_Insert_Input = {
  flag?: Maybe<Flag_Obj_Rel_Insert_Input>;
  flag_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  user?: Maybe<User_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Flag_Max_Fields = {
  __typename?: 'user_flag_max_fields';
  flag_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "user_flag" */
export type User_Flag_Max_Order_By = {
  flag_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type User_Flag_Min_Fields = {
  __typename?: 'user_flag_min_fields';
  flag_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "user_flag" */
export type User_Flag_Min_Order_By = {
  flag_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "user_flag" */
export type User_Flag_Mutation_Response = {
  __typename?: 'user_flag_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<User_Flag>;
};

/** input type for inserting object relation for remote table "user_flag" */
export type User_Flag_Obj_Rel_Insert_Input = {
  data: User_Flag_Insert_Input;
  on_conflict?: Maybe<User_Flag_On_Conflict>;
};

/** on conflict condition type for table "user_flag" */
export type User_Flag_On_Conflict = {
  constraint: User_Flag_Constraint;
  update_columns: Array<User_Flag_Update_Column>;
  where?: Maybe<User_Flag_Bool_Exp>;
};

/** ordering options when selecting data from "user_flag" */
export type User_Flag_Order_By = {
  flag?: Maybe<Flag_Order_By>;
  flag_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  user?: Maybe<User_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "user_flag" */
export type User_Flag_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "user_flag" */
export enum User_Flag_Select_Column {
  /** column name */
  FlagId = 'flag_id',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_flag" */
export type User_Flag_Set_Input = {
  flag_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "user_flag" */
export enum User_Flag_Update_Column {
  /** column name */
  FlagId = 'flag_id',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id'
}

/** input type for incrementing integer column in table "user" */
export type User_Inc_Input = {
  screener_score?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "user" */
export type User_Insert_Input = {
  accepts_email?: Maybe<Scalars['Boolean']>;
  accepts_notification?: Maybe<Scalars['Boolean']>;
  account?: Maybe<Auth_Accounts_Obj_Rel_Insert_Input>;
  avatar_url?: Maybe<Scalars['String']>;
  completed_profile_at?: Maybe<Scalars['timestamp']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date_of_birth?: Maybe<Scalars['date']>;
  display_name?: Maybe<Scalars['String']>;
  employment_status?: Maybe<Scalars['String']>;
  favourite_animal?: Maybe<Scalars['String']>;
  favourite_memes?: Maybe<Scalars['String']>;
  favourite_place?: Maybe<Scalars['String']>;
  favourite_spotify_playlists?: Maybe<Scalars['String']>;
  feedbacks?: Maybe<Feedback_Arr_Rel_Insert_Input>;
  first_name?: Maybe<Scalars['String']>;
  flags?: Maybe<User_Flag_Arr_Rel_Insert_Input>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  just_get_clear_anything_bad_happened?: Maybe<Scalars['String']>;
  just_get_clear_anything_bad_happened_description?: Maybe<Scalars['String']>;
  just_get_clear_feeling?: Maybe<Scalars['String']>;
  just_get_clear_feeling_description?: Maybe<Scalars['String']>;
  just_get_clear_how_long_has_this_been_going?: Maybe<Scalars['String']>;
  just_get_clear_shared_with_anyone?: Maybe<Scalars['String']>;
  mobile_number?: Maybe<Scalars['String']>;
  moderator_notes?: Maybe<Moderator_Note_Arr_Rel_Insert_Input>;
  personal_description?: Maybe<Scalars['String']>;
  personal_notes?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  push_new_story?: Maybe<Scalars['Boolean']>;
  push_story_approved?: Maybe<Scalars['Boolean']>;
  push_story_liked?: Maybe<Scalars['Boolean']>;
  push_story_saved?: Maybe<Scalars['Boolean']>;
  push_story_shared?: Maybe<Scalars['Boolean']>;
  push_token?: Maybe<Scalars['String']>;
  pushed_new_story_at?: Maybe<Scalars['timestamptz']>;
  reactions?: Maybe<Reaction_Arr_Rel_Insert_Input>;
  screener_score?: Maybe<Scalars['Int']>;
  shares?: Maybe<Share_Arr_Rel_Insert_Input>;
  show_intro?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  stories?: Maybe<Story_Arr_Rel_Insert_Input>;
  story_saves?: Maybe<Story_Save_Arr_Rel_Insert_Input>;
  story_violations?: Maybe<Story_Violation_Arr_Rel_Insert_Input>;
  suburb?: Maybe<Scalars['String']>;
  support_groups?: Maybe<User_Support_Group_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  verification_token?: Maybe<Scalars['String']>;
  verified_at?: Maybe<Scalars['timestamptz']>;
  violation_notes?: Maybe<Violation_Note_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type User_Max_Fields = {
  __typename?: 'user_max_fields';
  avatar_url?: Maybe<Scalars['String']>;
  completed_profile_at?: Maybe<Scalars['timestamp']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date_of_birth?: Maybe<Scalars['date']>;
  display_name?: Maybe<Scalars['String']>;
  employment_status?: Maybe<Scalars['String']>;
  favourite_animal?: Maybe<Scalars['String']>;
  favourite_memes?: Maybe<Scalars['String']>;
  favourite_place?: Maybe<Scalars['String']>;
  favourite_spotify_playlists?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  just_get_clear_anything_bad_happened?: Maybe<Scalars['String']>;
  just_get_clear_anything_bad_happened_description?: Maybe<Scalars['String']>;
  just_get_clear_feeling?: Maybe<Scalars['String']>;
  just_get_clear_feeling_description?: Maybe<Scalars['String']>;
  just_get_clear_how_long_has_this_been_going?: Maybe<Scalars['String']>;
  just_get_clear_shared_with_anyone?: Maybe<Scalars['String']>;
  mobile_number?: Maybe<Scalars['String']>;
  personal_description?: Maybe<Scalars['String']>;
  personal_notes?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  push_token?: Maybe<Scalars['String']>;
  pushed_new_story_at?: Maybe<Scalars['timestamptz']>;
  screener_score?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  suburb?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  verification_token?: Maybe<Scalars['String']>;
  verified_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "user" */
export type User_Max_Order_By = {
  avatar_url?: Maybe<Order_By>;
  completed_profile_at?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  date_of_birth?: Maybe<Order_By>;
  display_name?: Maybe<Order_By>;
  employment_status?: Maybe<Order_By>;
  favourite_animal?: Maybe<Order_By>;
  favourite_memes?: Maybe<Order_By>;
  favourite_place?: Maybe<Order_By>;
  favourite_spotify_playlists?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  gender?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  just_get_clear_anything_bad_happened?: Maybe<Order_By>;
  just_get_clear_anything_bad_happened_description?: Maybe<Order_By>;
  just_get_clear_feeling?: Maybe<Order_By>;
  just_get_clear_feeling_description?: Maybe<Order_By>;
  just_get_clear_how_long_has_this_been_going?: Maybe<Order_By>;
  just_get_clear_shared_with_anyone?: Maybe<Order_By>;
  mobile_number?: Maybe<Order_By>;
  personal_description?: Maybe<Order_By>;
  personal_notes?: Maybe<Order_By>;
  postal_code?: Maybe<Order_By>;
  push_token?: Maybe<Order_By>;
  pushed_new_story_at?: Maybe<Order_By>;
  screener_score?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  suburb?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  verification_token?: Maybe<Order_By>;
  verified_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type User_Min_Fields = {
  __typename?: 'user_min_fields';
  avatar_url?: Maybe<Scalars['String']>;
  completed_profile_at?: Maybe<Scalars['timestamp']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date_of_birth?: Maybe<Scalars['date']>;
  display_name?: Maybe<Scalars['String']>;
  employment_status?: Maybe<Scalars['String']>;
  favourite_animal?: Maybe<Scalars['String']>;
  favourite_memes?: Maybe<Scalars['String']>;
  favourite_place?: Maybe<Scalars['String']>;
  favourite_spotify_playlists?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  just_get_clear_anything_bad_happened?: Maybe<Scalars['String']>;
  just_get_clear_anything_bad_happened_description?: Maybe<Scalars['String']>;
  just_get_clear_feeling?: Maybe<Scalars['String']>;
  just_get_clear_feeling_description?: Maybe<Scalars['String']>;
  just_get_clear_how_long_has_this_been_going?: Maybe<Scalars['String']>;
  just_get_clear_shared_with_anyone?: Maybe<Scalars['String']>;
  mobile_number?: Maybe<Scalars['String']>;
  personal_description?: Maybe<Scalars['String']>;
  personal_notes?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  push_token?: Maybe<Scalars['String']>;
  pushed_new_story_at?: Maybe<Scalars['timestamptz']>;
  screener_score?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  suburb?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  verification_token?: Maybe<Scalars['String']>;
  verified_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "user" */
export type User_Min_Order_By = {
  avatar_url?: Maybe<Order_By>;
  completed_profile_at?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  date_of_birth?: Maybe<Order_By>;
  display_name?: Maybe<Order_By>;
  employment_status?: Maybe<Order_By>;
  favourite_animal?: Maybe<Order_By>;
  favourite_memes?: Maybe<Order_By>;
  favourite_place?: Maybe<Order_By>;
  favourite_spotify_playlists?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  gender?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  just_get_clear_anything_bad_happened?: Maybe<Order_By>;
  just_get_clear_anything_bad_happened_description?: Maybe<Order_By>;
  just_get_clear_feeling?: Maybe<Order_By>;
  just_get_clear_feeling_description?: Maybe<Order_By>;
  just_get_clear_how_long_has_this_been_going?: Maybe<Order_By>;
  just_get_clear_shared_with_anyone?: Maybe<Order_By>;
  mobile_number?: Maybe<Order_By>;
  personal_description?: Maybe<Order_By>;
  personal_notes?: Maybe<Order_By>;
  postal_code?: Maybe<Order_By>;
  push_token?: Maybe<Order_By>;
  pushed_new_story_at?: Maybe<Order_By>;
  screener_score?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  suburb?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  verification_token?: Maybe<Order_By>;
  verified_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "user" */
export type User_Mutation_Response = {
  __typename?: 'user_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<User>;
};

/** input type for inserting object relation for remote table "user" */
export type User_Obj_Rel_Insert_Input = {
  data: User_Insert_Input;
  on_conflict?: Maybe<User_On_Conflict>;
};

/** on conflict condition type for table "user" */
export type User_On_Conflict = {
  constraint: User_Constraint;
  update_columns: Array<User_Update_Column>;
  where?: Maybe<User_Bool_Exp>;
};

/** ordering options when selecting data from "user" */
export type User_Order_By = {
  accepts_email?: Maybe<Order_By>;
  accepts_notification?: Maybe<Order_By>;
  account?: Maybe<Auth_Accounts_Order_By>;
  avatar_url?: Maybe<Order_By>;
  completed_profile_at?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  date_of_birth?: Maybe<Order_By>;
  display_name?: Maybe<Order_By>;
  employment_status?: Maybe<Order_By>;
  favourite_animal?: Maybe<Order_By>;
  favourite_memes?: Maybe<Order_By>;
  favourite_place?: Maybe<Order_By>;
  favourite_spotify_playlists?: Maybe<Order_By>;
  feedbacks_aggregate?: Maybe<Feedback_Aggregate_Order_By>;
  first_name?: Maybe<Order_By>;
  flags_aggregate?: Maybe<User_Flag_Aggregate_Order_By>;
  gender?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  just_get_clear_anything_bad_happened?: Maybe<Order_By>;
  just_get_clear_anything_bad_happened_description?: Maybe<Order_By>;
  just_get_clear_feeling?: Maybe<Order_By>;
  just_get_clear_feeling_description?: Maybe<Order_By>;
  just_get_clear_how_long_has_this_been_going?: Maybe<Order_By>;
  just_get_clear_shared_with_anyone?: Maybe<Order_By>;
  mobile_number?: Maybe<Order_By>;
  moderator_notes_aggregate?: Maybe<Moderator_Note_Aggregate_Order_By>;
  personal_description?: Maybe<Order_By>;
  personal_notes?: Maybe<Order_By>;
  postal_code?: Maybe<Order_By>;
  push_new_story?: Maybe<Order_By>;
  push_story_approved?: Maybe<Order_By>;
  push_story_liked?: Maybe<Order_By>;
  push_story_saved?: Maybe<Order_By>;
  push_story_shared?: Maybe<Order_By>;
  push_token?: Maybe<Order_By>;
  pushed_new_story_at?: Maybe<Order_By>;
  reactions_aggregate?: Maybe<Reaction_Aggregate_Order_By>;
  screener_score?: Maybe<Order_By>;
  shares_aggregate?: Maybe<Share_Aggregate_Order_By>;
  show_intro?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  stories_aggregate?: Maybe<Story_Aggregate_Order_By>;
  story_saves_aggregate?: Maybe<Story_Save_Aggregate_Order_By>;
  story_violations_aggregate?: Maybe<Story_Violation_Aggregate_Order_By>;
  suburb?: Maybe<Order_By>;
  support_groups_aggregate?: Maybe<User_Support_Group_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
  verification_token?: Maybe<Order_By>;
  verified_at?: Maybe<Order_By>;
  violation_notes_aggregate?: Maybe<Violation_Note_Aggregate_Order_By>;
};

/** primary key columns input for table: "user" */
export type User_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "user" */
export enum User_Select_Column {
  /** column name */
  AcceptsEmail = 'accepts_email',
  /** column name */
  AcceptsNotification = 'accepts_notification',
  /** column name */
  AvatarUrl = 'avatar_url',
  /** column name */
  CompletedProfileAt = 'completed_profile_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateOfBirth = 'date_of_birth',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  EmploymentStatus = 'employment_status',
  /** column name */
  FavouriteAnimal = 'favourite_animal',
  /** column name */
  FavouriteMemes = 'favourite_memes',
  /** column name */
  FavouritePlace = 'favourite_place',
  /** column name */
  FavouriteSpotifyPlaylists = 'favourite_spotify_playlists',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Gender = 'gender',
  /** column name */
  Id = 'id',
  /** column name */
  JustGetClearAnythingBadHappened = 'just_get_clear_anything_bad_happened',
  /** column name */
  JustGetClearAnythingBadHappenedDescription = 'just_get_clear_anything_bad_happened_description',
  /** column name */
  JustGetClearFeeling = 'just_get_clear_feeling',
  /** column name */
  JustGetClearFeelingDescription = 'just_get_clear_feeling_description',
  /** column name */
  JustGetClearHowLongHasThisBeenGoing = 'just_get_clear_how_long_has_this_been_going',
  /** column name */
  JustGetClearSharedWithAnyone = 'just_get_clear_shared_with_anyone',
  /** column name */
  MobileNumber = 'mobile_number',
  /** column name */
  PersonalDescription = 'personal_description',
  /** column name */
  PersonalNotes = 'personal_notes',
  /** column name */
  PostalCode = 'postal_code',
  /** column name */
  PushNewStory = 'push_new_story',
  /** column name */
  PushStoryApproved = 'push_story_approved',
  /** column name */
  PushStoryLiked = 'push_story_liked',
  /** column name */
  PushStorySaved = 'push_story_saved',
  /** column name */
  PushStoryShared = 'push_story_shared',
  /** column name */
  PushToken = 'push_token',
  /** column name */
  PushedNewStoryAt = 'pushed_new_story_at',
  /** column name */
  ScreenerScore = 'screener_score',
  /** column name */
  ShowIntro = 'show_intro',
  /** column name */
  State = 'state',
  /** column name */
  Status = 'status',
  /** column name */
  Suburb = 'suburb',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VerificationToken = 'verification_token',
  /** column name */
  VerifiedAt = 'verified_at'
}

/** input type for updating data in table "user" */
export type User_Set_Input = {
  accepts_email?: Maybe<Scalars['Boolean']>;
  accepts_notification?: Maybe<Scalars['Boolean']>;
  avatar_url?: Maybe<Scalars['String']>;
  completed_profile_at?: Maybe<Scalars['timestamp']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date_of_birth?: Maybe<Scalars['date']>;
  display_name?: Maybe<Scalars['String']>;
  employment_status?: Maybe<Scalars['String']>;
  favourite_animal?: Maybe<Scalars['String']>;
  favourite_memes?: Maybe<Scalars['String']>;
  favourite_place?: Maybe<Scalars['String']>;
  favourite_spotify_playlists?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  just_get_clear_anything_bad_happened?: Maybe<Scalars['String']>;
  just_get_clear_anything_bad_happened_description?: Maybe<Scalars['String']>;
  just_get_clear_feeling?: Maybe<Scalars['String']>;
  just_get_clear_feeling_description?: Maybe<Scalars['String']>;
  just_get_clear_how_long_has_this_been_going?: Maybe<Scalars['String']>;
  just_get_clear_shared_with_anyone?: Maybe<Scalars['String']>;
  mobile_number?: Maybe<Scalars['String']>;
  personal_description?: Maybe<Scalars['String']>;
  personal_notes?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  push_new_story?: Maybe<Scalars['Boolean']>;
  push_story_approved?: Maybe<Scalars['Boolean']>;
  push_story_liked?: Maybe<Scalars['Boolean']>;
  push_story_saved?: Maybe<Scalars['Boolean']>;
  push_story_shared?: Maybe<Scalars['Boolean']>;
  push_token?: Maybe<Scalars['String']>;
  pushed_new_story_at?: Maybe<Scalars['timestamptz']>;
  screener_score?: Maybe<Scalars['Int']>;
  show_intro?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  suburb?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  verification_token?: Maybe<Scalars['String']>;
  verified_at?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "user_status" */
export type User_Status = {
  __typename?: 'user_status';
  value: Scalars['String'];
};

/** aggregated selection of "user_status" */
export type User_Status_Aggregate = {
  __typename?: 'user_status_aggregate';
  aggregate?: Maybe<User_Status_Aggregate_Fields>;
  nodes: Array<User_Status>;
};

/** aggregate fields of "user_status" */
export type User_Status_Aggregate_Fields = {
  __typename?: 'user_status_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<User_Status_Max_Fields>;
  min?: Maybe<User_Status_Min_Fields>;
};


/** aggregate fields of "user_status" */
export type User_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_status" */
export type User_Status_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<User_Status_Max_Order_By>;
  min?: Maybe<User_Status_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_status" */
export type User_Status_Arr_Rel_Insert_Input = {
  data: Array<User_Status_Insert_Input>;
  on_conflict?: Maybe<User_Status_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_status". All fields are combined with a logical 'AND'. */
export type User_Status_Bool_Exp = {
  _and?: Maybe<Array<Maybe<User_Status_Bool_Exp>>>;
  _not?: Maybe<User_Status_Bool_Exp>;
  _or?: Maybe<Array<Maybe<User_Status_Bool_Exp>>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_status" */
export enum User_Status_Constraint {
  /** unique or primary key constraint */
  UserStatusPkey = 'user_status_pkey'
}

/** input type for inserting data into table "user_status" */
export type User_Status_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type User_Status_Max_Fields = {
  __typename?: 'user_status_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "user_status" */
export type User_Status_Max_Order_By = {
  value?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type User_Status_Min_Fields = {
  __typename?: 'user_status_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "user_status" */
export type User_Status_Min_Order_By = {
  value?: Maybe<Order_By>;
};

/** response of any mutation on the table "user_status" */
export type User_Status_Mutation_Response = {
  __typename?: 'user_status_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<User_Status>;
};

/** input type for inserting object relation for remote table "user_status" */
export type User_Status_Obj_Rel_Insert_Input = {
  data: User_Status_Insert_Input;
  on_conflict?: Maybe<User_Status_On_Conflict>;
};

/** on conflict condition type for table "user_status" */
export type User_Status_On_Conflict = {
  constraint: User_Status_Constraint;
  update_columns: Array<User_Status_Update_Column>;
  where?: Maybe<User_Status_Bool_Exp>;
};

/** ordering options when selecting data from "user_status" */
export type User_Status_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: "user_status" */
export type User_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "user_status" */
export enum User_Status_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "user_status" */
export type User_Status_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "user_status" */
export enum User_Status_Update_Column {
  /** column name */
  Value = 'value'
}

/** aggregate stddev on columns */
export type User_Stddev_Fields = {
  __typename?: 'user_stddev_fields';
  screener_score?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "user" */
export type User_Stddev_Order_By = {
  screener_score?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type User_Stddev_Pop_Fields = {
  __typename?: 'user_stddev_pop_fields';
  screener_score?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "user" */
export type User_Stddev_Pop_Order_By = {
  screener_score?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type User_Stddev_Samp_Fields = {
  __typename?: 'user_stddev_samp_fields';
  screener_score?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "user" */
export type User_Stddev_Samp_Order_By = {
  screener_score?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type User_Sum_Fields = {
  __typename?: 'user_sum_fields';
  screener_score?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "user" */
export type User_Sum_Order_By = {
  screener_score?: Maybe<Order_By>;
};

/** columns and relationships of "user_support_group" */
export type User_Support_Group = {
  __typename?: 'user_support_group';
  id: Scalars['uuid'];
  /** An object relationship */
  supportGroupBySupportGroup: Support_Group;
  support_group: Support_Group_Enum;
  /** An object relationship */
  user: User;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "user_support_group" */
export type User_Support_Group_Aggregate = {
  __typename?: 'user_support_group_aggregate';
  aggregate?: Maybe<User_Support_Group_Aggregate_Fields>;
  nodes: Array<User_Support_Group>;
};

/** aggregate fields of "user_support_group" */
export type User_Support_Group_Aggregate_Fields = {
  __typename?: 'user_support_group_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<User_Support_Group_Max_Fields>;
  min?: Maybe<User_Support_Group_Min_Fields>;
};


/** aggregate fields of "user_support_group" */
export type User_Support_Group_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Support_Group_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_support_group" */
export type User_Support_Group_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<User_Support_Group_Max_Order_By>;
  min?: Maybe<User_Support_Group_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_support_group" */
export type User_Support_Group_Arr_Rel_Insert_Input = {
  data: Array<User_Support_Group_Insert_Input>;
  on_conflict?: Maybe<User_Support_Group_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_support_group". All fields are combined with a logical 'AND'. */
export type User_Support_Group_Bool_Exp = {
  _and?: Maybe<Array<Maybe<User_Support_Group_Bool_Exp>>>;
  _not?: Maybe<User_Support_Group_Bool_Exp>;
  _or?: Maybe<Array<Maybe<User_Support_Group_Bool_Exp>>>;
  id?: Maybe<Uuid_Comparison_Exp>;
  supportGroupBySupportGroup?: Maybe<Support_Group_Bool_Exp>;
  support_group?: Maybe<Support_Group_Enum_Comparison_Exp>;
  user?: Maybe<User_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_support_group" */
export enum User_Support_Group_Constraint {
  /** unique or primary key constraint */
  UserSupportGroupPkey = 'user_support_group_pkey'
}

/** input type for inserting data into table "user_support_group" */
export type User_Support_Group_Insert_Input = {
  id?: Maybe<Scalars['uuid']>;
  supportGroupBySupportGroup?: Maybe<Support_Group_Obj_Rel_Insert_Input>;
  support_group?: Maybe<Support_Group_Enum>;
  user?: Maybe<User_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Support_Group_Max_Fields = {
  __typename?: 'user_support_group_max_fields';
  id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "user_support_group" */
export type User_Support_Group_Max_Order_By = {
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type User_Support_Group_Min_Fields = {
  __typename?: 'user_support_group_min_fields';
  id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "user_support_group" */
export type User_Support_Group_Min_Order_By = {
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "user_support_group" */
export type User_Support_Group_Mutation_Response = {
  __typename?: 'user_support_group_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<User_Support_Group>;
};

/** input type for inserting object relation for remote table "user_support_group" */
export type User_Support_Group_Obj_Rel_Insert_Input = {
  data: User_Support_Group_Insert_Input;
  on_conflict?: Maybe<User_Support_Group_On_Conflict>;
};

/** on conflict condition type for table "user_support_group" */
export type User_Support_Group_On_Conflict = {
  constraint: User_Support_Group_Constraint;
  update_columns: Array<User_Support_Group_Update_Column>;
  where?: Maybe<User_Support_Group_Bool_Exp>;
};

/** ordering options when selecting data from "user_support_group" */
export type User_Support_Group_Order_By = {
  id?: Maybe<Order_By>;
  supportGroupBySupportGroup?: Maybe<Support_Group_Order_By>;
  support_group?: Maybe<Order_By>;
  user?: Maybe<User_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "user_support_group" */
export type User_Support_Group_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "user_support_group" */
export enum User_Support_Group_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  SupportGroup = 'support_group',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_support_group" */
export type User_Support_Group_Set_Input = {
  id?: Maybe<Scalars['uuid']>;
  support_group?: Maybe<Support_Group_Enum>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "user_support_group" */
export enum User_Support_Group_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  SupportGroup = 'support_group',
  /** column name */
  UserId = 'user_id'
}

/** update columns of table "user" */
export enum User_Update_Column {
  /** column name */
  AcceptsEmail = 'accepts_email',
  /** column name */
  AcceptsNotification = 'accepts_notification',
  /** column name */
  AvatarUrl = 'avatar_url',
  /** column name */
  CompletedProfileAt = 'completed_profile_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateOfBirth = 'date_of_birth',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  EmploymentStatus = 'employment_status',
  /** column name */
  FavouriteAnimal = 'favourite_animal',
  /** column name */
  FavouriteMemes = 'favourite_memes',
  /** column name */
  FavouritePlace = 'favourite_place',
  /** column name */
  FavouriteSpotifyPlaylists = 'favourite_spotify_playlists',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Gender = 'gender',
  /** column name */
  Id = 'id',
  /** column name */
  JustGetClearAnythingBadHappened = 'just_get_clear_anything_bad_happened',
  /** column name */
  JustGetClearAnythingBadHappenedDescription = 'just_get_clear_anything_bad_happened_description',
  /** column name */
  JustGetClearFeeling = 'just_get_clear_feeling',
  /** column name */
  JustGetClearFeelingDescription = 'just_get_clear_feeling_description',
  /** column name */
  JustGetClearHowLongHasThisBeenGoing = 'just_get_clear_how_long_has_this_been_going',
  /** column name */
  JustGetClearSharedWithAnyone = 'just_get_clear_shared_with_anyone',
  /** column name */
  MobileNumber = 'mobile_number',
  /** column name */
  PersonalDescription = 'personal_description',
  /** column name */
  PersonalNotes = 'personal_notes',
  /** column name */
  PostalCode = 'postal_code',
  /** column name */
  PushNewStory = 'push_new_story',
  /** column name */
  PushStoryApproved = 'push_story_approved',
  /** column name */
  PushStoryLiked = 'push_story_liked',
  /** column name */
  PushStorySaved = 'push_story_saved',
  /** column name */
  PushStoryShared = 'push_story_shared',
  /** column name */
  PushToken = 'push_token',
  /** column name */
  PushedNewStoryAt = 'pushed_new_story_at',
  /** column name */
  ScreenerScore = 'screener_score',
  /** column name */
  ShowIntro = 'show_intro',
  /** column name */
  State = 'state',
  /** column name */
  Status = 'status',
  /** column name */
  Suburb = 'suburb',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VerificationToken = 'verification_token',
  /** column name */
  VerifiedAt = 'verified_at'
}

/** aggregate var_pop on columns */
export type User_Var_Pop_Fields = {
  __typename?: 'user_var_pop_fields';
  screener_score?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "user" */
export type User_Var_Pop_Order_By = {
  screener_score?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type User_Var_Samp_Fields = {
  __typename?: 'user_var_samp_fields';
  screener_score?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "user" */
export type User_Var_Samp_Order_By = {
  screener_score?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type User_Variance_Fields = {
  __typename?: 'user_variance_fields';
  screener_score?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "user" */
export type User_Variance_Order_By = {
  screener_score?: Maybe<Order_By>;
};


/** expression to compare columns of type uuid. All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: Maybe<Scalars['uuid']>;
  _gt?: Maybe<Scalars['uuid']>;
  _gte?: Maybe<Scalars['uuid']>;
  _in?: Maybe<Array<Scalars['uuid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['uuid']>;
  _lte?: Maybe<Scalars['uuid']>;
  _neq?: Maybe<Scalars['uuid']>;
  _nin?: Maybe<Array<Scalars['uuid']>>;
};

/** columns and relationships of "violation_note" */
export type Violation_Note = {
  __typename?: 'violation_note';
  action: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  note: Scalars['String'];
  /** An object relationship */
  story: Story;
  story_id: Scalars['uuid'];
  /** An object relationship */
  user: User;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "violation_note" */
export type Violation_Note_Aggregate = {
  __typename?: 'violation_note_aggregate';
  aggregate?: Maybe<Violation_Note_Aggregate_Fields>;
  nodes: Array<Violation_Note>;
};

/** aggregate fields of "violation_note" */
export type Violation_Note_Aggregate_Fields = {
  __typename?: 'violation_note_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Violation_Note_Max_Fields>;
  min?: Maybe<Violation_Note_Min_Fields>;
};


/** aggregate fields of "violation_note" */
export type Violation_Note_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Violation_Note_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "violation_note" */
export type Violation_Note_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Violation_Note_Max_Order_By>;
  min?: Maybe<Violation_Note_Min_Order_By>;
};

/** input type for inserting array relation for remote table "violation_note" */
export type Violation_Note_Arr_Rel_Insert_Input = {
  data: Array<Violation_Note_Insert_Input>;
  on_conflict?: Maybe<Violation_Note_On_Conflict>;
};

/** Boolean expression to filter rows from the table "violation_note". All fields are combined with a logical 'AND'. */
export type Violation_Note_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Violation_Note_Bool_Exp>>>;
  _not?: Maybe<Violation_Note_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Violation_Note_Bool_Exp>>>;
  action?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  note?: Maybe<String_Comparison_Exp>;
  story?: Maybe<Story_Bool_Exp>;
  story_id?: Maybe<Uuid_Comparison_Exp>;
  user?: Maybe<User_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "violation_note" */
export enum Violation_Note_Constraint {
  /** unique or primary key constraint */
  ViolationNotePkey = 'violation_note_pkey'
}

/** input type for inserting data into table "violation_note" */
export type Violation_Note_Insert_Input = {
  action?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  story?: Maybe<Story_Obj_Rel_Insert_Input>;
  story_id?: Maybe<Scalars['uuid']>;
  user?: Maybe<User_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Violation_Note_Max_Fields = {
  __typename?: 'violation_note_max_fields';
  action?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  story_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "violation_note" */
export type Violation_Note_Max_Order_By = {
  action?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  note?: Maybe<Order_By>;
  story_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Violation_Note_Min_Fields = {
  __typename?: 'violation_note_min_fields';
  action?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  story_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "violation_note" */
export type Violation_Note_Min_Order_By = {
  action?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  note?: Maybe<Order_By>;
  story_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "violation_note" */
export type Violation_Note_Mutation_Response = {
  __typename?: 'violation_note_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Violation_Note>;
};

/** input type for inserting object relation for remote table "violation_note" */
export type Violation_Note_Obj_Rel_Insert_Input = {
  data: Violation_Note_Insert_Input;
  on_conflict?: Maybe<Violation_Note_On_Conflict>;
};

/** on conflict condition type for table "violation_note" */
export type Violation_Note_On_Conflict = {
  constraint: Violation_Note_Constraint;
  update_columns: Array<Violation_Note_Update_Column>;
  where?: Maybe<Violation_Note_Bool_Exp>;
};

/** ordering options when selecting data from "violation_note" */
export type Violation_Note_Order_By = {
  action?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  note?: Maybe<Order_By>;
  story?: Maybe<Story_Order_By>;
  story_id?: Maybe<Order_By>;
  user?: Maybe<User_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "violation_note" */
export type Violation_Note_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "violation_note" */
export enum Violation_Note_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Note = 'note',
  /** column name */
  StoryId = 'story_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "violation_note" */
export type Violation_Note_Set_Input = {
  action?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  story_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "violation_note" */
export enum Violation_Note_Update_Column {
  /** column name */
  Action = 'action',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Note = 'note',
  /** column name */
  StoryId = 'story_id',
  /** column name */
  UserId = 'user_id'
}

export type UpdateAccountAccountRolesMutationVariables = Exact<{
  objects: Array<Auth_Account_Roles_Insert_Input>;
  accountId: Scalars['uuid'];
  roles?: Maybe<Array<Scalars['String']>>;
}>;


export type UpdateAccountAccountRolesMutation = (
  { __typename?: 'mutation_root' }
  & { insert_auth_account_roles?: Maybe<(
    { __typename?: 'auth_account_roles_mutation_response' }
    & Pick<Auth_Account_Roles_Mutation_Response, 'affected_rows'>
  )>, delete_auth_account_roles?: Maybe<(
    { __typename?: 'auth_account_roles_mutation_response' }
    & Pick<Auth_Account_Roles_Mutation_Response, 'affected_rows'>
  )> }
);

export type AccountRolesQueryVariables = Exact<{
  orderBy?: Maybe<Array<Auth_Account_Roles_Order_By>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type AccountRolesQuery = (
  { __typename?: 'query_root' }
  & { auth_account_roles: Array<(
    { __typename?: 'auth_account_roles' }
    & Pick<Auth_Account_Roles, 'id' | 'role'>
  )>, auth_account_roles_aggregate: (
    { __typename?: 'auth_account_roles_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'auth_account_roles_aggregate_fields' }
      & Pick<Auth_Account_Roles_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type AuthRolesQueryVariables = Exact<{
  orderBy?: Maybe<Array<Auth_Roles_Order_By>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type AuthRolesQuery = (
  { __typename?: 'query_root' }
  & { auth_roles: Array<(
    { __typename?: 'auth_roles' }
    & Pick<Auth_Roles, 'role'>
  )>, auth_roles_aggregate: (
    { __typename?: 'auth_roles_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'auth_roles_aggregate_fields' }
      & Pick<Auth_Roles_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type SendEmailMutationVariables = Exact<{
  template: Scalars['String'];
  email: Scalars['String'];
  tags: Scalars['String'];
}>;


export type SendEmailMutation = (
  { __typename?: 'mutation_root' }
  & { SendEmail?: Maybe<(
    { __typename?: 'SendEmailOutput' }
    & Pick<SendEmailOutput, 'success'>
  )> }
);

export type FeedbacksQueryVariables = Exact<{
  orderBy?: Maybe<Array<Feedback_Order_By>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type FeedbacksQuery = (
  { __typename?: 'query_root' }
  & { feedback: Array<(
    { __typename?: 'feedback' }
    & Pick<Feedback, 'id' | 'created_at'>
    & { user: (
      { __typename?: 'user' }
      & Pick<User, 'first_name'>
      & { account?: Maybe<(
        { __typename?: 'auth_accounts' }
        & Pick<Auth_Accounts, 'email'>
      )> }
    ) }
  )>, feedback_aggregate: (
    { __typename?: 'feedback_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'feedback_aggregate_fields' }
      & Pick<Feedback_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type GetFeedbackQueryVariables = Exact<{
  FeedbackId: Scalars['uuid'];
}>;


export type GetFeedbackQuery = (
  { __typename?: 'query_root' }
  & { feedback: Array<(
    { __typename?: 'feedback' }
    & Pick<Feedback, 'id' | 'created_at' | 'description'>
    & { user: (
      { __typename?: 'user' }
      & Pick<User, 'display_name' | 'first_name'>
      & { account?: Maybe<(
        { __typename?: 'auth_accounts' }
        & Pick<Auth_Accounts, 'email'>
      )> }
    ) }
  )> }
);

export type DeleteFeedbackMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteFeedbackMutation = (
  { __typename?: 'mutation_root' }
  & { delete_feedback_by_pk?: Maybe<(
    { __typename?: 'feedback' }
    & Pick<Feedback, 'id'>
  )> }
);

export type InfoTilesQueryVariables = Exact<{
  orderBy?: Maybe<Array<Info_Tile_Order_By>>;
  status?: Maybe<Info_Tile_Status_Type_Enum>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type InfoTilesQuery = (
  { __typename?: 'query_root' }
  & { info_tile: Array<(
    { __typename?: 'info_tile' }
    & Pick<Info_Tile, 'id' | 'image_url' | 'action_url' | 'status' | 'created_at' | 'accessibility_text' | 'order'>
  )>, info_tile_aggregate: (
    { __typename?: 'info_tile_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'info_tile_aggregate_fields' }
      & Pick<Info_Tile_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type GetInfoTileQueryVariables = Exact<{
  InfoTileId: Scalars['uuid'];
}>;


export type GetInfoTileQuery = (
  { __typename?: 'query_root' }
  & { info_tile: Array<(
    { __typename?: 'info_tile' }
    & Pick<Info_Tile, 'id' | 'image_url' | 'action_url' | 'status' | 'created_at' | 'accessibility_text' | 'order'>
  )> }
);

export type GetInfoTilesQueryVariables = Exact<{
  InfoTileIds?: Maybe<Array<Scalars['uuid']>>;
}>;


export type GetInfoTilesQuery = (
  { __typename?: 'query_root' }
  & { info_tile: Array<(
    { __typename?: 'info_tile' }
    & Pick<Info_Tile, 'id' | 'image_url' | 'action_url' | 'status' | 'created_at' | 'accessibility_text' | 'order'>
  )> }
);

export type AddInfoTileMutationVariables = Exact<{
  object: Info_Tile_Insert_Input;
}>;


export type AddInfoTileMutation = (
  { __typename?: 'mutation_root' }
  & { insert_info_tile_one?: Maybe<(
    { __typename?: 'info_tile' }
    & Pick<Info_Tile, 'id' | 'image_url' | 'action_url' | 'status' | 'created_at' | 'accessibility_text'>
  )> }
);

export type GetInfoTileS3UploadUrlQueryVariables = Exact<{ [key: string]: never; }>;


export type GetInfoTileS3UploadUrlQuery = (
  { __typename?: 'query_root' }
  & { getInfoTileUploadUrl?: Maybe<(
    { __typename?: 'InfoTileUrlOutput' }
    & Pick<InfoTileUrlOutput, 'url'>
  )> }
);

export type UpdateInfoTileStatusMutationVariables = Exact<{
  id: Scalars['uuid'];
  status: Info_Tile_Status_Type_Enum;
}>;


export type UpdateInfoTileStatusMutation = (
  { __typename?: 'mutation_root' }
  & { update_info_tile_by_pk?: Maybe<(
    { __typename?: 'info_tile' }
    & Pick<Info_Tile, 'id' | 'status'>
  )> }
);

export type InfoTileCountQueryVariables = Exact<{
  status: Info_Tile_Status_Type_Enum;
}>;


export type InfoTileCountQuery = (
  { __typename?: 'query_root' }
  & { info_tile_aggregate: (
    { __typename?: 'info_tile_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'info_tile_aggregate_fields' }
      & Pick<Info_Tile_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type UpdateInfoTileMutationVariables = Exact<{
  id: Scalars['uuid'];
  infoTile: Info_Tile_Set_Input;
}>;


export type UpdateInfoTileMutation = (
  { __typename?: 'mutation_root' }
  & { update_info_tile_by_pk?: Maybe<(
    { __typename?: 'info_tile' }
    & Pick<Info_Tile, 'id'>
  )> }
);

export type SaveInfoTileOrderMutationVariables = Exact<{
  id: Scalars['uuid'];
  order?: Maybe<Scalars['Int']>;
}>;


export type SaveInfoTileOrderMutation = (
  { __typename?: 'mutation_root' }
  & { update_info_tile_by_pk?: Maybe<(
    { __typename?: 'info_tile' }
    & Pick<Info_Tile, 'id' | 'order'>
  )> }
);

export type ReferralsQueryVariables = Exact<{
  orderBy?: Maybe<Array<Referral_Order_By>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type ReferralsQuery = (
  { __typename?: 'query_root' }
  & { referral: Array<(
    { __typename?: 'referral' }
    & Pick<Referral, 'id' | 'value'>
  )>, referral_aggregate: (
    { __typename?: 'referral_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'referral_aggregate_fields' }
      & Pick<Referral_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type GetReferralsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetReferralsQuery = (
  { __typename?: 'query_root' }
  & { referral: Array<(
    { __typename?: 'referral' }
    & Pick<Referral, 'id' | 'value'>
  )> }
);

export type TopicsQueryVariables = Exact<{
  orderBy?: Maybe<Array<Topic_Order_By>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type TopicsQuery = (
  { __typename?: 'query_root' }
  & { topic: Array<(
    { __typename?: 'topic' }
    & Pick<Topic, 'id' | 'value'>
  )>, topic_aggregate: (
    { __typename?: 'topic_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'topic_aggregate_fields' }
      & Pick<Topic_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type GetTopicsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTopicsQuery = (
  { __typename?: 'query_root' }
  & { topic: Array<(
    { __typename?: 'topic' }
    & Pick<Topic, 'id' | 'value'>
  )> }
);

export type KeywordsQueryVariables = Exact<{
  orderBy?: Maybe<Array<Keyword_Order_By>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type KeywordsQuery = (
  { __typename?: 'query_root' }
  & { keyword: Array<(
    { __typename?: 'keyword' }
    & Pick<Keyword, 'id' | 'created_at' | 'value'>
    & { keyword_referrals: Array<(
      { __typename?: 'keyword_referral' }
      & { referral: (
        { __typename?: 'referral' }
        & Pick<Referral, 'value'>
      ) }
    )>, keyword_topics: Array<(
      { __typename?: 'keyword_topic' }
      & { topic: (
        { __typename?: 'topic' }
        & Pick<Topic, 'value'>
      ) }
    )>, sentiment: (
      { __typename?: 'sentiment' }
      & Pick<Sentiment, 'value'>
    ) }
  )>, keyword_aggregate: (
    { __typename?: 'keyword_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'keyword_aggregate_fields' }
      & Pick<Keyword_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type GetKeywordQueryVariables = Exact<{
  keywordId: Scalars['uuid'];
}>;


export type GetKeywordQuery = (
  { __typename?: 'query_root' }
  & { keyword: Array<(
    { __typename?: 'keyword' }
    & Pick<Keyword, 'id' | 'created_at' | 'value'>
    & { keyword_referrals: Array<(
      { __typename?: 'keyword_referral' }
      & { referral: (
        { __typename?: 'referral' }
        & Pick<Referral, 'value'>
      ) }
    )>, keyword_topics: Array<(
      { __typename?: 'keyword_topic' }
      & { topic: (
        { __typename?: 'topic' }
        & Pick<Topic, 'value'>
      ) }
    )>, sentiment: (
      { __typename?: 'sentiment' }
      & Pick<Sentiment, 'value'>
    ) }
  )> }
);

export type AddKeywordMutationVariables = Exact<{
  keywordId: Scalars['uuid'];
  value: Scalars['String'];
  sentimentId: Scalars['uuid'];
  keywordTopics: Array<Keyword_Topic_Insert_Input>;
  keywordReferrals: Array<Keyword_Referral_Insert_Input>;
}>;


export type AddKeywordMutation = (
  { __typename?: 'mutation_root' }
  & { insert_keyword_one?: Maybe<(
    { __typename?: 'keyword' }
    & Pick<Keyword, 'id' | 'value'>
  )>, insert_keyword_topic?: Maybe<(
    { __typename?: 'keyword_topic_mutation_response' }
    & { returning: Array<(
      { __typename?: 'keyword_topic' }
      & Pick<Keyword_Topic, 'id'>
    )> }
  )>, insert_keyword_referral?: Maybe<(
    { __typename?: 'keyword_referral_mutation_response' }
    & { returning: Array<(
      { __typename?: 'keyword_referral' }
      & Pick<Keyword_Referral, 'id'>
    )> }
  )> }
);

export type Components_Stories_GetKeywordValuesQueryVariables = Exact<{ [key: string]: never; }>;


export type Components_Stories_GetKeywordValuesQuery = (
  { __typename?: 'query_root' }
  & { keyword: Array<(
    { __typename?: 'keyword' }
    & Pick<Keyword, 'value'>
    & { sentiment: (
      { __typename?: 'sentiment' }
      & Pick<Sentiment, 'value'>
    ), keyword_referrals: Array<(
      { __typename?: 'keyword_referral' }
      & { referral: (
        { __typename?: 'referral' }
        & Pick<Referral, 'value'>
      ) }
    )>, keyword_topics: Array<(
      { __typename?: 'keyword_topic' }
      & { topic: (
        { __typename?: 'topic' }
        & Pick<Topic, 'value'>
      ) }
    )> }
  )> }
);

export type AddNoteMutationVariables = Exact<{
  note: Moderator_Note_Insert_Input;
  storyId: Scalars['uuid'];
  storyStatus: Scalars['String'];
  _eq?: Maybe<Scalars['uuid']>;
  publishedAt?: Maybe<Scalars['timestamptz']>;
}>;


export type AddNoteMutation = (
  { __typename?: 'mutation_root' }
  & { insert_moderator_note_one?: Maybe<(
    { __typename?: 'moderator_note' }
    & Pick<Moderator_Note, 'id'>
  )>, update_story?: Maybe<(
    { __typename?: 'story_mutation_response' }
    & { returning: Array<(
      { __typename?: 'story' }
      & Pick<Story, 'id'>
    )> }
  )> }
);

export type StoriesQueryVariables = Exact<{
  orderBy?: Maybe<Array<Story_Order_By>>;
  statuses?: Maybe<Array<Scalars['String']>>;
  titleQ?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type StoriesQuery = (
  { __typename?: 'query_root' }
  & { story: Array<(
    { __typename?: 'story' }
    & StoriesFragmentFragment
  )>, story_aggregate: (
    { __typename?: 'story_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'story_aggregate_fields' }
      & Pick<Story_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type ViolationStoriesQueryVariables = Exact<{
  orderBy?: Maybe<Array<Story_Order_By>>;
  statuses?: Maybe<Array<Scalars['String']>>;
  titleQ?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type ViolationStoriesQuery = (
  { __typename?: 'query_root' }
  & { story: Array<(
    { __typename?: 'story' }
    & StoriesFragmentFragment
  )>, story_aggregate: (
    { __typename?: 'story_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'story_aggregate_fields' }
      & Pick<Story_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type StoriesFragmentFragment = (
  { __typename?: 'story' }
  & Pick<Story, 'first_submitted_at' | 'last_submitted_at' | 'type' | 'number_of_approvals' | 'last_approved_at' | 'number_of_rejections' | 'last_rejected_at' | 'status' | 'id' | 'title' | 'created_at' | 'order' | 'transcription' | 'last_reported_at' | 'user_id'>
  & { user: (
    { __typename?: 'user' }
    & Pick<User, 'screener_score' | 'first_name'>
    & { account?: Maybe<(
      { __typename?: 'auth_accounts' }
      & Pick<Auth_Accounts, 'email'>
    )> }
  ), story_violations_aggregate: (
    { __typename?: 'story_violation_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'story_violation_aggregate_fields' }
      & Pick<Story_Violation_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type GetStoryQueryVariables = Exact<{
  StoryId: Scalars['uuid'];
}>;


export type GetStoryQuery = (
  { __typename?: 'query_root' }
  & { story: Array<(
    { __typename?: 'story' }
    & Pick<Story, 'id' | 'title' | 'first_submitted_at' | 'last_submitted_at' | 'type' | 'content' | 'caption' | 'status' | 'order' | 'transcription' | 'user_id'>
    & { tags: Array<(
      { __typename?: 'story_tag' }
      & Pick<Story_Tag, 'id' | 'tag_id'>
      & { tag: (
        { __typename?: 'tag' }
        & Pick<Tag, 'name' | 'id'>
      ) }
    )>, user: (
      { __typename?: 'user' }
      & Pick<User, 'screener_score' | 'display_name' | 'first_name'>
      & { account?: Maybe<(
        { __typename?: 'auth_accounts' }
        & Pick<Auth_Accounts, 'email'>
      )> }
    ), moderator_notes: Array<(
      { __typename?: 'moderator_note' }
      & Pick<Moderator_Note, 'id' | 'created_at' | 'action' | 'note'>
      & { user: (
        { __typename?: 'user' }
        & Pick<User, 'id' | 'display_name'>
      ) }
    )>, violation_notes: Array<(
      { __typename?: 'violation_note' }
      & Pick<Violation_Note, 'action' | 'created_at' | 'id' | 'note' | 'user_id'>
    )>, story_violations: Array<(
      { __typename?: 'story_violation' }
      & Pick<Story_Violation, 'user_id' | 'created_at' | 'id'>
    )>, resource?: Maybe<(
      { __typename?: 'resource' }
      & Pick<Resource, 'created_at' | 'id' | 'type' | 'updated_at' | 'url'>
    )>, replies: Array<(
      { __typename?: 'reply' }
      & Pick<Reply, 'id' | 'content' | 'created_at' | 'updated_at'>
      & { prompt: (
        { __typename?: 'prompt' }
        & Pick<Prompt, 'id' | 'title' | 'position'>
      ) }
    )> }
  )> }
);

export type StoryCountQueryVariables = Exact<{
  status: Scalars['String'];
}>;


export type StoryCountQuery = (
  { __typename?: 'query_root' }
  & { story_aggregate: (
    { __typename?: 'story_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'story_aggregate_fields' }
      & Pick<Story_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type DeleteStoryMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteStoryMutation = (
  { __typename?: 'mutation_root' }
  & { DeleteStory?: Maybe<(
    { __typename?: 'DeleteStoryOutput' }
    & Pick<DeleteStoryOutput, 'success'>
  )> }
);

export type InsertStoryTagMutationVariables = Exact<{
  object: Story_Tag_Insert_Input;
}>;


export type InsertStoryTagMutation = (
  { __typename?: 'mutation_root' }
  & { insert_story_tag_one?: Maybe<(
    { __typename?: 'story_tag' }
    & Pick<Story_Tag, 'id' | 'tag_id' | 'story_id'>
  )> }
);

export type DeleteStoryTagMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteStoryTagMutation = (
  { __typename?: 'mutation_root' }
  & { delete_story_tag_by_pk?: Maybe<(
    { __typename?: 'story_tag' }
    & Pick<Story_Tag, 'id'>
  )> }
);

export type SaveStoryOrderMutationVariables = Exact<{
  id: Scalars['uuid'];
  order?: Maybe<Scalars['Int']>;
}>;


export type SaveStoryOrderMutation = (
  { __typename?: 'mutation_root' }
  & { update_story_by_pk?: Maybe<(
    { __typename?: 'story' }
    & Pick<Story, 'id' | 'order'>
  )> }
);

export type UpdateStoryTranscriptMutationVariables = Exact<{
  id: Scalars['uuid'];
  transcription?: Maybe<Scalars['String']>;
}>;


export type UpdateStoryTranscriptMutation = (
  { __typename?: 'mutation_root' }
  & { update_story_by_pk?: Maybe<(
    { __typename?: 'story' }
    & Pick<Story, 'id' | 'transcription'>
  )> }
);

export type GetSignedUrlQueryVariables = Exact<{
  type: Scalars['String'];
}>;


export type GetSignedUrlQuery = (
  { __typename?: 'query_root' }
  & { generateSignedUrl?: Maybe<(
    { __typename?: 'SignedUrlOutput' }
    & Pick<SignedUrlOutput, 'resource_id' | 'content_type' | 'signed_url'>
  )> }
);

export type CreateStoryMutationVariables = Exact<{
  story: Story_Insert_Input;
  replies: Array<Reply_Insert_Input>;
  resource: Array<Resource_Insert_Input>;
  tags: Array<Story_Tag_Insert_Input>;
}>;


export type CreateStoryMutation = (
  { __typename?: 'mutation_root' }
  & { story?: Maybe<(
    { __typename?: 'story' }
    & Pick<Story, 'id' | 'title' | 'type' | 'caption' | 'content' | 'user_id' | 'status'>
  )>, insert_reply?: Maybe<(
    { __typename?: 'reply_mutation_response' }
    & { returning: Array<(
      { __typename?: 'reply' }
      & Pick<Reply, 'id'>
    )> }
  )>, insert_resource?: Maybe<(
    { __typename?: 'resource_mutation_response' }
    & { returning: Array<(
      { __typename?: 'resource' }
      & Pick<Resource, 'id' | 'url' | 'type'>
    )> }
  )>, insert_story_tag?: Maybe<(
    { __typename?: 'story_tag_mutation_response' }
    & { returning: Array<(
      { __typename?: 'story_tag' }
      & Pick<Story_Tag, 'id' | 'story_id' | 'tag_id'>
    )> }
  )> }
);

export type UpdateStoryAndResourceMutationVariables = Exact<{
  story?: Maybe<Story_Set_Input>;
  storyId: Scalars['uuid'];
  resource: Array<Resource_Insert_Input>;
}>;


export type UpdateStoryAndResourceMutation = (
  { __typename?: 'mutation_root' }
  & { update_story_by_pk?: Maybe<(
    { __typename?: 'story' }
    & Pick<Story, 'id' | 'title' | 'type' | 'caption'>
  )>, insert_resource?: Maybe<(
    { __typename?: 'resource_mutation_response' }
    & { returning: Array<(
      { __typename?: 'resource' }
      & Pick<Resource, 'id' | 'url' | 'type'>
    )> }
  )> }
);

export type UpdateStoryMutationVariables = Exact<{
  story?: Maybe<Story_Set_Input>;
  storyId: Scalars['uuid'];
}>;


export type UpdateStoryMutation = (
  { __typename?: 'mutation_root' }
  & { update_story_by_pk?: Maybe<(
    { __typename?: 'story' }
    & Pick<Story, 'id' | 'title' | 'type' | 'caption'>
  )> }
);

export type TagsQueryVariables = Exact<{
  orderBy?: Maybe<Array<Tag_Order_By>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type TagsQuery = (
  { __typename?: 'query_root' }
  & { tag: Array<(
    { __typename?: 'tag' }
    & TagFragmentFragment
  )>, tag_aggregate: (
    { __typename?: 'tag_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'tag_aggregate_fields' }
      & Pick<Tag_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type GetTagQueryVariables = Exact<{
  tagId: Scalars['uuid'];
}>;


export type GetTagQuery = (
  { __typename?: 'query_root' }
  & { tag: Array<(
    { __typename?: 'tag' }
    & TagFragmentFragment
  )> }
);

export type UpdateTagMutationVariables = Exact<{
  id: Scalars['uuid'];
  tag: Tag_Set_Input;
}>;


export type UpdateTagMutation = (
  { __typename?: 'mutation_root' }
  & { update_tag_by_pk?: Maybe<(
    { __typename?: 'tag' }
    & TagFragmentFragment
  )> }
);

export type DeleteTagMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteTagMutation = (
  { __typename?: 'mutation_root' }
  & { delete_story_tag?: Maybe<(
    { __typename?: 'story_tag_mutation_response' }
    & Pick<Story_Tag_Mutation_Response, 'affected_rows'>
  )>, delete_tag_by_pk?: Maybe<(
    { __typename?: 'tag' }
    & Pick<Tag, 'id'>
  )> }
);

export type AddTagMutationVariables = Exact<{
  object: Tag_Insert_Input;
}>;


export type AddTagMutation = (
  { __typename?: 'mutation_root' }
  & { insert_tag_one?: Maybe<(
    { __typename?: 'tag' }
    & TagFragmentFragment
  )> }
);

export type TagFragmentFragment = (
  { __typename?: 'tag' }
  & Pick<Tag, 'id' | 'name' | 'type' | 'verified' | 'order' | 'created_at' | 'updated_at'>
);

export type SaveTagOrderMutationVariables = Exact<{
  id: Scalars['uuid'];
  order?: Maybe<Scalars['Int']>;
}>;


export type SaveTagOrderMutation = (
  { __typename?: 'mutation_root' }
  & { update_tag_by_pk?: Maybe<(
    { __typename?: 'tag' }
    & Pick<Tag, 'id' | 'order'>
  )> }
);

export type UsersQueryVariables = Exact<{
  orderBy?: Maybe<Array<User_Order_By>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  where?: Maybe<User_Bool_Exp>;
}>;


export type UsersQuery = (
  { __typename?: 'query_root' }
  & { user: Array<(
    { __typename?: 'user' }
    & Pick<User, 'created_at' | 'id' | 'first_name' | 'display_name' | 'suburb'>
    & { account?: Maybe<(
      { __typename?: 'auth_accounts' }
      & Pick<Auth_Accounts, 'id' | 'email' | 'active'>
      & { account_roles: Array<(
        { __typename?: 'auth_account_roles' }
        & Pick<Auth_Account_Roles, 'id' | 'role'>
      )> }
    )>, stories_aggregate: (
      { __typename?: 'story_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'story_aggregate_fields' }
        & Pick<Story_Aggregate_Fields, 'count'>
      )> }
    ), story_violations_aggregate: (
      { __typename?: 'story_violation_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'story_violation_aggregate_fields' }
        & Pick<Story_Violation_Aggregate_Fields, 'count'>
      )> }
    ), violation_notes_aggregate: (
      { __typename?: 'violation_note_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'violation_note_aggregate_fields' }
        & Pick<Violation_Note_Aggregate_Fields, 'count'>
      )> }
    ) }
  )>, user_aggregate: (
    { __typename?: 'user_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'user_aggregate_fields' }
      & Pick<User_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type GetUserQueryVariables = Exact<{
  UserId: Scalars['uuid'];
}>;


export type GetUserQuery = (
  { __typename?: 'query_root' }
  & { user: Array<(
    { __typename?: 'user' }
    & Pick<User, 'created_at' | 'id' | 'first_name' | 'display_name' | 'suburb'>
    & { account?: Maybe<(
      { __typename?: 'auth_accounts' }
      & Pick<Auth_Accounts, 'id' | 'email' | 'active'>
      & { account_roles: Array<(
        { __typename?: 'auth_account_roles' }
        & Pick<Auth_Account_Roles, 'id' | 'role'>
      )> }
    )>, stories_aggregate: (
      { __typename?: 'story_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'story_aggregate_fields' }
        & Pick<Story_Aggregate_Fields, 'count'>
      )> }
    ), story_violations_aggregate: (
      { __typename?: 'story_violation_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'story_violation_aggregate_fields' }
        & Pick<Story_Violation_Aggregate_Fields, 'count'>
      )> }
    ), violation_notes_aggregate: (
      { __typename?: 'violation_note_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'violation_note_aggregate_fields' }
        & Pick<Violation_Note_Aggregate_Fields, 'count'>
      )> }
    ) }
  )> }
);

export type UserCountQueryVariables = Exact<{
  active: Scalars['Boolean'];
}>;


export type UserCountQuery = (
  { __typename?: 'query_root' }
  & { user_aggregate: (
    { __typename?: 'user_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'user_aggregate_fields' }
      & Pick<User_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['uuid'];
  user: User_Set_Input;
  account: Auth_Accounts_Set_Input;
}>;


export type UpdateUserMutation = (
  { __typename?: 'mutation_root' }
  & { update_user_by_pk?: Maybe<(
    { __typename?: 'user' }
    & Pick<User, 'id'>
  )>, update_auth_accounts?: Maybe<(
    { __typename?: 'auth_accounts_mutation_response' }
    & { returning: Array<(
      { __typename?: 'auth_accounts' }
      & Pick<Auth_Accounts, 'id'>
    )> }
  )> }
);

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteUserMutation = (
  { __typename?: 'mutation_root' }
  & { DeleteUser?: Maybe<(
    { __typename?: 'DeleteUserOutput' }
    & Pick<DeleteUserOutput, 'success'>
  )> }
);

export type SendPushToUserMutationVariables = Exact<{
  userId: Scalars['String'];
  message: Scalars['String'];
}>;


export type SendPushToUserMutation = (
  { __typename?: 'mutation_root' }
  & { SendPushToUser?: Maybe<(
    { __typename?: 'SendPushToUserOutput' }
    & Pick<SendPushToUserOutput, 'success'>
  )> }
);

export type SendPushToUsersMutationVariables = Exact<{
  userIds?: Maybe<Array<Scalars['String']>>;
  message: Scalars['String'];
}>;


export type SendPushToUsersMutation = (
  { __typename?: 'mutation_root' }
  & { SendPushToUsers?: Maybe<(
    { __typename?: 'SendPushToUsersOutput' }
    & Pick<SendPushToUsersOutput, 'success'>
  )> }
);

export type GetPushSettingsForUserQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;


export type GetPushSettingsForUserQuery = (
  { __typename?: 'query_root' }
  & { user_by_pk?: Maybe<(
    { __typename?: 'user' }
    & Pick<User, 'id' | 'push_new_story' | 'push_story_liked' | 'push_story_saved' | 'push_story_shared' | 'push_story_approved'>
  )> }
);

export type GetUsersToPushForNewStoriesQueryVariables = Exact<{
  storyUserId: Scalars['uuid'];
  date: Scalars['timestamptz'];
}>;


export type GetUsersToPushForNewStoriesQuery = (
  { __typename?: 'query_root' }
  & { user: Array<(
    { __typename?: 'user' }
    & Pick<User, 'id'>
  )> }
);

export type UpdateLastPushedNewStoryForUsersMutationVariables = Exact<{
  userIds: Array<Scalars['uuid']>;
  date: Scalars['timestamptz'];
}>;


export type UpdateLastPushedNewStoryForUsersMutation = (
  { __typename?: 'mutation_root' }
  & { update_user?: Maybe<(
    { __typename?: 'user_mutation_response' }
    & Pick<User_Mutation_Response, 'affected_rows'>
  )> }
);

export type GetUserIdByEmailQueryVariables = Exact<{
  email: Scalars['citext'];
}>;


export type GetUserIdByEmailQuery = (
  { __typename?: 'query_root' }
  & { user: Array<(
    { __typename?: 'user' }
    & Pick<User, 'id'>
    & { account?: Maybe<(
      { __typename?: 'auth_accounts' }
      & Pick<Auth_Accounts, 'email'>
    )> }
  )> }
);

export type AddViolationNoteMutationVariables = Exact<{
  object: Violation_Note_Insert_Input;
  storyId: Scalars['uuid'];
  storyStatus: Scalars['String'];
  publishedAt?: Maybe<Scalars['timestamptz']>;
}>;


export type AddViolationNoteMutation = (
  { __typename?: 'mutation_root' }
  & { insert_violation_note_one?: Maybe<(
    { __typename?: 'violation_note' }
    & Pick<Violation_Note, 'id'>
  )>, update_story?: Maybe<(
    { __typename?: 'story_mutation_response' }
    & { returning: Array<(
      { __typename?: 'story' }
      & Pick<Story, 'id'>
    )> }
  )> }
);

export type AdminViolationCountQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminViolationCountQuery = (
  { __typename?: 'query_root' }
  & { story_violation_aggregate: (
    { __typename?: 'story_violation_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'story_violation_aggregate_fields' }
      & Pick<Story_Violation_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type UserViolationCountQueryVariables = Exact<{ [key: string]: never; }>;


export type UserViolationCountQuery = (
  { __typename?: 'query_root' }
  & { story_violation_aggregate: (
    { __typename?: 'story_violation_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'story_violation_aggregate_fields' }
      & Pick<Story_Violation_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export const StoriesFragmentFragmentDoc = gql`
    fragment StoriesFragment on story {
  first_submitted_at
  last_submitted_at
  type
  number_of_approvals
  last_approved_at
  number_of_rejections
  last_rejected_at
  status
  user {
    account {
      email
    }
    screener_score
    first_name
    date_of_birth  
  }
  story_violations_aggregate {
    aggregate {
      count
    }
  }
  id
  title
  created_at
  order
  transcription
  last_reported_at
  user_id
}
    `;
export const TagFragmentFragmentDoc = gql`
    fragment TagFragment on tag {
  id
  name
  type
  verified
  order
  created_at
  updated_at
}
    `;
export const UpdateAccountAccountRolesDocument = gql`
    mutation UpdateAccountAccountRoles($objects: [auth_account_roles_insert_input!]!, $accountId: uuid!, $roles: [String!]) {
  insert_auth_account_roles(
    on_conflict: {constraint: user_roles_account_id_role_key, update_columns: []}
    objects: $objects
  ) {
    affected_rows
  }
  delete_auth_account_roles(
    where: {account_id: {_eq: $accountId}, role: {_nin: $roles}}
  ) {
    affected_rows
  }
}
    `;
export type UpdateAccountAccountRolesMutationFn = Apollo.MutationFunction<UpdateAccountAccountRolesMutation, UpdateAccountAccountRolesMutationVariables>;

/**
 * __useUpdateAccountAccountRolesMutation__
 *
 * To run a mutation, you first call `useUpdateAccountAccountRolesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountAccountRolesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountAccountRolesMutation, { data, loading, error }] = useUpdateAccountAccountRolesMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *      accountId: // value for 'accountId'
 *      roles: // value for 'roles'
 *   },
 * });
 */
export function useUpdateAccountAccountRolesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAccountAccountRolesMutation, UpdateAccountAccountRolesMutationVariables>) {
        return Apollo.useMutation<UpdateAccountAccountRolesMutation, UpdateAccountAccountRolesMutationVariables>(UpdateAccountAccountRolesDocument, baseOptions);
      }
export type UpdateAccountAccountRolesMutationHookResult = ReturnType<typeof useUpdateAccountAccountRolesMutation>;
export type UpdateAccountAccountRolesMutationResult = Apollo.MutationResult<UpdateAccountAccountRolesMutation>;
export type UpdateAccountAccountRolesMutationOptions = Apollo.BaseMutationOptions<UpdateAccountAccountRolesMutation, UpdateAccountAccountRolesMutationVariables>;
export const AccountRolesDocument = gql`
    query AccountRoles($orderBy: [auth_account_roles_order_by!], $limit: Int, $offset: Int) {
  auth_account_roles(order_by: $orderBy, limit: $limit, offset: $offset) {
    id
    role
  }
  auth_account_roles_aggregate {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useAccountRolesQuery__
 *
 * To run a query within a React component, call `useAccountRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountRolesQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useAccountRolesQuery(baseOptions?: Apollo.QueryHookOptions<AccountRolesQuery, AccountRolesQueryVariables>) {
        return Apollo.useQuery<AccountRolesQuery, AccountRolesQueryVariables>(AccountRolesDocument, baseOptions);
      }
export function useAccountRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountRolesQuery, AccountRolesQueryVariables>) {
          return Apollo.useLazyQuery<AccountRolesQuery, AccountRolesQueryVariables>(AccountRolesDocument, baseOptions);
        }
export type AccountRolesQueryHookResult = ReturnType<typeof useAccountRolesQuery>;
export type AccountRolesLazyQueryHookResult = ReturnType<typeof useAccountRolesLazyQuery>;
export type AccountRolesQueryResult = Apollo.QueryResult<AccountRolesQuery, AccountRolesQueryVariables>;
export const AuthRolesDocument = gql`
    query AuthRoles($orderBy: [auth_roles_order_by!], $limit: Int, $offset: Int) {
  auth_roles(order_by: $orderBy, limit: $limit, offset: $offset) {
    role
  }
  auth_roles_aggregate {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useAuthRolesQuery__
 *
 * To run a query within a React component, call `useAuthRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthRolesQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useAuthRolesQuery(baseOptions?: Apollo.QueryHookOptions<AuthRolesQuery, AuthRolesQueryVariables>) {
        return Apollo.useQuery<AuthRolesQuery, AuthRolesQueryVariables>(AuthRolesDocument, baseOptions);
      }
export function useAuthRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AuthRolesQuery, AuthRolesQueryVariables>) {
          return Apollo.useLazyQuery<AuthRolesQuery, AuthRolesQueryVariables>(AuthRolesDocument, baseOptions);
        }
export type AuthRolesQueryHookResult = ReturnType<typeof useAuthRolesQuery>;
export type AuthRolesLazyQueryHookResult = ReturnType<typeof useAuthRolesLazyQuery>;
export type AuthRolesQueryResult = Apollo.QueryResult<AuthRolesQuery, AuthRolesQueryVariables>;
export const SendEmailDocument = gql`
    mutation SendEmail($template: String!, $email: String!, $tags: String!) {
  SendEmail(template: $template, email: $email, tags: $tags) {
    success
  }
}
    `;
export type SendEmailMutationFn = Apollo.MutationFunction<SendEmailMutation, SendEmailMutationVariables>;

/**
 * __useSendEmailMutation__
 *
 * To run a mutation, you first call `useSendEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEmailMutation, { data, loading, error }] = useSendEmailMutation({
 *   variables: {
 *      template: // value for 'template'
 *      email: // value for 'email'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useSendEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendEmailMutation, SendEmailMutationVariables>) {
        return Apollo.useMutation<SendEmailMutation, SendEmailMutationVariables>(SendEmailDocument, baseOptions);
      }
export type SendEmailMutationHookResult = ReturnType<typeof useSendEmailMutation>;
export type SendEmailMutationResult = Apollo.MutationResult<SendEmailMutation>;
export type SendEmailMutationOptions = Apollo.BaseMutationOptions<SendEmailMutation, SendEmailMutationVariables>;
export const FeedbacksDocument = gql`
    query Feedbacks($orderBy: [feedback_order_by!], $limit: Int, $offset: Int) {
  feedback(order_by: $orderBy, limit: $limit, offset: $offset) {
    id
    created_at
    user {
      first_name
      account {
        email
      }
    }
  }
  feedback_aggregate {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useFeedbacksQuery__
 *
 * To run a query within a React component, call `useFeedbacksQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeedbacksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeedbacksQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useFeedbacksQuery(baseOptions?: Apollo.QueryHookOptions<FeedbacksQuery, FeedbacksQueryVariables>) {
        return Apollo.useQuery<FeedbacksQuery, FeedbacksQueryVariables>(FeedbacksDocument, baseOptions);
      }
export function useFeedbacksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FeedbacksQuery, FeedbacksQueryVariables>) {
          return Apollo.useLazyQuery<FeedbacksQuery, FeedbacksQueryVariables>(FeedbacksDocument, baseOptions);
        }
export type FeedbacksQueryHookResult = ReturnType<typeof useFeedbacksQuery>;
export type FeedbacksLazyQueryHookResult = ReturnType<typeof useFeedbacksLazyQuery>;
export type FeedbacksQueryResult = Apollo.QueryResult<FeedbacksQuery, FeedbacksQueryVariables>;
export const GetFeedbackDocument = gql`
    query GetFeedback($FeedbackId: uuid!) {
  feedback(where: {id: {_eq: $FeedbackId}}) {
    id
    created_at
    description
    user {
      display_name
      first_name
      account {
        email
      }
    }
  }
}
    `;

/**
 * __useGetFeedbackQuery__
 *
 * To run a query within a React component, call `useGetFeedbackQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeedbackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeedbackQuery({
 *   variables: {
 *      FeedbackId: // value for 'FeedbackId'
 *   },
 * });
 */
export function useGetFeedbackQuery(baseOptions: Apollo.QueryHookOptions<GetFeedbackQuery, GetFeedbackQueryVariables>) {
        return Apollo.useQuery<GetFeedbackQuery, GetFeedbackQueryVariables>(GetFeedbackDocument, baseOptions);
      }
export function useGetFeedbackLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeedbackQuery, GetFeedbackQueryVariables>) {
          return Apollo.useLazyQuery<GetFeedbackQuery, GetFeedbackQueryVariables>(GetFeedbackDocument, baseOptions);
        }
export type GetFeedbackQueryHookResult = ReturnType<typeof useGetFeedbackQuery>;
export type GetFeedbackLazyQueryHookResult = ReturnType<typeof useGetFeedbackLazyQuery>;
export type GetFeedbackQueryResult = Apollo.QueryResult<GetFeedbackQuery, GetFeedbackQueryVariables>;
export const DeleteFeedbackDocument = gql`
    mutation DeleteFeedback($id: uuid!) {
  delete_feedback_by_pk(id: $id) {
    id
  }
}
    `;
export type DeleteFeedbackMutationFn = Apollo.MutationFunction<DeleteFeedbackMutation, DeleteFeedbackMutationVariables>;

/**
 * __useDeleteFeedbackMutation__
 *
 * To run a mutation, you first call `useDeleteFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFeedbackMutation, { data, loading, error }] = useDeleteFeedbackMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFeedbackMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFeedbackMutation, DeleteFeedbackMutationVariables>) {
        return Apollo.useMutation<DeleteFeedbackMutation, DeleteFeedbackMutationVariables>(DeleteFeedbackDocument, baseOptions);
      }
export type DeleteFeedbackMutationHookResult = ReturnType<typeof useDeleteFeedbackMutation>;
export type DeleteFeedbackMutationResult = Apollo.MutationResult<DeleteFeedbackMutation>;
export type DeleteFeedbackMutationOptions = Apollo.BaseMutationOptions<DeleteFeedbackMutation, DeleteFeedbackMutationVariables>;
export const InfoTilesDocument = gql`
    query InfoTiles($orderBy: [info_tile_order_by!], $status: info_tile_status_type_enum, $limit: Int, $offset: Int) {
  info_tile(
    where: {status: {_eq: $status}}
    limit: $limit
    offset: $offset
    order_by: $orderBy
  ) {
    id
    image_url
    action_url
    status
    created_at
    accessibility_text
    order
  }
  info_tile_aggregate(where: {status: {_eq: $status}}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useInfoTilesQuery__
 *
 * To run a query within a React component, call `useInfoTilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInfoTilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInfoTilesQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      status: // value for 'status'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useInfoTilesQuery(baseOptions?: Apollo.QueryHookOptions<InfoTilesQuery, InfoTilesQueryVariables>) {
        return Apollo.useQuery<InfoTilesQuery, InfoTilesQueryVariables>(InfoTilesDocument, baseOptions);
      }
export function useInfoTilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InfoTilesQuery, InfoTilesQueryVariables>) {
          return Apollo.useLazyQuery<InfoTilesQuery, InfoTilesQueryVariables>(InfoTilesDocument, baseOptions);
        }
export type InfoTilesQueryHookResult = ReturnType<typeof useInfoTilesQuery>;
export type InfoTilesLazyQueryHookResult = ReturnType<typeof useInfoTilesLazyQuery>;
export type InfoTilesQueryResult = Apollo.QueryResult<InfoTilesQuery, InfoTilesQueryVariables>;
export const GetInfoTileDocument = gql`
    query GetInfoTile($InfoTileId: uuid!) {
  info_tile(where: {id: {_eq: $InfoTileId}}) {
    id
    image_url
    action_url
    status
    created_at
    accessibility_text
    order
  }
}
    `;

/**
 * __useGetInfoTileQuery__
 *
 * To run a query within a React component, call `useGetInfoTileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInfoTileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInfoTileQuery({
 *   variables: {
 *      InfoTileId: // value for 'InfoTileId'
 *   },
 * });
 */
export function useGetInfoTileQuery(baseOptions: Apollo.QueryHookOptions<GetInfoTileQuery, GetInfoTileQueryVariables>) {
        return Apollo.useQuery<GetInfoTileQuery, GetInfoTileQueryVariables>(GetInfoTileDocument, baseOptions);
      }
export function useGetInfoTileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInfoTileQuery, GetInfoTileQueryVariables>) {
          return Apollo.useLazyQuery<GetInfoTileQuery, GetInfoTileQueryVariables>(GetInfoTileDocument, baseOptions);
        }
export type GetInfoTileQueryHookResult = ReturnType<typeof useGetInfoTileQuery>;
export type GetInfoTileLazyQueryHookResult = ReturnType<typeof useGetInfoTileLazyQuery>;
export type GetInfoTileQueryResult = Apollo.QueryResult<GetInfoTileQuery, GetInfoTileQueryVariables>;
export const GetInfoTilesDocument = gql`
    query GetInfoTiles($InfoTileIds: [uuid!]) {
  info_tile(where: {id: {_in: $InfoTileIds}}) {
    id
    image_url
    action_url
    status
    created_at
    accessibility_text
    order
  }
}
    `;

/**
 * __useGetInfoTilesQuery__
 *
 * To run a query within a React component, call `useGetInfoTilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInfoTilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInfoTilesQuery({
 *   variables: {
 *      InfoTileIds: // value for 'InfoTileIds'
 *   },
 * });
 */
export function useGetInfoTilesQuery(baseOptions?: Apollo.QueryHookOptions<GetInfoTilesQuery, GetInfoTilesQueryVariables>) {
        return Apollo.useQuery<GetInfoTilesQuery, GetInfoTilesQueryVariables>(GetInfoTilesDocument, baseOptions);
      }
export function useGetInfoTilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInfoTilesQuery, GetInfoTilesQueryVariables>) {
          return Apollo.useLazyQuery<GetInfoTilesQuery, GetInfoTilesQueryVariables>(GetInfoTilesDocument, baseOptions);
        }
export type GetInfoTilesQueryHookResult = ReturnType<typeof useGetInfoTilesQuery>;
export type GetInfoTilesLazyQueryHookResult = ReturnType<typeof useGetInfoTilesLazyQuery>;
export type GetInfoTilesQueryResult = Apollo.QueryResult<GetInfoTilesQuery, GetInfoTilesQueryVariables>;
export const AddInfoTileDocument = gql`
    mutation AddInfoTile($object: info_tile_insert_input!) {
  insert_info_tile_one(object: $object) {
    id
    image_url
    action_url
    status
    created_at
    accessibility_text
  }
}
    `;
export type AddInfoTileMutationFn = Apollo.MutationFunction<AddInfoTileMutation, AddInfoTileMutationVariables>;

/**
 * __useAddInfoTileMutation__
 *
 * To run a mutation, you first call `useAddInfoTileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddInfoTileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addInfoTileMutation, { data, loading, error }] = useAddInfoTileMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useAddInfoTileMutation(baseOptions?: Apollo.MutationHookOptions<AddInfoTileMutation, AddInfoTileMutationVariables>) {
        return Apollo.useMutation<AddInfoTileMutation, AddInfoTileMutationVariables>(AddInfoTileDocument, baseOptions);
      }
export type AddInfoTileMutationHookResult = ReturnType<typeof useAddInfoTileMutation>;
export type AddInfoTileMutationResult = Apollo.MutationResult<AddInfoTileMutation>;
export type AddInfoTileMutationOptions = Apollo.BaseMutationOptions<AddInfoTileMutation, AddInfoTileMutationVariables>;
export const GetInfoTileS3UploadUrlDocument = gql`
    query GetInfoTileS3UploadUrl {
  getInfoTileUploadUrl {
    url
  }
}
    `;

/**
 * __useGetInfoTileS3UploadUrlQuery__
 *
 * To run a query within a React component, call `useGetInfoTileS3UploadUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInfoTileS3UploadUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInfoTileS3UploadUrlQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInfoTileS3UploadUrlQuery(baseOptions?: Apollo.QueryHookOptions<GetInfoTileS3UploadUrlQuery, GetInfoTileS3UploadUrlQueryVariables>) {
        return Apollo.useQuery<GetInfoTileS3UploadUrlQuery, GetInfoTileS3UploadUrlQueryVariables>(GetInfoTileS3UploadUrlDocument, baseOptions);
      }
export function useGetInfoTileS3UploadUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInfoTileS3UploadUrlQuery, GetInfoTileS3UploadUrlQueryVariables>) {
          return Apollo.useLazyQuery<GetInfoTileS3UploadUrlQuery, GetInfoTileS3UploadUrlQueryVariables>(GetInfoTileS3UploadUrlDocument, baseOptions);
        }
export type GetInfoTileS3UploadUrlQueryHookResult = ReturnType<typeof useGetInfoTileS3UploadUrlQuery>;
export type GetInfoTileS3UploadUrlLazyQueryHookResult = ReturnType<typeof useGetInfoTileS3UploadUrlLazyQuery>;
export type GetInfoTileS3UploadUrlQueryResult = Apollo.QueryResult<GetInfoTileS3UploadUrlQuery, GetInfoTileS3UploadUrlQueryVariables>;
export const UpdateInfoTileStatusDocument = gql`
    mutation UpdateInfoTileStatus($id: uuid!, $status: info_tile_status_type_enum!) {
  update_info_tile_by_pk(pk_columns: {id: $id}, _set: {status: $status}) {
    id
    status
  }
}
    `;
export type UpdateInfoTileStatusMutationFn = Apollo.MutationFunction<UpdateInfoTileStatusMutation, UpdateInfoTileStatusMutationVariables>;

/**
 * __useUpdateInfoTileStatusMutation__
 *
 * To run a mutation, you first call `useUpdateInfoTileStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInfoTileStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInfoTileStatusMutation, { data, loading, error }] = useUpdateInfoTileStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateInfoTileStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInfoTileStatusMutation, UpdateInfoTileStatusMutationVariables>) {
        return Apollo.useMutation<UpdateInfoTileStatusMutation, UpdateInfoTileStatusMutationVariables>(UpdateInfoTileStatusDocument, baseOptions);
      }
export type UpdateInfoTileStatusMutationHookResult = ReturnType<typeof useUpdateInfoTileStatusMutation>;
export type UpdateInfoTileStatusMutationResult = Apollo.MutationResult<UpdateInfoTileStatusMutation>;
export type UpdateInfoTileStatusMutationOptions = Apollo.BaseMutationOptions<UpdateInfoTileStatusMutation, UpdateInfoTileStatusMutationVariables>;
export const InfoTileCountDocument = gql`
    query InfoTileCount($status: info_tile_status_type_enum!) {
  info_tile_aggregate(where: {status: {_eq: $status}}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useInfoTileCountQuery__
 *
 * To run a query within a React component, call `useInfoTileCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useInfoTileCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInfoTileCountQuery({
 *   variables: {
 *      status: // value for 'status'
 *   },
 * });
 */
export function useInfoTileCountQuery(baseOptions: Apollo.QueryHookOptions<InfoTileCountQuery, InfoTileCountQueryVariables>) {
        return Apollo.useQuery<InfoTileCountQuery, InfoTileCountQueryVariables>(InfoTileCountDocument, baseOptions);
      }
export function useInfoTileCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InfoTileCountQuery, InfoTileCountQueryVariables>) {
          return Apollo.useLazyQuery<InfoTileCountQuery, InfoTileCountQueryVariables>(InfoTileCountDocument, baseOptions);
        }
export type InfoTileCountQueryHookResult = ReturnType<typeof useInfoTileCountQuery>;
export type InfoTileCountLazyQueryHookResult = ReturnType<typeof useInfoTileCountLazyQuery>;
export type InfoTileCountQueryResult = Apollo.QueryResult<InfoTileCountQuery, InfoTileCountQueryVariables>;
export const UpdateInfoTileDocument = gql`
    mutation UpdateInfoTile($id: uuid!, $infoTile: info_tile_set_input!) {
  update_info_tile_by_pk(pk_columns: {id: $id}, _set: $infoTile) {
    id
  }
}
    `;
export type UpdateInfoTileMutationFn = Apollo.MutationFunction<UpdateInfoTileMutation, UpdateInfoTileMutationVariables>;

/**
 * __useUpdateInfoTileMutation__
 *
 * To run a mutation, you first call `useUpdateInfoTileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInfoTileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInfoTileMutation, { data, loading, error }] = useUpdateInfoTileMutation({
 *   variables: {
 *      id: // value for 'id'
 *      infoTile: // value for 'infoTile'
 *   },
 * });
 */
export function useUpdateInfoTileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInfoTileMutation, UpdateInfoTileMutationVariables>) {
        return Apollo.useMutation<UpdateInfoTileMutation, UpdateInfoTileMutationVariables>(UpdateInfoTileDocument, baseOptions);
      }
export type UpdateInfoTileMutationHookResult = ReturnType<typeof useUpdateInfoTileMutation>;
export type UpdateInfoTileMutationResult = Apollo.MutationResult<UpdateInfoTileMutation>;
export type UpdateInfoTileMutationOptions = Apollo.BaseMutationOptions<UpdateInfoTileMutation, UpdateInfoTileMutationVariables>;
export const SaveInfoTileOrderDocument = gql`
    mutation SaveInfoTileOrder($id: uuid!, $order: Int) {
  update_info_tile_by_pk(pk_columns: {id: $id}, _set: {order: $order}) {
    id
    order
  }
}
    `;
export type SaveInfoTileOrderMutationFn = Apollo.MutationFunction<SaveInfoTileOrderMutation, SaveInfoTileOrderMutationVariables>;

/**
 * __useSaveInfoTileOrderMutation__
 *
 * To run a mutation, you first call `useSaveInfoTileOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveInfoTileOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveInfoTileOrderMutation, { data, loading, error }] = useSaveInfoTileOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useSaveInfoTileOrderMutation(baseOptions?: Apollo.MutationHookOptions<SaveInfoTileOrderMutation, SaveInfoTileOrderMutationVariables>) {
        return Apollo.useMutation<SaveInfoTileOrderMutation, SaveInfoTileOrderMutationVariables>(SaveInfoTileOrderDocument, baseOptions);
      }
export type SaveInfoTileOrderMutationHookResult = ReturnType<typeof useSaveInfoTileOrderMutation>;
export type SaveInfoTileOrderMutationResult = Apollo.MutationResult<SaveInfoTileOrderMutation>;
export type SaveInfoTileOrderMutationOptions = Apollo.BaseMutationOptions<SaveInfoTileOrderMutation, SaveInfoTileOrderMutationVariables>;
export const ReferralsDocument = gql`
    query Referrals($orderBy: [referral_order_by!], $limit: Int, $offset: Int) {
  referral(limit: $limit, offset: $offset, order_by: $orderBy) {
    id
    value
  }
  referral_aggregate {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useReferralsQuery__
 *
 * To run a query within a React component, call `useReferralsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferralsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferralsQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useReferralsQuery(baseOptions?: Apollo.QueryHookOptions<ReferralsQuery, ReferralsQueryVariables>) {
        return Apollo.useQuery<ReferralsQuery, ReferralsQueryVariables>(ReferralsDocument, baseOptions);
      }
export function useReferralsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReferralsQuery, ReferralsQueryVariables>) {
          return Apollo.useLazyQuery<ReferralsQuery, ReferralsQueryVariables>(ReferralsDocument, baseOptions);
        }
export type ReferralsQueryHookResult = ReturnType<typeof useReferralsQuery>;
export type ReferralsLazyQueryHookResult = ReturnType<typeof useReferralsLazyQuery>;
export type ReferralsQueryResult = Apollo.QueryResult<ReferralsQuery, ReferralsQueryVariables>;
export const GetReferralsDocument = gql`
    query GetReferrals {
  referral {
    id
    value
  }
}
    `;

/**
 * __useGetReferralsQuery__
 *
 * To run a query within a React component, call `useGetReferralsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReferralsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReferralsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetReferralsQuery(baseOptions?: Apollo.QueryHookOptions<GetReferralsQuery, GetReferralsQueryVariables>) {
        return Apollo.useQuery<GetReferralsQuery, GetReferralsQueryVariables>(GetReferralsDocument, baseOptions);
      }
export function useGetReferralsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReferralsQuery, GetReferralsQueryVariables>) {
          return Apollo.useLazyQuery<GetReferralsQuery, GetReferralsQueryVariables>(GetReferralsDocument, baseOptions);
        }
export type GetReferralsQueryHookResult = ReturnType<typeof useGetReferralsQuery>;
export type GetReferralsLazyQueryHookResult = ReturnType<typeof useGetReferralsLazyQuery>;
export type GetReferralsQueryResult = Apollo.QueryResult<GetReferralsQuery, GetReferralsQueryVariables>;
export const TopicsDocument = gql`
    query Topics($orderBy: [topic_order_by!], $limit: Int, $offset: Int) {
  topic(limit: $limit, offset: $offset, order_by: $orderBy) {
    id
    value
  }
  topic_aggregate {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useTopicsQuery__
 *
 * To run a query within a React component, call `useTopicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopicsQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useTopicsQuery(baseOptions?: Apollo.QueryHookOptions<TopicsQuery, TopicsQueryVariables>) {
        return Apollo.useQuery<TopicsQuery, TopicsQueryVariables>(TopicsDocument, baseOptions);
      }
export function useTopicsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TopicsQuery, TopicsQueryVariables>) {
          return Apollo.useLazyQuery<TopicsQuery, TopicsQueryVariables>(TopicsDocument, baseOptions);
        }
export type TopicsQueryHookResult = ReturnType<typeof useTopicsQuery>;
export type TopicsLazyQueryHookResult = ReturnType<typeof useTopicsLazyQuery>;
export type TopicsQueryResult = Apollo.QueryResult<TopicsQuery, TopicsQueryVariables>;
export const GetTopicsDocument = gql`
    query GetTopics {
  topic {
    id
    value
  }
}
    `;

/**
 * __useGetTopicsQuery__
 *
 * To run a query within a React component, call `useGetTopicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTopicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTopicsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTopicsQuery(baseOptions?: Apollo.QueryHookOptions<GetTopicsQuery, GetTopicsQueryVariables>) {
        return Apollo.useQuery<GetTopicsQuery, GetTopicsQueryVariables>(GetTopicsDocument, baseOptions);
      }
export function useGetTopicsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTopicsQuery, GetTopicsQueryVariables>) {
          return Apollo.useLazyQuery<GetTopicsQuery, GetTopicsQueryVariables>(GetTopicsDocument, baseOptions);
        }
export type GetTopicsQueryHookResult = ReturnType<typeof useGetTopicsQuery>;
export type GetTopicsLazyQueryHookResult = ReturnType<typeof useGetTopicsLazyQuery>;
export type GetTopicsQueryResult = Apollo.QueryResult<GetTopicsQuery, GetTopicsQueryVariables>;
export const KeywordsDocument = gql`
    query Keywords($orderBy: [keyword_order_by!], $limit: Int, $offset: Int) {
  keyword(limit: $limit, offset: $offset, order_by: $orderBy) {
    id
    created_at
    value
    keyword_referrals {
      referral {
        value
      }
    }
    keyword_topics {
      topic {
        value
      }
    }
    sentiment {
      value
    }
  }
  keyword_aggregate {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useKeywordsQuery__
 *
 * To run a query within a React component, call `useKeywordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useKeywordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKeywordsQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useKeywordsQuery(baseOptions?: Apollo.QueryHookOptions<KeywordsQuery, KeywordsQueryVariables>) {
        return Apollo.useQuery<KeywordsQuery, KeywordsQueryVariables>(KeywordsDocument, baseOptions);
      }
export function useKeywordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KeywordsQuery, KeywordsQueryVariables>) {
          return Apollo.useLazyQuery<KeywordsQuery, KeywordsQueryVariables>(KeywordsDocument, baseOptions);
        }
export type KeywordsQueryHookResult = ReturnType<typeof useKeywordsQuery>;
export type KeywordsLazyQueryHookResult = ReturnType<typeof useKeywordsLazyQuery>;
export type KeywordsQueryResult = Apollo.QueryResult<KeywordsQuery, KeywordsQueryVariables>;
export const GetKeywordDocument = gql`
    query GetKeyword($keywordId: uuid!) {
  keyword(where: {id: {_eq: $keywordId}}) {
    id
    created_at
    value
    keyword_referrals {
      referral {
        value
      }
    }
    keyword_topics {
      topic {
        value
      }
    }
    sentiment {
      value
    }
  }
}
    `;

/**
 * __useGetKeywordQuery__
 *
 * To run a query within a React component, call `useGetKeywordQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKeywordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKeywordQuery({
 *   variables: {
 *      keywordId: // value for 'keywordId'
 *   },
 * });
 */
export function useGetKeywordQuery(baseOptions: Apollo.QueryHookOptions<GetKeywordQuery, GetKeywordQueryVariables>) {
        return Apollo.useQuery<GetKeywordQuery, GetKeywordQueryVariables>(GetKeywordDocument, baseOptions);
      }
export function useGetKeywordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetKeywordQuery, GetKeywordQueryVariables>) {
          return Apollo.useLazyQuery<GetKeywordQuery, GetKeywordQueryVariables>(GetKeywordDocument, baseOptions);
        }
export type GetKeywordQueryHookResult = ReturnType<typeof useGetKeywordQuery>;
export type GetKeywordLazyQueryHookResult = ReturnType<typeof useGetKeywordLazyQuery>;
export type GetKeywordQueryResult = Apollo.QueryResult<GetKeywordQuery, GetKeywordQueryVariables>;
export const AddKeywordDocument = gql`
    mutation AddKeyword($keywordId: uuid!, $value: String!, $sentimentId: uuid!, $keywordTopics: [keyword_topic_insert_input!]!, $keywordReferrals: [keyword_referral_insert_input!]!) {
  insert_keyword_one(
    object: {id: $keywordId, value: $value, sentiment_id: $sentimentId}
  ) {
    id
    value
  }
  insert_keyword_topic(objects: $keywordTopics) {
    returning {
      id
    }
  }
  insert_keyword_referral(objects: $keywordReferrals) {
    returning {
      id
    }
  }
}
    `;
export type AddKeywordMutationFn = Apollo.MutationFunction<AddKeywordMutation, AddKeywordMutationVariables>;

/**
 * __useAddKeywordMutation__
 *
 * To run a mutation, you first call `useAddKeywordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddKeywordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addKeywordMutation, { data, loading, error }] = useAddKeywordMutation({
 *   variables: {
 *      keywordId: // value for 'keywordId'
 *      value: // value for 'value'
 *      sentimentId: // value for 'sentimentId'
 *      keywordTopics: // value for 'keywordTopics'
 *      keywordReferrals: // value for 'keywordReferrals'
 *   },
 * });
 */
export function useAddKeywordMutation(baseOptions?: Apollo.MutationHookOptions<AddKeywordMutation, AddKeywordMutationVariables>) {
        return Apollo.useMutation<AddKeywordMutation, AddKeywordMutationVariables>(AddKeywordDocument, baseOptions);
      }
export type AddKeywordMutationHookResult = ReturnType<typeof useAddKeywordMutation>;
export type AddKeywordMutationResult = Apollo.MutationResult<AddKeywordMutation>;
export type AddKeywordMutationOptions = Apollo.BaseMutationOptions<AddKeywordMutation, AddKeywordMutationVariables>;
export const Components_Stories_GetKeywordValuesDocument = gql`
    query Components_Stories_GetKeywordValues {
  keyword {
    value
    sentiment {
      value
    }
    keyword_referrals {
      referral {
        value
      }
    }
    keyword_topics {
      topic {
        value
      }
    }
  }
}
    `;

/**
 * __useComponents_Stories_GetKeywordValuesQuery__
 *
 * To run a query within a React component, call `useComponents_Stories_GetKeywordValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useComponents_Stories_GetKeywordValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useComponents_Stories_GetKeywordValuesQuery({
 *   variables: {
 *   },
 * });
 */
export function useComponents_Stories_GetKeywordValuesQuery(baseOptions?: Apollo.QueryHookOptions<Components_Stories_GetKeywordValuesQuery, Components_Stories_GetKeywordValuesQueryVariables>) {
        return Apollo.useQuery<Components_Stories_GetKeywordValuesQuery, Components_Stories_GetKeywordValuesQueryVariables>(Components_Stories_GetKeywordValuesDocument, baseOptions);
      }
export function useComponents_Stories_GetKeywordValuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Components_Stories_GetKeywordValuesQuery, Components_Stories_GetKeywordValuesQueryVariables>) {
          return Apollo.useLazyQuery<Components_Stories_GetKeywordValuesQuery, Components_Stories_GetKeywordValuesQueryVariables>(Components_Stories_GetKeywordValuesDocument, baseOptions);
        }
export type Components_Stories_GetKeywordValuesQueryHookResult = ReturnType<typeof useComponents_Stories_GetKeywordValuesQuery>;
export type Components_Stories_GetKeywordValuesLazyQueryHookResult = ReturnType<typeof useComponents_Stories_GetKeywordValuesLazyQuery>;
export type Components_Stories_GetKeywordValuesQueryResult = Apollo.QueryResult<Components_Stories_GetKeywordValuesQuery, Components_Stories_GetKeywordValuesQueryVariables>;
export const AddNoteDocument = gql`
    mutation AddNote($note: moderator_note_insert_input!, $storyId: uuid!, $storyStatus: String!, $_eq: uuid = "", $publishedAt: timestamptz) {
  insert_moderator_note_one(object: $note) {
    id
  }
  update_story(
    _set: {status: $storyStatus, published_at: $publishedAt}
    where: {id: {_eq: $storyId}}
  ) {
    returning {
      id
    }
  }
}
    `;
export type AddNoteMutationFn = Apollo.MutationFunction<AddNoteMutation, AddNoteMutationVariables>;

/**
 * __useAddNoteMutation__
 *
 * To run a mutation, you first call `useAddNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNoteMutation, { data, loading, error }] = useAddNoteMutation({
 *   variables: {
 *      note: // value for 'note'
 *      storyId: // value for 'storyId'
 *      storyStatus: // value for 'storyStatus'
 *      _eq: // value for '_eq'
 *      publishedAt: // value for 'publishedAt'
 *   },
 * });
 */
export function useAddNoteMutation(baseOptions?: Apollo.MutationHookOptions<AddNoteMutation, AddNoteMutationVariables>) {
        return Apollo.useMutation<AddNoteMutation, AddNoteMutationVariables>(AddNoteDocument, baseOptions);
      }
export type AddNoteMutationHookResult = ReturnType<typeof useAddNoteMutation>;
export type AddNoteMutationResult = Apollo.MutationResult<AddNoteMutation>;
export type AddNoteMutationOptions = Apollo.BaseMutationOptions<AddNoteMutation, AddNoteMutationVariables>;
export const StoriesDocument = gql`
    query Stories($orderBy: [story_order_by!], $statuses: [String!], $titleQ: String, $limit: Int, $offset: Int) {
  story(
    order_by: $orderBy
    where: {status: {_in: $statuses}, title: {_ilike: $titleQ}}
    limit: $limit
    offset: $offset
  ) {
    ...StoriesFragment
  }
  story_aggregate(where: {status: {_in: $statuses}, title: {_ilike: $titleQ}}) {
    aggregate {
      count
    }
  }
}
    ${StoriesFragmentFragmentDoc}`;

/**
 * __useStoriesQuery__
 *
 * To run a query within a React component, call `useStoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoriesQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      statuses: // value for 'statuses'
 *      titleQ: // value for 'titleQ'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useStoriesQuery(baseOptions?: Apollo.QueryHookOptions<StoriesQuery, StoriesQueryVariables>) {
        return Apollo.useQuery<StoriesQuery, StoriesQueryVariables>(StoriesDocument, baseOptions);
      }
export function useStoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StoriesQuery, StoriesQueryVariables>) {
          return Apollo.useLazyQuery<StoriesQuery, StoriesQueryVariables>(StoriesDocument, baseOptions);
        }
export type StoriesQueryHookResult = ReturnType<typeof useStoriesQuery>;
export type StoriesLazyQueryHookResult = ReturnType<typeof useStoriesLazyQuery>;
export type StoriesQueryResult = Apollo.QueryResult<StoriesQuery, StoriesQueryVariables>;
export const ViolationStoriesDocument = gql`
    query ViolationStories($orderBy: [story_order_by!], $statuses: [String!], $titleQ: String, $limit: Int, $offset: Int) {
  story(
    order_by: $orderBy
    where: {status: {_in: $statuses}, title: {_ilike: $titleQ}, story_violations: {id: {_neq: null}}}
    limit: $limit
    offset: $offset
  ) {
    ...StoriesFragment
  }
  story_aggregate(
    where: {status: {_in: $statuses}, title: {_ilike: $titleQ}, story_violations: {id: {_neq: null}}}
  ) {
    aggregate {
      count
    }
  }
}
    ${StoriesFragmentFragmentDoc}`;

/**
 * __useViolationStoriesQuery__
 *
 * To run a query within a React component, call `useViolationStoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useViolationStoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViolationStoriesQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      statuses: // value for 'statuses'
 *      titleQ: // value for 'titleQ'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useViolationStoriesQuery(baseOptions?: Apollo.QueryHookOptions<ViolationStoriesQuery, ViolationStoriesQueryVariables>) {
        return Apollo.useQuery<ViolationStoriesQuery, ViolationStoriesQueryVariables>(ViolationStoriesDocument, baseOptions);
      }
export function useViolationStoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ViolationStoriesQuery, ViolationStoriesQueryVariables>) {
          return Apollo.useLazyQuery<ViolationStoriesQuery, ViolationStoriesQueryVariables>(ViolationStoriesDocument, baseOptions);
        }
export type ViolationStoriesQueryHookResult = ReturnType<typeof useViolationStoriesQuery>;
export type ViolationStoriesLazyQueryHookResult = ReturnType<typeof useViolationStoriesLazyQuery>;
export type ViolationStoriesQueryResult = Apollo.QueryResult<ViolationStoriesQuery, ViolationStoriesQueryVariables>;
export const GetStoryDocument = gql`
    query GetStory($StoryId: uuid!) {
  story(where: {id: {_eq: $StoryId}}) {
    id
    title
    first_submitted_at
    last_submitted_at
    type
    tags {
      id
      tag_id
      tag {
        name
        id
      }
    }
    user {
      screener_score
      display_name
      first_name
      date_of_birth
      account {
        email
      }
    }
    moderator_notes {
      id
      created_at
      action
      user {
        id
        display_name
      }
      note
    }
    content
    caption
    status
    violation_notes {
      action
      created_at
      id
      note
      user_id
    }
    story_violations {
      user_id
      created_at
      id
    }
    resource {
      created_at
      id
      type
      updated_at
      url
    }
    order
    transcription
    user_id
    replies {
      id
      prompt {
        id
        title
        position
      }
      content
      created_at
      updated_at
    }
  }
}
    `;

/**
 * __useGetStoryQuery__
 *
 * To run a query within a React component, call `useGetStoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStoryQuery({
 *   variables: {
 *      StoryId: // value for 'StoryId'
 *   },
 * });
 */
export function useGetStoryQuery(baseOptions: Apollo.QueryHookOptions<GetStoryQuery, GetStoryQueryVariables>) {
        return Apollo.useQuery<GetStoryQuery, GetStoryQueryVariables>(GetStoryDocument, baseOptions);
      }
export function useGetStoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStoryQuery, GetStoryQueryVariables>) {
          return Apollo.useLazyQuery<GetStoryQuery, GetStoryQueryVariables>(GetStoryDocument, baseOptions);
        }
export type GetStoryQueryHookResult = ReturnType<typeof useGetStoryQuery>;
export type GetStoryLazyQueryHookResult = ReturnType<typeof useGetStoryLazyQuery>;
export type GetStoryQueryResult = Apollo.QueryResult<GetStoryQuery, GetStoryQueryVariables>;
export const StoryCountDocument = gql`
    query StoryCount($status: String!) {
  story_aggregate(where: {status: {_eq: $status}}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useStoryCountQuery__
 *
 * To run a query within a React component, call `useStoryCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoryCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoryCountQuery({
 *   variables: {
 *      status: // value for 'status'
 *   },
 * });
 */
export function useStoryCountQuery(baseOptions: Apollo.QueryHookOptions<StoryCountQuery, StoryCountQueryVariables>) {
        return Apollo.useQuery<StoryCountQuery, StoryCountQueryVariables>(StoryCountDocument, baseOptions);
      }
export function useStoryCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StoryCountQuery, StoryCountQueryVariables>) {
          return Apollo.useLazyQuery<StoryCountQuery, StoryCountQueryVariables>(StoryCountDocument, baseOptions);
        }
export type StoryCountQueryHookResult = ReturnType<typeof useStoryCountQuery>;
export type StoryCountLazyQueryHookResult = ReturnType<typeof useStoryCountLazyQuery>;
export type StoryCountQueryResult = Apollo.QueryResult<StoryCountQuery, StoryCountQueryVariables>;
export const DeleteStoryDocument = gql`
    mutation deleteStory($id: uuid!) {
  DeleteStory(id: $id) {
    success
  }
}
    `;
export type DeleteStoryMutationFn = Apollo.MutationFunction<DeleteStoryMutation, DeleteStoryMutationVariables>;

/**
 * __useDeleteStoryMutation__
 *
 * To run a mutation, you first call `useDeleteStoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStoryMutation, { data, loading, error }] = useDeleteStoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteStoryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteStoryMutation, DeleteStoryMutationVariables>) {
        return Apollo.useMutation<DeleteStoryMutation, DeleteStoryMutationVariables>(DeleteStoryDocument, baseOptions);
      }
export type DeleteStoryMutationHookResult = ReturnType<typeof useDeleteStoryMutation>;
export type DeleteStoryMutationResult = Apollo.MutationResult<DeleteStoryMutation>;
export type DeleteStoryMutationOptions = Apollo.BaseMutationOptions<DeleteStoryMutation, DeleteStoryMutationVariables>;
export const InsertStoryTagDocument = gql`
    mutation InsertStoryTag($object: story_tag_insert_input!) {
  insert_story_tag_one(object: $object) {
    id
    tag_id
    story_id
  }
}
    `;
export type InsertStoryTagMutationFn = Apollo.MutationFunction<InsertStoryTagMutation, InsertStoryTagMutationVariables>;

/**
 * __useInsertStoryTagMutation__
 *
 * To run a mutation, you first call `useInsertStoryTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertStoryTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertStoryTagMutation, { data, loading, error }] = useInsertStoryTagMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertStoryTagMutation(baseOptions?: Apollo.MutationHookOptions<InsertStoryTagMutation, InsertStoryTagMutationVariables>) {
        return Apollo.useMutation<InsertStoryTagMutation, InsertStoryTagMutationVariables>(InsertStoryTagDocument, baseOptions);
      }
export type InsertStoryTagMutationHookResult = ReturnType<typeof useInsertStoryTagMutation>;
export type InsertStoryTagMutationResult = Apollo.MutationResult<InsertStoryTagMutation>;
export type InsertStoryTagMutationOptions = Apollo.BaseMutationOptions<InsertStoryTagMutation, InsertStoryTagMutationVariables>;
export const DeleteStoryTagDocument = gql`
    mutation DeleteStoryTag($id: uuid!) {
  delete_story_tag_by_pk(id: $id) {
    id
  }
}
    `;
export type DeleteStoryTagMutationFn = Apollo.MutationFunction<DeleteStoryTagMutation, DeleteStoryTagMutationVariables>;

/**
 * __useDeleteStoryTagMutation__
 *
 * To run a mutation, you first call `useDeleteStoryTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStoryTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStoryTagMutation, { data, loading, error }] = useDeleteStoryTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteStoryTagMutation(baseOptions?: Apollo.MutationHookOptions<DeleteStoryTagMutation, DeleteStoryTagMutationVariables>) {
        return Apollo.useMutation<DeleteStoryTagMutation, DeleteStoryTagMutationVariables>(DeleteStoryTagDocument, baseOptions);
      }
export type DeleteStoryTagMutationHookResult = ReturnType<typeof useDeleteStoryTagMutation>;
export type DeleteStoryTagMutationResult = Apollo.MutationResult<DeleteStoryTagMutation>;
export type DeleteStoryTagMutationOptions = Apollo.BaseMutationOptions<DeleteStoryTagMutation, DeleteStoryTagMutationVariables>;
export const SaveStoryOrderDocument = gql`
    mutation SaveStoryOrder($id: uuid!, $order: Int) {
  update_story_by_pk(pk_columns: {id: $id}, _set: {order: $order}) {
    id
    order
  }
}
    `;
export type SaveStoryOrderMutationFn = Apollo.MutationFunction<SaveStoryOrderMutation, SaveStoryOrderMutationVariables>;

/**
 * __useSaveStoryOrderMutation__
 *
 * To run a mutation, you first call `useSaveStoryOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveStoryOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveStoryOrderMutation, { data, loading, error }] = useSaveStoryOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useSaveStoryOrderMutation(baseOptions?: Apollo.MutationHookOptions<SaveStoryOrderMutation, SaveStoryOrderMutationVariables>) {
        return Apollo.useMutation<SaveStoryOrderMutation, SaveStoryOrderMutationVariables>(SaveStoryOrderDocument, baseOptions);
      }
export type SaveStoryOrderMutationHookResult = ReturnType<typeof useSaveStoryOrderMutation>;
export type SaveStoryOrderMutationResult = Apollo.MutationResult<SaveStoryOrderMutation>;
export type SaveStoryOrderMutationOptions = Apollo.BaseMutationOptions<SaveStoryOrderMutation, SaveStoryOrderMutationVariables>;
export const UpdateStoryTranscriptDocument = gql`
    mutation UpdateStoryTranscript($id: uuid!, $transcription: String) {
  update_story_by_pk(pk_columns: {id: $id}, _set: {transcription: $transcription}) {
    id
    transcription
  }
}
    `;
export type UpdateStoryTranscriptMutationFn = Apollo.MutationFunction<UpdateStoryTranscriptMutation, UpdateStoryTranscriptMutationVariables>;

/**
 * __useUpdateStoryTranscriptMutation__
 *
 * To run a mutation, you first call `useUpdateStoryTranscriptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStoryTranscriptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStoryTranscriptMutation, { data, loading, error }] = useUpdateStoryTranscriptMutation({
 *   variables: {
 *      id: // value for 'id'
 *      transcription: // value for 'transcription'
 *   },
 * });
 */
export function useUpdateStoryTranscriptMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStoryTranscriptMutation, UpdateStoryTranscriptMutationVariables>) {
        return Apollo.useMutation<UpdateStoryTranscriptMutation, UpdateStoryTranscriptMutationVariables>(UpdateStoryTranscriptDocument, baseOptions);
      }
export type UpdateStoryTranscriptMutationHookResult = ReturnType<typeof useUpdateStoryTranscriptMutation>;
export type UpdateStoryTranscriptMutationResult = Apollo.MutationResult<UpdateStoryTranscriptMutation>;
export type UpdateStoryTranscriptMutationOptions = Apollo.BaseMutationOptions<UpdateStoryTranscriptMutation, UpdateStoryTranscriptMutationVariables>;
export const GetSignedUrlDocument = gql`
    query GetSignedUrl($type: String!) {
  generateSignedUrl(resourceType: $type) {
    resource_id
    content_type
    signed_url
  }
}
    `;

/**
 * __useGetSignedUrlQuery__
 *
 * To run a query within a React component, call `useGetSignedUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSignedUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSignedUrlQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetSignedUrlQuery(baseOptions: Apollo.QueryHookOptions<GetSignedUrlQuery, GetSignedUrlQueryVariables>) {
        return Apollo.useQuery<GetSignedUrlQuery, GetSignedUrlQueryVariables>(GetSignedUrlDocument, baseOptions);
      }
export function useGetSignedUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSignedUrlQuery, GetSignedUrlQueryVariables>) {
          return Apollo.useLazyQuery<GetSignedUrlQuery, GetSignedUrlQueryVariables>(GetSignedUrlDocument, baseOptions);
        }
export type GetSignedUrlQueryHookResult = ReturnType<typeof useGetSignedUrlQuery>;
export type GetSignedUrlLazyQueryHookResult = ReturnType<typeof useGetSignedUrlLazyQuery>;
export type GetSignedUrlQueryResult = Apollo.QueryResult<GetSignedUrlQuery, GetSignedUrlQueryVariables>;
export const CreateStoryDocument = gql`
    mutation CreateStory($story: story_insert_input!, $replies: [reply_insert_input!]!, $resource: [resource_insert_input!]!, $tags: [story_tag_insert_input!]!) {
  story: insert_story_one(object: $story) {
    id
    title
    type
    caption
    content
    user_id
    status
  }
  insert_reply(objects: $replies) {
    returning {
      id
    }
  }
  insert_resource(
    objects: $resource
    on_conflict: {constraint: resource_story_id_key, update_columns: [id, url, type]}
  ) {
    returning {
      id
      url
      type
    }
  }
  insert_story_tag(
    objects: $tags
    on_conflict: {constraint: unique_story_id_tag_id, update_columns: []}
  ) {
    returning {
      id
      story_id
      tag_id
    }
  }
}
    `;
export type CreateStoryMutationFn = Apollo.MutationFunction<CreateStoryMutation, CreateStoryMutationVariables>;

/**
 * __useCreateStoryMutation__
 *
 * To run a mutation, you first call `useCreateStoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStoryMutation, { data, loading, error }] = useCreateStoryMutation({
 *   variables: {
 *      story: // value for 'story'
 *      replies: // value for 'replies'
 *      resource: // value for 'resource'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useCreateStoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateStoryMutation, CreateStoryMutationVariables>) {
        return Apollo.useMutation<CreateStoryMutation, CreateStoryMutationVariables>(CreateStoryDocument, baseOptions);
      }
export type CreateStoryMutationHookResult = ReturnType<typeof useCreateStoryMutation>;
export type CreateStoryMutationResult = Apollo.MutationResult<CreateStoryMutation>;
export type CreateStoryMutationOptions = Apollo.BaseMutationOptions<CreateStoryMutation, CreateStoryMutationVariables>;
export const UpdateStoryAndResourceDocument = gql`
    mutation UpdateStoryAndResource($story: story_set_input, $storyId: uuid!, $resource: [resource_insert_input!]!) {
  update_story_by_pk(_set: $story, pk_columns: {id: $storyId}) {
    id
    title
    type
    caption
  }
  insert_resource(
    objects: $resource
    on_conflict: {constraint: resource_story_id_key, update_columns: [id, url, type]}
  ) {
    returning {
      id
      url
      type
    }
  }
}
    `;
export type UpdateStoryAndResourceMutationFn = Apollo.MutationFunction<UpdateStoryAndResourceMutation, UpdateStoryAndResourceMutationVariables>;

/**
 * __useUpdateStoryAndResourceMutation__
 *
 * To run a mutation, you first call `useUpdateStoryAndResourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStoryAndResourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStoryAndResourceMutation, { data, loading, error }] = useUpdateStoryAndResourceMutation({
 *   variables: {
 *      story: // value for 'story'
 *      storyId: // value for 'storyId'
 *      resource: // value for 'resource'
 *   },
 * });
 */
export function useUpdateStoryAndResourceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStoryAndResourceMutation, UpdateStoryAndResourceMutationVariables>) {
        return Apollo.useMutation<UpdateStoryAndResourceMutation, UpdateStoryAndResourceMutationVariables>(UpdateStoryAndResourceDocument, baseOptions);
      }
export type UpdateStoryAndResourceMutationHookResult = ReturnType<typeof useUpdateStoryAndResourceMutation>;
export type UpdateStoryAndResourceMutationResult = Apollo.MutationResult<UpdateStoryAndResourceMutation>;
export type UpdateStoryAndResourceMutationOptions = Apollo.BaseMutationOptions<UpdateStoryAndResourceMutation, UpdateStoryAndResourceMutationVariables>;
export const UpdateStoryDocument = gql`
    mutation UpdateStory($story: story_set_input, $storyId: uuid!) {
  update_story_by_pk(_set: $story, pk_columns: {id: $storyId}) {
    id
    title
    type
    caption
  }
}
    `;
export type UpdateStoryMutationFn = Apollo.MutationFunction<UpdateStoryMutation, UpdateStoryMutationVariables>;

/**
 * __useUpdateStoryMutation__
 *
 * To run a mutation, you first call `useUpdateStoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStoryMutation, { data, loading, error }] = useUpdateStoryMutation({
 *   variables: {
 *      story: // value for 'story'
 *      storyId: // value for 'storyId'
 *   },
 * });
 */
export function useUpdateStoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStoryMutation, UpdateStoryMutationVariables>) {
        return Apollo.useMutation<UpdateStoryMutation, UpdateStoryMutationVariables>(UpdateStoryDocument, baseOptions);
      }
export type UpdateStoryMutationHookResult = ReturnType<typeof useUpdateStoryMutation>;
export type UpdateStoryMutationResult = Apollo.MutationResult<UpdateStoryMutation>;
export type UpdateStoryMutationOptions = Apollo.BaseMutationOptions<UpdateStoryMutation, UpdateStoryMutationVariables>;
export const TagsDocument = gql`
    query Tags($orderBy: [tag_order_by!], $limit: Int, $offset: Int) {
  tag(
    where: {verified: {_eq: true}}
    limit: $limit
    offset: $offset
    order_by: $orderBy
  ) {
    ...TagFragment
  }
  tag_aggregate(where: {verified: {_eq: true}}) {
    aggregate {
      count
    }
  }
}
    ${TagFragmentFragmentDoc}`;

/**
 * __useTagsQuery__
 *
 * To run a query within a React component, call `useTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagsQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useTagsQuery(baseOptions?: Apollo.QueryHookOptions<TagsQuery, TagsQueryVariables>) {
        return Apollo.useQuery<TagsQuery, TagsQueryVariables>(TagsDocument, baseOptions);
      }
export function useTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TagsQuery, TagsQueryVariables>) {
          return Apollo.useLazyQuery<TagsQuery, TagsQueryVariables>(TagsDocument, baseOptions);
        }
export type TagsQueryHookResult = ReturnType<typeof useTagsQuery>;
export type TagsLazyQueryHookResult = ReturnType<typeof useTagsLazyQuery>;
export type TagsQueryResult = Apollo.QueryResult<TagsQuery, TagsQueryVariables>;
export const GetTagDocument = gql`
    query GetTag($tagId: uuid!) {
  tag(where: {id: {_eq: $tagId}}) {
    ...TagFragment
  }
}
    ${TagFragmentFragmentDoc}`;

/**
 * __useGetTagQuery__
 *
 * To run a query within a React component, call `useGetTagQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTagQuery({
 *   variables: {
 *      tagId: // value for 'tagId'
 *   },
 * });
 */
export function useGetTagQuery(baseOptions: Apollo.QueryHookOptions<GetTagQuery, GetTagQueryVariables>) {
        return Apollo.useQuery<GetTagQuery, GetTagQueryVariables>(GetTagDocument, baseOptions);
      }
export function useGetTagLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTagQuery, GetTagQueryVariables>) {
          return Apollo.useLazyQuery<GetTagQuery, GetTagQueryVariables>(GetTagDocument, baseOptions);
        }
export type GetTagQueryHookResult = ReturnType<typeof useGetTagQuery>;
export type GetTagLazyQueryHookResult = ReturnType<typeof useGetTagLazyQuery>;
export type GetTagQueryResult = Apollo.QueryResult<GetTagQuery, GetTagQueryVariables>;
export const UpdateTagDocument = gql`
    mutation UpdateTag($id: uuid!, $tag: tag_set_input!) {
  update_tag_by_pk(pk_columns: {id: $id}, _set: $tag) {
    ...TagFragment
  }
}
    ${TagFragmentFragmentDoc}`;
export type UpdateTagMutationFn = Apollo.MutationFunction<UpdateTagMutation, UpdateTagMutationVariables>;

/**
 * __useUpdateTagMutation__
 *
 * To run a mutation, you first call `useUpdateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTagMutation, { data, loading, error }] = useUpdateTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *      tag: // value for 'tag'
 *   },
 * });
 */
export function useUpdateTagMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTagMutation, UpdateTagMutationVariables>) {
        return Apollo.useMutation<UpdateTagMutation, UpdateTagMutationVariables>(UpdateTagDocument, baseOptions);
      }
export type UpdateTagMutationHookResult = ReturnType<typeof useUpdateTagMutation>;
export type UpdateTagMutationResult = Apollo.MutationResult<UpdateTagMutation>;
export type UpdateTagMutationOptions = Apollo.BaseMutationOptions<UpdateTagMutation, UpdateTagMutationVariables>;
export const DeleteTagDocument = gql`
    mutation DeleteTag($id: uuid!) {
  delete_story_tag(where: {tag_id: {_eq: $id}}) {
    affected_rows
  }
  delete_tag_by_pk(id: $id) {
    id
  }
}
    `;
export type DeleteTagMutationFn = Apollo.MutationFunction<DeleteTagMutation, DeleteTagMutationVariables>;

/**
 * __useDeleteTagMutation__
 *
 * To run a mutation, you first call `useDeleteTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTagMutation, { data, loading, error }] = useDeleteTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTagMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTagMutation, DeleteTagMutationVariables>) {
        return Apollo.useMutation<DeleteTagMutation, DeleteTagMutationVariables>(DeleteTagDocument, baseOptions);
      }
export type DeleteTagMutationHookResult = ReturnType<typeof useDeleteTagMutation>;
export type DeleteTagMutationResult = Apollo.MutationResult<DeleteTagMutation>;
export type DeleteTagMutationOptions = Apollo.BaseMutationOptions<DeleteTagMutation, DeleteTagMutationVariables>;
export const AddTagDocument = gql`
    mutation AddTag($object: tag_insert_input!) {
  insert_tag_one(object: $object) {
    ...TagFragment
  }
}
    ${TagFragmentFragmentDoc}`;
export type AddTagMutationFn = Apollo.MutationFunction<AddTagMutation, AddTagMutationVariables>;

/**
 * __useAddTagMutation__
 *
 * To run a mutation, you first call `useAddTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTagMutation, { data, loading, error }] = useAddTagMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useAddTagMutation(baseOptions?: Apollo.MutationHookOptions<AddTagMutation, AddTagMutationVariables>) {
        return Apollo.useMutation<AddTagMutation, AddTagMutationVariables>(AddTagDocument, baseOptions);
      }
export type AddTagMutationHookResult = ReturnType<typeof useAddTagMutation>;
export type AddTagMutationResult = Apollo.MutationResult<AddTagMutation>;
export type AddTagMutationOptions = Apollo.BaseMutationOptions<AddTagMutation, AddTagMutationVariables>;
export const SaveTagOrderDocument = gql`
    mutation SaveTagOrder($id: uuid!, $order: Int) {
  update_tag_by_pk(pk_columns: {id: $id}, _set: {order: $order}) {
    id
    order
  }
}
    `;
export type SaveTagOrderMutationFn = Apollo.MutationFunction<SaveTagOrderMutation, SaveTagOrderMutationVariables>;

/**
 * __useSaveTagOrderMutation__
 *
 * To run a mutation, you first call `useSaveTagOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveTagOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveTagOrderMutation, { data, loading, error }] = useSaveTagOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useSaveTagOrderMutation(baseOptions?: Apollo.MutationHookOptions<SaveTagOrderMutation, SaveTagOrderMutationVariables>) {
        return Apollo.useMutation<SaveTagOrderMutation, SaveTagOrderMutationVariables>(SaveTagOrderDocument, baseOptions);
      }
export type SaveTagOrderMutationHookResult = ReturnType<typeof useSaveTagOrderMutation>;
export type SaveTagOrderMutationResult = Apollo.MutationResult<SaveTagOrderMutation>;
export type SaveTagOrderMutationOptions = Apollo.BaseMutationOptions<SaveTagOrderMutation, SaveTagOrderMutationVariables>;
export const UsersDocument = gql`
    query Users($orderBy: [user_order_by!], $limit: Int, $offset: Int, $where: user_bool_exp) {
  user(where: $where, limit: $limit, offset: $offset, order_by: $orderBy) {
    created_at
    id
    first_name
    display_name
    account {
      id
      email
      active
      account_roles {
        id
        role
      }
    }
    stories_aggregate {
      aggregate {
        count
      }
    }
    story_violations_aggregate {
      aggregate {
        count
      }
    }
    violation_notes_aggregate {
      aggregate {
        count
      }
    }
    suburb
  }
  user_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const GetUserDocument = gql`
    query GetUser($UserId: uuid!) {
  user(where: {id: {_eq: $UserId}}) {
    created_at
    id
    first_name
    display_name
    account {
      id
      email
      active
      account_roles {
        id
        role
      }
    }
    stories_aggregate {
      aggregate {
        count
      }
    }
    story_violations_aggregate {
      aggregate {
        count
      }
    }
    violation_notes_aggregate {
      aggregate {
        count
      }
    }
    suburb
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      UserId: // value for 'UserId'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, baseOptions);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, baseOptions);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const UserCountDocument = gql`
    query UserCount($active: Boolean!) {
  user_aggregate(where: {account: {active: {_eq: $active}}}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useUserCountQuery__
 *
 * To run a query within a React component, call `useUserCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserCountQuery({
 *   variables: {
 *      active: // value for 'active'
 *   },
 * });
 */
export function useUserCountQuery(baseOptions: Apollo.QueryHookOptions<UserCountQuery, UserCountQueryVariables>) {
        return Apollo.useQuery<UserCountQuery, UserCountQueryVariables>(UserCountDocument, baseOptions);
      }
export function useUserCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserCountQuery, UserCountQueryVariables>) {
          return Apollo.useLazyQuery<UserCountQuery, UserCountQueryVariables>(UserCountDocument, baseOptions);
        }
export type UserCountQueryHookResult = ReturnType<typeof useUserCountQuery>;
export type UserCountLazyQueryHookResult = ReturnType<typeof useUserCountLazyQuery>;
export type UserCountQueryResult = Apollo.QueryResult<UserCountQuery, UserCountQueryVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($id: uuid!, $user: user_set_input!, $account: auth_accounts_set_input!) {
  update_user_by_pk(pk_columns: {id: $id}, _set: $user) {
    id
  }
  update_auth_accounts(where: {user_id: {_eq: $id}}, _set: $account) {
    returning {
      id
    }
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      user: // value for 'user'
 *      account: // value for 'account'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, baseOptions);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($id: uuid!) {
  DeleteUser(id: $id) {
    success
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, baseOptions);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const SendPushToUserDocument = gql`
    mutation SendPushToUser($userId: String!, $message: String!) {
  SendPushToUser(message: $message, userId: $userId) {
    success
  }
}
    `;
export type SendPushToUserMutationFn = Apollo.MutationFunction<SendPushToUserMutation, SendPushToUserMutationVariables>;

/**
 * __useSendPushToUserMutation__
 *
 * To run a mutation, you first call `useSendPushToUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPushToUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPushToUserMutation, { data, loading, error }] = useSendPushToUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useSendPushToUserMutation(baseOptions?: Apollo.MutationHookOptions<SendPushToUserMutation, SendPushToUserMutationVariables>) {
        return Apollo.useMutation<SendPushToUserMutation, SendPushToUserMutationVariables>(SendPushToUserDocument, baseOptions);
      }
export type SendPushToUserMutationHookResult = ReturnType<typeof useSendPushToUserMutation>;
export type SendPushToUserMutationResult = Apollo.MutationResult<SendPushToUserMutation>;
export type SendPushToUserMutationOptions = Apollo.BaseMutationOptions<SendPushToUserMutation, SendPushToUserMutationVariables>;
export const SendPushToUsersDocument = gql`
    mutation SendPushToUsers($userIds: [String!], $message: String!) {
  SendPushToUsers(message: $message, userIds: $userIds) {
    success
  }
}
    `;
export type SendPushToUsersMutationFn = Apollo.MutationFunction<SendPushToUsersMutation, SendPushToUsersMutationVariables>;

/**
 * __useSendPushToUsersMutation__
 *
 * To run a mutation, you first call `useSendPushToUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPushToUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPushToUsersMutation, { data, loading, error }] = useSendPushToUsersMutation({
 *   variables: {
 *      userIds: // value for 'userIds'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useSendPushToUsersMutation(baseOptions?: Apollo.MutationHookOptions<SendPushToUsersMutation, SendPushToUsersMutationVariables>) {
        return Apollo.useMutation<SendPushToUsersMutation, SendPushToUsersMutationVariables>(SendPushToUsersDocument, baseOptions);
      }
export type SendPushToUsersMutationHookResult = ReturnType<typeof useSendPushToUsersMutation>;
export type SendPushToUsersMutationResult = Apollo.MutationResult<SendPushToUsersMutation>;
export type SendPushToUsersMutationOptions = Apollo.BaseMutationOptions<SendPushToUsersMutation, SendPushToUsersMutationVariables>;
export const GetPushSettingsForUserDocument = gql`
    query GetPushSettingsForUser($userId: uuid!) {
  user_by_pk(id: $userId) {
    id
    push_new_story
    push_story_liked
    push_story_saved
    push_story_shared
    push_story_approved
  }
}
    `;

/**
 * __useGetPushSettingsForUserQuery__
 *
 * To run a query within a React component, call `useGetPushSettingsForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPushSettingsForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPushSettingsForUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetPushSettingsForUserQuery(baseOptions: Apollo.QueryHookOptions<GetPushSettingsForUserQuery, GetPushSettingsForUserQueryVariables>) {
        return Apollo.useQuery<GetPushSettingsForUserQuery, GetPushSettingsForUserQueryVariables>(GetPushSettingsForUserDocument, baseOptions);
      }
export function useGetPushSettingsForUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPushSettingsForUserQuery, GetPushSettingsForUserQueryVariables>) {
          return Apollo.useLazyQuery<GetPushSettingsForUserQuery, GetPushSettingsForUserQueryVariables>(GetPushSettingsForUserDocument, baseOptions);
        }
export type GetPushSettingsForUserQueryHookResult = ReturnType<typeof useGetPushSettingsForUserQuery>;
export type GetPushSettingsForUserLazyQueryHookResult = ReturnType<typeof useGetPushSettingsForUserLazyQuery>;
export type GetPushSettingsForUserQueryResult = Apollo.QueryResult<GetPushSettingsForUserQuery, GetPushSettingsForUserQueryVariables>;
export const GetUsersToPushForNewStoriesDocument = gql`
    query GetUsersToPushForNewStories($storyUserId: uuid!, $date: timestamptz!) {
  user(
    where: {_or: [{pushed_new_story_at: {_is_null: true}}, {pushed_new_story_at: {_lt: $date}}], id: {_neq: $storyUserId}, push_token: {_is_null: false}, push_new_story: {_eq: true}}
  ) {
    id
  }
}
    `;

/**
 * __useGetUsersToPushForNewStoriesQuery__
 *
 * To run a query within a React component, call `useGetUsersToPushForNewStoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersToPushForNewStoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersToPushForNewStoriesQuery({
 *   variables: {
 *      storyUserId: // value for 'storyUserId'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetUsersToPushForNewStoriesQuery(baseOptions: Apollo.QueryHookOptions<GetUsersToPushForNewStoriesQuery, GetUsersToPushForNewStoriesQueryVariables>) {
        return Apollo.useQuery<GetUsersToPushForNewStoriesQuery, GetUsersToPushForNewStoriesQueryVariables>(GetUsersToPushForNewStoriesDocument, baseOptions);
      }
export function useGetUsersToPushForNewStoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersToPushForNewStoriesQuery, GetUsersToPushForNewStoriesQueryVariables>) {
          return Apollo.useLazyQuery<GetUsersToPushForNewStoriesQuery, GetUsersToPushForNewStoriesQueryVariables>(GetUsersToPushForNewStoriesDocument, baseOptions);
        }
export type GetUsersToPushForNewStoriesQueryHookResult = ReturnType<typeof useGetUsersToPushForNewStoriesQuery>;
export type GetUsersToPushForNewStoriesLazyQueryHookResult = ReturnType<typeof useGetUsersToPushForNewStoriesLazyQuery>;
export type GetUsersToPushForNewStoriesQueryResult = Apollo.QueryResult<GetUsersToPushForNewStoriesQuery, GetUsersToPushForNewStoriesQueryVariables>;
export const UpdateLastPushedNewStoryForUsersDocument = gql`
    mutation UpdateLastPushedNewStoryForUsers($userIds: [uuid!]!, $date: timestamptz!) {
  update_user(where: {id: {_in: $userIds}}, _set: {pushed_new_story_at: $date}) {
    affected_rows
  }
}
    `;
export type UpdateLastPushedNewStoryForUsersMutationFn = Apollo.MutationFunction<UpdateLastPushedNewStoryForUsersMutation, UpdateLastPushedNewStoryForUsersMutationVariables>;

/**
 * __useUpdateLastPushedNewStoryForUsersMutation__
 *
 * To run a mutation, you first call `useUpdateLastPushedNewStoryForUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLastPushedNewStoryForUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLastPushedNewStoryForUsersMutation, { data, loading, error }] = useUpdateLastPushedNewStoryForUsersMutation({
 *   variables: {
 *      userIds: // value for 'userIds'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useUpdateLastPushedNewStoryForUsersMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLastPushedNewStoryForUsersMutation, UpdateLastPushedNewStoryForUsersMutationVariables>) {
        return Apollo.useMutation<UpdateLastPushedNewStoryForUsersMutation, UpdateLastPushedNewStoryForUsersMutationVariables>(UpdateLastPushedNewStoryForUsersDocument, baseOptions);
      }
export type UpdateLastPushedNewStoryForUsersMutationHookResult = ReturnType<typeof useUpdateLastPushedNewStoryForUsersMutation>;
export type UpdateLastPushedNewStoryForUsersMutationResult = Apollo.MutationResult<UpdateLastPushedNewStoryForUsersMutation>;
export type UpdateLastPushedNewStoryForUsersMutationOptions = Apollo.BaseMutationOptions<UpdateLastPushedNewStoryForUsersMutation, UpdateLastPushedNewStoryForUsersMutationVariables>;
export const GetUserIdByEmailDocument = gql`
    query GetUserIdByEmail($email: citext!) {
  user(where: {account: {email: {_eq: $email}}}) {
    id
    account {
      email
    }
  }
}
    `;

/**
 * __useGetUserIdByEmailQuery__
 *
 * To run a query within a React component, call `useGetUserIdByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserIdByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserIdByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetUserIdByEmailQuery(baseOptions: Apollo.QueryHookOptions<GetUserIdByEmailQuery, GetUserIdByEmailQueryVariables>) {
        return Apollo.useQuery<GetUserIdByEmailQuery, GetUserIdByEmailQueryVariables>(GetUserIdByEmailDocument, baseOptions);
      }
export function useGetUserIdByEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserIdByEmailQuery, GetUserIdByEmailQueryVariables>) {
          return Apollo.useLazyQuery<GetUserIdByEmailQuery, GetUserIdByEmailQueryVariables>(GetUserIdByEmailDocument, baseOptions);
        }
export type GetUserIdByEmailQueryHookResult = ReturnType<typeof useGetUserIdByEmailQuery>;
export type GetUserIdByEmailLazyQueryHookResult = ReturnType<typeof useGetUserIdByEmailLazyQuery>;
export type GetUserIdByEmailQueryResult = Apollo.QueryResult<GetUserIdByEmailQuery, GetUserIdByEmailQueryVariables>;
export const AddViolationNoteDocument = gql`
    mutation AddViolationNote($object: violation_note_insert_input!, $storyId: uuid!, $storyStatus: String!, $publishedAt: timestamptz) {
  insert_violation_note_one(object: $object) {
    id
  }
  update_story(
    _set: {status: $storyStatus, published_at: $publishedAt}
    where: {id: {_eq: $storyId}}
  ) {
    returning {
      id
    }
  }
}
    `;
export type AddViolationNoteMutationFn = Apollo.MutationFunction<AddViolationNoteMutation, AddViolationNoteMutationVariables>;

/**
 * __useAddViolationNoteMutation__
 *
 * To run a mutation, you first call `useAddViolationNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddViolationNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addViolationNoteMutation, { data, loading, error }] = useAddViolationNoteMutation({
 *   variables: {
 *      object: // value for 'object'
 *      storyId: // value for 'storyId'
 *      storyStatus: // value for 'storyStatus'
 *      publishedAt: // value for 'publishedAt'
 *   },
 * });
 */
export function useAddViolationNoteMutation(baseOptions?: Apollo.MutationHookOptions<AddViolationNoteMutation, AddViolationNoteMutationVariables>) {
        return Apollo.useMutation<AddViolationNoteMutation, AddViolationNoteMutationVariables>(AddViolationNoteDocument, baseOptions);
      }
export type AddViolationNoteMutationHookResult = ReturnType<typeof useAddViolationNoteMutation>;
export type AddViolationNoteMutationResult = Apollo.MutationResult<AddViolationNoteMutation>;
export type AddViolationNoteMutationOptions = Apollo.BaseMutationOptions<AddViolationNoteMutation, AddViolationNoteMutationVariables>;
export const AdminViolationCountDocument = gql`
    query AdminViolationCount {
  story_violation_aggregate(
    where: {user: {account: {account_roles: {role: {_eq: "admin"}}}}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useAdminViolationCountQuery__
 *
 * To run a query within a React component, call `useAdminViolationCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminViolationCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminViolationCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminViolationCountQuery(baseOptions?: Apollo.QueryHookOptions<AdminViolationCountQuery, AdminViolationCountQueryVariables>) {
        return Apollo.useQuery<AdminViolationCountQuery, AdminViolationCountQueryVariables>(AdminViolationCountDocument, baseOptions);
      }
export function useAdminViolationCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminViolationCountQuery, AdminViolationCountQueryVariables>) {
          return Apollo.useLazyQuery<AdminViolationCountQuery, AdminViolationCountQueryVariables>(AdminViolationCountDocument, baseOptions);
        }
export type AdminViolationCountQueryHookResult = ReturnType<typeof useAdminViolationCountQuery>;
export type AdminViolationCountLazyQueryHookResult = ReturnType<typeof useAdminViolationCountLazyQuery>;
export type AdminViolationCountQueryResult = Apollo.QueryResult<AdminViolationCountQuery, AdminViolationCountQueryVariables>;
export const UserViolationCountDocument = gql`
    query UserViolationCount {
  story_violation_aggregate(
    where: {user: {account: {_not: {account_roles: {role: {_eq: "admin"}}}}}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useUserViolationCountQuery__
 *
 * To run a query within a React component, call `useUserViolationCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserViolationCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserViolationCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserViolationCountQuery(baseOptions?: Apollo.QueryHookOptions<UserViolationCountQuery, UserViolationCountQueryVariables>) {
        return Apollo.useQuery<UserViolationCountQuery, UserViolationCountQueryVariables>(UserViolationCountDocument, baseOptions);
      }
export function useUserViolationCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserViolationCountQuery, UserViolationCountQueryVariables>) {
          return Apollo.useLazyQuery<UserViolationCountQuery, UserViolationCountQueryVariables>(UserViolationCountDocument, baseOptions);
        }
export type UserViolationCountQueryHookResult = ReturnType<typeof useUserViolationCountQuery>;
export type UserViolationCountLazyQueryHookResult = ReturnType<typeof useUserViolationCountLazyQuery>;
export type UserViolationCountQueryResult = Apollo.QueryResult<UserViolationCountQuery, UserViolationCountQueryVariables>;