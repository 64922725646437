import React from "react";
import {
  ArrayField,
  ChipField,
  Datagrid,
  DateField,
  Filter,
  SearchInput,
  SelectInput,
  SingleFieldList,
  TextField,
} from "react-admin";
import { connect } from "react-redux";
import { push } from "react-router-redux";

import List from "../../components/List";
import { sentimentChoices } from "./data/sentiment.data";

const StatusFilter = (props: any) => {
  return (
    <Filter {...props}>
      <SearchInput source="q" alwaysOn />
      <SelectInput source="sentiment" choices={sentimentChoices} />
    </Filter>
  );
};

export const _KeywordList = (props: any) => {
  return (
    <React.Fragment>
      <List filters={<StatusFilter />} {...props}>
        <Datagrid rowClick="edit">
          <DateField label="Date Added" source="created_at" />
          <TextField label="Keyword / Phrase" source="value" />
          <TextField label="Sentiment" source="sentiment.value" />
          <ArrayField label="Topics" source="keyword_topics">
            <SingleFieldList>
              <ChipField source="topic.value" />
            </SingleFieldList>
          </ArrayField>
          <ArrayField label="Referrals" source="keyword_referrals">
            <SingleFieldList>
              <ChipField source="referral.value" />
            </SingleFieldList>
          </ArrayField>
        </Datagrid>
      </List>
    </React.Fragment>
  );
};

export const KeywordList = connect(undefined, { push })(_KeywordList);
