import { ApolloClient } from "@apollo/client";
import { Lens, compose, curry, lensPath, set, toPairs } from "ramda";

import {
  AccountRolesDocument,
  AuthRolesDocument,
  FeedbacksDocument,
  InfoTilesDocument,
  KeywordsDocument,
  ReferralsDocument,
  StoriesDocument,
  TagsDocument,
  UsersDocument,
  ViolationStoriesDocument,
} from "../types/generated/gql";
import { Params } from "./";

type AdditionalParams = {
  page: number;
  perPage: number;
  sort: string;
  filter: Record<string, any>;
  statuses: Array<string>;
  orderBy?: Record<string, string>;
};

type WhereClause = { [k: string]: WhereClause | string };

const setDefined = curry((lens: Lens, value: any, obj: WhereClause) => {
  return value === undefined ? obj : set(lens, value, obj);
});

type FlatOrderByClause = Record<string, string>;
type NestedOrderByClause = { [k: string]: NestedOrderByClause | string };
type NestedOrderByClauseBuilder = (
  c: NestedOrderByClause
) => NestedOrderByClause;

function nestOrderBy(orderBy: FlatOrderByClause) {
  const pairs = toPairs(orderBy);

  const functions = pairs.map(([key, value]) => {
    return set(lensPath(key.split(".")), value) as NestedOrderByClauseBuilder;
  });

  return (compose as (
    ...args: Array<NestedOrderByClauseBuilder>
  ) => NestedOrderByClauseBuilder)(...functions)({} as NestedOrderByClause);
}

async function handleUsers<T>(
  client: ApolloClient<T>,
  _resource: string,
  { filter, orderBy: flatOrderBy, perPage, page }: AdditionalParams
) {
  const whereClause = compose<
    WhereClause,
    WhereClause,
    WhereClause,
    WhereClause
  >(
    setDefined(
      lensPath(["display_name", "_ilike"]),
      filter.q ? `%${filter.q}%` : undefined
    ),
    setDefined(lensPath(["account", "active", "_eq"]), filter.account?.active),
    setDefined(
      lensPath(["account", "account_roles", "role", "_eq"]),
      filter.roles
    )
  )({});

  const orderBy = flatOrderBy ? nestOrderBy(flatOrderBy) : undefined;

  const variables = {
    orderBy,
    limit: perPage,
    offset: (page - 1) * perPage,
    where: whereClause,
  };

  const response = await client.query({
    query: UsersDocument,
    variables,
  });

  const users = response.data.user;
  const total = response.data.user_aggregate.aggregate.count;

  return {
    data: users,
    total,
  };
}

async function handleStories<T>(
  client: ApolloClient<T>,
  resource: string,
  { filter, statuses, orderBy, perPage, page }: AdditionalParams
) {
  if (filter.status) {
    statuses.push(filter.status);
  } else if (resource === "violations") {
    statuses.push("PUBLISHED");
    statuses.push("QUARANTINED");
  }

  const query =
    resource === "violations" ? ViolationStoriesDocument : StoriesDocument;

  const variables = {
    orderBy,
    statuses: statuses.length > 0 ? statuses : undefined,
    limit: perPage,
    offset: (page - 1) * perPage,
    titleQ: filter.q && `%${filter.q}%`,
  };

  const response = await client.query({
    query,
    variables,
  });

  const stories = response.data.story;
  const total = response.data.story_aggregate.aggregate.count;

  return {
    data: stories,
    total,
  };
}

async function handleInfoTiles<T>(
  client: ApolloClient<T>,
  _resource: string,
  { filter, orderBy, perPage, page }: AdditionalParams
) {
  const variables = {
    orderBy,
    limit: perPage,
    offset: (page - 1) * perPage,
    status: filter.status,
  };
  const response = await client.query({
    query: InfoTilesDocument,
    variables,
  });
  const info_tile = response.data.info_tile;
  const total = response.data.info_tile_aggregate.aggregate.count;

  return {
    data: info_tile,
    total,
  };
}

async function handleFeedbacks<T>(
  client: ApolloClient<T>,
  _resource: string,
  { filter, orderBy, perPage, page }: AdditionalParams
) {
  const variables = {
    orderBy,
    limit: perPage,
    offset: (page - 1) * perPage,
    status: filter.status,
  };
  const response = await client.query({
    query: FeedbacksDocument,
    variables,
  });
  const feedback = response.data.feedback;
  const total = response.data.feedback_aggregate.aggregate.count;

  return {
    data: feedback,
    total,
  };
}

async function handleAuthRoles<T>(
  client: ApolloClient<T>,
  _resource: string,
  { filter, perPage, page }: AdditionalParams
) {
  const variables = {
    orderBy: {},
    limit: perPage,
    offset: (page - 1) * perPage,
    status: filter.status,
  };
  const response = await client.query({
    query: AuthRolesDocument,
    variables,
  });
  const auth_roles = response.data.auth_roles;
  const total = response.data.auth_roles_aggregate.aggregate.count;

  return {
    data: auth_roles,
    total,
  };
}

async function handleAccountRoles<T>(
  client: ApolloClient<T>,
  _resource: string,
  { filter, orderBy, perPage, page }: AdditionalParams
) {
  const variables = {
    orderBy: orderBy,
    limit: perPage,
    offset: (page - 1) * perPage,
    status: filter.status,
  };
  const response = await client.query({
    query: AccountRolesDocument,
    variables,
  });
  const account_roles = response.data.account_roles;
  const total = response.data.account_roles_aggregate.aggregate.count;

  return {
    data: account_roles,
    total,
  };
}

async function handleKeywords<T>(
  client: ApolloClient<T>,
  _resource: string,
  { filter, orderBy, perPage, page }: AdditionalParams
) {
  const variables = {
    orderBy: orderBy,
    limit: perPage,
    offset: (page - 1) * perPage,
    status: filter.status,
  };
  const response = await client.query({
    query: KeywordsDocument,
    variables,
  });

  const keyword_phrase = response.data.keyword;
  const total = response.data.keyword_aggregate.aggregate.count;

  return {
    data: keyword_phrase,
    total,
  };
}

async function handleTags<T>(
  client: ApolloClient<T>,
  _resource: string,
  { filter, orderBy, perPage, page }: AdditionalParams
) {
  const variables = {
    orderBy: orderBy,
    limit: perPage,
    offset: (page - 1) * perPage,
    status: filter.status,
  };
  const response = await client.query({
    query: TagsDocument,
    variables,
  });

  const tag = response.data.tag;
  const total = response.data.tag_aggregate.aggregate.count;

  return {
    data: tag,
    total,
  };
}

async function handleReferrals<T>(
  client: ApolloClient<T>,
  _resource: string,
  { filter, orderBy, perPage, page }: AdditionalParams
) {
  const variables = {
    orderBy: orderBy,
    limit: perPage,
    offset: (page - 1) * perPage,
    status: filter.status,
  };
  const response = await client.query({
    query: ReferralsDocument,
    variables,
  });

  const referral = response.data.referral;
  const total = response.data.referral_aggregate.aggregate.count;

  return {
    data: referral,
    total,
  };
}

export default function <T>(client: ApolloClient<T>) {
  return (resource: string, params: Params) => {
    const { page, perPage } = params.pagination;
    const { sort, filter } = params;

    var orderBy: Record<string, string> | undefined;
    var statuses: Array<string> = [];

    if (sort.field && sort.order) {
      orderBy = { [sort.field]: sort.order.toLowerCase() };
    }

    const additionalParams: AdditionalParams = {
      page,
      perPage,
      sort,
      filter,
      statuses,
      orderBy,
    };

    switch (resource) {
      case "auth_roles":
        return handleAuthRoles(client, resource, additionalParams);
      case "account_roles":
        return handleAccountRoles(client, resource, additionalParams);
      case "stories":
        return handleStories(client, resource, additionalParams);
      case "violations":
        return handleStories(client, resource, additionalParams);
      case "users":
        return handleUsers(client, resource, additionalParams);
      case "infotiles":
        return handleInfoTiles(client, resource, additionalParams);
      case "feedbacks":
        return handleFeedbacks(client, resource, additionalParams);
      case "keywords":
        return handleKeywords(client, resource, additionalParams);
      case "referrals":
        return handleReferrals(client, resource, additionalParams);
      case "tags":
        return handleTags(client, resource, additionalParams);
    }

    throw new Error(`resource ${resource} was not handled`);
  };
}
