import * as React from "react";
import Button from "@material-ui/core/Button";
import Check from "@material-ui/icons/Check";
import PropTypes from "prop-types";

import {
  Info_Tile,
  UpdateInfoTileStatusDocument,
} from "../../../types/generated/gql";
import { client } from "../../../dataProvider";

interface Props {
  record: Info_Tile;
  onActivated: () => unknown;
}

const ActivateButton = ({ record, onActivated }: Props) => {
  const [loading, setLoading] = React.useState(false);

  return (
    <Button
      variant="outlined"
      color="primary"
      size="small"
      onClick={() => {
        setLoading(true);

        client
          .mutate({
            mutation: UpdateInfoTileStatusDocument,
            variables: {
              id: record.id,
              status: "ACTIVE",
            },
          })
          .then((result) => {
            setLoading(false);

            onActivated();
          })
          .catch((error) => {
            setLoading(false);
          });
      }}
      disabled={loading}
    >
      <Check
        color="primary"
        style={{ paddingRight: "0.5em", color: "green" }}
      />
      Activate
    </Button>
  );
};

ActivateButton.propTypes = {
  record: PropTypes.any,
};

export default ActivateButton;
