import React from "react";
import UserIcon from "@material-ui/icons/People";
import { CardHeader, List, ListItem, ListItemText } from "@material-ui/core";

import Card from "../../../components/Card";
import { useUserCountQuery } from "../../../types/generated/gql";

function getCount<O extends ReturnType<typeof useUserCountQuery>>(obj: O) {
  return obj.data?.user_aggregate?.aggregate?.count;
}

export default function Users() {
  const activeCount =
    getCount(useUserCountQuery({ variables: { active: true } })) || 0;
  const inactiveCount =
    getCount(useUserCountQuery({ variables: { active: false } })) || 0;

  const total = activeCount + inactiveCount;
  return (
    <Card>
      <CardHeader
        avatar={<UserIcon />}
        title="Users"
        subheader={`total: ${total}`}
      />
      <List dense>
        <ListItem>
          <ListItemText primary="Active Users" secondary={activeCount} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Deactivated Users" secondary={inactiveCount} />
        </ListItem>
      </List>
    </Card>
  );
}
