const getSlackStorySubmissionWebhookUrl = () => {
  const publicUrl = process.env.PUBLIC_URL;

  if (publicUrl.includes("admin.av-batyr.co"))
    return "https://hooks.slack.com/services/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";

  return "https://hooks.slack.com/services/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";
};

export const SLACK_STORY_SUBMISSION_WEBHOOK_URL = getSlackStorySubmissionWebhookUrl();
