import React from "react";
import TextField from "@material-ui/core/TextField";

export interface InputProps {
  meta: { touched?: boolean; error?: string };
  input: Record<string, any>;
}

export default function Input({
  meta: { touched, error },
  input: inputProps,
  ...props
}: InputProps) {
  return (
    <TextField
      error={!!(touched && error)}
      helperText={touched && error}
      {...inputProps}
      {...props}
      fullWidth
    />
  );
}
