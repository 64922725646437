import jwt_decode from 'jwt-decode';

const ROLE_HIERARCHY = {
  admin: 0,
  user: 1
}

type Claims = {
  'x-hasura-allowed-roles': Array<keyof typeof ROLE_HIERARCHY>
  'x-hasura-default-role': string
  'x-hasura-user-id': string
}

type DecodedToken = {
  exp: number,
  'https://hasura.io/jwt/claims': string
}

function decode<K extends keyof DecodedToken>(token: string, key: K) {
  return (jwt_decode(token) as DecodedToken)[key]
}

export default {
  _claims: undefined as Claims | undefined,
  isAuthenticated: false,
  timeout: undefined as NodeJS.Timeout | undefined,

  get token() { return localStorage.getItem('token') || undefined },
  get claims() {
    if (this._claims) return this._claims

    const data = localStorage.getItem('claims')
    this._claims = data ? JSON.parse(data) as Claims : undefined

    return this._claims
  },

  setToken: function(token: string) {
    localStorage.setItem('claims', JSON.stringify(decode(token, 'https://hasura.io/jwt/claims')))
    localStorage.setItem('token', token)
    this._claims = undefined
  },

  clearToken: function() {
    this._claims = undefined
    localStorage.removeItem('claims')
    localStorage.removeItem('token')
  },

  getClaim: function(id: keyof Claims) {
    return this.claims ? this.claims[id] : undefined
  },

  getHighestRole: function() {
    if (!this.claims) return undefined

    return this.claims['x-hasura-allowed-roles']
      .map(role => ({ role, order: ROLE_HIERARCHY[role]}))
      .sort((a, b) => a.order - b.order)[0].role
  },

  hasToken: function() {
    return !!this.token
  },

  isExpired: function() {
    if (!this.token) throw new Error()

    const expiration = decode(this.token, "exp")
    return (new Date().getTime() / 1000) > expiration
  }
}
