import React, { useState } from "react";
import {
  KeyValuePair,
  compose,
  curry,
  flatten,
  fromPairs,
  identity,
  insert,
  isNil,
  last,
  map,
  reduceBy,
  reject,
} from "ramda";
import {
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import replaceKeyphrases from "./replaceKeyphrases";

type TooltipBuilder = (w: string) => JSX.Element;

// Specify only what I need
type Keyword = {
  value: string;
  sentiment: {
    value: string;
  };
  keyword_referrals: Array<{
    referral: {
      value: string;
    };
  }>;
  keyword_topics: Array<{
    topic: {
      value: string;
    };
  }>;
};

type Keywords = Array<Keyword>;

const BOUNDARY_FILTER = /\b/;

const useKeywordTooltipStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

const BACKGROUND_COLORS = {
  POSITIVE: "lightgreen",
  NEGATIVE: "orange",
  DANGER: "lightcoral",
} as Record<string, string>;

function KeywordToolTip({ keyword, text }: { keyword: Keyword; text: string }) {
  const classes = useKeywordTooltipStyles();
  const [anchorEl, setAnchorEl] = useState(null as null | Element);
  const handlePopoverClose = () => setAnchorEl(null);
  const open = Boolean(anchorEl);

  const sentiment = keyword.sentiment?.value || "n/a";
  const referrals =
    (keyword.keyword_referrals || [])
      .map((kr) => kr.referral.value)
      .join(", ") || "n/a";
  const topics =
    (keyword.keyword_topics || []).map((kr) => kr?.topic?.value).join(", ") ||
    "n/a";

  return (
    <span>
      <Typography
        component="span"
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={(event: React.MouseEvent) =>
          setAnchorEl(event.currentTarget)
        }
        onMouseLeave={handlePopoverClose}
        style={{
          backgroundColor: BACKGROUND_COLORS[sentiment] || "lightblue",
        }}
      >
        {text}
      </Typography>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <List dense>
          <ListItem>
            <ListItemText primary="Sentiment" secondary={sentiment} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Referrals" secondary={referrals} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Topics" secondary={topics} />
          </ListItem>
        </List>
      </Popover>
    </span>
  );
}

type ContentNodes = Array<string | JSX.Element>;

const keywordComponentBuilder = curry((keyword: Keyword, text: string) => (
  <KeywordToolTip keyword={keyword} text={text} />
));

// Return dictionary of { [key: string]: (word: string) => JSX.Element } that is used to construct
// all the keyword components while preserving the case of the 'key' word.
const getKeywordsDict = compose(
  fromPairs as (
    ary: Array<KeyValuePair<string, TooltipBuilder>>
  ) => Record<string, TooltipBuilder>,
  map((keyword: Keyword) => [
    keyword.value.toLowerCase(),
    keywordComponentBuilder(keyword),
  ]) as (keywords: Keywords) => Array<KeyValuePair<string, TooltipBuilder>>,
  reject((keyword: Partial<Keyword>) => isNil(keyword.value)) as (
    keywords: Keywords
  ) => Keywords
);

// Replace all the single word keywords using an efficient hash algorithm approach
const replaceSingles = curry(
  (keywords: Keywords, nodes: ContentNodes): ContentNodes => {
    const dict = getKeywordsDict(keywords);

    return flatten(
      nodes.map((node) => {
        if (typeof node === "string") {
          return node
            .split(BOUNDARY_FILTER)
            .map((word: string) =>
              (dict[word.toLowerCase()] || identity)(word)
            );
        } else {
          return node;
        }
      })
    );
  }
);

// concatenate sequential series of strings within the array of ContentNodes to
function coalesceStrings(nodes: ContentNodes): ContentNodes {
  return nodes.reduce((acc, curr) => {
    const lastItem = last(acc);
    if (typeof curr === "string" && typeof lastItem === "string") {
      return insert(acc.length, lastItem + curr, acc.slice(0, -1));
    } else {
      return [...acc, curr];
    }
  }, [] as ContentNodes);
}
// This function takes a long string & replaces some words (case insensitive) with
// KeywordToolTip components (keeps original word case). Uses dictionary lookups
// instead of 2 dimensional comparisons to stay fast.
export default function decorateContent(
  content: string,
  keywords: Keywords
): ContentNodes {
  // split keywords into singles & phrases
  const keywordGroups = reduceBy(
    (acc, keyword) => acc.concat(keyword),
    [] as Keywords,
    (keyword: PartialDeep<Keyword>) =>
      keyword.value && keyword.value.includes(" ") ? "phrases" : "singles",
    keywords
  );

  const singles = keywordGroups["singles"] || [];
  const phrases = keywordGroups["phrases"] || [];

  return compose(
    coalesceStrings,
    replaceSingles(singles) as (nodes: ContentNodes) => ContentNodes,
    replaceKeyphrases(keywordComponentBuilder, phrases) as (
      nodes: ContentNodes
    ) => ContentNodes
  )([content] as ContentNodes);
}
