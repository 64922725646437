import React, { ReactElement } from "react";
import {
  List as RaList,
  ListProps,
  Pagination,
  PaginationProps,
} from "react-admin";

function BigPagination(props: Partial<PaginationProps>) {
  return <Pagination rowsPerPageOptions={[25, 50, 100, 200]} {...props} />;
}

export default function List(
  props: ListProps & {
    children: ReactElement;
  }
) {
  return <RaList pagination={<BigPagination />} {...props} />;
}
