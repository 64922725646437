import React from "react";
import {
  Datagrid,
  DateField,
  Filter,
  SearchInput,
  SelectInput,
  TextField,
  useNotify,
  useRedirect,
  useRefresh,
  useUnselectAll,
} from "react-admin";
import { Drawer } from "@material-ui/core";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import { push } from "react-router-redux";

import DrawerContent from "../../components/DrawerContent";
import List from "../../components/List";
import BulkDeleteButton, {
  DeleteButtonType,
} from "../../components/BulkDeleteButton";
import OrderDropdown, {
  OrderDropdownType,
} from "../../components/OrderDropdown";
import { TagDetail } from "./tag.detail";

const BulkActionButtons = (props: any) => {
  const redirect = useRedirect();
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();

  return (
    <React.Fragment>
      <BulkDeleteButton
        type={DeleteButtonType.tag}
        onDeleted={() => {
          notify("Tags deleted");
          redirect("/tags");
          refresh();
          unselectAll("tags");
        }}
        {...props}
      />
    </React.Fragment>
  );
};

export const _TagsList = (props: any) => {
  const refresh = useRefresh();

  const [orderDropdownOpen, setOrderDropdownOpen] = React.useState(false);

  return (
    <React.Fragment>
      <List
        {...props}
        sort={{ field: "name", order: "ASC" }}
        bulkActionButtons={<BulkActionButtons />}
      >
        <Datagrid rowClick={orderDropdownOpen ? undefined : "edit"}>
          <DateField label="Date Created" source="created_at" />
          <TextField label="Name" source="name" />
          <TextField label="Type" source="type" />
          <OrderDropdown
            type={OrderDropdownType.tags}
            label="Order"
            source="order"
            onOpen={() => setOrderDropdownOpen(true)}
            onClose={() => setOrderDropdownOpen(false)}
            {...props}
          />
        </Datagrid>
      </List>
      <Route path={`/tags/:id`}>
        {({ match }) => {
          return (
            <Drawer
              open={!!match}
              anchor="right"
              onClose={() => {
                props.push(`/tags`);
                refresh();
              }}
            >
              {match && (
                <DrawerContent
                  onClose={() => {
                    props.push(`/tags`);
                    refresh();
                  }}
                >
                  <TagDetail
                    id={match.params.id}
                    onCancel={() => {
                      props.push(`/tags`);
                      refresh();
                    }}
                    {...props}
                  />
                </DrawerContent>
              )}
            </Drawer>
          );
        }}
      </Route>
    </React.Fragment>
  );
};

export const TagsList = connect(undefined, { push })(_TagsList);
