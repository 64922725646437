import Button from "@material-ui/core/Button";
import React from "react";
import ThumbDown from "@material-ui/icons/ThumbDown";
import { useNotify } from "react-admin";

import authStore from "../../../utils/auth/store";
import {
  GetStoryDocument,
  Story,
  useAddNoteMutation,
  useAddViolationNoteMutation,
} from "../../../types/generated/gql";

export enum RejectButtonType {
  moderation,
  violations,
}

interface Props {
  type: RejectButtonType;
  note: string;
  record: Story;
  onRejected: () => unknown;
}

export default function RejectButton({
  type,
  note,
  record,
  onRejected,
}: Props) {
  const notify = useNotify();
  const refetchQueries = [
    {
      query: GetStoryDocument,
      variables: {
        StoryId: record.id,
      },
    },
  ];
  const [addNote, { loading: addNoteLoading }] = useAddNoteMutation({
    refetchQueries,
    variables: {
      note: {
        action: "REJECT",
        note,
        story_id: record.id,
        user_id: authStore.getClaim("x-hasura-user-id"),
      },
      storyId: record.id,
      storyStatus: "DRAFT",
    },
  });
  const [
    addViolationNote,
    { loading: addViolationNoteLoading },
  ] = useAddViolationNoteMutation({
    refetchQueries,
    variables: {
      object: {
        action: "REVOKE",
        note,
        story_id: record.id,
        user_id: authStore.getClaim("x-hasura-user-id"),
      },
      storyId: record.id,
      storyStatus: "QUARANTINED",
    },
  });

  const loading = addNoteLoading || addViolationNoteLoading;

  return (
    <Button
      variant="outlined"
      color="primary"
      size="small"
      onClick={() => {
        if (note.length === 0) {
          notify("Please enter your moderator notes", "warning");
          return;
        }

        if (type === RejectButtonType.moderation) addNote().then(onRejected);
        if (type === RejectButtonType.violations)
          addViolationNote().then(onRejected);
      }}
      disabled={loading}
    >
      <ThumbDown
        color="primary"
        style={{ paddingRight: "0.5em", color: "red" }}
      />
      {type === RejectButtonType.violations ? "Revoke" : "Reject"}
    </Button>
  );
}
