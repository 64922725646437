import * as React from "react";
import { FC } from "react";
import { InputProps, SelectArrayInput } from "react-admin";

import referrals from "../data/referrals.data";

interface Props extends Omit<InputProps, "source"> {
  source?: string;
}

const ReferralsInput: FC<Props> = ({ addField, ...rest }) => {
  return (
    <SelectArrayInput
      source="referrals"
      {...rest}
      choices={referrals.map((referral) => ({
        id: referral.id,
        name: referral.name,
      }))}
    />
  );
};

export default ReferralsInput;
