import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import React from "react";
import { useNotify } from "react-admin";

import ConfirmDialog from "./ConfirmDialog";
import {
  Feedback,
  FeedbacksDocument,
  StoriesDocument,
  Story,
  Tag,
  TagsDocument,
  User,
  UsersDocument,
  useDeleteFeedbackMutation,
  useDeleteStoryMutation,
  useDeleteTagMutation,
  useDeleteUserMutation,
} from "../types/generated/gql";

export enum DeleteButtonType {
  story,
  user,
  feedback,
  tag,
}

interface Props {
  type: DeleteButtonType;
  record: Story | User | Feedback;
  onDeleted: () => unknown;
}

export default function DeleteButton({ type, record, onDeleted }: Props) {
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const [deleteStory, { loading: deleteStoryLoading }] = useDeleteStoryMutation(
    {
      refetchQueries: [
        {
          query: StoriesDocument,
        },
      ],
      variables: {
        id: record.id,
      },
    }
  );

  const [
    deleteUser,
    { loading: deleteUserLoading, error: deleteUserError },
  ] = useDeleteUserMutation({
    refetchQueries: [
      {
        query: UsersDocument,
      },
    ],
    variables: {
      id: record.id,
    },
  });

  const [
    deleteFeedback,
    { loading: deleteFeedbackLoading, error: deleteFeedbackError },
  ] = useDeleteFeedbackMutation({
    refetchQueries: [
      {
        query: FeedbacksDocument,
      },
    ],
    variables: {
      id: record.id,
    },
  });

  const [
    deleteTag,
    { loading: deleteTagLoading, error: deleteTagError },
  ] = useDeleteTagMutation({
    refetchQueries: [
      {
        query: TagsDocument,
      },
    ],
    variables: {
      id: record.id,
    },
  });

  const title = () => {
    if (type === DeleteButtonType.story) {
      return "Delete story";
    }

    if (type === DeleteButtonType.user) {
      return "Delete user";
    }

    if (type === DeleteButtonType.feedback) {
      return "Delete feedback";
    }

    if (type === DeleteButtonType.tag) {
      return "Delete tag";
    }

    return "Delete item";
  };

  const message = () => {
    if (type === DeleteButtonType.story) {
      return "Are you sure you want to delete this story?";
    }

    if (type === DeleteButtonType.user) {
      return "Are you sure you want to delete this user?";
    }

    if (type === DeleteButtonType.feedback) {
      return "Are you sure you want to delete this feedback?";
    }

    if (type === DeleteButtonType.tag) {
      return "Are you sure you want to delete this tag?";
    }

    return "Are you sure you want to delete this item?";
  };

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        size="small"
        onClick={() => setDialogOpen(true)}
        disabled={
          deleteStoryLoading ||
          deleteUserLoading ||
          deleteFeedbackLoading ||
          deleteTagLoading
        }
      >
        <DeleteIcon
          color="primary"
          style={{ paddingRight: "0.5em", color: "red" }}
        />
        Delete
      </Button>

      <ConfirmDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onYes={() => {
          if (type === DeleteButtonType.story) {
            deleteStory().then(onDeleted);
          } else if (type === DeleteButtonType.user) {
            deleteUser().then(onDeleted);
          } else if (type === DeleteButtonType.feedback) {
            deleteFeedback().then(onDeleted);
          } else if (type === DeleteButtonType.tag) {
            deleteTag().then(onDeleted);
          }

          setDialogOpen(false);
        }}
        onNo={() => setDialogOpen(false)}
        title={title()}
        message={message()}
      />
    </>
  );
}
