export default [
  { id: 'f1e00620-169b-41d4-97d1-8e5cc9047d52', name: 'Abuse' },
  { id: '8e69142d-8b79-4ba1-88d3-4355ac9d4ec9', name: 'Addiction' },
  { id: '2bf83380-e3c8-411c-bc3a-104944c14442', name: 'ADHD' },
  { id: 'b15585d0-a7c5-44b6-8e46-6de8be26814f', name: 'AlcoholDrugDependence' },
  { id: '31489001-8a1a-4bcc-ab27-f526ce68fc48', name: 'AlcoholUse' },
  { id: 'f82d16c2-2807-4c9e-8343-ecdf588815ab', name: 'AnorexiaNervosa' },
  { id: '50966f9f-0be1-4d91-adf4-c8fb41b11244', name: 'AspergerSyndrome' },
  { id: '727a9f39-5653-49a2-8108-0868fb7c07f8', name: 'Autism-SpectrumDisorders' },
  { id: 'a52b1885-fc26-4102-ba3a-6a88a31d5995', name: 'BehaviouralDisorders' },
  { id: 'ad73d27c-f3e2-4075-9299-55eaf65ddda9', name: 'BipolarDisorder' },
  { id: 'bba36a60-52f4-4e6d-a88b-dde9de522275', name: 'BulimiaNervosa' },
  { id: '28144674-20cb-4353-8176-a02805505f6c', name: 'ChildhoodAutism' },
  { id: '8c86f11d-84cf-4102-9472-caba289853f6', name: 'Dementia' },
  { id: '2e23d7b3-0a0d-468e-b90e-e2f55152ec21', name: 'Depression' },
  { id: 'd477b37f-ad7a-42cb-9472-627d3d3e7843', name: 'DevelopmentalDisorder' },
  { id: '015608ce-90ed-4f94-a74d-9238c7281bf7', name: 'DrugUse' },
  { id: '324eca9e-1097-4ffa-b383-98f961228a8f', name: 'EpilepsySeizures' },
  { id: 'bfbcbad7-411f-4e6d-9b39-2e387d5bd6d9', name: 'GeneralizedAnxietyDisorder' },
  { id: 'dd3f6e3f-a08f-4ee6-a8b3-f5fcabe207f3', name: 'IntellectualDisability' },
  { id: '9cc3e74f-346f-4282-88b9-6f1676107022', name: 'MentalDisorderPrevention' },
  { id: '35005c5c-86fd-41bd-9898-0dda17613bff', name: 'MentalDisorders' },
  { id: 'fa0fd177-42e4-40c9-9978-9670a677cda9', name: 'MentalHealth' },
  { id: 'f844429b-2179-47a3-9b46-865eb29e6ffc', name: 'MentalHealthLegislation' },
  { id: 'c761decf-59c6-410f-8fe6-90927469213e', name: 'MentalHealthPolicy' },
  { id: 'a6b752f6-f4f3-4af6-b5ab-49f9c9c78f35', name: 'MentalHealthPromotion' },
  { id: 'ec95f8cc-3e47-4606-b745-8fdc35467c76', name: 'MentalHealthServices' },
  { id: 'c5a67a53-32ee-436c-81fe-a1394134a60e', name: 'MisuseDrugOrAlcohol' },
  { id: 'b0a63e3b-fcfe-4e00-af8a-670c9cf7321d', name: 'ObsessiveCompulsiveDisorder' },
  { id: '9e55cb30-eab2-4e51-bc41-c8e3b262694c', name: 'OtherBehaviouralDisorders' },
  { id: 'ba212bc6-7e88-49fa-9e93-9b4b10566f6d', name: 'PervasiveDevelopmentalDisorders' },
  { id: '7422f786-e41a-44c4-82e8-3e19671cdaef', name: 'PostTraumaticStressDisorder' },
  { id: '2aa0233f-2e5e-43e7-bcf3-da4490c2a93b', name: 'PsychoactiveDrugOrSubstance' },
  { id: '1a3ae172-da5d-4178-857e-63b336be5251', name: 'PsychologicalFirstAid' },
  { id: '7f5a1a97-5603-4f47-8e69-55894ce6473c', name: 'Psychosis' },
  { id: 'c8283224-4499-46a6-a46f-19950ffb507a', name: 'PsychosocialDisabilities' },
  { id: '5f418494-81b9-4109-82b6-d7f9e54c4a3b', name: 'RespiteCare' },
  { id: '964dde84-0829-4d6f-bb8c-5d984853e285', name: 'Schizophrenia' },
  { id: '8467e84b-8a62-4c1d-9250-a39de5d1e991', name: 'Self-harm' },
  { id: '6720dbbf-a21c-4a1e-a847-a90d72c90258', name: 'Stigma' },
  { id: '3f1c6be6-e122-490b-a9ed-36353e9848ce', name: 'SuicidalBehavior' },
  { id: '11a77c32-f095-4696-8265-2e8622e77cf9', name: 'Suicide' },
  { id: '668fd9f7-cfa7-4546-97b6-5da67f8f3739', name: 'SuicideAttempt' },
  { id: '6dad48a9-373a-4dbd-af9e-35ee3958c11c', name: 'YoungOnsetDementia' },
]