import React from "react";
import {
  DateField,
  Edit,
  ImageField,
  SimpleForm,
  TextField,
} from "react-admin";

import { InfoTileToolbar } from "./info-tile.toolbar";

export const InfoTileDetail = (props: any) => {
  return (
    <Edit {...props}>
      <SimpleForm toolbar={<InfoTileToolbar />}>
        <DateField label="Date Added" source="created_at" />
        <ImageField label="Image" source="image_url" />
        <TextField source="action_url" />
        <TextField source="accessibility_text" />
        <TextField source="status" />
      </SimpleForm>
    </Edit>
  );
};
