import axios from "axios";
import { ApolloClient } from "@apollo/client";
import { v4 as uuidv4 } from "uuid";

import {
  GetSignedUrlDocument,
  UpdateAccountAccountRolesDocument,
  UpdateAccountAccountRolesMutationVariables,
  UpdateInfoTileDocument,
  UpdateInfoTileMutationVariables,
  UpdateStoryAndResourceDocument,
  UpdateStoryDocument,
  UpdateTagDocument,
  UpdateTagMutationVariables,
  UpdateUserDocument,
  UpdateUserMutationVariables,
} from "../types/generated/gql";
import { Params } from "./";

async function updateUser<T>(client: ApolloClient<T>, params: Params) {
  const response = await client.mutate({
    mutation: UpdateUserDocument,
    variables: {
      id: params.id,
      user: {
        display_name: params.data.display_name,
      },
      account: {
        active: params.data.account.active,
      },
    } as UpdateUserMutationVariables,
  });

  return {
    data: response.data,
  };
}

async function updateTag<T>(client: ApolloClient<T>, params: Params) {
  const response = await client.mutate({
    mutation: UpdateTagDocument,
    variables: {
      id: params.id,
      tag: {
        name: params.data.name,
        type: params.data.type,
      },
    } as UpdateTagMutationVariables,
  });

  return {
    data: response.data.update_tag_by_pk,
  };
}

function getUpsertData<T>(account_roles: Array<T>, account_id: string) {
  return account_roles.map((obj: any) => ({
    role: obj.role as string,
    account_id: account_id,
  }));
}

async function updateRoles<T>(client: ApolloClient<T>, params: Params) {
  const accountId = params.data.account.id as string;
  const objects = getUpsertData(
    params.data.account.account_roles as Array<any>,
    accountId
  );
  const desiredRoles = objects.map((obj) => obj.role);

  const response = await client.mutate({
    mutation: UpdateAccountAccountRolesDocument,
    variables: {
      objects: objects,
      accountId: accountId,
      roles: desiredRoles,
    } as UpdateAccountAccountRolesMutationVariables,
  });

  return {
    data: response.data,
  };
}

async function handleUsers<T>(
  client: ApolloClient<T>,
  _resource: string,
  params: Params
) {
  const result = await updateUser(client, params);
  await updateRoles(client, params);

  return result;
}

async function handleTags<T>(
  client: ApolloClient<T>,
  _resource: string,
  params: Params
) {
  const result = await updateTag(client, params);

  return result;
}

async function updateInfoTiles<T>(client: ApolloClient<T>, params: Params) {
  const response = await client.mutate({
    mutation: UpdateInfoTileDocument,
    variables: {
      id: params.id,
      infoTile: {
        action_url: params.data.action_url,
        accessibility_text: params.data.accessibility_text,
        status: params.data.status,
      },
    } as UpdateInfoTileMutationVariables,
  });

  return {
    data: { id: response.data.update_info_tile_by_pk.id },
  };
}

async function handleStories<T>(
  client: ApolloClient<T>,
  _resource: string,
  params: Params
) {
  const { title, caption, content, storyType } = params.data;

  // Get signed url to upload to
  const getUrlresponse = await client.query({
    query: GetSignedUrlDocument,
    variables: {
      type: storyType === "VIDEO" ? "video" : "image",
    },
  });
  const uploadUrl = getUrlresponse.data.generateSignedUrl.signed_url;
  const resourceId = getUrlresponse.data.generateSignedUrl.resource_id;

  const file = params.data.file?.rawFile as File;

  let resourceUrl;

  if (file) {
    // Upload the image or video
    await axios({
      url: uploadUrl,
      method: "put",
      headers: {
        "x-amz-acl": "public-read",
      },
      data: file,
      withCredentials: false, // dont include credentials when uploading to s3, causes CORS issues
      // "header in the response must not be the wildcard '*' when the request's credentials mode is 'include'"
    });

    resourceUrl = uploadUrl.split("?")[0];
  }

  const storyId = params.id;

  const resource = resourceUrl
    ? {
        id: resourceId,
        url: resourceUrl,
        type: storyType === "VIDEO" ? "VIDEO" : "IMAGE", // mediaType: "VIDEO" or "IMAGE"
        story_id: storyId,
      }
    : undefined;

  const mutation = resourceUrl
    ? UpdateStoryAndResourceDocument
    : UpdateStoryDocument;

  const variables = {
    story: {
      title,
      caption,
      content,
      type: storyType, // storyType: "TEXT", "VIDEO", "AUDIO"
      status: "SUBMITTED",
      transcription: resourceUrl ? null : undefined, // Reset edited transcription if needed
    },
    storyId,
    resource,
  };

  const result = await client.mutate({
    mutation,
    variables,
    refetchQueries: ["Stories"],
  });

  const data = result.data.update_story_by_pk;

  return {
    data,
  };
}

export default function <T>(client: ApolloClient<T>) {
  return (resource: string, params: Params) => {
    switch (resource) {
      case "users":
        return handleUsers(client, resource, params);
      case "infotiles":
        return updateInfoTiles(client, params);
      case "tags":
        return updateTag(client, params);
      case "stories":
      case "story":
        return handleStories(client, resource, params);
    }

    throw new Error(`resource ${resource} was not handled`);
  };
}
