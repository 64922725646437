import React, { PropsWithChildren } from "react";
import { Card as MuiCard, Grid } from "@material-ui/core";

export default function Card({ children }: PropsWithChildren<{}>) {
  return (
    <Grid item xs={12}>
      <MuiCard>{children}</MuiCard>
    </Grid>
  );
}
