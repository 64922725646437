import React from "react";
import {
  BooleanInput,
  Edit,
  RadioButtonGroupInput,
  SaveButton,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  useNotify,
  useRedirect,
  useRefresh,
  useUnselectAll,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

import DeleteButton, { DeleteButtonType } from "../../components/DeleteButton";

const useStyles = makeStyles({
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
});

const CustomToolbar = (props: any) => {
  const redirect = useRedirect();
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();

  return (
    <Toolbar {...props} classes={useStyles()}>
      <SaveButton />
      <DeleteButton
        type={DeleteButtonType.tag}
        onDeleted={() => {
          notify("Tag deleted");
          redirect("/tags");
          refresh();
          unselectAll("tags");
        }}
        {...props}
      />
    </Toolbar>
  );
};

export const TagDetail = (props: any) => {
  return (
    <Edit {...props}>
      <SimpleForm toolbar={<CustomToolbar />}>
        <TextInput source="name" />

        <RadioButtonGroupInput
          source="type"
          choices={[
            { id: "EXPERIENCE", name: "Experience" },
            { id: "FRAMING", name: "Framing" },
          ]}
        />
      </SimpleForm>
    </Edit>
  );
};
