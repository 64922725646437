import Alert from "@material-ui/lab/Alert";
import React, { useEffect } from "react";
import {
  ArrayField,
  ChipField,
  DateField,
  Edit,
  Labeled,
  SimpleForm,
  SingleFieldList,
  TextField,
  useNotify,
  useRedirect,
  useRefresh,
  useUnselectAll,
} from "react-admin";
import { Box, Button, MenuItem, Select, Snackbar } from "@material-ui/core";
import { insert } from "ramda";

import StoryContent from "./partials/StoryContent";
import authStore from "../../utils/auth/store";
import dataProvider from "../../dataProvider";
import {
  GetStoryDocument,
  Resource_Type_Enum,
  Story,
} from "../../types/generated/gql";
import { NotesTable } from "./partials/NotesTable";
import { StoryTags } from "./partials/StoryTags";
import { StoryToolbar } from "./story.toolbar";
import { VideoResource } from "./partials/VideoResource";
import { ViolationNotesTable } from "./partials/ViolationNotesTable";
import { ViolationsTable } from "./partials/ViolationsTable";
import { getResourceUrl } from "../../utils/resource";
import { toTitleCase } from "../../utils/string";
import { useSendEmailMutation } from "../../types/generated/gql";

const StoryDetailResource = (props: any) => {
  const { record } = props;
  const story = record as Story;

  return story?.resource?.type && story?.resource?.url ? (
    <Box mb={2}>
      <Labeled label={toTitleCase(story.resource.type)}>
        <>
          {story.resource.type === Resource_Type_Enum.Image && (
            <img
              src={getResourceUrl(story.resource)}
              width="100%"
              alt="untitled"
            />
          )}
          {story.resource.type === Resource_Type_Enum.Video && (
            <VideoResource story={story} />
          )}
        </>
      </Labeled>
    </Box>
  ) : (
    <div />
  );
};

const ALERT_MESSAGE =
  "An email has just been sent to the user providing them with a list of support organizations.";

export const StoryDetail = (props: any) => {
  const { resource, id, record } = props;

  const redirect = useRedirect();
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();

  const [user, setUser] = React.useState({} as any);
  const [moderator, setModerator] = React.useState({} as any);
  const [isAlertVisible, setIsAlertVisible] = React.useState(false);
  const [sendEmail] = useSendEmailMutation();

  const isModeration = resource === "stories";
  const isViolations = resource === "violations";

  useEffect(() => {
    const getModerator = async () => {
      const result = await dataProvider.getOne("users", {
        id: authStore.getClaim("x-hasura-user-id") as string,
      });
      setModerator(result.data);
    };

    const getUser = async () => {
      const story = await dataProvider.getOne("stories", {
        id,
      });
      setUser(story.data.user);
    };

    getModerator();
    getUser();
  }, [id]);

  const handleCloseAlert = (event: any, reason: string) => {
    if (reason === "clickaway") return;
    setIsAlertVisible(false);
  };

  const handleOnClick = () => {
    sendEmail({
      variables: {
        template: "moderator_high_risk_story",
        email: user.account.email,
        tags: JSON.stringify({
          firstName: user?.first_name,
          moderatorName: moderator?.first_name,
        }),
      },
    });
    setIsAlertVisible(true);
  };

  return (
    <Edit {...props}>
      <SimpleForm
        toolbar={
          <StoryToolbar
            onDeleted={() => {
              notify("Story deleted");
              redirect("/stories");
              refresh();
              unselectAll("stories");
            }}
          />
        }
      >
        <TextField label="Title" source="title" />
        <TextField label="Username" source="user.display_name" />
        <DateField label="First Submitted" source="first_submitted_at" />
        <DateField label="Last Submitted" source="last_submitted_at" />
        <TextField label="Screener Score" source="user.screener_score" />
        <TextField label="DoB" source="user.date_of_birth" />
        <p>High Risk Story?</p>
        <Button color="secondary" onClick={handleOnClick}>
          Send support email
        </Button>
        <StoryContent />
        <StoryDetailResource />
        {/* <ArrayField label="Tags" source="tags">
          <SingleFieldList linkType={false}>
            <ChipField source="tag.name" onDelete={() => {}} />
          </SingleFieldList>
        </ArrayField> */}
        <StoryTags />

        {isModeration && <NotesTable {...props} />}
        {isViolations && <ViolationsTable {...props} />}
        {isViolations && <ViolationNotesTable {...props} />}
        <Snackbar
          open={isAlertVisible}
          autoHideDuration={6000}
          onClose={handleCloseAlert}
        >
          <>
            {/* @ts-ignore */}
            <Alert onClose={handleCloseAlert} severity="info">
              {ALERT_MESSAGE}
            </Alert>
          </>
        </Snackbar>
      </SimpleForm>
    </Edit>
  );
};
