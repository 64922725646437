import Book from "@material-ui/icons/Book";
import React from "react";
import { CardHeader, List, ListItem, ListItemText } from "@material-ui/core";

import Card from "../../../components/Card";
import { useStoryCountQuery } from "../../../types/generated/gql";

function getCount<O extends ReturnType<typeof useStoryCountQuery>>(obj: O) {
  return obj.data?.story_aggregate?.aggregate?.count;
}

export default function Stories() {
  const approvedCount =
    getCount(useStoryCountQuery({ variables: { status: "APPROVED" } })) || 0;
  const quarantinedCount =
    getCount(useStoryCountQuery({ variables: { status: "QUARANTINED" } })) || 0;

  const total = approvedCount + quarantinedCount;

  return (
    <Card>
      <CardHeader
        avatar={<Book />}
        title="Moderations"
        subheader={`total: ${total}`}
      />
      <List>
        <ListItem>
          <ListItemText primary="Stories Approved" secondary={approvedCount} />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Stories Quarantined"
            secondary={quarantinedCount}
          />
        </ListItem>
      </List>
    </Card>
  );
}
