import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ReactHlsPlayer from "react-hls-player";
import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@material-ui/core";
import { path } from "ramda";

import TranscriptContent from "./TranscriptContent";
import { Resource, Story } from "../../../types/generated/gql";
import { getResourceUrl, getTranscriptUrl } from "../../../utils/resource";

interface VideoResourceProps {
  story: Story;
}

export function VideoResource({ story }: VideoResourceProps) {
  const [transcript, setTranscript] = useState(undefined as undefined | string);

  const { resource } = story;

  useEffect(() => {
    if (story.transcription) {
      setTranscript(story.transcription);
    } else {
      resource &&
        fetch(getTranscriptUrl(resource))
          .then((response) => response.json())
          .then((json) =>
            setTranscript(
              path(["results", "transcripts", "0", "transcript"], json)
            )
          );
    }
  }, [resource]);

  return (
    <Box>
      {resource && (
        <ReactHlsPlayer
          url={getResourceUrl(resource)}
          autoplay={false}
          controls={true}
          width="100%"
          height="auto"
        />
      )}

      <Box mt={2}>
        <Accordion disabled={!transcript}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle2">Transcript</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TranscriptContent
              story={story}
              content={transcript}
              onTranscriptionUpdated={(transcription) => {
                setTranscript(transcription);
              }}
            />
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
}
