import React from "react";
import { Box, BoxProps, Button } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

interface DrawerContentProps extends BoxProps {
  onClose: () => unknown;
}

export default function DrawerContent({
  children,
  onClose,
}: DrawerContentProps): JSX.Element {
  return (
    <Box width="750px">
      <Box display="flex" justifyContent="flex-end" mt={2}>
        <Button onClick={onClose}>
          <CloseIcon />
        </Button>
      </Box>
      {children}
    </Box>
  );
}
