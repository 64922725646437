import React from "react";
import {
  Create,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
  useNotify,
  useRedirect,
  useRefresh,
} from "react-admin";

import ReferralsInput from "./partials/ReferralsInput";
import TopicsInput from "./partials/TopicsInput";
import { sentimentChoices } from "./data/sentiment.data";

export const KeywordCreate = (props: any) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = (data: any) => {
    notify("New Keyword / Phrase created");
    redirect("/keywords?refresh=true");
    refresh();
  };

  return (
    <Create onSuccess={onSuccess} {...props}>
      <SimpleForm>
        <TextInput
          label="Keyword / Phrase"
          source="value"
          validate={[required()]}
        />
        <SelectInput
          source="sentiment"
          choices={sentimentChoices}
          validate={[required()]}
        />
        <TopicsInput />
        <ReferralsInput />
      </SimpleForm>
    </Create>
  );
};
