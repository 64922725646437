import React from "react";
import {
  BooleanInput,
  Create,
  RadioButtonGroupInput,
  SimpleForm,
  TextInput,
  useNotify,
  useRedirect,
  useRefresh,
} from "react-admin";

export const TagCreate = (props: any) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = (data: any) => {
    notify("New Tag created");
    redirect("/tags?refresh=true");
    refresh();
  };

  return (
    <Create {...props} onSuccess={onSuccess}>
      <SimpleForm>
        <TextInput source="name" />

        <RadioButtonGroupInput
          defaultValue="EXPERIENCE"
          source="type"
          choices={[
            { id: "EXPERIENCE", name: "Experience" },
            { id: "FRAMING", name: "Framing" },
          ]}
        />
      </SimpleForm>
    </Create>
  );
};
