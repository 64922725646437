import React from "react";
import {
  Datagrid,
  DateField,
  Filter,
  ImageField,
  SearchInput,
  SelectInput,
  TextField,
} from "react-admin";
import { Drawer } from "@material-ui/core";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import { push } from "react-router-redux";

import DrawerContent from "../../components/DrawerContent";
import List from "../../components/List";
import OrderDropdown, {
  OrderDropdownType,
} from "../../components/OrderDropdown";
import { InfoTileDetail } from "./info-tile.detail";

const StatusFilter = (props: any) => {
  return (
    <Filter {...props}>
      <SearchInput source="q" alwaysOn />
      <SelectInput
        source="status"
        choices={[
          {
            id: "ACTIVE",
            name: "Active",
          },
          {
            id: "ARCHIVED",
            name: "Archived",
          },
        ]}
      />
    </Filter>
  );
};

export const _InfoTileList = (props: any) => {
  const [orderDropdownOpen, setOrderDropdownOpen] = React.useState(false);

  return (
    <React.Fragment>
      <List filters={<StatusFilter />} {...props}>
        <Datagrid rowClick={orderDropdownOpen ? undefined : "edit"}>
          <DateField label="Date Added" source="created_at" />
          <ImageField label="Image" source="image_url" />
          <TextField label="Url" source="action_url" />
          <TextField label="Accessibility Text" source="accessibility_text" />
          <TextField label="Status" source="status" />
          <OrderDropdown
            type={OrderDropdownType.infotiles}
            label="Order"
            source="order"
            onOpen={() => setOrderDropdownOpen(true)}
            onClose={() => setOrderDropdownOpen(false)}
            {...props}
          />
        </Datagrid>
      </List>
      <Route path={"/infotiles/:id"}>
        {({ match }) => {
          return (
            <Drawer
              open={!!match}
              anchor="right"
              onClose={() => {
                props.push("/infotiles");
              }}
            >
              {match && (
                <DrawerContent onClose={() => props.push("/infotiles")}>
                  <InfoTileDetail
                    id={match.params.id}
                    onCancel={() => {
                      props.push("/infotiles");
                    }}
                    {...props}
                  />
                </DrawerContent>
              )}
            </Drawer>
          );
        }}
      </Route>
    </React.Fragment>
  );
};

export const InfoTileList = connect(undefined, { push })(_InfoTileList);
