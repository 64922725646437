import React from "react";
import {
  Create,
  FileInput,
  ImageField,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
  useNotify,
  useRedirect,
  useRefresh,
} from "react-admin";

export const InfoTileCreate = (props: any) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = (data: any) => {
    notify("New Info Tile created");
    redirect("/infotiles?refresh=true");
    refresh();
  };

  return (
    <Create onSuccess={onSuccess} {...props}>
      <SimpleForm>
        <TextInput source="action_url" label="URL" />
        <TextInput source="accessibility_text" validate={[required()]} />
        <FileInput
          source="file"
          label="Image"
          accept="image/*"
          validate={[required()]}
        >
          <ImageField source="src" title="title" />
        </FileInput>
        <SelectInput
          source="status"
          choices={[
            {
              id: "ACTIVE",
              name: "Active",
            },
            {
              id: "ARCHIVED",
              name: "Archived",
            },
          ]}
          validate={[required()]}
        />
      </SimpleForm>
    </Create>
  );
};
