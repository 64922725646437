import {
  AUTH_CHECK,
  AUTH_ERROR,
  AUTH_GET_PERMISSIONS,
  AUTH_LOGIN,
  AUTH_LOGOUT,
} from "react-admin";

import authStore from "./utils/auth/store";
import {
  initializeSession,
  logIn as performLogIn,
  logOut as performLogOut,
  startEndlessRefreshToken,
} from "./utils/auth/sessions";

type Params = {
  username?: string;
  password?: string;
  mfaToken?: string;
  status?: number;
  error?: string;
};

async function logIn(username: string, password: string, mfaToken?: string) {
  return await performLogIn(username, password, mfaToken);
}

async function logOut() {
  // need to catch errors here otherwise it wont redirect to the login screen
  try {
    return await performLogOut();
  } catch (err) {
    return;
  }
}

async function checkAuthentication() {
  // no token, so throw error & cause logout
  if (!authStore.hasToken()) throw new Error("Not logged in");
  // token's expired so try to refresh it
  if (authStore.isExpired()) await initializeSession();
  // check if user is allowed to be here (admin only)
  if (
    !(
      authStore.claims &&
      authStore.claims["x-hasura-allowed-roles"].includes("admin")
    )
  ) {
    throw new Error("Not An Administrator");
  }

  return;
}

async function handleAuthError(params: Params) {
  if (params.status === 401 || params.status === 403) {
    authStore.clearToken();
    throw new Error(params.error);
  }
}

export default async function (type: string, params: Params): Promise<any> {
  switch (type) {
    case AUTH_LOGIN:
      if (!params.username || !params.password) {
        throw new Error("AUTH_LOGIN params not set properly");
      }
      await logIn(params.username, params.password, params.mfaToken);
      return;
    case AUTH_LOGOUT:
      await logOut();
      return;
    case AUTH_GET_PERMISSIONS:
      authStore.getClaim("x-hasura-allowed-roles");
      return;
    case AUTH_ERROR:
      await handleAuthError(params);
      return;
    case AUTH_CHECK:
      await checkAuthentication();

      // Start endless refresh token if needed
      if (!authStore.isExpired() && !authStore.timeout) {
        startEndlessRefreshToken();
      }
      return;
    default:
      throw new Error();
  }
}
