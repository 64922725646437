import React from "react";
import { Box } from "@material-ui/core";
import { Toolbar, useRefresh } from "react-admin";

import ActivateButton from "./partials/ActivateButton";
import ArchiveButton from "./partials/ArchiveButton";

export const InfoTileToolbar = (props: any) => {
  const refresh = useRefresh();

  return (
    <Toolbar {...props}>
      <Box justifyContent="space-between" display="flex" flex={1} mt={2}>
        <ActivateButton
          onActivated={() => {
            refresh();
          }}
          {...props}
        />
        <ArchiveButton
          onArchived={() => {
            refresh();
          }}
          {...props}
        />
      </Box>
    </Toolbar>
  );
};
