import React from "react";
import {
  ArrayField,
  ChipField,
  Datagrid,
  DateField,
  Filter,
  FunctionField,
  SearchInput,
  SelectInput,
  SingleFieldList,
  TextField,
  useNotify,
  useRedirect,
  useRefresh,
  useUnselectAll,
} from "react-admin";
import { Drawer } from "@material-ui/core";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import { push } from "react-router-redux";

import DrawerContent from "../../components/DrawerContent";
import List from "../../components/List";
import BulkDeleteButton, {
  DeleteButtonType,
} from "../../components/BulkDeleteButton";
import { UserEdit } from "./users.edit";

const RoleFilter = (props: any) => {
  return (
    <Filter {...props}>
      <SearchInput source="q" alwaysOn />
      <SelectInput
        label="Role"
        source="roles"
        choices={[
          {
            id: "user",
            name: "user",
          },
          {
            id: "admin",
            name: "admin",
          },
        ]}
      />
      <SelectInput
        label="Status"
        source="account.active"
        choices={[
          {
            id: true,
            name: "ACTIVE",
          },
          {
            id: false,
            name: "DEACTIVATED",
          },
        ]}
      />
    </Filter>
  );
};

function renderStatus(record: any) {
  if (!record.account) {
    return "NO ACCOUNT"; // user does not have an account
  }

  return record.account.active ? "ACTIVE" : "DEACTIVATED";
}

const BulkActionButtons = (props: any) => {
  const redirect = useRedirect();
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();

  return (
    <React.Fragment>
      <BulkDeleteButton
        type={DeleteButtonType.user}
        onDeleted={() => {
          notify("Users deleted");
          redirect("/users");
          refresh();
          unselectAll("users");
        }}
        {...props}
      />
    </React.Fragment>
  );
};

export const _UserList = (props: any) => {
  const refresh = useRefresh();

  return (
    <React.Fragment>
      <List
        filters={<RoleFilter />}
        sort={{ field: "created_at", order: "DESC" }}
        {...props}
        bulkActionButtons={<BulkActionButtons />}
      >
        <Datagrid rowClick="edit">
          <DateField label="Date joined" source="created_at" />
          <TextField label="Username" source="display_name" />
          <TextField
            label="Num Stories"
            source="stories_aggregate.aggregate.count"
          />
          <TextField
            label="Num Violations"
            source="story_violations_aggregate.aggregate.count"
          />
          <TextField
            label="Num Notes"
            source="violation_notes_aggregate.aggregate.count"
          />
          <TextField label="Location" source="suburb" />
          <ArrayField label="Roles" source="account.account_roles">
            <SingleFieldList>
              <ChipField source="role" />
            </SingleFieldList>
          </ArrayField>
          <FunctionField
            label="Status"
            render={renderStatus}
            sortBy="account.active"
          />
        </Datagrid>
      </List>
      <Route path={"/users/:id"}>
        {({ match }) => {
          return (
            <Drawer
              open={!!match}
              anchor="right"
              onClose={() => {
                props.push("/users");
                refresh();
              }}
            >
              {match && (
                <DrawerContent
                  onClose={() => {
                    props.push("/users");
                    refresh();
                  }}
                >
                  <UserEdit
                    id={match.params.id}
                    onCancel={() => {
                      props.push("/users");
                      refresh();
                    }}
                    {...props}
                  />
                </DrawerContent>
              )}
            </Drawer>
          );
        }}
      </Route>
    </React.Fragment>
  );
};

export const UserList = connect(undefined, { push })(_UserList);
