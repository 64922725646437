import axios, { AxiosRequestConfig } from "axios";

const URL = process.env.REACT_APP_AUTH_URL;
const DEFAULT_HEADERS = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

if (!URL) throw new Error("REACT_APP_AUTH_URL is nil");

function generateHeaders(token?: string): Record<string, string> {
  return token
    ? { ...DEFAULT_HEADERS, Authorization: `Bearer ${token}` }
    : DEFAULT_HEADERS;
}

async function withSafety(url: string, config: AxiosRequestConfig) {
  const response = await axios(url, config);
  return response.status === 204 ? {} : response.data;
}

export async function post(
  path: string,
  data: any,
  token?: string
): Promise<any> {
  return await withSafety(`${URL}${path}`, {
    method: "POST",
    headers: generateHeaders(token),
    withCredentials: true,
    data: data,
  });
}

export async function get(path: string, token?: string): Promise<any> {
  return await withSafety(`${URL}${path}`, {
    method: "GET",
    headers: generateHeaders(token),
    withCredentials: true,
  });
}
