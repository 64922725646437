import React from "react";
import moment from "moment";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

import { Story } from "../../../types/generated/gql";

export const ViolationsTable = (props: any) => {
  const { record } = props;
  const story = record as Story;

  return story?.story_violations?.length > 0 ? (
    <Box mt={2} mb={2}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell align="right">Action</TableCell>
              <TableCell align="right">User ID</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {story?.story_violations?.map((violation: any) => (
              <TableRow key={violation.id}>
                <TableCell component="th" scope="row">
                  {violation.created_at &&
                    moment(violation.created_at).format("l")}
                </TableCell>
                <TableCell align="right">REPORTED</TableCell>
                <TableCell align="right">{violation.user_id}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  ) : (
    <div />
  );
};
